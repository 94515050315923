import { createSlice } from '@reduxjs/toolkit';

export const webviewDataSlice = createSlice({
  name: 'webviewData',
  initialState: { accessToken: '', refreshToken: '' },
  reducers: {
    updateToken(state, action) {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    expireToken(state) {
      state.accessToken = '';
      state.refreshToken = '';
    },
  },
});

export const { updateToken, expireToken } = webviewDataSlice.actions;

export default webviewDataSlice.reducer;
