/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SubscriptionChannel from '@component/app/profile/SubscriptionChannel';
import ActivityBadge from '@component/app/profile/ActivityBadge';
import StocksOwned from '@component/app/profile/StocksOwned';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ProfileInfo from '@component/app/common/ProfileInfo';
import Activity from '@component/app/profile/Activity';
import HeaderBasic from '@component/app/common/HeaderBasic';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFollow, getProfile } from '@lib/api-call/app/profileApi';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { setClickedWrittenPostId } from '@store/app/postReducer';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const PeopleProfile = () => {
  const navigate = useNavigate();
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const { state: routerState, hash } = useLocation();

  const [value, setValue] = useState(hash ? Number(hash.replace('#', '')) : 0);
  const [otherProfile, setOtherProfile] = useState({});
  const [tabList, setTabList] = useState(['구독', '배지', '투자 중', '쓴글']);
  const { userId } = useParams(); // 쿼리 호출
  const dispatch = useDispatch();

  const onOtherProfileGet = async () => {
    if (!userId) return;
    if (userId === (loginedUser?.user_id || loginedUser?.USER_ID)) {
      navigate('/my-profile', { replace: true });
      return;
    }
    const otherProfileData = await getProfile({ profileId: userId });
    // const myProfileData = await getProfile();
    setOtherProfile({ ...otherProfileData });
    if (Number(otherProfileData.user_type) !== 0) {
      setTabList(['게시글', '댓글', '반응']);
    }
  };

  // const handleChange = (event, newValue) => {
  //   routePush(newValue);
  //   setValue(newValue);
  // };

  const routePush = path => {
    navigate(`/profile/${encodeURIComponent(userId)}#${path}`, {
      replace: true,
      state: { prevLink: routerState?.prevLink || undefined },
    });
  };

  const updateFollow = async () => {
    const updateStatus = await getFollow({
      followingId: userId,
    });

    if (updateStatus.message_cd !== '0') return;
    otherProfile.isFollowed = otherProfile.isFollowed === '0' ? '1' : '0';
    setOtherProfile({ ...otherProfile });
  };

  useEffect(() => {
    onOtherProfileGet();
  }, []);

  useEffect(() => {}, []);

  // 탭 클릭 시  실행
  const tabClick = (event, newValue) => {
    if (newValue === 0) {
      window.GA_Event('커뮤니티', '타인 프로필', '구독');
    } else if (newValue === 1) {
      window.GA_Event('커뮤니티', '타인 프로필', '배지');
    } else if (newValue === 2) {
      window.GA_Event('커뮤니티', '타인 프로필', '투자 중');
    } else if (newValue === 3) {
      window.GA_Event('커뮤니티', '타인 프로필', '쓴글');
    }
    setValue(newValue);
    routePush(newValue);
  };
  const isProfileOfOthers = true;
  // const tabList = ['구독', '배지', '투자중', '쓴글'];

  return (
    <div
      css={css`
        height: cacl(100dvh - ${stringToVW('60px')});
      `}
    >
      <HeaderBasic
        title={otherProfile.user_nm && `${otherProfile.user_nm}의 프로필`}
        statusUser="general"
        reportData={{
          report_type: '1',
          // report_id: loginedUser.
          user_id: encodeURIComponent(otherProfile.user_id),
        }}
        backLink={
          routerState && routerState.prevLink ? routerState.prevLink : -1
        }
        backButtonClickListener={() => {
          dispatch(setClickedWrittenPostId(undefined));
        }}
      />
      <div
        css={css`
          padding: ${stringToVW('0 16px')};
        `}
      >
        <ProfileInfo
          profile="people"
          userId={otherProfile.user_id}
          isProfileOfOthers={isProfileOfOthers}
          profileAvatarImg={otherProfile.profile_img}
          follower={otherProfile.follower}
          following={otherProfile.following}
          intro={otherProfile.profile_introduction}
          name={otherProfile.user_nm}
          isFollowed={otherProfile.isFollowed}
          updateFollow={updateFollow}
          totalFollowerAssets={otherProfile.total_follower_assets}
          userType={otherProfile.user_type}
        />
      </div>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            borderBottom: 1.5,
            borderColor: 'divider',
            // margin: stringToVW('0 -15px'),
          }}
        >
          <Tabs
            sx={{ transform: 'translateY(1.5px)' }}
            value={value}
            onChange={tabClick}
            aria-label="basic tabs example"
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'black',
                fontSize: 600,
              },
            }}
          >
            {tabList.map((item, index) => (
              <Tab
                css={css`
                  ${globalStyle.fontNanu21}
                  font-weight: 400;
                  letter-spacing: 0em;
                  text-align: center;
                  margin-top: ${stringToVW('13px')};
                  opacity: 1;
                `}
                sx={{
                  color: '#999',
                  '&.Mui-selected': {
                    fontWeight: 600,
                    color: '#000',
                  },
                }}
                label={item}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={tabList.indexOf('구독')}>
          <SubscriptionChannel
            isProfileOfOthers={isProfileOfOthers}
            userId={userId}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('배지')}>
          <ActivityBadge
            isProfileOfOthers={isProfileOfOthers}
            badgeYn={otherProfile.badge_open_yn}
            userId={userId}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('투자 중')}>
          <StocksOwned
            isProfileOfOthers={isProfileOfOthers}
            userId={userId}
            stockYn={otherProfile.stock_info_yn}
            investingOpenYn={otherProfile.investing_open_yn}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('쓴글')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            userId={userId}
            userNm={otherProfile.user_nm}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('게시글')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            userId={userId}
            tabFix="write"
            tabCorverStatus={false}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('댓글')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            userId={userId}
            userNm={otherProfile.user_nm}
            tabFix="comment"
            tabCorverStatus={false}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('반응')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            userNm={otherProfile.user_nm}
            userId={userId}
            tabFix="reaction"
            tabCorverStatus={false}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default PeopleProfile;
