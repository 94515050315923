import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconEllipsisVertical } from '@asset/icons/common/icon-ellipsis-vertical.svg';
import { useNavigate } from 'react-router-dom';
import { stringToVW } from '@style/globalStyle';
import BackButton from './BackButton';
import Backdrop from '@mui/material/Backdrop';

const HeaderBasic = ({
  backLink,
  title,
  statusUser,
  complete,
  saveEvent,
  reportData,
  backButtonClickListener = undefined,
}) => {
  const [isShow, SetIsShow] = useState(false);
  const navigate = useNavigate();
  const styles = {
    wrapper: css`
      font-family: NanumBarunGothic;
      font-style: normal;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
    `,
    title: css`
      color: #000;
      text-align: center;
      font-size: ${stringToVW('16px')};
      font-weight: 400;
    `,
    wrapperPopup: css`
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      margin: 0 auto;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
    `,
    popup: css`
      z-index: 10;
      display: flex;
      gap: ${stringToVW('14px')};
      align-items: center;
      border-radius: ${stringToVW('10px')};
      background: #f7f7f7;
      color: #000;
      font-size: ${stringToVW('14px')};
      font-weight: 400;
      line-height: ${stringToVW('32px')};
      padding: ${stringToVW('9.5px 16px')};
      right: ${stringToVW('18px')};
      top: ${stringToVW('52px')};
      position: absolute;
      text-decoration-line: none;
      > #프로필 {
        min-width: ${stringToVW('112px')};
      }
    `,
  };

  return (
    <>
      <div
        css={css`
          height: ${stringToVW('40px')};
        `}
      />

      <div css={styles.wrapper}>
        <div
          css={css`
            height: ${stringToVW('40px')};
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: ${stringToVW('9.3px 16px')};
          `}
        >
          <BackButton
            // backLink={backLink}
            backButtonClickListener={backButtonClickListener}
          />

          <div css={styles.title}>
            {/*&#123;username&#125; 의 프로필*/}
            {title}
          </div>
          <div
            css={css`
              min-width: ${stringToVW('22.75px')};
            `}
          >
            {statusUser !== '' && statusUser === 'company' ? (
              <div
                css={css`
                  color: var(--nm-primary-246022, #84c13d);
                  font-size: ${stringToVW('14px')};
                  font-weight: 600;
                  line-height: ${stringToVW('24px')};
                `}
                onClick={() => saveEvent()}
              >
                완료
              </div>
            ) : statusUser !== '' && statusUser === 'general' ? (
              <div
                css={css`
                  cursor: pointer;
                  position: relative;
                  display: flex;
                  align-items: end;
                `}
              >
                <IconEllipsisVertical
                  onClick={() => SetIsShow(!isShow)}
                  style={{ width: stringToVW('22.7px') }}
                />
                {isShow ? (
                  <Backdrop
                    sx={{
                      color: '#000',
                      opacity: 0.4,
                      zIndex: theme => theme.zIndex.drawer + 1,
                    }}
                    open={true}
                    onClick={() => SetIsShow(false)}
                  >
                    <Link
                      to={{
                        pathname: '/report',
                        search: `${new URLSearchParams(reportData).toString()}`,
                      }}
                      css={styles.popup}
                    >
                      <span
                        style={{
                          width: stringToVW('21px'),
                          height: stringToVW('21px'),
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 1.62496C5.11152 1.62496 1.95833 4.77815 1.95833 8.66663C1.95833 12.5551 5.11152 15.7083 9 15.7083C12.8893 15.7083 16.0417 12.5551 16.0417 8.66663C16.0417 4.7781 12.8893 1.62496 9 1.62496ZM0.625 8.66663C0.625 4.04177 4.37514 0.291626 9 0.291626C13.6257 0.291626 17.375 4.04182 17.375 8.66663C17.375 13.2914 13.6257 17.0416 9 17.0416C4.37514 17.0416 0.625 13.2915 0.625 8.66663Z"
                            fill="#999999"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.99601 4.83655C9.3642 4.83655 9.66268 5.13502 9.66268 5.50321V9.18571C9.66268 9.5539 9.3642 9.85238 8.99601 9.85238C8.62782 9.85238 8.32935 9.5539 8.32935 9.18571V5.50321C8.32935 5.13502 8.62782 4.83655 8.99601 4.83655Z"
                            fill="#999999"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.3291 11.8301C8.3291 11.4619 8.62758 11.1634 8.99577 11.1634H9.0041C9.37229 11.1634 9.67077 11.4619 9.67077 11.8301C9.67077 12.1982 9.37229 12.4967 9.0041 12.4967H8.99577C8.62758 12.4967 8.3291 12.1982 8.3291 11.8301Z"
                            fill="#999999"
                          />
                        </svg>
                      </span>
                      <span id="프로필">프로필 신고</span>
                    </Link>
                  </Backdrop>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBasic;
