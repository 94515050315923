import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { NAMU_BTN_COLOR } from '@data/constants';
import { globalStyle, toVW, stringToVW } from '@style/globalStyle';

/**
 * size : xl, lg, sm, xs
 */
const NamuButton = ({
  children,
  onClick = e => {},
  chipBtn,
  size = 'md',
  color = NAMU_BTN_COLOR.DARK_GRAY_BLUE,
  disabled = false,
}) => {
  const theme = useTheme();

  const defaultStyle = css`
    border: none;
    border-radius: ${chipBtn
      ? theme.nh.borderRadius.pill
      : toVW('6.67px')}; //theme.nh.borderRadius.sm};
  `;

  const colorStyles = {
    [NAMU_BTN_COLOR.DARK_GRAY_BLUE]: css`
      background: #4e5255;
      color: #fff;
      &:active {
        background: ${theme.nh.colors.essentialGrayscale[800]};
      }
    `,
    [NAMU_BTN_COLOR.STRONG_GRAY]: css`
      background: #999;
      color: #fff;
      &:active {
        background: ${theme.nh.colors.defalutGrayscale[500]};
      }
    `,
    [NAMU_BTN_COLOR.GRAY]: css`
      background: #f1f1f1;
      color: #666;
      &:active {
        background: ${theme.nh.colors.defalutGrayscale[100]};
      }
    `,
    [NAMU_BTN_COLOR.BLUE_GRAY]: css`
      background: #ebedf1;
      color: #666;
      &:active {
        background: ${theme.nh.colors.defalutGrayscale[100]};
      }
    `,
    [NAMU_BTN_COLOR.OUTLINE]: css`
      border: 1px solid #e0e0e0;
      background: #fff;
      color: #666;
      &:active {
        background: ${theme.nh.colors.defalutGrayscale[24]};
      }
    `,
    [NAMU_BTN_COLOR.NO_BORDER]: css`
      border: none;
      background: none;
      color: #3c9800;
      padding: 0 0;
      font-weight: 600;
      &:disabled {
        color: #c5c5c5;
      }
    `,
  };
  return (
    <button
      type="button"
      css={[defaultStyle, getSizeStyle(size), colorStyles[color]]}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const defaultPadding = stringToVW('8.7px 13.33px');

const getSizeStyle = size => {
  switch (size) {
    case 'xl':
      return css`
        height: ${toVW('60px')};
        padding: ${defaultPadding};
        ${globalStyle.fontNanu21}
        line-height: normal;
      `;
    case 'lg':
      return css`
        height: ${toVW('54px')};
        padding: ${defaultPadding};
        ${globalStyle.fontNanu21};
        line-height: normal;
      `;
    case 'md':
      return css`
        min-width: ${toVW('80px')};
        height: ${toVW('30.67px')};
        padding: ${defaultPadding};
        ${globalStyle.fontNanu18}
        line-height: normal;
      `;
    case 'sm':
      return css`
        height: ${toVW('40px')};
        padding: ${defaultPadding};
        ${globalStyle.fontNanu18}
        line-height: normal;
      `;
    case 'xs':
      return css`
        height: ${toVW('32px')};
        padding: ${stringToVW('15px 14px 13px 14px')};
        ${globalStyle.fontNanu15}
        line-height: normal;
      `;
    case 'nonePadding':
      return css`
        min-width: ${toVW('70px')};
        min-height: ${toVW('30px')};
        ${globalStyle.fontNanu18}
        line-height: normal;
      `;
    default:
      return css`
        height: ${toVW('46px')};
        padding: ${stringToVW('12px 20px 12px 20px')};
        font-size: ${toVW('18px')};
        line-height: normal;
      `;
  }
};

export default NamuButton;
