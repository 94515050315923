import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import IconLikeXl from '@asset/icons/post/icon_like_xl.svg';
import IconLikeActiveXl from '@asset/icons/post/icon_like_active_xl.svg';
import IconDislikeXl from '@asset/icons/post/icon_dislike_xl.svg';
import IconDislikeActiveXl from '@asset/icons/post/icon_dislike_active_xl.svg';

import IconLikeMd from '@asset/icons/post/icon_like_md.svg';
import IconLikeActiveMd from '@asset/icons/post/icon_like_active_md.svg';
import IconDislikeMd from '@asset/icons/post/icon_dislike_md.svg';
import IconDislikeActiveMd from '@asset/icons/post/icon_dislike_active_md.svg';

import IconLikeSm from '@asset/icons/post/icon_like_sm.svg';
import IconLikeActiveSm from '@asset/icons/post/icon_like_active_sm.svg';
import IconDislikeSm from '@asset/icons/post/icon_dislike_sm.svg';
import IconDislikeActiveSm from '@asset/icons/post/icon_dislike_active_sm.svg';
import { useState, useEffect } from 'react';
import { toVW } from '@style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { setBlockPopupIsShow } from '@store/app/commonReducer';
const LikeButton = ({
  type = TYPE_LIKE,
  item,
  size = 'sm',
  isActive = false,
  clickHandler,
}) => {
  const DefaultIcon = iconMap[size][type].default;
  const ActiveIcon = iconMap[size][type].active;

  const [isLoading, setIsLoading] = useState(false);
  const [iconWidth, setIconWidth] = useState(toVW('16px'));
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const dispatch = useDispatch();

  const onClickHandler = async e => {
    if (loginedUser && loginedUser.USER_STAT_CD === '1') {
      dispatch(setBlockPopupIsShow(true));
      return;
    }
    setIsLoading(true);
    try {
      await clickHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let iconSize;
    switch (size) {
      case SIZE_XL:
        iconSize = toVW('26.7px');
        break;
      default:
        iconSize = toVW('18px');
        break;
    }

    setIconWidth(iconSize);
  }, [size]);

  return (
    <button
      type="button"
      disabled={isLoading}
      css={css`
        padding: 0;
        height: ${iconWidth};
        border: none;
        background: none;
        cursor: ${clickHandler ? 'pointer' : 'default'};
      `}
      onClick={clickHandler ? onClickHandler : undefined}
    >
      <img
        alt="action-button"
        src={!isActive ? DefaultIcon : ActiveIcon}
        css={css`
          width: ${iconWidth};
          height: ${iconWidth};
        `}
      />
    </button>
  );
};

LikeButton.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  size: PropTypes.string,
  isActive: PropTypes.bool,
  clickHandler: PropTypes.func,
};

const iconMap = {
  xl: {
    like: { default: IconLikeXl, active: IconLikeActiveXl },
    dislike: { default: IconDislikeXl, active: IconDislikeActiveXl },
  },
  md: {
    like: { default: IconLikeMd, active: IconLikeActiveMd },
    dislike: { default: IconDislikeMd, active: IconDislikeActiveMd },
  },
  sm: {
    like: { default: IconLikeSm, active: IconLikeActiveSm },
    dislike: {
      default: IconDislikeSm,
      active: IconDislikeActiveSm,
    },
  },
};

export const TYPE_LIKE = 'like';
export const TYPE_DISLIKE = 'dislike';
export const SIZE_XL = 'xl';
export const SIZE_MD = 'md';
export const SIZE_SM = 'sm';

export default LikeButton;
