/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import Header from '@component/admin/common/Header';
import { DatePicker, Select, Input, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getMemberList } from '@lib/api-call/admin/memberApi';
import { Alert } from '@mui/material';
import { toNumber } from 'lodash';
import dayjs from 'dayjs';

// 숫자에 천 단위 쉼표 추가하는 함수
function formatNumberWithCommas(number) {
  return toNumber(number).toLocaleString();
}

function getOneMonthAgoToNow() {
  // 1달전
  const oneMonthAgo = dayjs().subtract(1, 'month').format('YYYYMMDD');

  // 현재 날짜
  const currentDate = dayjs().format('YYYYMMDD');

  return [oneMonthAgo, currentDate];
}

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'idx',
  },

  {
    title: '타입',
    dataIndex: 'user_type_nm',
    key: 'user_type',
  },
  // {
  //   title: '아이디',
  //   dataIndex: 'user_id',
  //   key: 'user_email',
  // },
  {
    title: '닉네임',
    dataIndex: 'user_nm',
    key: 'user_nickname',
  },
  {
    title: '회원 상태',
    dataIndex: 'user_stat_nm',
    key: 'status',
  },
  {
    title: '가입일',
    dataIndex: 'join_dt',
    key: 'date',
  },
];

const dataOptionSelect = [
  {
    value: '0',
    label: '서비스 이용중',
  },
  {
    value: '1',
    label: '서비스 이용중지',
  },
  {
    value: '2',
    label: '서비스 탈퇴',
  },
];

const dataUserTypeOptionSelect = [
  {
    value: '0',
    label: '일반 회원',
  },
  {
    value: '1',
    label: '기업 회원',
  },
];

const MessageBox = ({ type, message, onClose }) => {
  return (
    <Alert variant="filled" severity={type} onClose={onClose}>
      {message}
    </Alert>
  );
};

const UserManage = () => {
  const navigate = useNavigate();
  const [alertCtrl, setAlertCtrl] = useState({
    type: 'info',
    display: false,
    message: '',
  });

  const [userList, setUserList] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  //search
  const [joinDate, setJoinDate] = useState(getOneMonthAgoToNow());
  const [userStatus, setUserStatus] = useState(null);
  const [userType, setUserType] = useState(null);
  const [nickName, setNickName] = useState('');

  const onUserListGet = async () => {
    setLoading(true);
    const userData = await getMemberList({
      p_start: page * 10,
      p_from_dt: joinDate[0],
      p_to_dt: joinDate[1],
      p_user_stat_cd: userStatus,
      p_length: 10,
      p_nickname: nickName,
      p_user_type: userType,
    });
    if (userData.message_cd !== '0') {
      // navigate('/admin');
    }

    setUserList(userData.urUserList);
    setTotalUserCount(userData.total_count);
    setTimeout(() => {
      setLoading(false);
    });
  };

  const alertClose = () => {
    setAlertCtrl({ type: 'info', display: false, message: '' });
  };

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const dateChange = (dates, dateStrings) => {
    setJoinDate(dates ? dateStrings : []);
  };

  const statusChange = (key, values) => {
    setUserStatus(key || null);
  };

  const statusUserTypeChange = (key, values) => {
    setUserType(key || null);
  };

  const onSearch = () => {
    if (page === 0) {
      onUserListGet();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    onUserListGet();
  }, []);

  useEffect(() => {
    onUserListGet();
  }, [page]);

  const dataProp = [
    {
      title: '회원 관리',
      href: '/admin/user-manage',
    },
    {
      title: '회원 목록',
      href: '',
    },
  ];

  return (
    <>
      <div>
        <Header dataProp={dataProp} />
        <div className="wrapper">
          <div className="flex gap-16">
            <RangePicker
              value={joinDate.map(x => dayjs(x))}
              format="YYYYMMDD"
              onChange={dateChange}
              allowClear={false}
            />
            <Select
              // defaultValue="회원 상태"
              placeholder="회원 상태"
              style={{ width: 140, height: 42 }}
              onChange={statusChange}
              options={dataOptionSelect.map(item => ({
                value: item.value,
                label: item.label,
                disabled: item.disabled,
              }))}
              allowClear
            />
            <Select
              placeholder="회원 타입"
              style={{ width: 140, height: 42 }}
              onChange={statusUserTypeChange}
              options={dataUserTypeOptionSelect.map(item => ({
                value: item.value,
                label: item.label,
                disabled: item.disabled,
              }))}
              allowClear
            />
            <Input
              onChange={e => setNickName(e.target.value)}
              onKeyDown={e => {
                // 엔터 키를 눌렀을 때 onSearch 호출
                if (e.key === 'Enter') {
                  onSearch();
                }
              }}
              css={css`
                width: 421px;
              `}
              placeholder="닉네임/해쉬키값을 입력해주세요."
              allowClear
            />
            <SearchOutlined className="style-search" onClick={onSearch} />
          </div>
          <div
            css={css`
              padding-top: 24px;
            `}
          >
            <div className="bg-title-table">
              <p className="text-title-table">
                총 회원 &#123;{formatNumberWithCommas(totalUserCount)}&#125;{' '}
              </p>
            </div>
            <Table
              className="ant-table"
              columns={columns}
              dataSource={userList}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    navigate(
                      `/admin/user-detail/${encodeURIComponent(
                        record.user_id,
                      )}`,
                    );
                  },
                };
              }}
              pagination={false}
              loading={loading}
            ></Table>
            <Pagination
              current={page + 1}
              defaultCurrent={1}
              total={totalUserCount || 1}
              showSizeChanger={false}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '16px 0',
              }}
              onChange={(page, pageSize) => pageChange(page)}
            />
          </div>
        </div>
      </div>
      {alertCtrl.display && (
        <MessageBox
          type={alertCtrl.type}
          message={alertCtrl.message}
          onClose={alertClose}
        />
      )}
    </>
  );
};

export default UserManage;
