import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { css } from '@emotion/react';

import { ReactComponent as CheckIcon } from '@asset/icons/common/check-icon.svg';
import { ReactComponent as CheckedIcon } from '@asset/icons/common/check-icon-checked.svg';
import { globalStyle, stringToVW } from '@style/globalStyle';

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: css`
          color: #333;
          ${globalStyle.fontNanu21}
        `,
      },
    },
  },
});

const Checkbox = ({ label, checked = false, onChange = () => {} }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <MuiCheckbox
            sx={{
              mb: '2px',
              padding: stringToVW('0px 6.7px 0px 0px'),
            }}
            icon={<CheckIcon />}
            checkedIcon={<CheckedIcon />}
            checked={checked}
            onChange={onChange}
          />
        }
        label={label}
      />
    </ThemeProvider>
  );
};

export default Checkbox;
