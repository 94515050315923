import React, { useEffect, useRef, useState } from 'react';

import CommentItem from './CommentItem';
import { isEmpty } from 'lodash';

const CommentWrapper = ({ item }) => {
  const { postCommentPeplyList } = item;

  const [replyOpen, setReplyOpen] = useState(false);

  const prevValueRef = useRef(undefined);
  const [isChangeReply, setIsChangeReply] = useState(false);

  const clickReplyOpen = open => {
    setReplyOpen(open || !replyOpen);
  };

  useEffect(() => {
    if (
      prevValueRef.current &&
      prevValueRef.current.length < postCommentPeplyList.length
    ) {
      setReplyOpen(true);
      setIsChangeReply(true);
    }
    prevValueRef.current = postCommentPeplyList;
  }, [postCommentPeplyList]);

  return (
    <div>
      <CommentItem
        item={item}
        replyOpen={replyOpen}
        clickReplyOpen={clickReplyOpen}
      />
      {!isEmpty(postCommentPeplyList) &&
        replyOpen &&
        postCommentPeplyList.map((item, idx) => {
          const isReplyLast = postCommentPeplyList.length === idx + 1;

          return (
            <CommentItem
              key={`comment_${item.comment_id}_${item.comment_reply_id}`}
              item={item}
              isReply
              isReplyLast={isReplyLast}
              isChangeReply={isReplyLast ? isChangeReply : undefined}
              setIsChangeReply={isReplyLast ? setIsChangeReply : undefined}
            />
          );
        })}
    </div>
  );
};

CommentWrapper.propTypes = {};

export default CommentWrapper;
