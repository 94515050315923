import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getTop10List } from '@lib/api-call/app/searchApi';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode } from 'swiper/modules';

import channelDefaultImgKOR from '@asset/images/channel/channel-default-image-KOR.png';
import channelDefaultImgENG from '@asset/images/channel/channel-default-image-ENG.png';
import channelDefaultImg from '@asset/images/channel/channel-default-image.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalStyle, stringToVW, ratio } from '@style/globalStyle';
import { getUploadImage } from '@lib/commonUtils';

const countryChnlImage = country => {
  if (country === 'KOR') return channelDefaultImgKOR;
  else if (country === 'USA') return channelDefaultImgENG;
  else return channelDefaultImg;
};

const ChannelList = () => {
  const [top10List, setTop10list] = useState([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // mounted
  useEffect(() => {
    (async () => {
      // top10 목록 호출
      const top10ListData = await getTop10List();
      setTop10list(top10ListData);
    })();
  }, []);

  return (
    <div
      css={css`
        margin-top: ${stringToVW('24px')};
        margin-bottom: ${stringToVW('28px')};
      `}
    >
      <div>
        <div
          css={css`
            ${globalStyle.fontNanu24}
            font-weight: 600;
            letter-spacing: 0em;
            text-align: left;
            padding-bottom: ${stringToVW('10.67px')};
          `}
        >
          이런 채널도 있어요
        </div>
        <Swiper
          style={{
            width: `calc(100% + ${stringToVW('30px')})`,
            margin: stringToVW('0px -15px'),
            padding: stringToVW('0px 15px'),
          }}
          loop={false}
          slidesPerView="auto"
          spaceBetween={8 * ratio}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode]}
        >
          {top10List.map((car, index) => (
            <SwiperSlide
              style={{
                width: stringToVW('60px'),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: stringToVW('8px'),
              }}
              key={index}
              onClick={() =>
                navigate(`/channel/${car.stock_cd}/${car.channel_id}`, {
                  state: { prevLink: pathname },
                })
              }
            >
              <img
                css={css`
                  width: ${stringToVW('53.3px')};
                  height: ${stringToVW('53.3px')};
                  border-radius: 50%;
                `}
                src={
                  (car.thumb_url && getUploadImage(car.thumb_url)) ||
                  countryChnlImage(car.country_cd)
                }
                onError={e => (e.target.src = countryChnlImage(car.country_cd))}
                alt=""
              />
              <div
                css={css`
                  width: 100%;
                  ${globalStyle.fontNanu18}
                  color: #333;
                  text-align: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                `}
              >
                {car.channel_nm}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ChannelList;
