import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

import { stringToVW, toVW } from '@style/globalStyle';

const Popup = ({
  open,
  onClose,
  data,
  handleClick,
  confirmClick,
  cancelButton = true,
  cancelText,
  cancelEvent,
  confirmButton = true,
  confirmText,
  confirmEvent,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      slots={{ backdrop: Backdrop }}
      PaperProps={{
        sx: {
          borderRadius: toVW('10.7px'),
          width: '100%',
          margin: toVW('16px'),
          backgroundColor: 'unset',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          },
        },
      }}
    >
      <DialogContent
        sx={{
          padding: stringToVW('33.5px 20px'),
          backgroundColor: '#fff',
          borderRadius: stringToVW('10.7px 10.7px 0px 0px'),
        }}
      >
        <Stack justifyContent="center" sx={{ gap: toVW('13.5px') }}>
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              fontSize: toVW('17px'),
              letterSpacing: 0,
            }}
          >
            {data.title}
          </Box>
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              fontSize: toVW('14px'),
              letterSpacing: 0,
            }}
          >
            {data.content}
            {/* {data.content.map((item, index) => (
            <div css={styles.contentPopup} key={index}>
              {item}
            </div>
          ))} */}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: '0',
          height: toVW('50px'),
          background: 'none',
        }}
      >
        {cancelButton && (
          <Button
            onClick={() => {
              onClose();
              cancelEvent && cancelEvent();
            }}
            fullWidth
            disableRipple
            disableFocusRipple
            sx={{
              bgcolor: '#e0e0e0',
              height: '100%',
              fontSize: toVW('14px'),
              lineHeight: toVW('25.5px'),
              fontWeight: 400,
              borderRadius: 0,
              color: '#000',
              '&.MuiButtonBase-root:hover': { bgcolor: '#e0e0e0' },
            }}
          >
            {cancelText}
          </Button>
        )}
        {confirmButton && (
          <Button
            onClick={() => {
              onClose();
              confirmEvent && confirmEvent();
            }}
            fullWidth
            disableRipple
            disableFocusRipple
            sx={{
              bgcolor: '#84c13d',
              borderRadius: 0,
              height: '100%',
              fontSize: toVW('14px'),
              lineHeight: toVW('25.5px'),
              fontWeight: 400,
              color: '#fff',
              fontFamily: 'NanumBarunGothic',
              margin: '0 !important',
              '&.MuiButtonBase-root:hover': { bgcolor: '#84c13d' },
            }}
          >
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default Popup;
