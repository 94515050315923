/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import ProfileAvatar from '../user/ProfileAvatar';
import { ReactComponent as KebabIcon } from '@asset/icons/common/nds_icon_kebab.svg';
import LikeButton, { SIZE_MD, TYPE_DISLIKE, TYPE_LIKE } from './LikeButton';
import { isEmpty } from 'lodash';
import { getUploadImage } from '@lib/commonUtils';
import { noDecoraionLink } from '@style/common/commonStyles';
import { Link, useLocation } from 'react-router-dom';
import { elapsedTime } from '@lib/dateTimeUtils';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconCerti } from '@asset/icons/common/icon-certification.svg';

import {
  updateCommentReply,
  updatePostComment,
  updateReplyTargetComment,
} from '@store/app/postReducer';
import {
  DISLIKE_ACTION,
  DISLIKE_DELETE_ACTION,
  LIKE_ACTION,
  LIKE_DELETE_ACTION,
  updateReactionComment,
  updateReactionReply,
} from '@lib/api-call/app/loungeApi';
import { deleleFeedData } from '@lib/api-call/app/commApi';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { globalStyle, stringToVW, ratio, toVW } from '@style/globalStyle';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

function CommentItem({
  item,
  replyOpen,
  clickReplyOpen,
  isReply,
  isReplyLast,
  isChangeReply,
  setIsChangeReply,
}) {
  const {
    user_id,
    user_nm,
    contents,
    profile_img,
    liked,
    disliked,
    like_count,
    dislike_count,
    postCommentPeplyList,
    reg_dt,
  } = item;
  const { betweenTime, postFix } = elapsedTime(reg_dt);

  const commentInputHeight = useSelector(
    state => state.post.commentInputHeight,
  );

  const loginedUser = useSelector(state => state.auth.loginedUser);

  const [isLoading, setIsLoading] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [blockMsg, setBlockMsg] = useState('');
  const [type, setType] = useState('');

  const ref = useRef();
  const menuBtnRef = useRef();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const styles = {
    container: css`
      display: flex;
      padding: ${!isReply
        ? stringToVW('16px 16px')
        : stringToVW('16px 24px 16px 80px')};

      align-items: flex-start;
      gap: ${stringToVW('16px')};
      align-self: stretch;
      background: #fff;
    `,
    borderBottom: css`
      border-bottom: 1px solid var(--essential-line-line-200025, #f0f0f0);
    `,
    borderBottomNone: css`
      border-bottom: none;
    `,
    commentInfo: css`
      display: flex;
      padding: ${stringToVW('2px 0px')};
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: ${stringToVW('4px')};
      flex: 1 0 0;
      & > * {
        align-self: stretch;
      }
    `,
    commentTop: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: ${stringToVW('10px')};
      padding-right: ${stringToVW('6px')};
      height: ${toVW('34px')};
    `,
    commentWriter: css`
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      ${globalStyle.fontNanu19}
      font-weight: 600;
    `,
    commentContent: css`
      white-space: pre-wrap;
      color: #2c363b;
      ${globalStyle.fontNanu21}
      word-break: break-all;

      & > a {
        color: #247ef2;
        text-decoration: none;
      }
    `,
    commentBottom: css`
      display: flex;
      padding: ${stringToVW('4px 6px 0px 0px')};
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: ${stringToVW('16px')};
    `,
    replyWriteBtn: css`
      margin-right: ${stringToVW('8px')};
      cursor: pointer;
      color: #7d7d7d;
      ${globalStyle.fontNanu18}
      font-weight: 600;

      &:hover {
        background: none;
      }
    `,
    writeTime: css`
      display: flex;
      align-items: center;
      width: ${stringToVW('110px')};
      color: #c5c5c5;

      & > div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      & > div:nth-of-type(1) {
        ${globalStyle.fontRobo18}
      }

      & > div:nth-of-type(2) {
        flex: 1 0 0;
        ${globalStyle.fontNanu18}
      }
    `,
    like: css`
      display: flex;
      align-items: center;
      gap: ${stringToVW('4px')};

      & > div:nth-of-type(1) {
        align-self: stretch;

        color: #7d7d7d;
        ${globalStyle.fontRobo19}
      }
    `,
    replyOpenText: css`
      display: flex;
      padding: 0px;
      align-items: center;
      gap: ${stringToVW('3px')};
      flex: 1 0 0;

      color: #3c9800;
    `,
    replyLength: css`
      ${globalStyle.fontRobo18}
    `,
    replyBtnTxt: css`
      padding-top: ${stringToVW('1px')};
      ${globalStyle.fontNanu18}
      font-weight: 400;
    `,
    wrapperPopup: css`
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      margin: 0 auto;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
    `,
    popup: css`
      display: inline-flex;
      gap: ${stringToVW('14px')};
      align-items: flex-start;
      border-radius: ${stringToVW('10px')};
      background: #f7f7f7;
      color: #000;
      font-size: ${stringToVW('21px')};
      font-weight: 400;
      line-height: ${stringToVW('32px')};
      padding: ${stringToVW('16px 24px')};
      // right: ${stringToVW('40px')};
      // bottom: ${stringToVW('70px')};
      // position: absolute;
      text-decoration-line: none;
      flex-direction: column;
      margin: ${stringToVW('6px 0px')};
    `,
    popupText: css`
      text-decoration-line: none;
      font-size: ${stringToVW('14px')};
      font-weight: 400;
      line-height: ${stringToVW('21.33px')};
      color: #000;
      display: inline-flex;
      gap: ${stringToVW('14px')};
      align-items: flex-start;
    `,
    flexRow: css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    flexRowEnd: css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `,
  };

  const handleReactionBtn = async type => {
    const updateItem = { ...item };
    const param = {};
    let actionCall;
    let updateAction;

    if (!isReply) {
      //댓글일때
      param.comment_id = item.comment_id;
      actionCall = updateReactionComment;
      updateAction = updatePostComment;
    } else {
      //답글일때
      param.comment_reply_id = item.comment_reply_id;
      actionCall = updateReactionReply;
      updateAction = updateCommentReply;
    }

    if (type === 'like') {
      window.GA_Event('커뮤니티', '공통', '댓글 추천');
      if (liked === 'Y') {
        param.react = LIKE_DELETE_ACTION;
        const { message_cd, count } = await actionCall(param);

        if (message_cd !== '0') {
          return;
        }

        updateItem.liked = 'N';
        updateItem.like_count = count || 0;
      } else {
        if (disliked === 'Y') {
          param.react = DISLIKE_DELETE_ACTION;
          const { message_cd, count } = await actionCall(param);

          if (message_cd !== '0') {
            return;
          }

          updateItem.disliked = 'N';
          updateItem.dislike_count = count || 0;
        }

        param.react = LIKE_ACTION;
        const { message_cd, count } = await actionCall(param);

        if (message_cd !== '0') {
          return;
        }

        updateItem.liked = 'Y';
        updateItem.like_count = count || 0;
      }
    } else {
      window.GA_Event('커뮤니티', '공통', '댓글 비추천');
      if (disliked === 'Y') {
        param.react = DISLIKE_DELETE_ACTION;
        const { message_cd, count } = await actionCall(param);

        if (message_cd !== '0') {
          return;
        }

        updateItem.disliked = 'N';
        updateItem.dislike_count = count || 0;
      } else {
        if (liked === 'Y') {
          param.react = LIKE_DELETE_ACTION;
          const { message_cd, count } = await actionCall(param);

          if (message_cd !== '0') {
            return;
          }

          updateItem.liked = 'N';
          updateItem.like_count = count || 0;
        }

        param.react = DISLIKE_ACTION;
        const { message_cd, count } = await actionCall(param);

        if (message_cd !== '0') {
          return;
        }

        updateItem.disliked = 'Y';
        updateItem.dislike_count = count || 0;
      }
    }

    dispatch(updateAction(updateItem));
  };

  const deleteHandler = async () => {
    // e.preventDefault();

    let msg = `삭제 실패! 다시 시도해주세요.`;
    let severity = 'error';

    try {
      setIsLoading(true);

      let p_report_type;
      let p_report_key;
      let updateAction;
      let activeKey;

      if (!isReply) {
        p_report_type = '2';
        p_report_key = item.comment_id;
        updateAction = updatePostComment;
        activeKey = 'comment_active';
      } else {
        p_report_type = '3';
        p_report_key = item.comment_reply_id;
        updateAction = updateCommentReply;
        activeKey = 'comment_reply_active';
      }

      const { message_cd } = await deleleFeedData({
        p_user_id: loginedUser.USER_ID,
        p_report_type,
        p_report_key,
      });

      if (message_cd === '0') {
        const updateItem = { ...item };
        updateItem[activeKey] = 2;
        msg = '삭제 성공!';
        severity = 'success';
        dispatch(updateAction(updateItem));
      }
    } finally {
      setIsLoading(false);
      dispatch(setAlertInfo({ msg: type + ' ' + msg, severity }));
      dispatch(openSnackbar());
    }
  };

  useEffect(() => {
    if (replyOpen) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [replyOpen]);

  useEffect(() => {
    if (isReplyLast && isChangeReply) {
      ref.current.style.scrollMargin = `${commentInputHeight}px`;
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      setIsChangeReply(false);
    }
  }, [isReplyLast, isChangeReply]);

  useEffect(() => {
    if (item) {
      setType(!isReply ? '댓글' : '답글');

      let tempIsBlock = false;
      let tempBlockMsg = '';

      if (item?.comment_active === 2 || item?.comment_reply_active === 2) {
        tempIsBlock = true;
        tempBlockMsg = '삭제된 댓글입니다.';
      } else if (
        item.user_block === 1 ||
        item?.comment_active === 1 ||
        item?.comment_reply_active === 1
      ) {
        tempIsBlock = true;
        tempBlockMsg = '차단된 댓글입니다.';
      }

      setIsBlock(tempIsBlock);
      setBlockMsg(tempBlockMsg);
    }

    return () => {};
  }, [item]);

  useEffect(() => {
    console.log('mounted : ', item);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div
      css={[
        styles.container,
        replyOpen || (isReply && !isReplyLast)
          ? styles.borderBottomNone
          : styles.borderBottom,
      ]}
      ref={ref}
    >
      <Link
        css={[
          noDecoraionLink,
          css`
            display: block;
            position: relative;
            padding: ${stringToVW('3px 0px')};
          `,
        ]}
        to={
          (`/profile/${encodeURIComponent(user_id)}`,
          {
            state: { prevLink: pathname },
          })
        }
      >
        <ProfileAvatar
          width={27 * ratio}
          height={27 * ratio}
          userInfo={{
            userName: user_nm,
            filePath: getUploadImage(profile_img),
          }}
        />
        {item.user_type === '1' && (
          <IconCerti
            css={css`
              width: ${stringToVW('14px')};
              height: ${stringToVW('14px')};
              position: absolute;
              bottom: 0;
              right: 0;
              transform: translate(25%, 25%);
            `}
          />
        )}
      </Link>
      <div css={styles.commentInfo}>
        <div css={styles.commentTop}>
          <Link
            css={[noDecoraionLink, styles.commentWriter]}
            to={`/profile/${encodeURIComponent(user_id)}`}
            state={{ prevLink: pathname }}
          >
            {user_nm}
          </Link>
          {!isBlock && (
            <>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: toVW('34px'),
                  height: toVW('34px'),
                  marginRight: toVW('-8px'),
                }}
                ref={menuBtnRef}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <SvgIcon sx={{ width: toVW('18px'), height: toVW('18px') }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 4 12"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.05905 1.86498C3.05905 2.44975 2.585 2.9238 2.00023 2.9238C1.41546 2.9238 0.941406 2.44975 0.941406 1.86498C0.941406 1.2802 1.41546 0.806152 2.00023 0.806152C2.585 0.806152 3.05905 1.2802 3.05905 1.86498ZM3.05905 6.10027C3.05905 6.68504 2.585 7.15909 2.00023 7.15909C1.41546 7.15909 0.941406 6.68504 0.941406 6.10027C0.941406 5.5155 1.41546 5.04145 2.00023 5.04145C2.585 5.04145 3.05905 5.5155 3.05905 6.10027ZM2.00023 11.3944C2.585 11.3944 3.05905 10.9203 3.05905 10.3356C3.05905 9.75079 2.585 9.27674 2.00023 9.27674C1.41546 9.27674 0.941406 9.75079 0.941406 10.3356C0.941406 10.9203 1.41546 11.3944 2.00023 11.3944Z"
                      fill="#999999"
                    />
                  </svg>
                </SvgIcon>
              </Stack>
              <Backdrop
                sx={{
                  color: '#000',
                  opacity: 0.4,
                  zIndex: theme => theme.zIndex.drawer + 1,
                }}
                open={Boolean(anchorEl)}
              ></Backdrop>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                backdrop={{ invisible: false }}
                slotProps={{
                  paper: {
                    sx: {
                      background: 'none',
                      boxShadow: 'none',
                    },
                  },
                }}
              >
                <div css={styles.popup}>
                  {loginedUser?.USER_ID !== item.user_id && (
                    <Link
                      to={{
                        pathname: '/report',
                        search: `${new URLSearchParams({
                          report_type: isReply ? '3' : '2',
                          user_id: encodeURIComponent(user_id),
                          post_id: isReply
                            ? item.comment_reply_id
                            : item.comment_id,
                        }).toString()}`,
                      }}
                      onClick={() => {
                        window.GA_Event('커뮤니티', '공통', '댓글 신고');
                      }}
                      css={styles.popupText}
                    >
                      <SvgIcon sx={{ width: toVW('18px') }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 1.62533C5.11152 1.62533 1.95833 4.77852 1.95833 8.66699C1.95833 12.5555 5.11152 15.7087 9 15.7087C12.8893 15.7087 16.0417 12.5555 16.0417 8.66699C16.0417 4.77847 12.8893 1.62533 9 1.62533ZM0.625 8.66699C0.625 4.04214 4.37514 0.291992 9 0.291992C13.6257 0.291992 17.375 4.04218 17.375 8.66699C17.375 13.2918 13.6257 17.042 9 17.042C4.37514 17.042 0.625 13.2918 0.625 8.66699Z"
                            fill="#999999"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.99626 4.83691C9.36445 4.83691 9.66292 5.13539 9.66292 5.50358V9.18608C9.66292 9.55427 9.36445 9.85275 8.99626 9.85275C8.62807 9.85275 8.32959 9.55427 8.32959 9.18608V5.50358C8.32959 5.13539 8.62807 4.83691 8.99626 4.83691Z"
                            fill="#999999"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.3291 11.8307C8.3291 11.4625 8.62758 11.1641 8.99577 11.1641H9.0041C9.37229 11.1641 9.67077 11.4625 9.67077 11.8307C9.67077 12.1989 9.37229 12.4974 9.0041 12.4974H8.99577C8.62758 12.4974 8.3291 12.1989 8.3291 11.8307Z"
                            fill="#999999"
                          />
                        </svg>
                      </SvgIcon>
                      {type} 신고
                    </Link>
                  )}
                  {loginedUser?.USER_ID === item.user_id && (
                    <Link
                      onClick={() => {
                        window.GA_Event('커뮤니티', '공통', '댓글 삭제');
                        if (!isLoading) {
                          deleteHandler();
                        }
                      }}
                      css={styles.popupText}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.6667 4.33333H3.33333V13C3.33333 14.1046 4.22876 15 5.33333 15H10.6667C11.7712 15 12.6667 14.1046 12.6667 13V4.33333ZM2 3V13C2 14.8409 3.49238 16.3333 5.33333 16.3333H10.6667C12.5076 16.3333 14 14.8409 14 13V3H2Z"
                          fill="#999999"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.666504 3.66667C0.666504 3.29848 0.964981 3 1.33317 3H14.6665C15.0347 3 15.3332 3.29848 15.3332 3.66667C15.3332 4.03486 15.0347 4.33333 14.6665 4.33333H1.33317C0.964981 4.33333 0.666504 4.03486 0.666504 3.66667Z"
                          fill="#999999"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.6665 1.66634C6.29831 1.66634 5.99984 1.96482 5.99984 2.33301V3.66634C5.99984 4.03453 5.70136 4.33301 5.33317 4.33301C4.96498 4.33301 4.6665 4.03453 4.6665 3.66634V2.33301C4.6665 1.22844 5.56193 0.333008 6.6665 0.333008H9.33317C10.4377 0.333008 11.3332 1.22844 11.3332 2.33301V3.66634C11.3332 4.03453 11.0347 4.33301 10.6665 4.33301C10.2983 4.33301 9.99984 4.03453 9.99984 3.66634V2.33301C9.99984 1.96482 9.70136 1.66634 9.33317 1.66634H6.6665Z"
                          fill="#999999"
                        />
                        <path
                          d="M5.3335 5.66602H6.66683V12.9993H5.3335V5.66602Z"
                          fill="#999999"
                        />
                        <path
                          d="M9.3335 5.66602H10.6668V12.9993H9.3335V5.66602Z"
                          fill="#999999"
                        />
                      </svg>
                      {type} 삭제
                    </Link>
                  )}
                </div>
              </Popover>
            </>
          )}
        </div>
        <div css={styles.commentContent}>{!isBlock ? contents : blockMsg}</div>
        <div css={styles.commentBottom}>
          <div css={styles.flexRow}>
            <span
              css={css`
                display: flex;
                justify-content: flex-start;
                align-items: center;
              `}
            >
              {!isReply && (
                <div
                  css={styles.replyWriteBtn}
                  onClick={() => {
                    if (!isEmpty(postCommentPeplyList)) {
                      clickReplyOpen(true);
                    }

                    dispatch(updateReplyTargetComment(item));

                    if (replyOpen) {
                      ref?.current?.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }}
                >
                  답글 달기
                </div>
              )}
              <div css={styles.writeTime}>
                <div>{betweenTime}</div>
                <div>{postFix}</div>
              </div>
            </span>
            {/* </div> */}
            {/* <div css={styles.flexRowEnd}> */}
            <span
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: ${stringToVW('100px')};
              `}
            >
              <div css={styles.like}>
                <LikeButton
                  type={TYPE_LIKE}
                  size={SIZE_MD}
                  isActive={liked === 'Y'}
                  clickHandler={
                    !isBlock
                      ? () => {
                          handleReactionBtn('like');
                        }
                      : undefined
                  }
                />
                <div>{like_count || 0}</div>
              </div>
              <div css={styles.like}>
                <LikeButton
                  type={TYPE_DISLIKE}
                  size={SIZE_MD}
                  isActive={disliked === 'Y'}
                  clickHandler={
                    !isBlock
                      ? () => {
                          handleReactionBtn('dislike');
                        }
                      : undefined
                  }
                />
                <div>{dislike_count || 0}</div>
              </div>
            </span>
          </div>
        </div>
        {!isReply && !isEmpty(postCommentPeplyList) && (
          <div
            css={styles.replyOpenText}
            onClick={() => {
              clickReplyOpen();
            }}
          >
            {!replyOpen ? (
              <>
                <div css={styles.replyLength}>
                  {postCommentPeplyList.length}
                </div>
                <div css={styles.replyBtnTxt}>개의 답글 보기</div>
              </>
            ) : (
              <div css={styles.replyBtnTxt}>접기</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

CommentItem.propTypes = {
  isReply: PropTypes.bool,
  replyOpen: PropTypes.bool,
  isreplyLast: PropTypes.bool,
};

export default CommentItem;
