import { css } from '@emotion/react';
import Confetti from '@asset/icons/profile/confetti.svg';
import React, { useEffect } from 'react';
import '@style/PopupAchievements.css';
import { isEmpty } from 'lodash';
import { ReactComponent as IconBadgeHistory } from '@asset/icons/profile/icon-badge-history.svg';
import dayjs from 'dayjs';
import { globalStyle, stringToVW } from '@style/globalStyle';

const country = {
  BAT001000: '한국',
  BAT001001: '미국',
  BAT001002: '일본',
  BAT001003: '중국',
  BAT001004: '홍콩',
  BAT001005: '영국',
  BAT001006: '베트남',
  BAT001007: '독일',
  BAT001008: '인도네시아',
};

const dateFormat = date => {
  const formattedDate = dayjs(date, 'YYYYMMDD').format('YYYY.MM');
  return formattedDate;
};

const commentList = code => {
  if ('BAT000000,BAT000001,BAT000002'.includes(code)) {
    return '투자 성과 상위 \n 30% / 10% / 5% \n 투자자가 되어보세요!';
  } else if ('BAT000005,BAT000006,BAT000007'.includes(code)) {
    return '투자 수익률\n 5% / 10% / 20% 이상을\n 달성해 보세요!';
  } else if ('BAT000100,BAT000101,BAT000102'.includes(code)) {
    return '거래 경험 종목 수\n 10개 / 50개 / 100개를\n 달성해 보세요.';
  } else if ('BAT000010'.includes(code)) {
    return '내 종목 상한가\n GAZUA!';
  } else if (
    `BAT001000,BAT001001,BAT001002
    ,BAT001003,BAT001004,BAT001005
    ,BAT001006,BAT001007,BAT001008
    ,BAT001009`.includes(code)
  ) {
    return `${country[code]} 시장과 함께하는\n 위대한 투자 발걸음!`;
  }
};

const PopupAchievements = ({ handleClick, totalNumber, data }) => {
  const styles = {
    wrapper: css`
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      margin: 0 auto;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10000;
    `,
    boxPopup: css`
      overflow: hidden;
      width: 91%;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      position: absolute;
      gap: ${stringToVW('20px')};
      border: 0px solid white;
      border-radius: ${stringToVW('10.7px')};
      @media (min-width: 768px) {
        max-width: 50%;
      }
    `,
    title: css`
      color: #333;
      text-align: center;
      ${globalStyle.fontNanu18}
      font-weight: 600;
    `,
    description: css`
      color: #666;
      text-align: center;
      font-size: ${stringToVW('14px')};
      font-weight: 400;
      line-height: ${stringToVW('22px')};
      white-space: pre-wrap;
      text-align: center;
      margin: ${stringToVW('20px 0px')};
    `,
    totalNumber: css`
      display: flex;
      right: 0;
      top: 0;
      position: absolute;
      padding: ${stringToVW('4px 8px')};
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;
      border-radius: var(--pill, 1000px);
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      color: var(--essential-primary-pri-600272, #3c9800);
      text-align: center;
      ${globalStyle.fontRobo18}
      font-weight: 500;
      letter-spacing: 0em;
      z-index: 1;
    `,
    btn: css`
      width: 100%;
      height: ${stringToVW('50px')};
      background: #84c13d;
      border: 0px solid white;
      border-radius: ${stringToVW('0 0 10.7px 10.7px')};
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
      padding: 0;
      color: var(--namuh-defult-5063, #fff);
      text-align: center;
      font-size: ${stringToVW('17px')};
      font-weight: 400;
      line-height: ${stringToVW('25px')};
    `,
    history: css`
      padding: ${stringToVW('8px 5px 8px 21px')};
      border-radius: ${stringToVW('13.33px')};
      background: #f5f5f5;
      max-height: ${stringToVW('250px')};
      margin: ${stringToVW('5px 16px 0px 16px')};
      overflow-y: hidden;
    `,
    historyItem: css`
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      padding-top: ${stringToVW('12px')};
    `,
    historyTime: css`
      color: #333;
      ${globalStyle.fontRobo19}
      font-weight: 400;
      width: 30%;
    `,
    historyData: css`
      color: #666;
      ${globalStyle.fontNanu18}
      font-weight: 400;
      width: 70%;
    `,
  };

  useEffect(() => {}, []);
  return (
    <div
      css={styles.wrapper}
      onClick={event => {
        handleClick(false);
      }}
    >
      <div
        css={styles.boxPopup}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {!isEmpty(data?.list?.data) && (
          <img
            css={css`
              position: absolute;
              top: ${stringToVW('24px')};
            `}
            src={Confetti}
            alt="background"
            width="100%"
          />
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background: #fff;
            padding-bottom: ${stringToVW('27px')};
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: center;
              position: relative;
              margin-top: ${stringToVW('80px')};
            `}
          >
            {data.total > 0 && (
              <div css={styles.totalNumber}>x{data.total}</div>
            )}
            <img
              css={css`
                width: ${stringToVW('120px')};
                height: ${stringToVW('120px')};
              `}
              src={data.image}
              alt=""
            />
          </div>
          <div css={styles.title}>{data.title}</div>
          <div css={styles.description}>
            {/* {'투자 수익률\n' + '5% / 10% / 20% 이상을\n' + '달성해 보세요!'} */}
            {commentList(data.badgeCd)}
          </div>
        </div>
        {data.list.data?.length > 0 ? (
          <div
            css={css`
              background: #fff;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: ${stringToVW('8px')};
                padding: ${stringToVW('8px 5px 8px 21px')};
                align-items: center;
                padding: ${stringToVW('0x 16px')};
              `}
            >
              <IconBadgeHistory />
              <div css={styles.title}>획득 History</div>
            </div>
            <div css={styles.history} className="scrollbar" id="style-2">
              <div
                className="scrollbar"
                id="style-2"
                css={css`
                  // height: 100%;
                  div:last-child {
                    border: none;
                  }
                `}
              >
                {data.list.data.map((item, index) => {
                  return (
                    <div css={styles.historyItem} key={index}>
                      <span css={styles.historyTime}>
                        {dateFormat(item.BADGE_FIRST_DT)}
                      </span>
                      &nbsp;
                      <span css={styles.historyData}>
                        {item.BADGE_CD === 'BAT000010'
                          ? `${item.STOCK_NM} `
                          : item.ACD_NM}
                      </span>
                    </div>
                  );
                })}
              </div>
              {/* <div css={styles.historyItem}>
                <span css={styles.historyTime}>2023.09</span>
                <span css={styles.historyData}>수익률 20% 달성</span>
              </div>
              <div css={styles.historyItem}>
                <span css={styles.historyTime}>2023.09</span>
                <span css={styles.historyData}>수익률 20% 달성</span>
              </div>
              <div css={styles.historyItem}>
                <span css={styles.historyTime}>2023.09</span>
                <span css={styles.historyData}>수익률 20% 달성</span>
              </div>
              <div css={styles.historyItem}>
                <span css={styles.historyTime}>2023.09</span>
                <span css={styles.historyData}>수익률 20% 달성</span>
              </div>
              <div css={styles.historyItem}>
                <span css={styles.historyTime}>2023.09</span>
                <span css={styles.historyData}>수익률 20% 달성</span>
              </div>
              <div css={styles.historyItem}>
                <span css={styles.historyTime}>2023.09</span>
                <span css={styles.historyData}>수익률 20% 달성</span>
              </div> */}
            </div>
            <div style={{ height: stringToVW('20px') }} />
          </div>
        ) : null}
        <div
          css={styles.btn}
          // style={
          //   data.total > 0
          //     ? { marginTop: '0px' }
          //     : { marginTop: stringToVW('27px') }
          // }
          onClick={() => handleClick(false)}
        >
          확인
        </div>
      </div>
    </div>
  );
};
export default PopupAchievements;
