import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import Header from '@component/admin/common/Header';
import { DatePicker, Select, Input, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import icon_right from '@asset/icons/common/icon_right.svg';
import { getMemberView } from '@lib/api-call/admin/memberApi';
import { getMemberStockList } from '@lib/api-call/admin/channelApi';

const { RangePicker } = DatePicker;

const handleChange = value => {
  console.log(`selected ${value}`);
};

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '종목',
    dataIndex: 'stock_nm',
    key: 'stock_nm',
  },
  {
    title: '수량',
    dataIndex: 'stock_qty',
    key: 'stock_qty',
  },
];

const StockContent = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});

  const { userId } = useParams(); // 쿼리 호출

  const [stockList, setStockList] = useState([]);
  const [totalStockCount, setTotalStockCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const onUserInfoGet = async () => {
    const userData = await getMemberView({ p_user_id: userId });
    console.log(userData);
    setUserInfo(userData.urUserView);
  };

  const onStockListGet = async () => {
    setLoading(true);
    const stockData = await getMemberStockList({
      p_user_id: userId,
      p_start: page * 10,
      p_length: 10,
    });

    console.log(stockData);

    setStockList(stockData.urUserPropertyList);
    setTotalStockCount(stockData.total_count);
    setTimeout(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    onUserInfoGet();
    onStockListGet();
  }, []);

  useEffect(() => {
    onStockListGet();
  }, [page]);

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const dataProp = [
    {
      title: '회원 관리',
      href: '/admin/user-manage',
    },
    {
      title: `${userInfo.user_nm}`,
      href: `/admin/user-detail/${userId}`,
    },
    {
      title: '보유 주식 내역',
      href: '',
    },
  ];

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div
          className="flex cursor-pointer"
          css={css`
            margin-left: -20px;
          `}
          onClick={() => navigate(-1)}
        >
          <img
            css={css`
              rotate: 180deg;
            `}
            src={icon_right}
            alt=""
          />
          <p>{userInfo.user_nm}</p>
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="bg-title-table">
            <p className="text-title-table">보유 주식 내역</p>
          </div>
          <Table
            className="ant-table"
            columns={columns}
            dataSource={stockList}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: event => {
            //       console.log(record);
            //       navigate('/admin/user-detail');
            //     },
            //   };
            // }}
            pagination={false}
            loading={loading}
          />
          <Pagination
            current={page + 1}
            defaultCurrent={1}
            total={totalStockCount || 1}
            showSizeChanger={false}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px 0',
            }}
            onChange={(page, pageSize) => pageChange(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default StockContent;
