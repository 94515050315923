import { SvgIcon } from '@mui/material';

const SearchIcon = ({ isActive, width, height }) => {
  return (
    <SvgIcon
      inheritViewBox
      sx={{
        width,
        height,
        background: 'none',
      }}
    >
      {isActive ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.81632 16.4671C14.0505 16.4671 17.483 13.0346 17.483 8.80046C17.483 4.56627 14.0505 1.13379 9.81632 1.13379C5.58214 1.13379 2.14966 4.56627 2.14966 8.80046C2.14966 13.0346 5.58214 16.4671 9.81632 16.4671Z"
            fill="#303038"
          />
          <g clipPath="url(#clip0_3106_182460)">
            <path
              d="M4.8782 9C4.43918 9 4.08276 8.64359 4.08276 8.20456C4.08276 6.81668 4.62564 5.50905 5.60755 4.52478C6.59181 3.54288 7.89944 3 9.28733 3C9.72635 3 10.0828 3.35641 10.0828 3.79544C10.0828 4.23446 9.72635 4.59087 9.28733 4.59087C8.32431 4.59087 7.41557 4.96853 6.73343 5.65067C6.05129 6.33281 5.67364 7.24154 5.67364 8.20456C5.67364 8.64359 5.31722 9 4.8782 9Z"
              fill="white"
            />
          </g>
          <path
            d="M19.0832 19L14.4165 14"
            stroke="#303038"
            strokeWidth="1.73333"
            strokeLinecap="round"
          />
          <defs>
            <clipPath id="clip0_3106_182460">
              <rect
                width="7.33333"
                height="7.33333"
                fill="white"
                transform="translate(3.4165 2.33398)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 18 20"
          fill="none"
        >
          <path
            d="M14.6161 8.46745C14.6161 12.223 11.5716 15.2674 7.81608 15.2674C4.06054 15.2674 1.01608 12.223 1.01608 8.46745C1.01608 4.71191 4.06054 1.66745 7.81608 1.66745C11.5716 1.66745 14.6161 4.71191 14.6161 8.46745Z"
            stroke="#45454C"
            strokeWidth="1.73333"
          />
          <path
            d="M17.0829 18.667L12.4163 13.667"
            stroke="#45454C"
            strokeWidth="1.73333"
            strokeLinecap="round"
          />
        </svg>
      )}
    </SvgIcon>
  );
};

export default SearchIcon;
