import { Box, Modal } from '@mui/material';
import React from 'react';
import { css } from '@emotion/react';
import { Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { pxFromVW } from '@style/globalStyle';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { getUploadImage } from '@lib/commonUtils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'black',
};

const ImageViewer = ({ open, handleClose, images = [], initialSlide }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
          `}
        >
          <div
            onClick={e => {
              handleClose();
            }}
            css={css`
              display: flex;
              position: absolute;
              top: 10px;
              right: 10px;
              width: 20px;
              height: 20px;
              padding: 3.971px 3.97px 3.97px 3.971px;
              justify-content: center;
              align-items: center;
              border-radius: 66.667px;
              background: rgba(0, 0, 0, 0.1);
              z-index: 2;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8139 1.18607C12.5268 0.898916 12.0612 0.898915 11.7741 1.18607L5.96002 7.0001L11.7741 12.8142C12.0612 13.1013 12.5268 13.1013 12.8139 12.8142C13.1011 12.527 13.1011 12.0615 12.8139 11.7743L8.03975 7.0001L12.8139 2.22593C13.1011 1.93878 13.1011 1.47322 12.8139 1.18607Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.18607 1.18607C1.47321 0.898916 1.93878 0.898915 2.22593 1.18607L8.03998 7.0001L2.22593 12.8142C1.93878 13.1013 1.47322 13.1013 1.18607 12.8142C0.898916 12.527 0.898915 12.0615 1.18607 11.7743L5.96025 7.0001L1.18607 2.22593C0.898916 1.93878 0.898915 1.47322 1.18607 1.18607Z"
                fill="white"
              />
            </svg>
          </div>
          <Swiper
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              '--swiper-pagination-color': '#FFF',
              '--swiper-pagination-bullet-inactive-color': '#666',
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-bullet-size': '8px',
              '--swiper-pagination-bullet-horizontal-gap': '4px',
            }}
            initialSlide={initialSlide}
            loop={false}
            zoom={true}
            onZoomChange={(swiper, scale, imageEl, slideEl) => {
              if (scale > 1) {
                swiper.allowTouchMove = false;
              } else {
                swiper.allowTouchMove = true;
              }
            }}
            slidesPerView={1}
            spaceBetween={pxFromVW(4.4)}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Zoom]}
          >
            {images.map((postImg, idx) => (
              <SwiperSlide
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                key={`postImg_${postImg.image_id}`}
              >
                <div className="swiper-zoom-container">
                  <img
                    css={css`
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    `}
                    alt=""
                    src={getUploadImage(postImg.image_url)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Box>
    </Modal>
  );
};

export default ImageViewer;
