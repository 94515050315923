import axiosUtils from '../../axiosUtils';
import Popup from '@component/app/common/PopupMui';
import {
  CALL_MTS_ACTION,
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
} from '@data/constants';
import { removeCookie } from '@lib/cookieUtils';
import { callMTSFunction } from '@lib/commonUtils';

export const LIKE_ACTION = 'like';
export const LIKE_DELETE_ACTION = 'deleteLike';
export const DISLIKE_ACTION = 'dislike';
export const DISLIKE_DELETE_ACTION = 'deleteDislike';
export const getTopRankChannel = async param => {
  return await axiosUtils
    .get('/lounge/channelTop5', param)
    .then(res => res.data);
};

export const getLoungeList = async param => {
  return await axiosUtils.get('/lounge/list', param).then(res => res.data);
};

export const getLoungeListVersion2 = async param => {
  return await axiosUtils.get('v2/lounge/list', param).then(res => res.data);
};

export const getOgMeta = async url => {
  let result = await axiosUtils.post('v2/lounge/ogMeta', { link: url });
  return result?.data?.[0];
};

export const updateReaction = async ({ react, post_id }) => {
  return await axiosUtils
    .post('/lounge/updreact', { react, post_id }, { requireAuth: true })
    .then(res => res.data)
    .catch(err => {
      return { error: err?.response?.status };
    });
};

export const writePost = async param => {
  return await axiosUtils
    .post('/lounge/write', param, { requireAuth: true })
    .then(res => res.data);
};

export const getChannelInfo = async param => {
  return await axiosUtils
    .post('/lounge/userChannelInfo', param)
    .then(res => res.data);
};

export const getPostDetail = async postId => {
  return await axiosUtils
    .post('/lounge/view', { post_id: postId })
    .then(res => res.data);
};

export const writeComment = async param => {
  return await axiosUtils
    .post('/lounge/writeComment', param, {
      requireAuth: true,
    })
    .then(res => res.data)
    .catch(err => {
      return { error: err?.response?.status };
    });
};

export const writeCommentReply = async param => {
  return await axiosUtils
    .post('/lounge/writeCommentReply', param, {
      requireAuth: true,
    })
    .then(res => res.data)
    .catch(err => {
      return { error: err?.response?.status };
    });
};

export const updateReactionComment = async param => {
  return await axiosUtils
    .post('/lounge/updcommentReaction', param, { requireAuth: true })
    .then(res => res.data);
};

export const updateReactionReply = async param => {
  return await axiosUtils
    .post('/lounge/updcommentReplyReaction', param, { requireAuth: true })
    .then(res => res.data);
};

export const updatePost = async param => {
  return await axiosUtils
    .post('/lounge/editWrite', param, {
      requireAuth: true,
    })
    .then(res => res.data);
};

export const getLoungeReactionList = async param => {
  return await axiosUtils
    .post('/lounge/reactionList', param)
    .then(res => res.data);
};
