import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isOpenSnackbar: false,
    alertInfo: { msg: '', severity: 'info' },
    blockPopupIsShow: false,
    isOpenReloadPopup: false,
  },
  reducers: {
    openSnackbar(state) {
      state.isOpenSnackbar = true;
    },
    closeSnackbar(state) {
      state.isOpenSnackbar = false;
    },

    setAlertInfo(state, action) {
      state.alertInfo = action.payload;
    },
    setBlockPopupIsShow(state, action) {
      state.blockPopupIsShow = action.payload;
    },
    openReloadPopup(state) {
      state.isOpenReloadPopup = true;
    },
    closeReloadPopup(state) {
      state.isOpenReloadPopup = false;
    },
  },
});

export const {
  openSnackbar,
  closeSnackbar,
  setAlertInfo,
  setBlockPopupIsShow,
  closeReloadPopup,
  openReloadPopup,
} = commonSlice.actions;

export default commonSlice.reducer;
