import { createSlice } from '@reduxjs/toolkit';

export const postSlice = createSlice({
  name: 'post',
  initialState: {
    writePostItem: undefined,
    writePostImages: [],
    postItem: undefined,
    replyTargetComment: undefined,
    commentInputHeight: 30,
    isUpdatePost: false,
    clickedPostId: undefined,
    clickedWrittenPostId: undefined,
  },
  reducers: {
    updateWritePostItem(state, action) {
      state.writePostItem = action.payload;
    },
    updateWritePostImages(state, action) {
      state.writePostImages = action.payload;
    },
    updatePostItem(state, action) {
      state.postItem = action.payload;
    },
    addPostComment(state, action) {
      state.postItem.postCommentList.push(action.payload);
      state.postItem.countComment++;
    },
    updateReplyTargetComment(state, action) {
      state.replyTargetComment = action.payload;
    },
    setCommentInputHeight(state, action) {
      state.commentInputHeight = action.payload;
    },
    addCommentReply(state, action) {
      const { payload } = action;

      const commentIdx = state.postItem.postCommentList.findIndex(
        c => c.comment_id === payload.comment_id,
      );

      state.postItem.postCommentList[commentIdx].postCommentPeplyList.push(
        payload,
      );

      state.postItem.countComment++;
    },
    updatePostComment(state, action) {
      const { payload } = action;

      const commentIdx = state.postItem.postCommentList.findIndex(
        c => c.comment_id === payload.comment_id,
      );

      state.postItem.postCommentList[commentIdx] = payload;
    },
    updateCommentReply(state, action) {
      const { payload } = action;

      const commentIdx = state.postItem.postCommentList.findIndex(
        c => c.comment_id === payload.comment_id,
      );

      const replyIdx = state.postItem.postCommentList[
        commentIdx
      ].postCommentPeplyList.findIndex(
        r => r.comment_reply_id === payload.comment_reply_id,
      );

      state.postItem.postCommentList[commentIdx].postCommentPeplyList[
        replyIdx
      ] = payload;
    },
    setIsUpdatePost(state, action) {
      state.isUpdatePost = action.payload;
    },
    setClickedPostId(state, action) {
      state.clickedPostId = action.payload;
    },
    setClickedWrittenPostId(state, action) {
      state.clickedWrittenPostId = action.payload;
    },
  },
});

export const {
  updateWritePostItem,
  updateWritePostImages,
  updatePostItem,
  addPostComment,
  updateReplyTargetComment,
  setCommentInputHeight,
  addCommentReply,
  updatePostComment,
  updateCommentReply,
  setIsUpdatePost,
  setClickedPostId,
  setClickedWrittenPostId,
} = postSlice.actions;

export default postSlice.reducer;
