/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ListFollowItem from '@component/app/profile/ListFollowItem';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import HeaderBasic from '@component/app/common/HeaderBasic';
import { useSearchParams } from 'react-router-dom';
import { toNumber, chain, compact, find } from 'lodash';
import {
  getFollow,
  getFollowerList,
  getFollowingList,
  getProfile,
} from '@lib/api-call/app/profileApi';
import { globalStyle, stringToVW } from '@style/globalStyle';

const styles = {
  totalFollowTab1: css`
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #f7f7f7;
    margin: 0;
    padding: ${stringToVW('13px 16px')};
    ${globalStyle.fontNanu18}
    font-weight: 400;
  `,
  totalFollowTab2: css`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    background: #f7f7f7;
    margin: 0;
    padding: ${stringToVW('13px 16px')};
    ${globalStyle.fontNanu18}
    font-weight: 400;
  `,
};

function formatNumberToKorean(number) {
  const unitNames = ['', '만', '억', '조', '경']; // 한글 단위

  const formattedString = chain(number)
    .toString()
    .split('')
    .reverse()
    .chunk(4)
    .flatMap((chunk, index) => {
      const formattedChunk = compact(chunk.reverse()).join('');
      return formattedChunk ? [formattedChunk + unitNames[index]] : [];
    })
    .reverse()
    .join(' ')
    .value();

  return formattedString;
}

// 주어진 숫자의 세 번째 자리를 반올림
function roundThirdDigit(num) {
  let a = Number('1' + '0'.repeat(num.length - 2));
  return Math.round(num / a) * a;
}

const formatAmount = number => {
  if (!number) return null;
  let lengths = String(number);
  let newNum = null;
  // console.log(lengths)

  // 반올림
  if (lengths.length > 3) {
    newNum = roundThirdDigit(lengths);
    // console.log(newNum);
  }
  // 천 단위로 쉼표 처리하는 함수
  const addCommas = val => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  if (String(newNum).length < 5) return `${addCommas(newNum || number)} 원`;
  else if (lengths.length < 9) {
    // 5자리 ~ 8자리 (만원단위)
    let sum =
      newNum / Math.pow(10, String(newNum).length - (lengths.length - 4));
    return `${addCommas(sum)} 만`;
  } else if (String(newNum).length < 13) {
    // 9~12자리 (억원단위)
    let sum =
      newNum / Math.pow(10, String(newNum).length - (lengths.length - 8));
    return `${addCommas(sum)} 억`;
  } else {
    return `9999 억`;
  }
};

const FollowComponent = ({ userId, myId }) => {
  const [followList, setFollowList] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [init, setInit] = useState(false);

  const onFollowGet = async () => {
    if (!userId) return;
    const followData = await getFollowerList({
      profileId: userId,
    });

    const dataFormat = followData.list.map(x => {
      x.status = x.followed === 0 ? 'follow' : 'following';
      return x;
    });
    setFollowList(dataFormat);
    setTotalAssets(followData.totalAssets);
    setTimeout(() => {
      setInit(true);
    });
  };

  const updateFollow = async ({ type, userId }) => {
    const updateStatus = await getFollow({
      followingId: userId,
    });

    if (updateStatus.message_cd !== '0') return;
    const findInfo = find(followList, { USER_ID: userId });
    if (type === 'follow') {
      findInfo.status = 'following';
    } else if (type === 'following') {
      findInfo.status = 'follow';
    }
    setFollowList([...followList]);
  };

  // mounted
  useEffect(() => {
    onFollowGet();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {init && (
        <>
          <div css={css``}>
            <Box sx={{ flexGrow: 1 }}>
              <div css={styles.totalFollowTab1}>
                {toNumber(followList.length).toLocaleString() < 5 ? (
                  <span
                    css={css`
                      width: 100%;
                      ${globalStyle.fontNanu18}
                    `}
                  >
                    팔로워가 5명 이상 모이면, 팔로워 자산을 확인할 수 있어요!
                  </span>
                ) : (
                  <span
                    css={css`
                      width: 100%;
                      ${globalStyle.fontNanu18}
                    `}
                  >
                    팔로워들의 총 자산은 약 {formatAmount(totalAssets)}원이에요.
                  </span>
                )}
              </div>
            </Box>
          </div>
          <div
            css={css`
              padding: ${stringToVW('0 16px')};
            `}
          >
            <ListFollowItem
              lowItem
              active={true}
              data={followList}
              updateFollow={updateFollow}
              myId={myId}
            />
          </div>
        </>
      )}
    </>
  );
};

const FollowingComponent = ({ userId, myId }) => {
  const [followingList, setFollowingList] = useState([]);

  const onFollowingGet = async () => {
    if (!userId) return;
    const followData = await getFollowingList({
      profileId: userId,
    });

    const dataFormat = followData.list.map(x => {
      x.status = x.followed === 0 ? 'follow' : 'following';
      return x;
    });

    setFollowingList(dataFormat);
  };

  const updateFollow = async ({ type, userId }) => {
    const updateStatus = await getFollow({
      followingId: userId,
    });

    if (updateStatus.message_cd !== '0') return;
    const findInfo = find(followingList, { FOLLOWING_USER_ID: userId });
    if (type === 'follow') {
      findInfo.status = 'following';
    } else if (type === 'following') {
      findInfo.status = 'follow';
    }
    setFollowingList([...followingList]);
  };

  // mounted
  useEffect(() => {
    onFollowingGet();
  }, []);

  return (
    <>
      <div css={css``}>
        <Box sx={{ flexGrow: 1 }}>
          <div css={styles.totalFollowTab2}>
            <span
              css={css`
                ${globalStyle.fontNanu18}
                font-weight: 500;
                margin-left: ${stringToVW('2px')};
                display: inline-block;
              `}
            >
              {followingList.length}
            </span>
            &nbsp;명을 팔로우 하고 있어요.
          </div>
        </Box>
      </div>
      <div
        css={css`
          padding: ${stringToVW('0 16px')};
        `}
      >
        <ListFollowItem
          active={false}
          data={followingList}
          type="following"
          updateFollow={updateFollow}
          myId={myId}
        />
      </div>
    </>
  );
};

const ListFollow = () => {
  let [searchParams] = useSearchParams();

  const [profile, setProfile] = useState({});
  const [myProfile, setMyProfile] = useState({});
  const [value, setValue] = useState('follow');
  const [tabList] = useState([
    { flag: 'follow', name: '팔로워' },
    { flag: 'following', name: '팔로잉' },
  ]);
  const userId = searchParams.get('userId'); // 쿼리 호출

  const onProfileGet = async () => {
    if (!userId) return;
    const followData = await getProfile({
      profileId: userId,
    });

    setProfile(followData);
  };

  const onMyProfileGet = async () => {
    const myProfileData = await getProfile();
    setMyProfile({
      ...myProfileData,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    onProfileGet();
    onMyProfileGet();
    setValue(searchParams.get('tab'));
  }, []);

  return (
    <div>
      <HeaderBasic
        title={profile.user_nm && `${profile.user_nm}의 프로필`}
        statusUser=""
      />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1.5,
              borderColor: 'divider',
              // margin: stringToVW('0 -15px'),
            }}
          >
            <TabList
              sx={{ transform: 'translateY(1.5px)' }}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'black',
                  bottom: 0,
                },
              }}
            >
              {tabList.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    style={{
                      color: value === item.flag ? '#000' : '#999',
                      opacity: 1,
                    }}
                    css={css`
                      ${globalStyle.fontNanu24};
                      font-weight: 600;
                    `}
                    label={item.name}
                    value={item.flag}
                  />
                );
              })}
            </TabList>
          </Box>
          {tabList.map((item, index) => {
            return (
              <TabPanel
                value={item.flag}
                key={index}
                css={css`
                  padding: 0;
                `}
              >
                {item.flag === 'follow' ? (
                  <FollowComponent userId={userId} myId={myProfile.user_id} />
                ) : (
                  <FollowingComponent
                    userId={userId}
                    myId={myProfile.user_id}
                  />
                )}
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </div>
  );
};

export default ListFollow;
