import React, { useState } from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { globalStyle, stringToVW } from '@style/globalStyle';
import PopupAchievements from '@component/app/profile/PopupAchievements';
const AchievementItem = ({ dataProps }) => {
  const [isShow, setIsShow] = useState(false);
  const [showItem, setShowItem] = useState({});
  const handleClick = boolean => {
    setIsShow(boolean);
  };
  const styles = {
    titleArea: css`
      display: flex;
      padding: ${stringToVW('28px 0px 12px 0px')};

      align-items: center;
      gap: ${stringToVW('6px')};
      align-self: stretch;
    `,
    title: css`
      color: #000;
      font-weight: 600;
      ${globalStyle.fontNanu21}
    `,
    totalLable: css`
      color: #3c9800;
      font-weight: 500;
      ${globalStyle.fontRobo21}
    `,
    totalNumber: css`
      display: flex;
      right: 0;
      top: 0;
      position: absolute;
      padding: ${stringToVW('4px 8px')};
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;
      border-radius: var(--pill, 1000px);
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      color: var(--essential-primary-pri-600272, #3c9800);
      text-align: center;
      ${globalStyle.fontRobo18}
      font-weight: 500;
      letter-spacing: 0em;
      z-index: 1;
    `,
  };
  return (
    <div
      css={css`
        padding: ${stringToVW('0px 16px')};
        font-family: NanumBarunGothic;
        font-style: normal;
      `}
    >
      <div css={styles.titleArea}>
        <div css={styles.title}>{dataProps.title}</div>
        <div css={styles.totalLable}>
          {/* {(dataProps.data.reduce((total, item) => total + item.total), 0)} */}
          {dataProps.total()}
        </div>
      </div>
      <div>
        <Box
          sx={{ flexGrow: 1 }}
          css={css`
            padding: ${stringToVW('21px')};
            border-radius: ${stringToVW('16px')};
            border: 1px solid var(--essential-line-line-200025, #f0f0f0);
          `}
        >
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {dataProps.data.map((item, index) => (
              <Grid
                item
                key={index}
                onClick={() => {
                  setIsShow(true);
                  // setTotalNumber(item.total);
                  setShowItem(item);
                }}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                  `}
                >
                  {item.total > 0 ? (
                    <div css={styles.totalNumber}>x{item.total}</div>
                  ) : null}
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      position: relative;
                    `}
                  >
                    <img
                      css={css`
                        width: ${stringToVW('80px')};
                        height: ${stringToVW('80px')};
                      `}
                      src={item.image}
                      alt=""
                    />
                  </div>
                  <div
                    css={css`
                      color: #333;
                      text-align: center;
                      font-size: ${stringToVW('12px')};
                      font-weight: 400;
                      line-height: ${stringToVW('16px')};
                      margin-top: ${stringToVW('10px')};
                    `}
                  >
                    {item.title}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
      {isShow ? (
        <PopupAchievements
          handleClick={handleClick}
          data={showItem}
          // totalNumber={totalNumber}
        />
      ) : null}
    </div>
  );
};

export default AchievementItem;
