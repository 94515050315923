import { css } from '@emotion/react';
import { noDecoraionLink } from '@style/common/commonStyles';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { globalStyle, stringToVW } from '@style/globalStyle';

const ReactionRatioBar = ({ cnt, total, isDislike, isStock, isPareZero }) => {
  const { postId } = useParams();

  const ratio = isPareZero ? 0.5 : cnt / total;
  const styles = {
    container: css`
      height: ${stringToVW('27px')};
      padding: ${stringToVW('0px 16px')};

      display: flex;
      justify-content: ${!isDislike ? 'start' : 'end'};
      align-items: center;
      gap: ${stringToVW('2px')};

      flex-basis: ${stringToVW('58.5px')};
      flex-grow: ${ratio};
      ${'' /* flex-shrink: 0; */}

      color: ${!isDislike ? '#d73838' : '#247ef2'};
    `,
    likeStyle: css`
      border-radius: ${stringToVW('40px 4px 4px 40px')};
      color: #d73838;
    `,
    disLikeStyle: css`
      border-radius: ${stringToVW('4px 40px 40px 4px')};
      color: #247ef2;
    `,
  };
  const borderStyle = css`
    border-radius: ${ratio === 1
      ? stringToVW('40px')
      : !isDislike
      ? stringToVW('40px 4px 4px 40px')
      : stringToVW('4px 40px 40px 4px')};
    background: ${isStock && ratio === 1
      ? 'none'
      : cnt > 0 || isPareZero
      ? !isDislike
        ? '#ffeff0'
        : '#e3f3ff'
      : 'none'};
  `;
  return (
    <Link
      to={`/post/${postId}/${!isDislike ? 'like' : 'dislike'}`}
      css={[styles.container, borderStyle, noDecoraionLink]} //noDecoraionLink
    >
      {(!isStock || (isStock && cnt > 0)) && (
        <>
          <div
            css={css`
              ${globalStyle.fontRobo19}
            `}
          >
            {Intl.NumberFormat('ko-KR').format(cnt)}
          </div>
          {isStock && (
            <div
              css={css`
                ${globalStyle.fontNanu15}
                font-weight: 400;
              `}
            >
              주
            </div>
          )}
        </>
      )}
    </Link>
  );
};

ReactionRatioBar.propTypes = {
  cnt: PropTypes.number,
  total: PropTypes.number,
  isDislike: PropTypes.bool,
  isStock: PropTypes.bool,
};

export default ReactionRatioBar;
