import { css } from '@emotion/react';

import PostItemHeader from './PostItemHeader';
import PostContent from './PostContent';
import PostItemFooter from './PostItemFooter';
import { stringToVW } from '@style/globalStyle';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loungeHeaderHeight } from '../common/LoungeHeader';
import {
  setClickedPostId,
  setClickedWrittenPostId,
} from '@store/app/postReducer';
import { setClickedChannelPostId } from '@store/app/channelReducer';

const PostItem = ({
  item,
  isDetail = false,
  isChDetail = false,
  isProfile = false,
  clickedPostRef = undefined,
  mutate,
}) => {
  const dispatch = useDispatch();

  const styles = {
    container: css`
      background: #fff;
      padding: ${stringToVW('21px 16px 16px 16px')};
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
    `,
  };

  useEffect(() => {
    if (clickedPostRef && clickedPostRef.current) {
      if (!isChDetail) {
        clickedPostRef.current.style.scrollMargin = loungeHeaderHeight;
      }
      clickedPostRef.current?.scrollIntoView({});

      if (isProfile) {
        dispatch(setClickedWrittenPostId(undefined));
      } else if (isChDetail) {
        dispatch(setClickedChannelPostId(undefined));
      } else {
        dispatch(setClickedPostId(undefined));
      }
    }
  }, [clickedPostRef]);

  return (
    <div css={styles.container} ref={clickedPostRef}>
      {!isDetail && (
        <PostItemHeader
          item={item}
          isChDetail={isChDetail}
          isProfile={isProfile}
        />
      )}
      <PostContent
        item={item}
        isDetail={isDetail}
        isChDetail={isChDetail}
        isProfile={isProfile}
      />
      {!isDetail && (
        <PostItemFooter
          item={item}
          mutate={mutate}
          isChDetail={isChDetail}
          isProfile={isProfile}
        />
      )}
    </div>
  );
};

export default PostItem;
