/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';
import { css } from '@emotion/react';

import SubscribeChannelPicker from '@component/app/subscribe/SubscribeChannelPicker';
import LoungeHeader from '@component/app/common/LoungeHeader';
import PostListHeader from '@component/app/post/PostListHeader';
import PostList from '@component/app/post/PostList';

import {
  getSubChnlList,
  getSubsChnlList10,
} from '@lib/api-call/app/subsribeApi';
import styled from '@emotion/styled';
import { InputBase, SvgIcon } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import _, { isEmpty, isUndefined, last } from 'lodash';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { getChannelInfo } from '@lib/api-call/app/loungeApi';
import useSubsPostQuery, { subsQueryKey } from 'query-hook/useSubsPostQuery';
import useLoungePostQuery, { loungePost } from 'query-hook/useLoungePostQuery';
import Ptr from '@component/app/common/ptr';
import { POST_VIEW_COUNT } from '@data/constants';

const styles = {
  listHeader: css`
    padding: ${stringToVW('10.7px 16px')};
    background: #f5f5f5;
  `,
};

const HasSubscribe = forwardRef(({ channelList }, refs) => {
  //store
  // ref
  const [ref, inView] = useInView();
  useImperativeHandle(refs, () => ({
    handleRefetch() {
      queryClient.removeQueries(subsQueryKey);
      refetch();
    },
  }));

  // state
  const [orderBy, setOrderBy] = useState('0'); // 0 = 최신순, 1 = 인기순
  const [selectChannel, setSelectChannel] = useState({
    channelId: undefined,
    stockCd: undefined,
  });
  const [optionHolder, setOptionHolder] = useState(false);
  const [optionFollow, setOptionFollow] = useState(false);

  const [layoutData, setLayoutData] = useOutletContext();

  // Inifinite-Query
  const fetchingPosts = async ({ pageParam, meta }) => {
    const pageSize = meta?.prefetch.PageSize || POST_VIEW_COUNT;
    const lastPostId =
      meta?.prefetch.lastPostId || pageParam === 0 ? undefined : pageParam;

    return await getSubChnlList({
      pageSize: pageSize,
      lastPostId: lastPostId,
      orderBy: orderBy,
      channel_id: selectChannel.channelId,
      stock_cd: selectChannel.stockCd,
    });
  };

  const params = { orderBy: orderBy, selectChannel: selectChannel };

  const {
    data,
    isLoading,
    // isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useSubsPostQuery({
    rowPerPage: POST_VIEW_COUNT,
    queryFn: fetchingPosts,
    params: params,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: [subsQueryKey],
    mutationFn: ({ post, actionFunc }) => updateReactionFunc(post, actionFunc),
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([subsQueryKey, params], oldData => {
        const temp = { ...oldData };
        if (temp.pages[0]) {
          for (let page of temp.pages) {
            if (!page) return;
            const idx = page.findIndex(item => item.post_id === data.post_id);

            if (idx !== -1) {
              page[idx] = data;
              break;
            }
          }
          return temp;
        }
      });
    },
  });

  const updateReactionFunc = async (post, actionFunc) => {
    return await actionFunc(post);
  };

  const fetchingChInfo = async () => {
    const ch = await getChannelInfo({
      channel_id: selectChannel.channelId,
      stock_cd: selectChannel.stockCd,
    });

    if (ch) {
      setLayoutData({ ...layoutData, channelInfo: ch });
    }
  };

  const channelClick = ({ stockCd, channelId }) => {
    window.GA_Event('커뮤니티', '구독', '채널 선택');
    setSelectChannel({ stockCd, channelId });
  };

  //mounted
  useEffect(() => {
    // window.scrollTo(0, 0);

    return () => {};
  }, []);

  // inView 변경 시
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  // selectChannel 변경 시
  useEffect(() => {
    if (
      !isUndefined(selectChannel.channelId) &&
      !isUndefined(selectChannel.stockCd)
    ) {
      fetchingChInfo();
    }
  }, [selectChannel]);

  return (
    <>
      <SubscribeChannelPicker
        channelList={channelList}
        channelClick={channelClick}
        fixedChannel={selectChannel}
      />
      <div css={styles.listHeader}>
        <PostListHeader
          isSubscribe
          holderYn={optionHolder}
          setHolderYn={setOptionHolder}
          followYn={optionFollow}
          setFollowYn={setOptionFollow}
          setOrderBy={setOrderBy}
        />
      </div>
      <PostList
        holderYn={optionHolder}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        postList={
          !isLoading
            ? data?.pages.filter(x => {
                if (optionHolder || optionFollow) {
                  let boolean = false;
                  let item = _.find(channelList, { stock_cd: x.stock_cd });
                  if (optionHolder && optionFollow) {
                    boolean =
                      x.stock_qty &&
                      x.stock_holding_dt &&
                      item?.stock_holder === 'Y' &&
                      String(x.isFollowed) === '1';
                  } else if (optionHolder) {
                    boolean =
                      x.stock_qty &&
                      x.stock_holding_dt &&
                      item?.stock_holder === 'Y';
                  } else if (optionFollow) {
                    boolean = String(x.isFollowed) === '1';
                  }
                  return boolean;
                } else {
                  return x;
                }
              })
            : undefined
        }
        reloadRef={ref}
        mutate={mutate}
      />
      {!isEmpty(data?.pages) && hasNextPage && !isFetchingNextPage && (
        <div
          css={css`
            min-height: ${stringToVW('30px')};
            width: 100%;
            background: 'yellow';
            color: #b1f54d;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        ></div>
      )}
    </>
  );
});
const NoSubscribe = () => {
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: toVW('50px'),
    backgroundColor: '#F1F1F1',
    marginLeft: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: stringToVW('0px 20px'),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    height: stringToVW('40px'),

    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),

      // vertical padding + font size from searchIcon
      paddingLeft: stringToVW('50px'),
      transition: theme.transitions.create('width'),
      width: '100%',
      color: '#666',
      fontFamily: 'NanumBarunGothic',
      fontSize: stringToVW('14px'),
      fontWeight: 400,
      lineHeight: stringToVW('21.333px'),
      [theme.breakpoints.up('sm')]: {
        width: stringToVW('142px'),
        '&:focus': {
          width: stringToVW('252px'),
        },
      },

      '&::placeholder': {
        color: '#000',
        opacity: 1,
      },
    },
  }));

  const navigate = useNavigate();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 70vh;
      `}
    >
      <div
        css={css`
          ${globalStyle.fontNanu21}
          font-weight: 400;
          letter-spacing: 0em;
          text-align: center;
          display: flex;
          justify-content: center;
          color: #666666;
        `}
      >
        <span
          css={css`
            display: block;
            font-size: ${stringToVW('14px')};
            font-weight: 400;
            line-height: ${stringToVW('21px')};
          `}
        >
          구독한 채널이 없어요. <br />
          찾고싶은 채널이 있으신가요?
        </span>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: ${stringToVW('8px')};
          color: #666666;
          margin-top: ${stringToVW('21.3px')};
          width: ${stringToVW('148px')};
          height: ${stringToVW('40px')};
        `}
      >
        <Search>
          <SearchIconWrapper>
            <SvgIcon
              sx={{ width: stringToVW('20px'), height: stringToVW('20px') }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 17 17"
                fill="none"
              >
                <rect
                  x="1.89909"
                  y="2.23356"
                  width="10.9333"
                  height="10.9333"
                  rx="5.46667"
                  stroke="#666666"
                  strokeWidth="1.33333"
                />
                <path
                  d="M14.5661 14.9001L11.6328 11.9668"
                  stroke="#666666"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="검색하러 가기"
            inputProps={{ 'aria-label': 'search' }}
            onClick={() => {
              window.GA_Event('커뮤니티', '구독', '검색하러 가기');
              navigate('/search');
            }}
          />
        </Search>
      </div>

      <style>{`
    .float-button-controller {
      display: none !important;
    }
    `}</style>
    </div>
  );
};
function Subscribe(props) {
  const [channelList, setChannelList] = useState(undefined);
  const subRef = useRef({});

  const onChannelListGet = async () => {
    const chnlListData = await getSubsChnlList10();
    setChannelList(chnlListData?.subsChannelList || []);
  };

  const { refetch } = useLoungePostQuery({
    queryFn: async () => {
      await onChannelListGet();
    },
  });

  return (
    <Ptr
      onRefresh={async () => {
        await refetch();
        await subRef?.current.handleRefetch();
      }}
    >
      <div
      // css={css`
      //   min-height: 100vh;
      // `}
      >
        <LoungeHeader />
        {channelList &&
          (channelList.length > 0 ? (
            <HasSubscribe channelList={channelList} ref={subRef} />
          ) : (
            <NoSubscribe />
          ))}
      </div>
      <CheckWithdrawAccount />
    </Ptr>
  );
}

Subscribe.propTypes = {};

export default Subscribe;
