import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { useNavigate } from 'react-router-dom';
import NamuButton from './NamuButton';
import { CALL_MTS_ACTION, NAMU_BTN_COLOR } from '@data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { includes, isEmpty, some } from 'lodash';
import { updatePost, writePost } from '@lib/api-call/app/loungeApi';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { removeFile, uploadFiles } from '@lib/api-call/app/fileApi';
import { getDictionaryList } from '@lib/api-call/app/commApi';
import Popup from './PopupMui';
import { globalStyle, stringToVW } from '@style/globalStyle';
import BackButton from './BackButton';
import {
  callMTSFunction,
  checkImageUrlValidWithGreenEyeAPI,
  isDev,
} from '@lib/commonUtils';
import { checkTextWithKiso } from '@lib/commonUtils';

const WritePostHeader = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [badwords, setBadwords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowInvalidImage, setIsShowInvalidImage] = useState(false);
  const [modalContent, setModalContent] = useState({
    type: '',
    title: '',
    content: '',
  });

  const writePostItem = useSelector(state => state.post.writePostItem);
  const writePostImages = useSelector(state => state.post.writePostImages);
  const alertInfo = useSelector(state => state.common.alertInfo);
  const isUpdatePost = useSelector(state => state.post.isUpdatePost);

  const isWrite = writePostItem?.title || writePostItem?.contents;

  const isNoChannel =
    isEmpty(writePostItem?.channel_id) && isEmpty(writePostItem?.stock_cd);

  const closeDialog = () => {
    setIsShow(false);
  };

  const handleModal = (title, content, isAlert) => {
    const type = isAlert ? 'alert' : 'confirm';
    setModalContent({ type, title, content });
    setIsShow(true);
  };

  const hasInappropriateText = (text, dictionaryList) => {
    const lowercaseText = text.toLowerCase();
    const lowercaseInappropriateWords = dictionaryList.map(word =>
      word.toLowerCase(),
    );

    const filteredWords = lowercaseInappropriateWords.filter(word =>
      includes(lowercaseText, word),
    );

    const isInappropriateWords = some(lowercaseInappropriateWords, word => {
      const has = includes(lowercaseText, word);

      return has;
    });

    return { isInappropriateWords, filteredWords };
  };

  const fetchingBadWords = async () => {
    const { badWord } = await getDictionaryList();
    setBadwords(badWord);
  };

  const onClickComplete = async () => {
    setIsLoading(true);

    let isValid = true;

    const newAlertInfo = { ...alertInfo };
    newAlertInfo.severity = 'info';

    if (!writePostItem.title || !/^.{1,40}$/.test(writePostItem?.title)) {
      isValid = false;
      newAlertInfo.msg = '제목을 입력해주세요. (최대 40자)';
    } else if (
      !writePostItem.contents ||
      !/^[\s\S]{1,2000}$/.test(writePostItem?.contents)
    ) {
      isValid = false;
      newAlertInfo.msg = '내용을 입력해주세요. (최대 2000자)';
    } else {
      let hasBadwords = false;
      let badwordText;
      let badwordLength = 0;
      let badwordDetected = '';
      const [checkBadwordTitle, checkBadwordContent] = await Promise.all([
        checkTextWithKiso(writePostItem.title),
        checkTextWithKiso(writePostItem.contents),
      ]);
      const badWordTitleDetected = [
        ...checkBadwordTitle,
        ...hasInappropriateText(writePostItem.title, badwords).filteredWords,
      ];

      const badWordContentDetected = [
        ...checkBadwordContent,
        ...hasInappropriateText(writePostItem.contents, badwords).filteredWords,
      ];
      if (badWordTitleDetected?.length) {
        hasBadwords = true;
        badwordText = '제목';
        badwordLength = badWordTitleDetected?.length;
        badwordDetected = badWordTitleDetected[0];
      } else if (badWordContentDetected?.length) {
        hasBadwords = true;
        badwordText = '내용';
        badwordLength = badWordContentDetected?.length;
        badwordDetected = badWordContentDetected[0];
      }

      if (hasBadwords) {
        badwordLength > 1
          ? handleModal(
              `${badwordText}에 금칙어(${badwordDetected}) 등이\n포함되어 있습니다.`,
              `${badwordText}을 수정해주세요!`,
              true,
            )
          : handleModal(
              `${badwordText}에 금칙어(${badwordDetected})가\n포함되어 있습니다.`,
              `${badwordText}을 수정해주세요!`,
              true,
            );

        setIsLoading(false);
        return;
      }
    }

    if (isValid) {
      if (isNoChannel) {
        handleModal(
          '채널 미지정',
          `채널 미지정 시 '자유토론방' 채널로 지정됩니다.`,
          false,
        );
        setIsLoading(false);
        return;
      }

      if (!isUpdatePost) {
        const { isSuccess, msg } = await createPostHandler();

        if (!isSuccess && !msg?.length) {
          return;
        } else if (isSuccess) {
          newAlertInfo.severity = 'success';
          if (window.history.state.idx === 0 || !window.history.state?.idx) {
            callMTSFunction(CALL_MTS_ACTION.CLOSE_VIEW);
          } else {
            navigate('/');
          }
        } else if (msg?.length) {
          newAlertInfo.severity = 'error';
        }

        newAlertInfo.msg = msg;
      } else {
        const { isSuccess, msg } = await updatePostHandler();

        if (!isSuccess && !msg?.length) {
          return;
        }
        if (isSuccess) {
          newAlertInfo.severity = 'success';
          navigate(-1);
        } else {
          newAlertInfo.severity = 'error';
        }

        newAlertInfo.msg = msg;
      }
    }

    setIsLoading(false);

    dispatch(setAlertInfo(newAlertInfo));
    dispatch(openSnackbar());
  };

  const noChannelConfirmHandler = async () => {
    setIsLoading(true);

    const newAlertInfo = { ...alertInfo };

    if (!isUpdatePost) {
      const { isSuccess, msg } = await createPostHandler();
      if (!isSuccess && !msg?.length) {
        return;
      } else if (isSuccess) {
        newAlertInfo.severity = 'success';
        // navigate(-1);
        if (window.history.state.idx === 0 || !window.history.state?.idx) {
          callMTSFunction(CALL_MTS_ACTION.CLOSE_VIEW);
        } else {
          navigate('/');
        }
        newAlertInfo.msg = msg;
      } else if (msg?.length) {
        newAlertInfo.severity = 'error';
        newAlertInfo.msg = msg;
      }
    } else {
      const { isSuccess, msg } = await updatePostHandler();

      if (isSuccess) {
        newAlertInfo.severity = 'success';
        navigate(-1);
      } else {
        newAlertInfo.severity = 'error';
      }

      newAlertInfo.msg = msg;
    }

    setIsLoading(false);

    dispatch(setAlertInfo(newAlertInfo));
    dispatch(openSnackbar());
  };

  const createPostHandler = async () => {
    let isSuccess = false;
    let msg = '';

    try {
      let imgUploadError = false;

      const param = {
        postReq: { ...writePostItem, type: '0' },
      };

      if (isNoChannel) {
        param.postReq.channel_id = 0;
        param.postReq.stock_cd = 'ZZZ0001';
      }

      if (!isEmpty(writePostImages)) {
        try {
          const uploadFileRes = await uploadFiles(
            writePostImages.map(item => item.file),
          );

          if (!isEmpty(uploadFileRes)) {
            const imgListReq = uploadFileRes.map(res => ({
              image_url: res.imageURL,
              thumbnail_url: res.thumbnailURL,
              width: res.width,
              height: res.height,
            }));
            const { isConfidence, isConfidenceData } =
              await checkImageUrlValidWithGreenEyeAPI(
                uploadFileRes.map(res => {
                  let url = decodeURIComponent(res.imageURL);
                  return url;
                }),
              );
            if (!isConfidence) {
              setIsShowInvalidImage(true);
              return {
                isSuccess: false,
                msg: isDev()
                  ? 'GreenEyeResult ' + JSON.stringify(isConfidenceData)
                  : '',
              };
            } else {
              param.imgListReq = imgListReq;
            }
          } else {
            msg = '이미지 업로드 실패! 다시 시도해주세요.';
            imgUploadError = true;
          }
        } catch (error) {
          msg = `이미지 업로드 실패! 다시 시도해주세요.`;
          imgUploadError = true;
        }
      }
      if (!imgUploadError) {
        const { message_cd, message_nm } = await writePost(param);

        if (message_cd === '0') {
          msg = '게시글 등록 성공!';
          isSuccess = true;
        } else {
          msg = message_nm;
        }
      }
    } catch (error) {
      msg = '게시글 등록 실패! 다시 시도해주세요.';
    }

    return { isSuccess, msg };
  };

  const updatePostHandler = async () => {
    let isSuccess = false;
    let msg = '';

    try {
      let imgUploadError = false;

      const param = {
        postReq: { ...writePostItem },
      };

      if (!isEmpty(param.post_img)) {
        for (const img of param.post_img) {
          try {
            await removeFile(img.image_url);
          } catch (error) {
            // 파일 에러 발생
            console.error(error);
          }
        }
      }

      if (!isEmpty(writePostImages)) {
        try {
          const uploadFileRes = await uploadFiles(
            writePostImages.map(item => item.file),
          );

          if (!isEmpty(uploadFileRes)) {
            const imgListReq = uploadFileRes.map(res => ({
              image_url: res.imageURL,
              thumbnail_url: res.thumbnailURL,
              width: res.width,
              height: res.height,
            }));
            const { isConfidence, isConfidenceData } =
              await checkImageUrlValidWithGreenEyeAPI(
                uploadFileRes.map(res => {
                  let url = decodeURIComponent(res.imageURL);
                  return url;
                }),
              );
            if (!isConfidence) {
              setIsShowInvalidImage(true);
              return {
                isSuccess: false,
                msg: isDev()
                  ? 'GreenEyeResult ' + JSON.stringify(isConfidenceData)
                  : '',
              };
            } else {
              param.imgListReq = imgListReq;
            }
          } else {
            msg = '이미지 업로드 실패! 다시 시도해주세요.';
            imgUploadError = true;
          }
        } catch (error) {
          msg = `이미지 업로드 실패! 다시 시도해주세요.`;
          alert(JSON.stringify(error));
          imgUploadError = true;
        }
      }

      if (!imgUploadError) {
        const { message_cd, message_nm } = await updatePost(param);

        if (message_cd === '0') {
          msg = '게시글 수정 성공!';
          isSuccess = true;
        } else {
          msg = message_nm;
        }
      }
    } catch (error) {
      msg = '게시글 수정 실패! 다시 시도해주세요.';
    }

    return { isSuccess, msg };
  };

  useEffect(() => {
    fetchingBadWords();
  }, []);

  return (
    <div css={styles.container}>
      <BackButton
        close
        backButtonClickListener={() => {
          window.GA_Event('커뮤니티', '글쓰기', '뒤로가기');
        }}
      />
      <div css={styles.channelName}>
        {!isUpdatePost ? '글쓰기' : '게시글 수정'}
      </div>

      <NamuButton
        color={NAMU_BTN_COLOR.NO_BORDER}
        // onClick={onClickComplete}
        onClick={() => {
          window.GA_Event('커뮤니티', '글쓰기', '완료');
          onClickComplete();
        }}
        disabled={!isWrite || isLoading}
        size="lg"
      >
        완료
      </NamuButton>
      <Popup
        open={isShow}
        data={{
          title: (
            <div css={styles.modalTitle}>
              {modalContent.title?.split('\n').map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          ),
          content: <div css={styles.modalContent}>{modalContent.content}</div>,
        }}
        cancelButton={modalContent.type === 'confirm'}
        // confirmButton={false}
        onClose={closeDialog}
        cancelText="취소"
        confirmText="확인"
        confirmEvent={() => {
          // modalContent.type === 'confirm' && noChannelConfirmHandler
          if (modalContent.type === 'confirm') {
            noChannelConfirmHandler();
          }
        }}
      />
      <Popup
        open={isShowInvalidImage}
        data={{
          title: (
            <div css={styles.modalTitle}>
              이미지에 불건전한 컨텐츠가
              <br />
              발견되었습니다.
            </div>
          ),
          content: (
            <div css={styles.modalContent}>
              쾌적한 커뮤니티를 위해 광고/유해성 이미지
              <br />
              필터링 중입니다. 이미지를 재업로드 해주세요.
            </div>
          ),
        }}
        cancelButton={false}
        onClose={() => setIsShowInvalidImage(false)}
        confirmText="확인"
      />
    </div>
  );
};

const styles = {
  container: css`
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${stringToVW('9px 10.5px')};
  `,
  channelName: css`
    color: #000;
    font-style: normal;
    font-weight: 400;
    ${globalStyle.fontNanu24}
  `,
  modalTitle: css`
    width: 100%;
    padding-bottom: 0;
    color: #333;
    text-align: center;
    ${globalStyle.fontNanu24}
    word-break: keep-all;
  `,
  modalContent: css`
    color: #666;
    text-align: center;
    ${globalStyle.fontNanu21}
    font-weight: 400;
  `,
};

export default WritePostHeader;
