import { css } from '@emotion/react';

export const pxFromVW = size => {
  return (size / 100) * document.documentElement.clientWidth;
};
export const ratio =
  document.documentElement.clientWidth > 768
    ? 1
    : document.documentElement.clientWidth / 360;
export const toVW = size => {
  if (!size) return;
  // const widthRatio = document.documentElement.clientWidth / 360;
  if (document.documentElement.clientWidth > 768) {
    return size;
  } else if (size?.includes('px')) {
    return `${size?.replaceAll('px', '') / 3.6}vw`;
  } else if (size?.includes('em') || size?.includes('rem')) {
    return `${(size?.replaceAll('em', '').replaceAll('rem', '') * 16) / 3.6}vw`;
  } else {
    return `${size / 3.6}vw`;
  }
};

export const stringToVW = string => {
  return string
    .split(' ')
    .map(size => toVW(size))
    .join(' ');
};

export const globalStyle = {
  sortBtnGroup: css`
    display: flex;
    align-items: center;
    border-radius: 1000px;
    border: 1px solid var(--namuh-grayscale-62029, #e9e9e9);
    background-color: #fff;
  `,
  sortBtn: css`
    cursor: pointer;
    margin-right: ${toVW('-4px')};
    display: flex;
    height: ${toVW('26px')};
    padding: ${stringToVW('6px 10.7px')};
    justify-content: center;
    align-items: center;
    color: #666;

    font-family: NanumBarunGothic;
    font-size: ${toVW('12px')};
    line-height: ${toVW('16px')};
    font-family: NanumBarunGothic;
    font-weight: 400;
  `,
  sortActive: css`
    cursor: default;
    border-radius: 1000px;
    background: #4e5255;
    color: #fff;
    padding: ${stringToVW('6px 10.7px')};
  `,
  fontNanu15: `font-size: ${toVW('10px')}; line-height: ${toVW(
    '13.5px',
  )};font-family: NanumBarunGothic;`,
  fontNanu18: `font-size: ${toVW('12px')}; line-height: ${toVW(
    '16px',
  )};font-family: NanumBarunGothic;`,
  fontNanu19: `font-size: ${toVW('12.7px')}; line-height: ${toVW(
    '16px',
  )};font-family: NanumBarunGothic;`,
  fontNanu21: `font-size: ${toVW('14px')}; line-height: ${toVW(
    '21.5px',
  )};font-family: NanumBarunGothic;`,
  fontNanu24: `font-size: ${toVW('16px')}; line-height: ${toVW(
    '22.5px',
  )};font-family: NanumBarunGothic;`,
  fontNanu26: `font-size: ${toVW('17.5px')}; line-height: ${toVW(
    '25.5px',
  )};font-family: NanumBarunGothic;`,
  fontNanu30: `font-size: ${toVW('20px')}; line-height: ${toVW(
    '25.5px',
  )};font-family: NanumBarunGothic;`,
  fontNanu32: `font-size: ${toVW('21.5px')}; line-height: ${toVW(
    '29.5px',
  )};font-family: NanumBarunGothic;`,
  fontNanu40: `font-size: ${toVW('26.5px')}; line-height: ${toVW(
    '32px',
  )};font-family: NanumBarunGothic;`,
  fontRobo15: `font-size: ${toVW('10px')}; line-height: ${toVW(
    '12px',
  )};font-family: Roboto;`,
  fontRobo18: `font-size: ${toVW('12px')}; line-height: ${toVW(
    '14.5px',
  )};font-family: Roboto;`,
  fontRobo19: `font-size: ${toVW('12.7px')}; line-height: ${toVW(
    '16px',
  )};font-family: Roboto;`,
  fontRobo21: `font-size: ${toVW('14px')}; line-height: ${toVW(
    '17.5px',
  )};font-family: Roboto;`,
  fontRobo24: `font-size: ${toVW('16px')}; line-height: ${toVW(
    '21.5px',
  )};font-family: Roboto;`,
  fontRobo26: `font-size: ${toVW('17.5px')}; line-height: ${toVW(
    '24px',
  )};font-family: Roboto;`,
  fontRobo30: `font-size: ${toVW('20px')}; line-height: ${toVW(
    '24px',
  )};font-family: Roboto;`,
  fontRobo32: `font-size:${toVW('21.5px')}; line-height: ${toVW(
    '25.5px',
  )};font-family: Roboto;`,
  fontRobo40: `font-size: ${toVW('26.5px')}; line-height: ${toVW(
    '32px',
  )};font-family: Roboto;`,
};
