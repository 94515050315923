/* eslint-disable react-hooks/exhaustive-deps */
import ChannelHeader from '@component/app/common/ChannelHeader';
import PostList from '@component/app/post/PostList';
import PostListHeader from '@component/app/post/PostListHeader';
import { COUNTRY_CD_KR, COUNTRY_CD_US, POST_VIEW_COUNT } from '@data/constants';
import { css } from '@emotion/react';
import {
  getChannelInfo,
  getLoungeList,
  getLoungeListVersion2,
} from '@lib/api-call/app/loungeApi';
import { setClickedChannelPostId } from '@store/app/channelReducer';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { stringToVW } from '@style/globalStyle';
import useChannelPostQuery, {
  channelQueryKey,
  useChannelPostQueryV2,
} from 'query-hook/useChannelPostQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import history from '@lib/history';

const ChannelDetail = ({ isSlide = false }) => {
  const [init, setInit] = useState(false);
  const { stockCd, channelId } = useParams();
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const [layoutData, setLayoutData] = useOutletContext();

  const [orderBy, setOrderBy] = useState(null);
  const [holderYn, setHolderYn] = useState(undefined);
  const [channelInfo, setChannelInfo] = useState(null);

  const loginedUser = useSelector(state => state.auth.loginedUser);
  const clickedPostId = useSelector(state => state.channel.clickedPostId);

  const [ref, inView] = useInView();
  const clickedPostRef = useRef(null);

  const dispatch = useDispatch();

  const styles = {
    listHeader: css`
      padding: ${stringToVW('10.667px 16px')};
      background: #f5f5f5;
    `,
  };

  const fetchingPosts = async ({ pageParam, meta }) => {
    const pageSize = meta?.prefetch.PageSize || POST_VIEW_COUNT;
    // const lastPostId =
    //   meta?.prefetch.lastPostId || pageParam === 0 ? undefined : pageParam;
    const pageNumber = (pageParam ?? 0) + 1;

    return await getLoungeListVersion2({
      pageSize: pageSize,
      // lastPostId: lastPostId,
      pageNumber,
      orderBy: orderBy || '0',
      holderYn: holderYn ? 'Y' : 'N',
      stock_cd: stockCd,
      channel_id: channelId,
    });
  };

  const fetchingChInfo = async () => {
    const ch = await getChannelInfo({
      channel_id: channelId,
      stock_cd: stockCd,
    });

    if (ch) {
      setChannelInfo(ch);
      setLayoutData({ ...layoutData, channelInfo: ch });
    } else {
      navigate('/');
      throw new Error('존재하지 않는 채널입니다.');
    }
  };

  const updateSub = ({ flag }) => {
    channelInfo.subs_yn = flag;
    setChannelInfo({ ...channelInfo });
  };

  const params = {
    orderBy: orderBy || '0',
    holderYn: holderYn ? 'Y' : 'N',
    stockCd,
    channelId,
  };

  const {
    data,
    isLoading,
    // isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useChannelPostQueryV2({
    rowPerPage: POST_VIEW_COUNT,
    queryFn: fetchingPosts,
    params: params,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: [channelQueryKey],
    mutationFn: ({ post, actionFunc }) => updateReactionFunc(post, actionFunc),
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([channelQueryKey, params], oldData => {
        const temp = { ...oldData };

        for (let page of temp.pages) {
          const idx = page.data?.findIndex(
            item => item.post_id === data.post_id,
          );

          if (idx !== -1) {
            page.data[idx] = data;
            break;
          }
        }

        return temp;
      });
    },
  });

  const updateReactionFunc = async (post, actionFunc) => {
    return await actionFunc(post);
  };

  useEffect(() => {
    fetchingChInfo();
    refetch();
  }, [loginedUser]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    history.listen(({ action }) => {
      if (isSlide && action === 'POP') {
        return false;
      }
      return true;
    });

    return () => {
      setLayoutData({ ...layoutData, channelInfo: undefined });
    };
  }, []);

  return (
    <>
      {channelInfo && (
        <ChannelHeader
          channel={channelInfo}
          updateChnlInfo={updateSub}
          isSlide={isSlide}
        />
      )}
      <div css={styles.listHeader}>
        <PostListHeader
          setOrderBy={setOrderBy}
          setHolderYn={
            channelInfo &&
            (channelInfo.country_cd === COUNTRY_CD_KR ||
              channelInfo.country_cd === COUNTRY_CD_US) &&
            setHolderYn
          }
          holderYn={holderYn}
          isChDetail
        />
      </div>
      <PostList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        postList={!isLoading ? data?.pages : undefined}
        reloadRef={ref}
        mutate={mutate}
        isChDetail
      />
      {!isEmpty(data?.pages) && hasNextPage && !isFetchingNextPage && (
        <div
          css={css`
            min-height: ${stringToVW('30px')};
            width: 100%;
            background: 'none';
            color: #b1f54d;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        ></div>
      )}
    </>
  );
};

export default ChannelDetail;
