import { useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { toVW, stringToVW } from '@style/globalStyle';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F9FDF4',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '84dvw',
    padding: stringToVW('16px'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#F9FDF4',
    border: '1px solid #84C13D',
    color: '#333',
    borderRadius: toVW('5.3px'),
    fontFamily: 'NanumBarunGothic',
    margin: stringToVW('12px 6px 0px 6px') + '!important',
    '& > div': {
      fontSize: toVW('12px'),
      lineHeight: toVW('20px'),
      color: '#333',
    },
    '& > span::before': {
      border: '1px solid #84C13D',
    },
  },
}));

const HtmlTooltip = ({ children, Icon }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ display: 'flex' }}>
        <StyledTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableTouchListener
          disableFocusListener
          disableHoverListener
          title={children}
          onClick={e => {
            setOpen(!open);
          }}
        >
          {Icon}
        </StyledTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default HtmlTooltip;
