import { updateLoginedAdmin } from '@store/app/adminReducer';
import axiosUtils, {
  getAdminIdParam,
  getAdminIdParam2,
} from '../../axiosUtils';
import { removeCookie } from '@lib/cookieUtils';

export const getChannelList = async param => {
  return await axiosUtils
    .post('/admin/channellist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getChannelDetail = async param => {
  return await axiosUtils
    .post('/admin/channelview', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getChannelDel = async param => {
  return await axiosUtils
    .post('/admin/channeldel', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getChannelRecomm = async param => {
  return await axiosUtils
    .post('/admin/channelrecomm', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getChannelInsert = async param => {
  return await axiosUtils
    .post('/admin/channelinsert', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getMemberBlock = async param => {
  return await axiosUtils
    .post('/admin/memblock', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getMemberChange = async param => {
  return await axiosUtils
    .post('/admin/memchange', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getMemberNoticeList = async param => {
  return await axiosUtils
    .post('/admin/memnoticelist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getMemberStockList = async param => {
  return await axiosUtils
    .post('/admin/memstocklist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
