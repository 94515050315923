import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { cloneDeep, isEmpty } from 'lodash';
import { Button, SvgIcon } from '@mui/material';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { useSelector } from 'react-redux';
import { callMTSFunction } from '@lib/commonUtils';
import { CALL_MTS_ACTION } from '@data/constants';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from '@page/app/profile/EditProfile';

const PostListHeader = ({
  isSubscribe,
  setOrderBy,
  holderYn,
  setHolderYn,
  followYn,
  setFollowYn,
  line,
}) => {
  const styles = {
    container: css`
      padding: ${stringToVW('32px 24px 20px 24px')};
      border-radius: ${stringToVW('32px 32px 0px 0px')};
      background: #fff;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: ${toVW('20px')};
    `,
    option: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: ${toVW('25.5px')};
    `,
    filterContainer: css`
      display: flex;
      align-items: center;
      gap: ${toVW('10px')};
    `,
    homeOption: css`
      width: 100%;
      height: auto;
      padding-bottom: ${toVW('10.7px')};
      border-bottom: 1px solid var(--essential-line-line-200025, #f0f0f0);
    `,
  };
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  const [sortOptions, setSortOptions] = useState([
    { text: '최신순', value: '0', active: true },
    { text: '인기순', value: '1', active: false },
  ]);

  const [anchor, setAnchor] = useState(null);

  const loginedUser = useSelector(state => state.auth.loginedUser);

  const handleClick = event => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const open = Boolean(anchor);

  useEffect(() => {
    if (hash) {
      let type = hash.replace('#', '');
      sortOptions.forEach(obj => {
        if (obj.value === type) {
          obj.active = true;
          setOrderBy(type);
        } else obj.active = false;
      });
    }
  }, []);

  return (
    <div css={[styles.option, ...[line && styles.homeOption]]}>
      <div css={globalStyle.sortBtnGroup}>
        {sortOptions.map((item, index) => {
          const compose =
            index !== 0
              ? [
                  globalStyle.sortBtn,
                  css`
                    padding-right: ${toVW('14.7px')};
                  `,
                ]
              : [globalStyle.sortBtn];
          if (item.active) compose.push(globalStyle.sortActive);

          return (
            <div
              key={item.value}
              css={compose}
              onClick={() => {
                sortOptions.forEach(obj => {
                  if (obj.value === item.value) obj.active = true;
                  else obj.active = false;
                });

                setSortOptions(cloneDeep(sortOptions));
                //
                if (pathname === '/') {
                  navigate(`/#${item.value}`, { replace: true });
                } else if (pathname === '/subscribe') {
                  navigate(`/subscribe#${item.value}`, { replace: true });
                }
                setOrderBy(item.value);
              }}
            >
              {item.text}
            </div>
          );
        })}
      </div>
      {setHolderYn && (
        // <BasePopup id={id} open={open} anchor={anchor}>
        //   <PopupBody>The content of the Popup.</PopupBody>
        // </BasePopup>
        <div css={styles.filterContainer}>
          {isSubscribe && (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: ${toVW('5.3px')};
                ${globalStyle.fontNanu18}
              `}
              aria-describedby="options"
              onClick={handleClick}
            >
              옵션
              <SvgIcon sx={{ fontSize: toVW('14px'), margin: toVW('2px') }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.2354 3.09814L13.353 3.09814"
                    stroke="#333333"
                    strokeLinecap="round"
                  />
                  <path
                    d="M0.646973 3.09814H6.99991"
                    stroke="#333333"
                    strokeLinecap="round"
                  />
                  <path
                    d="M0.646973 7.3335H13.3529"
                    stroke="#333333"
                    strokeLinecap="round"
                  />
                  <path
                    d="M0.646973 11.5688H2.76462"
                    stroke="#333333"
                    strokeLinecap="round"
                  />
                  <path
                    d="M7 11.5688L13.3529 11.5688"
                    stroke="#333333"
                    strokeLinecap="round"
                  />
                  <circle
                    cx="4.8823"
                    cy="11.5688"
                    r="1.61765"
                    stroke="#333333"
                  />
                  <circle
                    cx="9.11765"
                    cy="3.09812"
                    r="1.61765"
                    stroke="#333333"
                  />
                </svg>
              </SvgIcon>
            </div>
          )}

          {!isSubscribe && (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: ${toVW('10px')};
              `}
            >
              <span
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: ${toVW('10px')};
                `}
              >
                <div
                  css={css`
                    color: #000;
                    text-align: center;
                    ${globalStyle.fontNanu18}
                    font-weight: 400;
                  `}
                >
                  주주 전용글만
                </div>
                <Button
                  disableRipple
                  css={css`
                    width: ${toVW('36px')};
                    border-radius: ${toVW('6px')};
                    font-weight: 600;
                    min-width: ${toVW('32px')};
                    font-size: ${toVW('12px')};
                    line-height: ${toVW('16px')};
                    color: #fff;
                    padding: ${stringToVW('4px 6px')};
                    ${holderYn
                      ? `
                    background-color: #84c13d;
                    :hover {
                      background-color: #84c13d;
                    }`
                      : `
                    background-color: #666;
                    :hover {
                      background-color: #666;
                    }`}
                  `}
                  onClick={() => {
                    if (isEmpty(loginedUser)) {
                      callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
                      return;
                    }
                    setHolderYn(!!!holderYn);
                  }}
                >
                  {holderYn ? 'ON' : 'OFF'}
                </Button>
              </span>
            </div>
          )}
          <Backdrop
            sx={{
              color: '#000',
              opacity: 0.4,
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={open}
            onClick={e =>
              e.target.getAttribute('aria-hidden') && setAnchor(null)
            }
          >
            <BasePopup
              id="options"
              open={open}
              anchor={anchor}
              placement="bottom-end"
              css={css`
                z-index: 1201;
                margin-top: ${toVW('14px')};
              `}
            >
              <Box
                css={css`
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  border-radius: ${toVW('10.7px')};
                  padding: ${stringToVW('6.7px 16px')};
                  background-color: #fff;
                  flex-direction: column;
                `}
              >
                <span
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: ${toVW('6.7px')};
                    height: ${toVW('40px')};
                  `}
                >
                  <div
                    css={css`
                      color: #000;
                      text-align: center;
                      ${globalStyle.fontNanu21}
                      font-weight: 400;
                    `}
                  >
                    팔로우한 유저글만
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={followYn}
                        onChange={e => {
                          setFollowYn(e.target.checked);
                        }}
                      />
                    }
                    label=""
                    css={css`
                      padding: 0;
                      margin: 0;
                    `}
                  />
                  {/* <Button
                    disableRipple
                    css={css`
                      border-radius: ${toVW('6px')};
                      padding: ${stringToVW('4px 6px')};
                      min-width: ${toVW('32px')};
                      font-weight: 600;
                      ${globalStyle.fontNanu15};
                      line-height: normal;
                      color: #fff;
                      ${followYn
                        ? `
                  background-color: #84c13d;
                  :hover {
                    background-color: #84c13d;
                  }`
                        : `
                  background-color: #666;
                  :hover {
                    background-color: #666;
                  }`}
                    `}
                    onClick={() => {
                      setFollowYn(!followYn);
                    }}
                  >
                    {followYn ? 'ON' : 'OFF'}
                  </Button> */}
                </span>
                <span
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: ${toVW('6.7px')};
                    height: ${toVW('40px')};
                  `}
                >
                  <div
                    css={css`
                      color: #000;
                      text-align: center;
                      ${globalStyle.fontNanu21}
                      font-weight: 400;
                    `}
                  >
                    주주 전용글만
                  </div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={holderYn}
                        onChange={e => {
                          setHolderYn(e.target.checked);
                        }}
                      />
                    }
                    label=""
                    css={css`
                      padding: 0;
                      margin: 0;
                    `}
                  />
                </span>
              </Box>
            </BasePopup>
          </Backdrop>
        </div>
      )}
    </div>
  );
};

export default PostListHeader;
