import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { Link, useLocation, useSearchParams } from 'react-router-dom';
import NamuButton from './NamuButton';
import { CALL_MTS_ACTION, NAMU_BTN_COLOR } from '@data/constants';
import { getSubChnl } from '@lib/api-call/app/subsribeApi';
import { useDispatch, useSelector } from 'react-redux';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { callMTSFunction } from '@lib/commonUtils';
import { noDecoraionLink } from '@style/common/commonStyles';
import BackButton from './BackButton';
import { setClickedChannelPostId } from '@store/app/channelReducer';
import { isEmpty } from 'lodash';
import LoungeIcon from '../icon/LoungeIcon';

const ChannelHeader = ({ channel, backLink, updateChnlInfo, isSlide }) => {
  const location = useLocation();

  // location.search를 통해 현재 페이지의 쿼리 매개변수를 가져옴
  const queryParams = new URLSearchParams(location.search);

  const loginedUser = useSelector(state => state.auth.loginedUser);
  const [init, setInit] = useState(false);
  // const [fontSize, setFontSize] = useState(16); // 초기 폰트 크기 설정
  // const ref = useRef(null);
  const dispatch = useDispatch();
  const { state: routerState } = useLocation();

  const updateSub = async () => {
    const flagChange = await getSubChnl({
      p_user_id: loginedUser?.user_id || loginedUser?.USER_ID,
      p_subs_yn: channel.subs_yn !== '1' ? 'Y' : 'N',
      p_stock_cd: channel.stock_cd,
      p_channel_id: channel.channel_id,
    });

    if (flagChange.message_cd !== '0') return;
    updateChnlInfo({ flag: channel.subs_yn === '0' ? '1' : '0' });
  };
  const styles = {
    container: css`
      position: fixed;
      z-index: 9;
      min-height: ${stringToVW('56px')};
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${stringToVW('12.67px 16px')};
      width: 100%;
      gap: ${stringToVW('5px')};
    `,
    headerDetail: css`
      flex-wrap: nowrap;
      flex: 1 0 0;
      display: flex;
      align-items: center;
      gap: ${stringToVW('12px')};
    `,
    channelName: css`
      flex: 1 0 0;
      color: #000;
      ${globalStyle.fontNanu24}
      font-weight: 600;
      transform: translateY(${stringToVW('2px')});
    `,
  };

  const getBackLink = () => {
    if (
      !isEmpty(routerState?.prevLink) &&
      routerState.prevLink === '/search/result'
    ) {
      return '/search';
      // } else if (routerState && routerState.prevLink) {
      //   return routerState.prevLink; // 뒤로가기
    } else {
      // return '/';
      return -1;
    }
  };

  // useEffect(() => {
  //   const containerWidth = ref.current.clientWidth;
  //   if (ref.current.scrollWidth > containerWidth) {
  //     setFontSize(prevSize => prevSize - 1); // 폰트 크기를 1씩 줄임
  //   }
  // }, [ref.current?.scrollWidth, fontSize]);

  return (
    <>
      <div css={styles.container}>
        <div
          css={styles.headerDetail}
          // ref={ref}
        >
          {(!isSlide || queryParams.get('backYn') === 'Y') && (
            <BackButton
              backLink={getBackLink()}
              backButtonClickListener={() => {
                dispatch(setClickedChannelPostId(undefined));
              }}
              closeAll={isSlide}
            />
          )}
          <div
            css={styles.channelName}
            onClick={() => {
              if (window.scrollY !== 0) {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {channel?.channel_nm}
          </div>
        </div>
        {!isSlide ? (
          <NamuButton
            color={
              !channel || channel.subs_yn !== '1'
                ? NAMU_BTN_COLOR.DARK_GRAY_BLUE
                : NAMU_BTN_COLOR.OUTLINE
            }
            onClick={e => {
              window.GA_Event('커뮤니티', '채널', '채널구독');
              updateSub();
            }}
            size="nonePadding"
          >
            {!channel || channel.subs_yn !== '1' ? '채널구독' : '구독중'}
          </NamuButton>
        ) : queryParams.get('current') === 'Y' ? (
          <>
            <LoungeIcon
              isActive={true}
              width={toVW('25.5px')}
              height={toVW('20px')}
              clickEvent={e => {
                callMTSFunction(CALL_MTS_ACTION.MOVE_TO_HOME);
              }}
            />
            <NamuButton
              color={NAMU_BTN_COLOR.OUTLINE}
              onClick={e => {
                callMTSFunction(CALL_MTS_ACTION.MOVE_TO_CHANNEL, [
                  channel.stock_cd,
                  channel.channel_id,
                ]);
              }}
            >
              글쓰기
              {/* 커뮤니티홈 */}
            </NamuButton>
          </>
        ) : (
          <NamuButton
            color={NAMU_BTN_COLOR.OUTLINE}
            onClick={e => {
              callMTSFunction(CALL_MTS_ACTION.MOVE_TO_HOME);
            }}
          >
            커뮤니티홈
          </NamuButton>
        )}
      </div>
      <div
        css={[
          styles.channelName,
          css`
            width: 60dvw;
          `,
        ]}
      >
        {channel?.channel_nm}
      </div>

      <div
        css={css`
          margin-top: -${stringToVW('24px')};
          height: ${stringToVW('56px')};
        `}
      />
    </>
  );
};

ChannelHeader.propTypes = {
  channel: PropTypes.object,
  backLink: PropTypes.string,
};

export default ChannelHeader;
