import React from 'react';
import { css } from '@emotion/react';
import HeaderBasic from '@component/app/common/HeaderBasic';
import { useParams } from 'react-router-dom';
import TermsOfUse from '@component/app/profile/TermsOfUse';
import RulesOfUse from '@component/app/profile/RulesOfUse';
import { globalStyle, stringToVW } from '@style/globalStyle';
import FAQPage from '@component/app/profile/FAQPage';
import WithdrawPage from '@component/app/profile/WithdrawPage';

const contentsMap = {
  terms: { title: '서비스 약관', content: <TermsOfUse /> },
  rules: { title: '서비스 이용규칙', content: <RulesOfUse /> },
  faq: { title: '서비스 FAQ', content: <FAQPage /> },
  withdraw: {
    title: '커뮤니티 해지',
    content: <WithdrawPage />,
  },
};

const SettingContent = () => {
  const { contentName } = useParams();

  const styles = {
    wrapper: css`
      font-style: normal;
      min-height: 95vh;
    `,
  };

  return (
    <div css={styles.wrapper}>
      <HeaderBasic title={contentsMap[contentName]?.title} statusUser="" />
      <div
        css={css`
          padding: ${stringToVW('21.3px 16px')};
          ${globalStyle.fontNanu21}
        `}
      >
        {contentsMap[contentName]?.content}
      </div>
    </div>
  );
};

export default SettingContent;
