/* eslint-disable react-hooks/exhaustive-deps */
import {
  Outlet,
  ScrollRestoration,
  useNavigate,
  // useLocation,
} from 'react-router-dom';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import Navbar, { navbarHeight } from '@component/app/common/Navbar';
import FloatButton, {
  floatBtnBottom,
  floatBtnBottomWithNavbar,
  floatBtnHeight,
} from '@component/app/common/FloatButton';
import { useEffect, useState } from 'react';
import withLocalLayout from './withLocalLayout';
import { isEmpty } from 'lodash';
import { getUserInfo, loginRequest } from '@lib/api-call/app/authApi';
import { jwtDecode } from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateAfterAuthMovePage,
  updateLoginedUser,
} from '@store/app/authReducer';
import { Alert, Button, Snackbar, Typography } from '@mui/material';
import {
  CALL_MTS_ACTION,
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
} from '@data/constants';
import { callMTSFunction, isMobileDevice, isDev } from '@lib/commonUtils';
import SvgIcon from '@mui/material/SvgIcon';
import {
  closeSnackbar,
  setAlertInfo,
  setBlockPopupIsShow,
  closeReloadPopup,
} from '@store/app/commonReducer';
import Popup from '@component/app/common/PopupMui';
import { getDateTwoWeeksLater } from '@lib/dateTimeUtils';
import { globalStyle, toVW, stringToVW } from '@style/globalStyle';
import './AppLayout.css';

/**
 * TODO: 로컬에서만 전체 wrapper margin 0 auto 사용
 * -> 추후에 운영 시 지우거나 로컬에서만 적용되도록 변경 필요
 */
const wrapperCss = css`
  //width: 540px;
  width: 100%;
  background-color: #fff;
  //user-select: none;
  -webkit-touch-callout: none;
`;

const localTestStyle = css`
  //margin: 0 auto;
`;

//TODO: test이후 삭제
const userList = [
  'VYTTzQjgY/RErXjCHBqryCULx1T0x2aIX/fobh68WdU=',
  'rxoXNOjRKClKe3lXTrwZ9W+f5HDUT8uflcWF18L4TO4=',
  '7KCkATAcNct/+YVizFX82EA+rjfRDdrazrPztcYLgGI=',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000001',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000002',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000003',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000004',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000005',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000006',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000010',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000017',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000018',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000019',
  // 'aaaaaaaaaabbbbbbbbbbccccccccccddddd000000020',
  // 'sylas-number-one',
  'testnnnnnnnnnnn',
  'NH_POST_BOT1',
];

/**
 * TODO: 로컬에만 배경색 적용하여 wrapperCss영역 감싸도록 해둠
 * -> 추후에 운영 시 지우거나 로컬에서만 적용되도록 변경 필요
 */
const AppLayout = ({ hasNavbar, hasFloatingButton }) => {
  let bottomPadding = 0;

  if (hasNavbar) bottomPadding += navbarHeight;
  if (hasFloatingButton) bottomPadding += floatBtnHeight;

  if (hasFloatingButton && hasNavbar) {
    bottomPadding += floatBtnBottomWithNavbar - navbarHeight;
  } else if (hasFloatingButton && !hasNavbar) {
    bottomPadding += floatBtnBottom;
  }

  const [layoutData, setLayoutData] = useState({
    channelInfo: undefined,
  });
  const [popupContent, setPopupContent] = useState({
    title: '',
    content: <span></span>,
  });

  const [isHide, setIsHide] = useState(true);

  const loginedUser = useSelector(state => state.auth.loginedUser);
  const afterAuthMovePage = useSelector(state => state.auth.afterAuthMovePage);
  const isOpenSnackbar = useSelector(state => state.common.isOpenSnackbar);
  const isOpenReloadPopup = useSelector(
    state => state.common.isOpenReloadPopup,
  );
  const alertInfo = useSelector(state => state.common.alertInfo);
  const blockPopupIsShow = useSelector(state => state.common.blockPopupIsShow);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessToken, setAccToken, removeAcctoken] = useCookies([
    COOKIE_NAME_ACC_TOKEN,
  ]);

  // Do not clear the refresh token variable.
  // Setter cannot be used when the order is changed because the return value comes from the array
  const [refreshToken, setRefreshToken, removefreshToken] = useCookies([
    COOKIE_NAME_REFRESH_TOKEN,
  ]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnackbar());
  };

  const toMail = () => {
    window.location.href = 'mailto:community_support@nhqv.com';
  };

  const closeDialog = () => {
    dispatch(setBlockPopupIsShow(false));
  };

  useEffect(() => {
    if (!isEmpty(accessToken) && accessToken[COOKIE_NAME_ACC_TOKEN]) {
      const decodeAccToken = jwtDecode(accessToken[COOKIE_NAME_ACC_TOKEN]);
      if (isEmpty(loginedUser) || loginedUser.USER_ID !== decodeAccToken.sub) {
        (async () => {
          const userInfo = await getUserInfo();
          if (userInfo) {
            dispatch(updateLoginedUser(userInfo));
          }
        })();
      }
    } else if (loginedUser) {
      dispatch(updateLoginedUser(undefined));
    }
  }, [accessToken]);

  useEffect(() => {
    if (!isEmpty(loginedUser) && !isEmpty(afterAuthMovePage)) {
      navigate(afterAuthMovePage);
    }

    if (!isEmpty(loginedUser) && loginedUser.USER_STAT_CD === '1') {
      const newPopupCont = { ...popupContent };

      if (loginedUser.USER_LOCK !== 'Y') {
        newPopupCont.title = (
          <div
            css={css`
              ${globalStyle.fontNanu24};
              font-weight: 400;
              text-align: center;
            `}
          >
            계정이 일시적으로
            <br />
            활동정지 되었습니다
          </div>
        );

        const date = getDateTwoWeeksLater(loginedUser.USER_LOCK_DT);
        newPopupCont.content = (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${stringToVW('13.3px')};
            `}
          >
            <div
              css={css`
                ${globalStyle.fontNanu21};
                font-weight: 400;
                text-align: center;
                color: #666666;
              `}
            >
              정지기간에는 게시글작성/반응 등의 활동이
              <br />
              불가하며, 게시글 열람만 가능합니다.
            </div>
            <div
              css={css`
                ${globalStyle.fontNanu21};
                font-weight: 400;
                text-align: center;
                color: #666666;
              `}
            >
              정지기간 : {date[0]} - {date[1]}
            </div>
            <div
              css={css`
                ${globalStyle.fontNanu21};
                font-weight: 400;
                text-align: center;
                color: #666666;
              `}
            >
              문의 : community_support@nhqv.com
            </div>
          </div>
        );
      } else {
        newPopupCont.title = <span>계정이 관리자에 의해 차단되었습니다.</span>;

        newPopupCont.content = (
          <span>
            계정차단 시 게시글작성/반응 등의 활동이 불가하며,
            <br /> 게시글 열람만 가능합니다.
          </span>
        );
      }

      setPopupContent(newPopupCont);
      dispatch(setBlockPopupIsShow(true));
    }

    dispatch(updateAfterAuthMovePage(''));
  }, [loginedUser]);

  useEffect(() => {
    if (!isOpenSnackbar) {
      dispatch(setAlertInfo({ msg: '', severity: 'info' }));
    }
  }, [isOpenSnackbar]);

  useEffect(() => {
    return () => {
      window.NMCommunityInterface = undefined;
      dispatch(updateAfterAuthMovePage(undefined));
      removeAcctoken(COOKIE_NAME_ACC_TOKEN, { path: '/' });
      removefreshToken(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
    };
  }, []);

  window.NMCommunityInterface = {
    updateLoginToken: ({
      [COOKIE_NAME_ACC_TOKEN]: accToken,
      [COOKIE_NAME_REFRESH_TOKEN]: reToken,
    }) => {
      setAccToken(COOKIE_NAME_ACC_TOKEN, accToken, { path: '/' });
      setRefreshToken(COOKIE_NAME_REFRESH_TOKEN, reToken, { path: '/' });
    },
    expireLoginToken: () => {
      removeAcctoken(COOKIE_NAME_ACC_TOKEN, { path: '/' });
      removefreshToken(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
    },
    goPrevPage: () => {
      navigate(-1);
    },
    hideTestArea: () => {
      // alert('hideTestArea');
      // setTestAreaShow(false);
    },
    webReload: () => {
      navigate(0);
    },
    goToPage: path => {
      navigate(path);
    },
  };

  // useEffect(() => {
  //   if (!isEmpty(accessToken) && accessToken[COOKIE_NAME_ACC_TOKEN]) {
  //     (async () => {
  //       const userInfo = await getUserInfo();
  //       if (userInfo) {
  //         dispatch(updateLoginedUser(userInfo));
  //       }
  //     })();
  //   } else if (loginedUser) {
  //     dispatch(updateLoginedUser(undefined));
  //   }
  // }, [location.pathname]);

  // const location = useLocation();

  // useEffect(() => {
  //   fetch('/meta.json')
  //     .then(response => response.json())
  //     .then(meta => {
  //       const latestVersionDate = meta.buildDate.toString();
  //       const version = localStorage.getItem('version');

  //       if (version !== latestVersionDate) {
  //         localStorage.setItem('version', latestVersionDate);
  //         window.location.reload();
  //       }
  //     });
  // }, [location?.pathname]);

  return (
    <div
      css={[
        wrapperCss,
        process.env.NODE_ENV !== 'production' && localTestStyle,
      ]}
    >
      <ScrollRestoration />
      <div
        css={css`
          /* min-height: ${hasNavbar ? 'calc(85vh - 74px - 80px)' : '85vh'}; */
          padding-bottom: ${toVW(`${bottomPadding}px`)};
        `}
      >
        {!isMobileDevice() && isDev() && (
          <div>
            <Button
              size="small"
              sx={{ position: 'fixed', zIndex: 1001, height: '4px' }}
              onClick={() => setIsHide(!isHide)}
            >
              hide TestArea
            </Button>
            <div
              css={css`
                border: 3px solid black;
                display: ${isHide ? 'none' : 'block'};
                //position: fixed;
                overflow: hidden;
              `}
            >
              <Typography variant="h3" gutterBottom>
                TestArea
              </Typography>
              <div>
                <button
                  onClick={() => {
                    callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
                  }}
                >
                  CheckUser
                </button>
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  BackBtn
                </button>
              </div>
              <select
                onChange={e => {
                  if (e.target.value) {
                    (async () => {
                      const { accessToken: accToken, refreshToken: reToken } =
                        await loginRequest({
                          userId: e.target.value,
                        });

                      if (!isEmpty(accToken) && !isEmpty(reToken)) {
                        setAccToken(COOKIE_NAME_ACC_TOKEN, accToken, {
                          path: '/',
                        });
                        setRefreshToken(COOKIE_NAME_REFRESH_TOKEN, reToken, {
                          path: '/',
                        });
                      }
                    })();
                  } else {
                    removeAcctoken(COOKIE_NAME_ACC_TOKEN, { path: '/' });
                    removefreshToken(COOKIE_NAME_REFRESH_TOKEN, {
                      path: '/',
                    });
                  }
                }}
              >
                <option value="">선택</option>
                <option value="oF4nTHZQixSwKiTj22I87yMfhDW53om0loCIc7xZudA=">
                  ACCOUNT TEST
                </option>
                <option value="peWLZ0UoVwrPsdakyBWvht87CuInkrip5EshU9CxpYc=">
                  ACCOUNT BADGE
                </option>
                {!isEmpty(userList) &&
                  userList.map((item, idx) => (
                    <option key={'locallogintest_' + idx} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <Typography variant="body1" gutterBottom>
                loginedUser: {loginedUser ? loginedUser?.USER_ID : '없음'}
              </Typography>
              <Typography variant="body1" gutterBottom>
                UserName: {loginedUser ? loginedUser?.USER_NM : '없음'}
              </Typography>
              <Typography variant="body1" gutterBottom>
                UserAgent: {navigator.userAgent}
              </Typography>
            </div>
          </div>
        )}

        <Outlet context={[layoutData, setLayoutData]} />
      </div>
      {hasFloatingButton && (
        <FloatButton
          hasNavbar={hasNavbar}
          channelInfo={layoutData?.channelInfo}
        />
      )}
      {hasNavbar && <Navbar />}

      {alertInfo && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isOpenSnackbar}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
          sx={{ bottom: 58 }}
        >
          <Alert
            severity={alertInfo.severity}
            sx={{
              borderRadius: '1000px',
              backgroundColor: 'rgba(48, 48, 52, 0.90)',
              color: '#FFF',
            }}
            iconMapping={{
              success: (
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle
                      cx="8.66667"
                      cy="8.83317"
                      r="8.33333"
                      fill="#84C13D"
                      stroke="#84C13D"
                      strokeWidth="0.666667"
                    />
                    <path
                      d="M5.3334 8.34025L8.01674 11.1663L12.3334 6.83301"
                      stroke="white"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              ),
              error: (
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.625 8.8335C0.625 4.20864 4.37514 0.458496 9 0.458496C13.6257 0.458496 17.375 4.20869 17.375 8.8335C17.375 13.4583 13.6257 17.2085 9 17.2085C4.37514 17.2085 0.625 13.4584 0.625 8.8335Z"
                      fill="#D73838"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.00407 12.6636C8.63588 12.6636 8.3374 12.3651 8.3374 11.9969L8.3374 8.31441C8.3374 7.94622 8.63588 7.64774 9.00407 7.64774C9.37226 7.64774 9.67074 7.94622 9.67074 8.31441L9.67074 11.9969C9.67074 12.3651 9.37226 12.6636 9.00407 12.6636Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.67082 5.66976C9.67082 6.03795 9.37234 6.33643 9.00415 6.33643L8.99582 6.33643C8.62763 6.33643 8.32915 6.03795 8.32915 5.66976C8.32915 5.30157 8.62763 5.00309 8.99582 5.00309L9.00415 5.00309C9.37234 5.00309 9.67082 5.30157 9.67082 5.66976Z"
                      fill="white"
                    />
                  </svg>
                </SvgIcon>
              ),
            }}
          >
            {alertInfo.msg}
          </Alert>
        </Snackbar>
      )}
      {loginedUser && loginedUser.USER_STAT_CD === '1' && (
        <Popup
          open={blockPopupIsShow}
          data={popupContent}
          onClose={closeDialog}
          cancelEvent={toMail}
          cancelButton={loginedUser.USER_LOCK === 'Y'}
          cancelText="문의하기"
          confirmText="확인"
        />
      )}
      <Popup
        open={isOpenReloadPopup}
        data={{
          title: <div>커뮤니티 기능 업데이트</div>,
          content: <div>개선된 새로운 버전의 커뮤니티를 만나보세요.</div>,
        }}
        cancelButton={false}
        onClose={() => {
          dispatch(closeReloadPopup());
          window.location.reload();
        }}
        confirmText="확인"
      />
    </div>
  );
};

AppLayout.propTypes = {
  hasNavbar: PropTypes.bool,
  hasFloatingButton: PropTypes.bool,
};

const exportLayout =
  process.env.NODE_ENV !== 'production'
    ? withLocalLayout(AppLayout)
    : AppLayout;

export default exportLayout;
