import React from 'react';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { css } from '@emotion/react';

const GroupButton = ({ sortOptions, onClick, value }) => {
  const [type, setType] = React.useState(sortOptions[0]?.value);
  return (
    <div
      css={css`
        display: flex;
        height: ${stringToVW('32px')};
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div css={globalStyle.sortBtnGroup}>
        {sortOptions.map((item, index) => {
          return (
            <div
              key={item.value}
              css={[
                globalStyle.sortBtn,
                css`
                  padding-right: ${stringToVW('14.7px')};
                `,
                ...[item.value === value && globalStyle.sortActive],
              ]}
              onClick={() => {
                setType(item.value);
                onClick(item.value);
              }}
            >
              {item.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupButton;
