import axiosUtils, {
  getAdminIdParam,
  getAdminIdParam2,
} from '../../axiosUtils';
import { removeCookie } from '@lib/cookieUtils';
import { updateLoginedAdmin } from '@store/app/adminReducer';

export const getNoticeList = async param => {
  return await axiosUtils
    .post('/admin/noticelist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};

export const getNoticeView = async param => {
  return await axiosUtils
    .post('/admin/noticeview', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getPostCommentList = async param => {
  return await axiosUtils
    .post('/admin/commentlist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getPostCommentReplyList = async param => {
  return await axiosUtils
    .post(
      '/admin/commentreplylist',
      { ...param },
      { headers: getAdminIdParam2() },
    )
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};

export const getNoticeBlock = async param => {
  return await axiosUtils
    .post('/admin/noticeblock', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};

export const getCommentBlock = async param => {
  return await axiosUtils
    .post('/admin/commentblock', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getCommentReplyBlock = async param => {
  return await axiosUtils
    .post(
      '/admin/commentreplyblock',
      { ...param },
      { headers: getAdminIdParam2() },
    )
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
