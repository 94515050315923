export const NAMU_BTN_COLOR = {
  DARK_GRAY_BLUE: 'darkGrayBlue',
  STRONG_GRAY: 'strongGray',
  GRAY: 'gray',
  BLUE_GRAY: 'blueGray',
  OUTLINE: 'outline',
  NO_BORDER: 'noBorder',
};

export const HEADER_KEY_ACC_TOKEN = 'Access-Token';
export const JS_KEY_ACC_TOKEN = 'access-token';
export const HEADER_KEY_REFRESH_TOKEN = 'Refresh-Token';
export const JS_KEY_REFRESH_TOKEN = 'refresh-token';

export const COOKIE_NAME_ACC_TOKEN = 'access_token';
export const COOKIE_NAME_REFRESH_TOKEN = 'refresh_token';

export const CALL_MTS_ACTION = {
  CLOSE_ALL: 'CloseAll',
  CHECK_USER: 'CheckUser',
  SHARE_POST: 'SharePost',
  PERMIT_CAMERA: 'PermitCamera',
  MOVE_TO_HOME: 'MoveToHome',
  MOVE_TO_CHANNEL: 'MoveToChannel',
  CLOSE_VIEW: 'CloseView',
  WITHDRAW: 'Terminate',
};

export const COUNTRY_CD_KR = 'KOR';
export const COUNTRY_CD_US = 'USA';

export const HAVE_PREV_BACK_LINK = ['/channel', '/profile'];

export const POST_VIEW_COUNT = 10;
