import { css } from '@emotion/react';
import { useEffect } from 'react';

import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateWritePostImages,
  updateWritePostItem,
} from '@store/app/postReducer';

import IconUploadImage from '@asset/icons/common/icon-upload-image.svg';
import { ReactComponent as IconRemove } from '@asset/icons/common/nds_icon_remove.svg';

import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import VisuallyHiddenInput from '../common/VisuallyHiddenInput';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { globalStyle, pxFromVW, stringToVW, toVW } from '@style/globalStyle';

const Input = styled(TextareaAutosize)`
  border: none;
  outline: none;
  display: flex;
  padding: ${stringToVW('16px 0px')};
  align-items: center;
  gap: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--essential-line-line-200025, #f0f0f0);
  width: 100%;
  color: #000;
  outline: none;
  ${globalStyle.fontNanu24}

  font-weight: 700;
  resize: none;
`;

const Textarea = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  padding-top: ${stringToVW('16px')};
  border: none;
  outline: none;

  color: #333;
  ${globalStyle.fontNanu21}
  font-weight: 400;
`;

const MAX_IMG_CNT = 10;

const InputPost = () => {
  const writePostItem = useSelector(state => state.post.writePostItem);
  const writePostImages = useSelector(state => state.post.writePostImages);
  const alertInfo = useSelector(state => state.common.alertInfo);
  const dispatch = useDispatch();

  const styles = {
    wrapper: css`
      display: flex;
      padding: ${stringToVW('0px 16px')};
      flex-direction: column;
      align-self: stretch;
    `,
    post: css`
      display: flex;
      padding: ${stringToVW('13.33px 16px 16px 16px')};
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      align-self: stretch;
      border-radius: ${stringToVW('20px')};
      border: 1px solid var(--essential-line-line-200025, #f0f0f0);
      background: #fff;
    `,
  };

  const isOpenSnackbar = useSelector(state => state.common.isOpenSnackbar);
  const handlePostData = (e, key) => {
    const newItem = {
      ...writePostItem,
      [key]: e.target.value,
    };

    dispatch(updateWritePostItem(newItem));

    if (
      key === 'contents' &&
      e.target.value?.length >= 2000 &&
      !isOpenSnackbar
    ) {
      dispatch(
        setAlertInfo({
          msg: '입력은 최대 2,000자까지 가능합니다.',
          severity: 'error',
        }),
      );
      dispatch(openSnackbar());
    }
  };

  const onChangeFiles = e => {
    const files = e.target.files;
    const maxFileSize = 5242880;
    const newInputLength = files.length;

    for (let i = 0; i < newInputLength; i++) {
      if (files[i].size > maxFileSize) {
        const newAlertInfo = { ...alertInfo };
        newAlertInfo.severity = 'info';
        newAlertInfo.msg = '각 이미지별 용량은 5MB를 초과할 수 없습니다.';
        dispatch(setAlertInfo(newAlertInfo));
        dispatch(openSnackbar());

        return;
      }
    }

    const selectedLength = writePostImages.length;

    if (newInputLength + selectedLength <= MAX_IMG_CNT) {
      const fileArray = Array.from(files);

      Promise.all(
        fileArray.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = event => {
              resolve({ img: event.target.result, file: file });
            };

            reader.onerror = error => {
              reject(error);
            };

            reader.readAsDataURL(file);
          });
        }),
      )
        .then(images => {
          dispatch(updateWritePostImages(writePostImages.concat(images)));
        })
        .catch(error => {
          console.error('Error reading files:', error);
        });
    } else {
      const newAlertInfo = { ...alertInfo };
      newAlertInfo.severity = 'info';
      newAlertInfo.msg = `이미지파일은 ${MAX_IMG_CNT}개 이하로 선택 가능합니다.`;
      dispatch(setAlertInfo(newAlertInfo));
      dispatch(openSnackbar());
    }

    e.target.value = null;
  };

  const removePostImage = target => {
    const newList = writePostImages.filter(f => f !== target);

    dispatch(updateWritePostImages(newList));
  };

  return (
    <div css={styles.wrapper}>
      <div css={styles.post}>
        <Input
          minRows={1}
          placeholder="제목을 입력해주세요.(최대 40자)"
          maxLength={40}
          value={writePostItem && writePostItem.title}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ',') {
              e.preventDefault();
            }
          }}
          onChange={e => {
            handlePostData(e, 'title');
          }}
        />
        <Textarea
          maxLength={2000}
          minRows={4}
          placeholder="내용을 입력해주세요.(최대 2000자) &#13;  &#10;글 또는 이미지에 욕설, 비방, 명예훼손, 외설적&#13;혹은 선정적 내용, 기타 불건전하거나 불쾌감을&#13;유발하는 내용이 포함된 경우&#13;게시글이 숨김처리/삭제되거나,&#13;작성자의 서비스 이용이 제한될 수 있습니다."
          value={writePostItem && writePostItem.contents}
          onChange={e => {
            handlePostData(e, 'contents');
          }}
        />
        <Swiper
          style={{ width: '100%', margin: '8px 0px' }}
          loop={false}
          slidesPerView="auto"
          spaceBetween={pxFromVW(3.3)}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode]}
        >
          {writePostImages.map((item, idx) => (
            <SwiperSlide
              key={`uploadimg_preview_${idx}`}
              style={{
                width: toVW('120px'),
                height: toVW('120px'),
                position: 'relative',
                marginRight: toVW('8px'),
              }}
            >
              <img
                css={css`
                  border-radius: ${stringToVW('10.67px')};
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                `}
                src={item.img}
                alt={`uploadimg_preview_${idx}`}
              />
              <IconRemove
                css={css`
                  position: absolute;
                  top: ${stringToVW('10px')};
                  right: ${stringToVW('10px')};
                  cursor: pointer;
                  width: ${stringToVW('16px')};
                  height: ${stringToVW('16px')};
                `}
                onClick={e => {
                  removePostImage(item);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <label
          css={css`
            height: ${toVW('21px')};
          `}
        >
          <img
            src={IconUploadImage}
            alt="IconUploadImage"
            css={css`
              width: ${toVW('21px')};
            `}
          />
          <VisuallyHiddenInput
            type="file"
            multiple
            accept="image/jpeg, image/png"
            onInput={onChangeFiles}
          />
        </label>
      </div>
    </div>
  );
};

export default InputPost;
