import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import Header from '@component/admin/common/Header';
import { Input, Button, Pagination } from 'antd';
import { css } from '@emotion/react';
import { Table, Switch } from 'antd';
import icon_right from '@asset/icons/common/icon_right.svg';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ModalConfirm from '@component/admin/common/ModalConfirm';
import {
  getMemberView,
  getMemberBadgeList,
  withdrawMember,
} from '@lib/api-call/admin/memberApi';
import { getUploadImage } from '@lib/commonUtils';
import dayjs from 'dayjs';

import {
  getMemberBlock,
  getMemberChange,
} from '@lib/api-call/admin/channelApi';

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
    // render: (text, record, index) => index + 1,
  },
  {
    title: '뱃지명',
    dataIndex: 'badge_nm',
    key: 'badge_nm',
  },
  {
    title: '획득일',
    dataIndex: 'badge_first_dt',
    key: 'badge_first_dt',
  },
];

const UserDetail = () => {
  const navigate = useNavigate();
  const [checkCompanyMember, setCheckCompanyMember] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isButtonClick, setIsButtonClick] = useState(false);

  const onChangeCompanyMember = checked => {
    // 만약 스위치가 체크되었을 때의 로직
    if (checked) {
      setCheckCompanyMember(checked);
      setChecked(checked);
    } else {
      // 만약 스위치가 언체크되었을 때의 로직
      if (userInfo.user_type == 1) {
        setChangeNickName(userInfo.user_nm);
        showModalConfirm(2);
        setShowPopup(false);
      } else {
        setCheckCompanyMember(checked);
        setChecked(checked);
        setShowPopup(false);
      }
    }
  };

  const [userInfo, setUserInfo] = useState({});
  const [badgeList, setBadgeList] = useState([]);
  const [totalBadgeCount, setTotalBadgeCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const { userId } = useParams(); // 쿼리 호출

  const onUserInfoGet = async () => {
    const userData = await getMemberView({ p_user_id: userId });
    setUserInfo(userData.urUserView);
    setChecked(userData.urUserView.user_type == 1);
  };

  const onUserBadgeGet = async () => {
    const badgeData = await getMemberBadgeList({
      p_user_id: userId,
      p_start: page * 10,
    });
    setBadgeList(badgeData.urUserBadgeList);
    setTotalBadgeCount(badgeData.total_count);
  };

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const onUserLock = async type => {
    const lock = await getMemberBlock({
      p_user_id: userId,
      p_user_stat_cd: type,
    });

    if (lock.message_cd !== '0') return false;
    return true;
  };

  const [changeNickName, setChangeNickName] = useState('');
  const userTypeChange = async type => {
    // 글자가 2글자 이상인 경우에만 userTypeChange 함수 실행
    if (changeNickName.length >= 2) {
      // userTypeChange 함수의 나머지 로직
      const changeType = await getMemberChange({
        p_user_id: userId,
        p_user_type: type,
        p_nickname: changeNickName,
      });
      if (changeType.message_cd !== '0') return;
      navigate(0);
    } else {
      // 2글자 미만인 경우에는 메세지 호출
      setShowPopup(true);
    }
  };

  const userTypeChange2 = async type => {
    const changeType = await getMemberChange({
      p_user_id: userId,
      p_user_type: type,
      p_nickname: userInfo.user_nm,
    });
    if (changeType.message_cd !== '0') return;
    navigate(0);
  };

  useEffect(() => {
    onUserInfoGet();
    onUserBadgeGet();
  }, []);

  useEffect(() => {
    onUserBadgeGet();
  }, [page]);

  const [modalProps, setModalProps] = useState({
    open: false,
    title: 'Title',
    desc: 'Title desc',
    onOk: () => {},
    onCancel: () => {
      setModalProps({
        ...modalProps,
        open: false,
        title: '',
        desc: '',
      });
    },
  });

  const showModalConfirm = type => {
    if (type === 0) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '이용 중지 해제 처리 안내',
        desc: '해당 사용자를 이용 중지 해제 처리하시겠습니까?',
        onOk: async data => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          const lock = await onUserLock(type);
          setTimeout(() => {
            setIsButtonClick(false);
            if (lock) {
              navigate(0);
            }
          });
        },
        onCancel: () => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(true);
        },
      });
    } else if (type === 1) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '이용 중지 처리 안내',
        desc: '해당 사용자를 이용 중지 처리하시겠습니까?',
        onOk: async data => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });

          const lock = await onUserLock(type);
          setTimeout(() => {
            setIsButtonClick(true);
            if (lock) {
              navigate(0);
            }
          });
        },
        onCancel: () => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
        },
      });
    } else if (type === 2) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '일반 회원 전환',
        desc: '해당 사용자를 일반 회원으로 전환하시겠습니까?',
        onOk: async data => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });

          const lock = await userTypeChange2(0);
          setTimeout(() => {
            setIsButtonClick(true);
            if (lock) {
              navigate(0);
              setCheckCompanyMember(false);
              setChecked(false);
            }
          });
        },
        onCancel: () => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
        },
      });
    } else if (type === 3) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '2글자 이상 입력해주세요',
        desc: '',
        onCancel: () => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
        },
      });
    } else if (type === 4) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '서비스 해지 안내',
        desc: '해당 사용자를 서비스 해지처리 하시겠습니까?',
        onOk: async () => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
          await withdrawMember({
            userId,
            reason: 'ADMIN WITHDRAWAL',
          });
          onUserInfoGet();
        },
        onCancel: () => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
        },
      });
    }
  };

  const dataProp = [
    {
      title: '회원 관리',
      href: '/admin/user-manage',
    },
    {
      title: `${userInfo.user_nm}`,
      href: `/admin/user-detail/${userId}`,
    },
  ];

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div className="flex justify-between">
          <div className="flex gap-20">
            <NavLink
              to={`/admin/user-detail/post-content/${encodeURIComponent(
                userId,
              )}`}
              css={css`
                text-decoration: none;
              `}
            >
              <div className="style-box">
                <div
                  className="display-box"
                  css={css`
                    color: black;
                  `}
                >
                  <p>게시글 내역</p>
                  <p>{userInfo.post_cnt}</p>
                  <img className="size-icon" src={icon_right} alt="" />
                </div>
              </div>
            </NavLink>
            <NavLink
              to={`/admin/user-detail/stock-content/${encodeURIComponent(
                userId,
              )}`}
              css={css`
                text-decoration: none;
              `}
            >
              <div className="style-box">
                <div
                  className="display-box"
                  css={css`
                    color: black;
                  `}
                >
                  <p>보유 주식 내역</p>
                  <p>{userInfo.property_cnt}</p>
                  <img className="size-icon" src={icon_right} alt="" />
                </div>
              </div>
            </NavLink>
          </div>
          <div className="style-box flex gap-10">
            <div className="display-box">
              {showPopup && (
                <div className="popup-container">
                  <div className="popup-content">
                    2글자 이상 입력해주세요.{' '}
                    <button onClick={() => setShowPopup(false)}>확인</button>
                  </div>
                </div>
              )}
              <p>기업 회원 전환</p>
              <Switch checked={checked} onChange={onChangeCompanyMember} />
            </div>
            {checkCompanyMember ? (
              <div className="display-box-small">
                <Input
                  onChange={e => setChangeNickName(e.target.value)}
                  css={css`
                    width: 250px;
                    height: 43px;
                  `}
                  placeholder="회원명을 입력해주세요."
                />
                <button
                  className="style-button"
                  onClick={() => userTypeChange(1)}
                >
                  저장
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex-between mt-40">
          <div className="style-component-box">
            <div
              css={css`
                border-bottom: 1px solid #ebecef;
              `}
            >
              <div className="flex-between p-0-40-0-25 h-60">
                <p className="text-content">회원 정보</p>
                <div className="flex item-center gap-20">
                  {dayjs(userInfo?.withdrawal_dt).isValid() ? (
                    <p className="text-title-date-report">
                      {dayjs(userInfo?.withdrawal_dt).format('YYYY.MM.DD')}{' '}
                      사용자 해지 처리
                    </p>
                  ) : null}

                  <Button
                    onClick={() => showModalConfirm(4)}
                    type="primary"
                    style={{ width: 126, height: 46, background: 'black' }}
                  >
                    서비스 해지
                  </Button>
                  {userInfo.user_lock === 'Y' ? (
                    <>
                      <p className="text-title-date-report">
                        {userInfo.user_lock_dt} 사용자 이용 중지 처리
                      </p>
                      <Button
                        onClick={() => showModalConfirm(0)}
                        type="primary"
                        style={{ width: 126, height: 46, background: 'black' }}
                      >
                        이용 중지 해제
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => showModalConfirm(1)}
                      type="primary"
                      style={{ width: 126, height: 46, background: 'black' }}
                    >
                      이용 중지
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="p-24-40-0-25 grid grid-col-2 gap-x-50 gap-y-24">
              <div className="col-span-1">
                <div className="flex-between">
                  <p className="text-content-small-component">회원 닉네임</p>
                  <Input
                    css={css`
                      width: 330px;
                      height: 43px;
                    `}
                    disabled
                    placeholder={userInfo.user_nm}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex-between">
                  <p className="text-content-small-component">회원 상태</p>
                  <Input
                    css={css`
                      width: 330px;
                      height: 43px;
                    `}
                    disabled
                    placeholder={userInfo.user_stat_nm}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex-between">
                  <p className="text-content-small-component">가입일</p>
                  <Input
                    css={css`
                      width: 330px;
                      height: 43px;
                    `}
                    disabled
                    placeholder={userInfo.join_dt}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="style-component-box-2">
            <div
              css={css`
                border-bottom: 1px solid #ebecef;
              `}
            >
              <div className="flex-between p-0-40-0-25 h-60">
                <p className="text-content">프로필 정보</p>
              </div>
            </div>
            <div className="p-24-40-0-25 flex gap-20">
              <div
                css={css`
                  width: 180px;
                  height: 180px;
                  border-radius: 120px;
                  background: #e6e8f2;
                  overflow: hidden;
                `}
              >
                <img
                  src={getUploadImage(userInfo.profile_img)}
                  alt=""
                  css={css`
                    width: 100%;
                  `}
                />
              </div>
              <p>{userInfo.profile_introduction}</p>
            </div>
          </div>
        </div>
        <div className="mt-25px">
          <div className="bg-title-table">
            <p className="text-title-table">뱃지 목록 </p>
          </div>
          <Table
            className="ant-table"
            columns={columns}
            dataSource={badgeList}
            pagination={false}
            loading={loading}
          />
          <Pagination
            current={page + 1}
            defaultCurrent={1}
            total={totalBadgeCount || 1}
            showSizeChanger={false}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px 0',
            }}
            onChange={(page, pageSize) => pageChange(page)}
          />
        </div>
      </div>
      <ModalConfirm model={modalProps} />
    </div>
  );
};

export default UserDetail;
