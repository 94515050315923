import { css } from '@emotion/react';
import { globalStyle, stringToVW } from '@style/globalStyle';
const Popup = ({ handleClick, data, confirmClick }) => {
  const styles = {
    wrapper: css`
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      margin: 0 auto;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
    `,
    boxPopup: css`
      width: calc(100% - ${stringToVW('32px')});
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      position: absolute;
      gap: ${stringToVW('20px')};
      border: 0px solid white;
      border-radius: ${stringToVW('10.67px')};
      overflow: hidden;
      @media (min-width: 768px) {
        max-width: 50%;
      }
    `,
    titlePopup: css`
      ${globalStyle.fontNanu26}
      font-weight: 400;
      letter-spacing: 0;
      text-align: center;
      padding: ${stringToVW('33px 20px 13.3px 20px')};
      color: #333;
    `,
    contentPopup: css`
      ${globalStyle.fontRobo21}
      font-weight: 400;
      letter-spacing: 0em;
      text-align: center;
      padding: ${stringToVW('0px 20px')};
      color: #666666;
    `,
  };

  return (
    <div
      css={styles.wrapper}
      onClick={event => {
        handleClick(false);
      }}
    >
      <div
        css={styles.boxPopup}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <div
          css={css`
            background: white;
            padding-bottom: ${stringToVW('26px')};
          `}
        >
          <div css={styles.titlePopup}>{data.title}</div>
          {data.content.map((item, index) => (
            <div css={styles.contentPopup} key={index}>
              {item}
            </div>
          ))}
        </div>
        <div
          css={css`
            display: flex;
            ${globalStyle.fontNanu21}
            font-weight: 400;
            letter-spacing: 0px;
            text-align: center;
          `}
        >
          {data.textBtnLeft === '' ? null : (
            <div
              css={css`
                width: 100%;
                height: ${stringToVW('50px')};
                background: #e0e0e0;
                border: 0px solid white;
                border-radius: ${stringToVW('0 0 0 10.67px')};
                display: flex;
                justify-content: center;
                align-items: center;
                color: #333;
                cursor: pointer;
              `}
              onClick={event => {
                handleClick(false);
              }}
            >
              {data.textBtnLeft}
            </div>
          )}
          <div
            css={css`
              width: 100%;
              height: ${stringToVW('50px')};
              background: #84c13d;
              border: 0px solid white;
              border-radius: ${stringToVW('0 0 10.67px 0px')};
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              cursor: pointer;
            `}
            style={
              data.textBtnLeft === ''
                ? { borderRadius: stringToVW('0 0 10.67px 10.67px') }
                : null
            }
            onClick={event => {
              confirmClick();
            }}
          >
            {data.textBtnRight}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Popup;
