import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import HeaderSearch from '@component/app/search/HeaderSearch';
import ChannelList from '@component/app/search/ChannelList';
import { css } from '@emotion/react';
import CountryList from '@component/app/search/ContryList';
import SearchItem from '@component/app/search/SearchItem';

import { getChannelList, getSearchHistList } from '@lib/api-call/app/searchApi';
import LoungeHeader from '@component/app/common/LoungeHeader';
import { isEmpty } from 'lodash';
import { stringToVW } from '@style/globalStyle';

const SearchPage = () => {
  const [searchChannelList, setSearchChannelList] = useState([]);
  const [searchHistList, setSearchHistList] = useState([]);
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const getAutoSearchList = async () => {
    try {
      const data = await getChannelList();
      setSearchChannelList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const onHistListGet = async () => {
    // if (!getAccTokenHeader()['Access-Token']) return;
    const searchHistListData = await getSearchHistList();
    setSearchHistList(searchHistListData);
  };

  //mounted
  useEffect(() => {
    if (isEmpty(searchChannelList)) {
      getAutoSearchList();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(loginedUser)) {
      onHistListGet();
    }
  }, [loginedUser]);

  return (
    <>
      <LoungeHeader />
      <div
        css={css`
          padding: ${stringToVW('0px 15px')};
        `}
      >
        <HeaderSearch search="true" channelList={searchChannelList} />
        <ChannelList />
        <CountryList title="국가별 라운지 채널" />
        {/*<CountryList items={items2} />*/}
        <SearchItem histList={searchHistList} histListReset={onHistListGet} />
      </div>
    </>
  );
};

export default SearchPage;
