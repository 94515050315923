import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import './style.css';
import icon_eye from '@asset/icons/common/icon_eye.svg';
import icon_like from '@asset/icons/common/icon_like.svg';
import icon_dislike from '@asset/icons/common/icon_dislike.svg';
import Header from '@component/admin/common/Header';
import {
  Select,
  Input,
  Button,
  Modal,
  Breadcrumb,
  Upload,
  Switch,
  Pagination,
} from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import checkbox from '@component/app/common/Checkbox';
import ReportDetailComponent from '@component/admin/report/ReportDetailComponent';
import ModalConfirm from '@component/admin/common/ModalConfirm';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getReportUserDetailList,
  getReportUserDetailView,
} from '@lib/api-call/admin/reportApi';
import { getMemberView } from '@lib/api-call/admin/memberApi';
import { toNumber } from 'lodash';
import { getUploadImage } from '@lib/commonUtils';
import { getMemberBlock } from '@lib/api-call/admin/channelApi';
import {
  getCommentBlock,
  getCommentReplyBlock,
  getNoticeBlock,
} from '@lib/api-call/admin/postApi';

function addCommasToNumberWithDecimal(number) {
  const parsedNumber = toNumber(number);
  return parsedNumber.toLocaleString();
}

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '신고한 사람',
    dataIndex: 'reporter_nm',
    key: 'reporter_nm',
  },
  {
    title: '신고 유형',
    dataIndex: 'report_type_nm',
    key: 'report_type_nm',
  },
  {
    title: '신고 사유',
    dataIndex: 'report_reason_nm',
    key: 'report_reason_nm',
  },
  {
    title: '상태',
    dataIndex: 'report_key_nm',
    key: 'report_key_nm',
    // render: (text, record) => (
    //   <Select
    //     defaultValue="--"
    //     style={{ width: 80, height: 40 }}
    //     onChange={handleChange}
    //     options={[
    //       {
    //         value: 'reserve',
    //         label: '보류',
    //       },
    //       {
    //         value: 'process',
    //         label: '처리',
    //       },
    //     ]}
    //   />
    // ),
  },
  {
    title: '등록일',
    dataIndex: 'reg_date',
    key: 'reg_date',
  },
];
const data = [
  {
    key: 1,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '게시글',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 2,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '프로필',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 3,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '댓글',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 4,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '대댓글',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 5,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 6,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 7,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 8,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 9,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 10,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 11,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
  {
    key: 12,
    idx: '{idx}',
    reporter_nickname: '{reporter_nickname}',
    type: '{type}',
    status: '{status}',
    reason: '{reason}',
    date: 'YYYY.MM.DD',
  },
];
const handleChange = value => {
  console.log(`selected ${value}`);
};

const RepostManageDetail = () => {
  const [content, setContent] = useState();
  const { userId } = useParams(); // 쿼리 호출

  const [reportList, setReportList] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  // count
  const [totalReportCount, setTotalReportCount] = useState(0);

  // page
  const [page, setPage] = useState(0);

  // loading
  const [loading, setLoading] = useState(false);

  // select comment
  const [selectReport, setSelectReport] = useState(null);
  const navigate = useNavigate();

  const onUserInfoGet = async () => {
    const userData = await getMemberView({ p_user_id: userId });
    console.log(userData);
    // if (userData.message_cd !== '0') return navigate('/admin');
    setUserInfo(userData.urUserView);
  };

  const onReportListView = async () => {
    const reportData = await getReportUserDetailList({
      p_user_id: userId,
      p_start: page * 10,
      p_length: 10,
    });
    console.log(reportData);
    setReportList(reportData.reportUserDetailList);
    setTotalReportCount(reportData.total_count);
  };

  const [reportDetail, setReportDetail] = useState({});
  const onReportDetailView = async item => {
    console.log(item);
    const detailData = await getReportUserDetailView({
      p_user_id: item.user_id,
      p_report_type: item.report_type,
      p_report_key: item.report_key,
    });
    console.log(detailData);

    if (detailData.message_cd !== '0') return;

    if (item.report_type === '1') {
      setReportDetail({
        ...detailData.urUserView,
      });
    } else if (item.report_type === '0') {
      setReportDetail({
        ...detailData.ntPostView,
        postImage: detailData.ntPostImgList,
        comment: item.comment,
      });
    } else if (item.report_type === '2') {
      setReportDetail(detailData.cmPostCommentView);
    } else if (item.report_type === '3') {
      setReportDetail({
        ...detailData.cmPostCommentReplyView,
        comment_contents: detailData.cmPostCommentView.contents,
      });
    }

    setContent(item.report_type);
    //     ntPostView
    // reportUserView
  };

  const onUserLock = async type => {
    const lock = await getMemberBlock({
      p_user_id: reportDetail.user_id,
      p_user_stat_cd: type ? 0 : 1,
    });

    if (lock.message_cd !== '0') return false;
    return true;
  };

  const onPostLock = async type => {
    const lockData = await getNoticeBlock({
      p_post_id: reportDetail.post_id,
      p_post_active: type ? 0 : 1,
    });
    if (lockData.message_cd !== '0') return false;
    return true;
  };

  const onCommentLock = async type => {
    const lockData = await getCommentBlock({
      p_post_id: reportDetail.post_id,
      p_comment_active: type ? 0 : 1,
      p_comment_id_array: [reportDetail.comment_id],
    });
    if (lockData.message_cd !== '0') return false;
    return true;
  };

  const onReplyLock = async type => {
    const lockData = await getCommentReplyBlock({
      p_post_id: reportDetail.post_id,
      p_comment_reply_active: type ? 0 : 1,
      p_comment_reply_id_array: [reportDetail.comment_reply_id],
    });
    if (lockData.message_cd !== '0') return false;
    return true;
  };

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  useEffect(() => {
    onReportListView();
    onUserInfoGet();
  }, []);

  useEffect(() => {
    onReportListView();
  }, [page]);

  const dataProp = [
    {
      title: '신고 관리',
      href: '/admin/report-manage',
    },
    {
      title: '신고 목록',
      href: '',
    },
  ];

  const showModalConfirm = () => {
    setModalProps({
      ...modalProps,
      open: true,
      title: '상태 변경 안내',
      desc: '신고 건 상태 변경 처리하시겠습니까?',
      onOk: data => {
        console.log('on ok: ', data);
        setModalProps({
          ...modalProps,
          open: false,
          title: '',
          desc: '',
        });
      },
      onCancel: () => {
        console.log('on cancel');
        setModalProps({
          ...modalProps,
          open: false,
          title: '',
          desc: '',
        });
      },
    });
  };
  const [modalProps, setModalProps] = useState({
    open: false,
    title: 'Title',
    desc: 'Title desc',
    onOk: () => {},
    onCancel: () => {
      console.log('on cancel');
      setModalProps({
        ...modalProps,
        open: false,
        title: '',
        desc: '',
      });
    },
  });

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div className="wrapper-report">
          <div className="border-b">
            <div className="flex justify-between item-center padding-title-report">
              <p className="text-content">
                {userInfo.user_nm} | {userInfo.user_type_nm}
              </p>
            </div>
          </div>
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="flex gap-16">
            <div
              css={css`
                width: 65%;
              `}
            >
              <div className="bg-title-table flex-between">
                <p className="text-title-table">
                  총 신고 수 {addCommasToNumberWithDecimal(totalReportCount)}
                </p>
                {/* <Button className="mr-15" onClick={showModalConfirm}>
                  상태 변경
                </Button> */}
              </div>
              <Table
                className="ant-table"
                columns={columns}
                dataSource={reportList}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      onReportDetailView(record);
                      // setContent(record.report_type);
                    },
                  };
                }}
                pagination={false}
                loading={loading}
              />
              <Pagination
                current={page + 1}
                defaultCurrent={1}
                total={totalReportCount || 1}
                showSizeChanger={false}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '16px 0',
                }}
                onChange={(page, pageSize) => pageChange(page)}
              />
            </div>
            {/* 0: 게시글 */}
            {content === '0' ? (
              <ReportDetailComponent
                title={
                  reportDetail.post_active === '1' &&
                  `${reportDetail.post_active_date} 게시물 숨김 처리`
                }
                confirmEvent={onPostLock}
                use={reportDetail.post_active === '1'}
                type="게시글"
              >
                <p className="text-post-title-report">{reportDetail.title}</p>
                <p className="text-upload-date-report">
                  upload date : {reportDetail.reg_date}
                </p>
                <p className="text-upload-date-report">
                  {reportDetail?.comment}
                </p>
                <pre
                  className="text-content"
                  css={css`
                    white-space: break-spaces;
                  `}
                >
                  {reportDetail.contents}
                </pre>
                <div className="flex gap-16 mt-30">
                  {reportDetail.postImage?.slice(0, 2).map((x, index) => {
                    return (
                      <div
                        key={index}
                        css={css`
                          width: 140px;
                          height: 140px;
                          background: #e6e8f2;
                        `}
                      >
                        <img
                          src={getUploadImage(x.image_url)}
                          alt=""
                          css={css`
                            width: 100%;
                          `}
                        />
                      </div>
                    );
                  })}
                  {/* <div
                    css={css`
                      width: 140px;
                      height: 140px;
                      background: #e6e8f2;
                    `}
                  ></div> */}
                </div>
              </ReportDetailComponent>
            ) : (
              ''
            )}

            {/* 1: 프로필 */}
            {content === '1' ? (
              <ReportDetailComponent
                title={
                  reportDetail.user_lock === 'Y' &&
                  `${reportDetail.user_lock_dt} 프로필 숨김 처리`
                }
                use={reportDetail.user_lock === 'Y'}
                confirmEvent={onUserLock}
                type="프로필"
              >
                <div className="flex justify-center item-center">
                  <div
                    css={css`
                      width: 180px;
                      height: 180px;
                      border-radius: 250px;
                      background: #e6e8f2;
                      overflow: hidden;
                    `}
                  >
                    <img
                      src={getUploadImage(reportDetail.profile_img)}
                      css={css`
                        width: 100%;
                      `}
                      alt=""
                    />
                  </div>
                </div>
                <p className="text-content">
                  {reportDetail.profile_introduction}
                </p>
              </ReportDetailComponent>
            ) : (
              ''
            )}

            {/* 2: 댓글 */}
            {content === '2' ? (
              <ReportDetailComponent
                title={
                  reportDetail.comment_active === 1 &&
                  `${reportDetail.comment_active_date} 댓글 숨김 처리`
                }
                use={reportDetail.comment_active === 1}
                confirmEvent={onCommentLock}
                type="댓글"
              >
                <div className="flex justify-between">
                  <p className="text-content">댓글</p>
                  <p className="text-upload-date-report">
                    upload date : {reportDetail.reg_date}
                  </p>
                </div>
                <pre className="text-content">{reportDetail.contents}</pre>
              </ReportDetailComponent>
            ) : (
              ''
            )}

            {/* 3: 대댓글 */}
            {content === '3' ? (
              <ReportDetailComponent
                title={
                  reportDetail.comment_reply_active === 1 &&
                  `${reportDetail.comment_reply_active_date} 대댓글 숨김 처리`
                }
                use={reportDetail.comment_reply_active === 1}
                confirmEvent={onReplyLock}
                type="대댓글"
              >
                <div className="flex flex-col gap-16">
                  <p className="text-content">댓글</p>
                  <pre className="text-content">
                    {reportDetail.comment_contents}
                  </pre>
                </div>
                <div className="flex flex-col gap-16">
                  <div className="flex justify-between">
                    <p className="text-content">대댓글</p>
                    <p className="text-upload-date-report">
                      upload date : {reportDetail.reg_date}
                    </p>
                  </div>
                  <pre className="text-content">{reportDetail.contents}</pre>
                </div>
              </ReportDetailComponent>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <ModalConfirm model={modalProps} />
    </div>
  );
};

export default RepostManageDetail;
