import { Outlet, ScrollRestoration } from 'react-router-dom';
import SideMenu from '@component/admin/common/SideMenu';
import { css } from '@emotion/react';
import Header from '@component/admin/common/Header';
import { useEffect } from 'react';

const AdminLayout = () => {
  useEffect(() => {
    const parentElement = document.getElementById('customBody'); // 부모 엘리먼트의 id를 지정
    if (parentElement) parentElement.style.zoom = 1;
  }, []);
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <ScrollRestoration />
      <div
        css={css`
          width: 13%;
          height: 100vh;
          background: white;
        `}
      >
        <SideMenu />
      </div>
      <div
        css={css`
          background: #ebecef;
          width: 100%;
        `}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
