import React from 'react';
import Avatar from '@mui/material/Avatar';
import { css } from '@mui/material';
import IconCertificate from '@asset/images/profile/icon-certificate.svg';
import { Link, useLocation } from 'react-router-dom';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { getUploadImage } from '@lib/commonUtils';
const ListFollowItem = ({ active, data, type, updateFollow, myId }) => {
  const { pathname } = useLocation();
  return (
    <div>
      {data?.map((info, index) => {
        return (
          <div
            key={index}
            css={css`
              height: ${stringToVW('68px')};
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: ${stringToVW('16px')};
            `}
          >
            <Link
              to={`/profile/${encodeURIComponent(
                info.FOLLOWING_USER_ID || info.USER_ID,
              )}`}
              //state={{ prevLink: pathname }}
              css={css`
                height: ${stringToVW('68px')};
                display: flex;
                align-items: center;
                gap: ${stringToVW('10.7px')};
                text-decoration: none;
              `}
            >
              <div css={css``}>
                <Avatar
                  src={getUploadImage(info.PROFILE_IMG || info.profile_img)}
                  // sx={{ width: 40 * ratio, height: 40 * ratio }}
                  sx={{ width: 40, height: 40 }}
                />
              </div>
              <div
                css={css`
                  ${globalStyle.fontNanu21}
                  font-weight: 600;
                  letter-spacing: 0em;
                  text-align: left;
                  display: flex;
                  align-items: center;
                  color: #000000;
                `}
              >
                {info.USER_NM}
                {Number(info.USER_TYPE) !== 0 && (
                  <img
                    css={css`
                      margin-left: ${stringToVW('5px')};
                    `}
                    src={IconCertificate}
                    alt=""
                    style={{
                      width: stringToVW('15px'),
                      height: stringToVW('15px'),
                    }}
                  />
                )}
              </div>
            </Link>
            <div>
              {myId !== (info.FOLLOWING_USER_ID || info.USER_ID) && (
                <button
                  css={css`
                    width: ${stringToVW('60px')};
                    height: ${stringToVW('30px')};
                    border-radius: ${stringToVW('6.7px')};
                    border: none;
                    color: white;
                    ${globalStyle.fontNanu18};
                    line-height: ${stringToVW('12px')};
                  `}
                  style={{
                    background:
                      info.status === 'follow' ? '#4e5255' : '#ffffff',
                    color: info.status === 'follow' ? 'white' : '#666666 ',
                    border:
                      info.status === 'follow'
                        ? 'none'
                        : '1px solid var(--nmess-line-2017, #E0E0E0)',
                  }}
                  onClick={() =>
                    updateFollow({
                      type: info.status,
                      userId: info.FOLLOWING_USER_ID || info.USER_ID,
                    })
                  }
                >
                  {info.status === 'follow' ? '팔로우' : '팔로잉'}
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListFollowItem;
