import { NavLink } from 'react-router-dom';
import React from 'react';
import './style.css';
import { Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import logo from '@asset/icons/common/logo.svg';

const SideMenu = () => {
  return (
    <div>
      <div className="flex-center w-full mt-25px mb-30">
        <img src={logo} alt="logo" />
      </div>
      <Menu mode="vertical">
        <Menu.Item key="1" icon={React.createElement(RightOutlined)}>
          <NavLink to="/admin/user-manage">회원 관리</NavLink>
        </Menu.Item>

        <Menu.Item key="2" icon={React.createElement(RightOutlined)}>
          <NavLink to="/admin/post-manage">게시글 관리</NavLink>
        </Menu.Item>

        <Menu.Item key="3" icon={React.createElement(RightOutlined)}>
          <NavLink to="/admin/report-manage">신고 관리</NavLink>
        </Menu.Item>

        <Menu.Item key="4" icon={React.createElement(RightOutlined)}>
          <NavLink to="/admin/channel-manage">채널 관리</NavLink>
        </Menu.Item>

        <Menu.Item key="5" icon={React.createElement(RightOutlined)}>
          <NavLink to="/admin/admin-manage">관리자 관리</NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default SideMenu;
