import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import '@asset/css/style.css';
import { isEmpty } from "lodash";
import { toNumber } from "lodash";
import { css } from "@emotion/react";
import Header from '@component/admin/common/Header';
import { Select, Input, Table, Button } from "antd";
import {
    getAdminDet,
    getAdminIdDup,
    insAdmin,
    uptAdmin
} from "@lib/api-call/admin/adminManageApi";

const AdminDetail = () => {
    const adminId = useParams();
    const navigate = useNavigate();

    // itemInfo
    const [detailItem, setDetailItem] = useState();

    // reg or edit Item
    const [adminNewId, setAdminNewId] = useState("");
    const [adminPw, setAdminPw] = useState("");
    const [adminIp, setAdminIp] = useState("");
    const [approval, setApproval] = useState("Y");
    const [adminIdChk, setAdminIdChk] = useState("N");
    const [isEditing, setIsEditing] = useState(false);
    const selectList = [
        {value: "Y", label: "예"},
        {value: "N", label: "아니오"}
    ];

    const onAdminDetailGet = async () => {
        if (!isEmpty(adminId)) {
          const getDetailData = await getAdminDet(adminId);
          getDetailData.adminPw = ""; // 패스워드 제거
          setDetailItem(getDetailData);
        }
    };

    // ID 체크 
    const adminIdChkChange = (e) => {
        setAdminNewId(e.target.value);
        setAdminIdChk("N");
    };

     // PW 버튼 변경
     const onPwButtonChange = (e) => {
        detailItem.adminPw = ""; // 패스워드 초기화
        if(isEditing) {
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
     };

    const onAdminIdDupGet = async (e) => {
        if(isEmpty(adminNewId)) {
            alert("관리자 ID를 입력해 주세요.");
            document.getElementById("adminNewId").focus();
            return;
        }
        const getData = await getAdminIdDup({
            adminId:adminNewId
        })
        if(!getData.resData) {
            alert("사용가능한 ID입니다.");
            setAdminIdChk("Y");
        } else {
            alert("이미 사용중인 ID입니다.");
            document.getElementById("adminNewId").focus();
            setAdminIdChk("N");
        }
    };

    // 등록
    const regDetailItem = async () => {
        if (onValidate()) {
            if(confirm('등록하시겠습니까?')) {
                const onRegItem = await insAdmin({
                    adminId: adminNewId,
                    adminPw: adminPw,
                    adminIp: adminIp,
                    approval: approval
                });
                if (onRegItem.messageCd === "S") {
                    alert("등록이 완료되었습니다.");
                    navigate("/admin/admin-manage");
                } else {
                    alert(onRegItem.messageNm);
                }
            }
        }
    };

    // 수정
    const editDetailItem = async () => {
        if (onValidate()) {
            if(confirm('수정하시겠습니까?')) {
                const onEditItem = await uptAdmin(detailItem);
                if (onEditItem.messageCd === "S") {
                    alert("수정이 완료되었습니다.");
                    navigate("/admin/admin-manage");
                } else {
                    alert(onEditItem.messageNm);
                }
            }
        }
    };

    const onValidate = () => {
        if (!isEmpty(adminId) && detailItem !== undefined) {
            if(isEditing) {
                if(isEmpty(detailItem.adminPw)) {
                    alert("관리자 PW를 입력해 주세요.");
                    document.getElementById("adminPw").focus();
                    return false;
                }
                if(detailItem.adminPw.length<3) {
                    alert("관리자 PW는 3자이상 사용가능합니다.");
                    document.getElementById("adminPw").focus();
                    return false;
                }
            }
            if(isEmpty(detailItem.adminIp)) {
                alert("관리자 IP를 입력해 주세요.");
                document.getElementById("adminIp").focus();
                return false;
            }
            return true;
        } else{
            if(isEmpty(adminNewId)) {
                alert("관리자 ID를 입력해 주세요.");
                document.getElementById("adminNewId").focus();
                return false;
            }
            if(adminIdChk !== 'Y') {
                alert("관리자 ID 중복체크를 확인해 주세요.");
                document.getElementById("adminNewId").focus();
                return false;
            }
            if(adminNewId.length<3) {
                alert("관리자 ID는 3자이상 사용가능합니다.");
                document.getElementById("adminNewId").focus();
                return false;
            }
            if(isEmpty(adminPw)) {
                alert("관리자 PW를 입력해 주세요.");
                document.getElementById("adminPw").focus();
                return false;
            }
            if(adminPw.length<3) {
                alert("관리자 PW는 3자이상 사용가능합니다.");
                document.getElementById("adminPw").focus();
                return false;
            }
            if(isEmpty(adminIp)) {
                alert("관리자 IP를 입력해 주세요.");
                document.getElementById("adminIp").focus();
                return false;
            }
            return true;
        }
    };

    const onMoveList = () => {
        navigate(-1);
    };

    useEffect(() => {
        onAdminDetailGet();
    }, []);

    // Header BreadCrumb
    const dataProp = [
    {
        title: "관리자 관리",
        href: "/admin/admin-manage",
    },
    ];

    return (
        <>
            <Header dataProp={dataProp} />
            <div className="wrapper">
                {!isEmpty(detailItem) ? (
                <div>
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자ID
                            </p>
                        </div>
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  {detailItem.adminId}
                            </p>
                        </div>
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    {(isEditing) ? (
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자PW
                            </p>
                        </div>
                        <Input
                            id="adminPw"
                            value={detailItem.adminPw}
                                onChange={(e) => {
                                    setDetailItem({
                                    ...detailItem,
                                    adminPw: e.target.value,
                                });
                                }
                            }
                            css={css`width: 120px;`}
                            placeholder="관리자PW"
                            allowClear
                        /> 
                        <Button onClick={onPwButtonChange}>
                        수정취소
                        </Button>
                        <div className="flex-center gap-24"  
                             css={css`margin-left: 10px;`}> 
                            <p>
                                PW입력시 변경됩니다.
                            </p>
                        </div>
                    </div>
                    ) : (
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자PW
                            </p>
                        </div>
                        <div className="flex-center gap-24">
                        ******
                        </div>
                        <Button onClick={onPwButtonChange}>
                        수정하기
                        </Button>
                    </div>
                    )}
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자IP
                            </p>
                        </div>
                        <Input
                            id="adminIp"
                            value={detailItem.adminIp}
                            onChange={(e) => {
                                setDetailItem({
                                    ...detailItem,
                                    adminIp: e.target.value,
                                });
                            }}
                            css={css`width: 350px;`}
                            placeholder="관리자IP"
                            allowClear
                        />
                        <div className="flex-center gap-24"  
                             css={css`margin-left: 10px;`}> 
                            <p>
                                IP 추가구분 ,
                            </p>
                        </div>
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  승인여부
                            </p>
                        </div>
                        <Select
                            placeholder="승인여부"
                            style={{ width: 100, height: 42 }}
                            options={selectList.map((item) => ({
                                value: item.value,
                                label: item.label,
                            }))}
                            onChange={(val) => {
                                setDetailItem({
                                    ...detailItem,
                                    approval: val,
                                });
                            }}
                            value={detailItem.approval}
                        />
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    <div className="flex-center gap-24">
                        <Button onClick={onMoveList}>목록</Button>
                        <Button type="primary" onClick={editDetailItem}>
                            수정
                        </Button>
                    </div>
                </div>
                ) : (
                <div>
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자ID
                            </p>
                        </div>
                        <Input
                            id = "adminNewId"
                            value={adminNewId}
                            onChange={(e) => adminIdChkChange(e)}
                            onKeyDown={(e) => {
                                // 엔터 키를 눌렀을 때 onAdminIdDupGet 호출
                                if (e.key === "Enter") {
                                    onAdminIdDupGet();
                                }
                            }}
                            css={css`width: 120px;`}
                            placeholder="관리자ID"
                            allowClear
                        />
                        <Button onClick={onAdminIdDupGet}>
                        중복체크
                        </Button>
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자PW
                            </p>
                        </div>
                        <Input
                            id="adminPw"
                            value={adminPw}
                            onChange={(e) => setAdminPw(e.target.value)}
                            css={css`width: 120px;`}
                            placeholder="관리자PW"
                            allowClear
                        />
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  관리자IP
                            </p>
                        </div>
                        <Input
                            id="adminIp"
                            value={adminIp}
                            onChange={(e) => setAdminIp(e.target.value)}
                            css={css`width: 350px;`}
                            placeholder="관리자IP"
                            allowClear
                        />
                        <div className="flex-center gap-24"  
                             css={css`margin-left: 10px;`}> 
                            <p>
                                IP 추가구분 ,
                            </p>
                        </div>
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                    />
                    <div className="flex">
                        <div className="flex-center gap-24">
                            <p
                                css={css`
                                    width: 120px;
                                `}
                            >  승인여부
                            </p>
                        </div>
                        <Select
                            defaultValue={"Y"}
                            placeholder="승인여부"
                            style={{ width: 100, height: 42 }}
                            options={[
                            {
                                label: "예",
                                value: "Y",
                            },
                            {
                                label: "아니오",
                                value: "N",
                            },
                            ]}
                            onChange={(e) => setApproval(e)}
                            value={approval}
                        />
                    </div>
                    <div
                        css={css`
                            margin-top: 30px;
                        `}
                        />
                        <div className="flex-center gap-24">
                        <Button onClick={onMoveList}>목록</Button>
                        <Button type="primary" onClick={regDetailItem}>
                            등록
                        </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdminDetail;
