import { css } from '@emotion/react';

import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { useState } from 'react';
import { getUploadImage } from '@lib/commonUtils';
import { globalStyle, ratio, toVW } from '@style/globalStyle';
import ImageViewer from './ImageViewer';

const ImgComponent = ({ isSlide = false, src, handleOpen, idx = 1 }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return !imageError ? (
    <img
      css={css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        ${isSlide === true && `border-radius: var(--md-16, 10.67px);`}
      `}
      onClick={e => {
        e.stopPropagation();
        handleOpen(idx);
      }}
      src={getUploadImage(src)}
      alt=""
      onError={handleImageError}
    />
  ) : (
    <></>
  );
};

const PostImageList = ({ imgList, isDetail }) => {
  const [open, setOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const styles = {
    imageList: css`
      width: 100%;
      height: ${toVW('240px')};
      margin-bottom: ${toVW('-10.67px')};
      border-radius: ${toVW('10.67px')};
      overflow: hidden;
    `,
    detailPageImg: css`
      width: ${toVW('280px')};
      height: ${toVW('360px')};
    `,
    postListCase: css`
      display: flex;
      flex-wrap: wrap;
      height: auto;
      gap: ${toVW('2px')};
    `,
  };

  const childImgOne = css`
    // width: auto;
    width: 100%;
    /* height: calc(60vw - ${toVW('17px')});
    object-fit: cover; */
  `;

  const childImgTwo = css`
    width: calc(50% - ${toVW('1px')});
  `;

  const childImgFour = css`
    width: calc(50% - ${toVW('1px')});
    height: calc(30vw - ${toVW('17px')});
  `;

  const handleOpen = index => {
    // e.stopPropagation();
    setOpen(true);
    setInitialSlide(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isDetail ? (
    <>
      <Swiper
        style={{ width: '100%' }}
        loop={false}
        slidesPerView="auto"
        spaceBetween={8 * ratio}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode]}
        className="mySwiper"
      >
        {imgList.map((postImg, idx) => (
          <SwiperSlide
            style={{
              width: imgList?.length === 1 ? '100%' : toVW('280px'),
              height: toVW('360px'),
            }}
            key={`postImg_${postImg.image_id}`}
          >
            <ImgComponent
              isSlide
              src={postImg.image_thumbnail_url}
              handleOpen={handleOpen}
              idx={idx}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <ImageViewer
        open={open}
        handleClose={handleClose}
        images={imgList}
        initialSlide={initialSlide}
      />
    </>
  ) : (
    <div css={[styles.imageList, styles.postListCase]}>
      {(() => {
        if (imgList.length === 1) {
          return (
            <div css={childImgOne} key={`postImg_${imgList[0].image_id}`}>
              <ImgComponent
                src={imgList[0].image_thumbnail_url}
                handleOpen={handleOpen}
                idx={0}
              />
            </div>
          );
        } else if (imgList.length === 2) {
          return imgList.map((postImg, idx) => (
            <div css={childImgTwo} key={`postImg_${postImg.image_id}`}>
              <ImgComponent
                src={postImg.image_thumbnail_url}
                handleOpen={handleOpen}
                idx={idx}
              />
            </div>
          ));
        } else if (imgList.length === 3) {
          return (
            <div
              css={css`
                width: 100%;
                height: calc((100vw - ${toVW('30px')}) * 2 / 3);
                display: flex;
                gap: ${toVW('2px')};
              `}
            >
              <div css={childImgTwo}>
                <ImgComponent
                  src={imgList[0].image_thumbnail_url}
                  handleOpen={handleOpen}
                  idx={0}
                />
              </div>
              <div
                css={[
                  css`
                    width: calc(50% - ${toVW('1px')});
                    display: flex;
                    flex-direction: column;
                    gap: ${toVW('2px')};
                  `,
                ]}
              >
                <div
                  css={css`
                    width: 100%;
                    height: calc(50% - ${toVW('1px')});
                  `}
                >
                  <ImgComponent
                    src={imgList[1].image_thumbnail_url}
                    handleOpen={handleOpen}
                    idx={1}
                  />
                </div>
                <div
                  css={css`
                    width: 100%;
                    height: calc(50% - ${toVW('1px')});
                  `}
                >
                  <ImgComponent
                    src={imgList[2].image_thumbnail_url}
                    handleOpen={handleOpen}
                    idx={2}
                  />
                </div>
              </div>
            </div>
          );
        }

        return imgList.slice(0, 4).map((postImg, idx) => (
          <div
            css={[
              childImgFour,
              imgList.length > 4 &&
                idx === 3 &&
                css`
                  position: relative;
                `,
            ]}
            key={`postImg_${postImg.image_id}`}
          >
            <ImgComponent
              src={postImg.image_thumbnail_url}
              handleOpen={handleOpen}
              idx={idx}
            />
            {imgList.length > 4 && idx === 3 && (
              <div
                onClick={e => {
                  e.stopPropagation();
                  handleOpen(3);
                }}
                css={[
                  css`
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.4);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #fff;
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
                    ${globalStyle.fontRobo40}
                    font-weight: 400;
                  `,
                ]}
              >
                +{imgList.length - idx - 1}
              </div>
            )}
          </div>
        ));
      })()}
      <ImageViewer
        open={open}
        handleClose={handleClose}
        images={imgList}
        initialSlide={initialSlide}
      />
    </div>
  );
};

export default PostImageList;
