import IconSubscribe from '@asset/images/common/icon-nav-subscribe.png';
import IconSubscribeActive from '@asset/images/common/icon-nav-subscribe-active.png';
import SvgIcon from '@mui/material/SvgIcon';

import { css } from '@emotion/react';

const SubscribeIcon = ({ isActive, width = 34, height = 34 }) => {
  return (
    <SvgIcon
      inheritViewBox
      sx={{
        width,
        height,
        background: 'none',
      }}
    >
      {isActive ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 21 21"
          fill="none"
        >
          <g clipPath="url(#clip0_3106_182522)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.75 1.53366C5.75 1.05501 6.1231 0.666992 6.58333 0.666992H14.9167C15.3769 0.666992 15.75 1.05501 15.75 1.53366C15.75 2.01231 15.3769 2.40033 14.9167 2.40033H6.58333C6.1231 2.40033 5.75 2.01231 5.75 1.53366Z"
              fill="#303038"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.41675 4.86667C3.41675 4.38802 3.77846 4 4.22466 4H17.2755C17.7217 4 18.0834 4.38802 18.0834 4.86667C18.0834 5.34531 17.7217 5.73333 17.2755 5.73333H4.22466C3.77846 5.73333 3.41675 5.34531 3.41675 4.86667Z"
              fill="#303038"
            />
            <rect
              x="0.75"
              y="7.33301"
              width="20"
              height="13.3333"
              rx="2.66667"
              fill="#303038"
            />
          </g>
          <defs>
            <clipPath id="clip0_3106_182522">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.75 0.666992)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 21 20"
          fill="none"
        >
          <g clipPath="url(#clip0_3105_132224)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.75 0.866667C5.75 0.38802 6.1231 0 6.58333 0H14.9167C15.3769 0 15.75 0.38802 15.75 0.866667C15.75 1.34531 15.3769 1.73333 14.9167 1.73333H6.58333C6.1231 1.73333 5.75 1.34531 5.75 0.866667Z"
              fill="#45454C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.41675 4.19967C3.41675 3.72103 3.77846 3.33301 4.22466 3.33301H17.2755C17.7217 3.33301 18.0834 3.72103 18.0834 4.19967C18.0834 4.67832 17.7217 5.06634 17.2755 5.06634H4.22466C3.77846 5.06634 3.41675 4.67832 3.41675 4.19967Z"
              fill="#45454C"
            />
            <rect
              x="1.61667"
              y="7.53268"
              width="18.2667"
              height="11.6"
              rx="1.8"
              stroke="#45454C"
              strokeWidth="1.73333"
            />
          </g>
          <defs>
            <clipPath id="clip0_3105_132224">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.75)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </SvgIcon>
    // <img
    //   css={css`
    //     background: 'none';
    //     &:active {
    //       background: 'none';
    //     }
    //     width: ${width};
    //     height: ${height};
    //   `}
    //   alt="subscribe icon"
    //   src={!isActive ? IconSubscribe : IconSubscribeActive}
    // />
  );
};

export default SubscribeIcon;
