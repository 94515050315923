import './style.css';
import { Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateLoginedAdmin } from '@store/app/adminReducer';
import { removeCookie } from '@lib/cookieUtils';
import { adminLogout } from '@lib/api-call/admin/authApi';

const Header = props => {
  const data = props.dataProp;
  const adminLogin = useSelector(state => state.admin.loginedAdmin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    const logout = await adminLogout();
    if (logout.message_cd === '0') {
      dispatch(updateLoginedAdmin(false));
      removeCookie('admin_access_token', { path: '/' });
      removeCookie('admin_refresh_token', { path: '/' });
    }
  };

  return (
    <div className="p-25px-40px border-b flex-between">
      <Breadcrumb
        className="text-breadcrumb"
        separator=">"
        items={data?.map((item, index) => ({
          title: item.title,
          href: item.href,
          key: index,
        }))}
      />
      <div className="border-747274 rounded-4px">
        <button
          className="p-10px-20px m-0 text-logout cursor-pointer"
          onClick={() => logout()}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default Header;
