import axiosInstance from './axiosInstance';
import { Cookies, useCookies } from 'react-cookie';
import { getCookie, setCookie } from './cookieUtils';
import {
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
  HEADER_KEY_ACC_TOKEN,
  HEADER_KEY_REFRESH_TOKEN,
} from '@data/constants';

export const APP = 'app';
export const ADMIN = 'admin';

export const getAccTokenHeader = () => {
  const accessToken = getCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
  const refreshToken = getCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });

  return {
    [HEADER_KEY_ACC_TOKEN]: accessToken,
    [HEADER_KEY_REFRESH_TOKEN]: refreshToken,
  };
  // return {
  //   [HEADER_KEY_ACC_TOKEN]: accessToken,
  //   [HEADER_KEY_REFRESH_TOKEN]: refreshToken,
  // };
};

export const getAdminIdParam = () => {
  const adminId = getCookie('admin_id');

  return { p_admin_id: adminId };
};

export const getAdminIdParam2 = () => {
  const adminId = getCookie('admin_access_token');
  const refreshToken = getCookie('admin_refresh_token');

  return {
    [HEADER_KEY_ACC_TOKEN]: adminId,
    [HEADER_KEY_REFRESH_TOKEN]: refreshToken,
  };
};

const axiosUtils = {
  async get(
    url,
    params,
    config = { headers: {}, delay: undefined, requireAuth: false },
  ) {
    return await axiosInstance
      .get(url, {
        ...config,
        params,
      })
      .then(res => res);
  },
  async post(
    url,
    params,
    config = { headers: {}, delay: undefined, requireAuth: false },
  ) {
    return await axiosInstance
      .post(url, params, {
        ...config,
      })
      .then(res => res);
  },
};
export default axiosUtils;
