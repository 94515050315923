import axiosUtils, { getAccTokenHeader } from '../../axiosUtils';

export const getTop10List = async param => {
  return await axiosUtils
    .get('/search/top10list', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getSearchHistList = async param => {
  return await axiosUtils
    .get('/search/searchHistList', param, { headers: getAccTokenHeader() })
    .then(res => {
      return res.data;
    });
};

export const getSearchList = async param => {
  return await axiosUtils
    .get('/search/list', param, { headers: getAccTokenHeader() })
    .then(res => {
      return res.data;
    });
};

export const searchHistListDelete = async param => {
  return await axiosUtils
    .get('/search/searchHistDelete', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const searchCountryList = async param => {
  return await axiosUtils
    .get('/search/country', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getChannelList = async param => {
  return await axiosUtils
    .get('/search/channelList', null, { headers: getAccTokenHeader() })
    .then(res => {
      return res.data;
    });
};
