import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SubscriptionChannel from '@component/app/profile/SubscriptionChannel';
import ActivityBadge from '@component/app/profile/ActivityBadge';
import StocksOwned from '@component/app/profile/StocksOwned';
import PropTypes from 'prop-types';
import ProfileInfo from '@component/app/common/ProfileInfo';
import Activity from '@component/app/profile/Activity';
import HeaderSearch from '@component/app/search/HeaderSearch';
import LoungeHeader from '@component/app/common/LoungeHeader';
import { getMyProfile, getProfile } from '@lib/api-call/app/profileApi';
import { useState } from 'react';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%', overflow: 'hidden' }}>{children}</Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const tabType = type => {
  if (!String(type)) return;
  if (Number(type) !== 0) {
    return ['게시글', '댓글', '반응'];
  } else if (Number(type) === 0) {
    return ['구독', '배지', '투자 중', '쓴글'];
  }
};
const MyProfile = () => {
  const { hash } = useLocation();
  let route = useLocation();

  const loginedUser = useSelector(state => state.auth.loginedUser);
  const [value, setValue] = useState(hash ? Number(hash.replace('#', '')) : 0);

  const [myProfile, setMyProfile] = useState({});
  const [tabList, setTabList] = useState(
    tabType(loginedUser?.USER_TYPE || loginedUser?.user_type),
  );

  const onMyProfileGet = async () => {
    const [myProfileData, myProfileData2] = await Promise.all([
      getProfile(),
      getMyProfile(),
    ]);
    // const myProfileData2 = await getMyProfile();
    setMyProfile({
      ...myProfileData,
      joinDate: myProfileData2.JOIN_DATE,
    });
    setTabList(tabType(myProfileData.user_type));
  };

  useEffect(() => {
    onMyProfileGet();
  }, []);
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      window.GA_Event('커뮤니티', '내 프로필', '구독');
    } else if (newValue === 1) {
      window.GA_Event('커뮤니티', '내 프로필', '배지');
    } else if (newValue === 2) {
      window.GA_Event('커뮤니티', '내 프로필', '투자 중');
    } else if (newValue === 3) {
      window.GA_Event('커뮤니티', '내 프로필', '쓴글');
    }
    routePush(newValue);
    setValue(newValue);
  };

  const isProfileOfOthers = false;

  const navigate = useNavigate();

  const routePush = path => {
    navigate(`${route.pathname}?#${path}`, { replace: true });
  };

  return (
    <div
      css={css`
        height: cacl(100dvh - ${toVW('60px')});
      `}
    >
      <LoungeHeader />
      <div
        css={css`
          padding: ${stringToVW('16px 16px 0px 16px')};
        `}
      >
        <HeaderSearch />
      </div>
      <div
        css={css`
          padding: ${stringToVW('0 16px')};
        `}
      >
        <ProfileInfo
          profile="my"
          isProfileOfOthers={isProfileOfOthers}
          name={myProfile.user_nm}
          intro={myProfile.profile_introduction}
          userId={myProfile.user_id}
          follower={myProfile.follower}
          following={myProfile.following}
          profileAvatarImg={myProfile.profile_img}
          totalFollowerAssets={myProfile.total_follower_assets}
          totalAssets={myProfile.total_assets}
          userType={myProfile.user_type}
        />
      </div>
      <Box
        id="detect-area"
        sx={{
          width: '100%',
          paddingBottom: stringToVW('54px'),
        }}
      >
        <Box
          sx={{
            borderBottom: 1.5,
            borderColor: 'divider',
          }}
        >
          <Tabs
            sx={{ transform: 'translateY(1.5px)' }}
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'black',
              },
            }}
          >
            {tabList.map((item, index) => (
              <Tab
                css={css`
                  ${globalStyle.fontNanu21}
                  font-weight: 400;
                  letter-spacing: 0em;
                  text-align: center;
                  margin-top: ${stringToVW('13px')};
                  opacity: 1;
                `}
                sx={{
                  color: '#999',
                  '&.Mui-selected': {
                    fontWeight: 600,
                    color: '#000',
                  },
                }}
                label={item}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={tabList.indexOf('구독')}>
          <SubscriptionChannel isProfileOfOthers={isProfileOfOthers} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('배지')}>
          <ActivityBadge
            isProfileOfOthers={isProfileOfOthers}
            badgeOpenYn={myProfile.badge_open_yn}
            updateProfile={onMyProfileGet}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('투자 중')}>
          <StocksOwned
            isProfileOfOthers={isProfileOfOthers}
            stockYn={myProfile.stock_info_yn}
            userJoinDate={myProfile.joinDate}
            investingOpenYn={myProfile.investing_open_yn}
            updateProfile={onMyProfileGet}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('쓴글')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            userNm={myProfile.user_nm}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('게시글')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            tabFix="write"
            tabCorverStatus={false}
            userNm={myProfile.user_nm}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('댓글')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            tabFix="comment"
            tabCorverStatus={false}
            userNm={myProfile.user_nm}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={tabList.indexOf('반응')}>
          <Activity
            isProfileOfOthers={isProfileOfOthers}
            tabFix="reaction"
            tabCorverStatus={false}
            userNm={myProfile.user_nm}
          />
        </CustomTabPanel>
      </Box>
      <CheckWithdrawAccount />
    </div>
  );
};

export default MyProfile;
