import axiosUtils, { getAccTokenHeader } from '../../axiosUtils';

export const getAlarmList = async param => {
  return await axiosUtils
    .get('/alarm/list', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};
export const getAlarmRead = async param => {
  return await axiosUtils
    .get('/alarm/read', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};
