import { css } from '@emotion/react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { stringToVW } from '@style/globalStyle';

import { FreeMode } from 'swiper/modules';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchCountryList } from '@lib/api-call/app/searchApi';
import { useEffect, useState } from 'react';
import { getUploadImage } from '@lib/commonUtils';
import { globalStyle, ratio } from '@style/globalStyle';

const countryTitleFilter = stockCd => {
  if (stockCd === 'Z000001') return '국내';
  else if (stockCd === 'Z000002') return '미국';
  else if (stockCd === 'Z000003') return '일본';
  else if (stockCd === 'Z000004') return '중국';
  else if (stockCd === 'Z000005') return '홍콩';
  else if (stockCd === 'Z000006') return '영국';
  else if (stockCd === 'Z000007') return '베트남';
  else if (stockCd === 'Z000008') return '독일';
  else if (stockCd === 'Z000009') return '인도네시아';
  else if (stockCd === 'Z000010') return '호주';
};
const CountryList = props => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const { items } = props;
  const { title } = props;
  const styles = {
    title: css`
      ${globalStyle.fontNanu18}
      font-weight: 400;
      letter-spacing: 0em;
    `,
  };

  const [countryList, setCountryList] = useState([]);

  const onCountryListGet = async () => {
    const countryList = await searchCountryList();
    setCountryList(countryList);
  };

  useEffect(() => {
    onCountryListGet();
  }, []);
  return (
    <div>
      <div
        css={css`
          ${globalStyle.fontNanu24}
          font-weight: 600;
          letter-spacing: 0em;
          text-align: left;
        `}
      >
        {title || '상품/서비스 채널'}
      </div>
      <Swiper
        style={{
          width: `calc(100% + ${stringToVW('30px')})`,
          margin: stringToVW('0px -15px'),
          padding: stringToVW('10.7px 15px'),
        }}
        loop={false}
        slidesPerView="auto"
        spaceBetween={8 * ratio}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode]}
      >
        {countryList.map((item, index) => (
          <SwiperSlide
            style={{
              gap: stringToVW('5.33px'),
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'fit-content',
              padding: stringToVW('0px 3.4px'),
            }}
            key={index}
            onClick={() => {
              navigate(`/channel/${item.stock_cd}/${item.channel_id}`, {
                state: { prevLink: pathname },
              });
            }}
          >
            <img
              css={css`
                width: ${stringToVW('53.3px')};
                height: ${stringToVW('53.3px')};
                border-radius: 50%;
              `}
              src={getUploadImage(item.thumb_url)}
              alt=""
            />
            <div css={styles.title}>{item.world_nm}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CountryList;
