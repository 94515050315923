import React from 'react';
import { css } from '@emotion/react';
import IconComment from '@asset/icons/profile/icon-comment.svg';
import IconLike from '@asset/icons/profile/icon-like.svg';
import IconDislike from '@asset/icons/profile/icon-dislike.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalStyle, stringToVW } from '@style/globalStyle';

const styles = {
  wrapper: css`
    padding: ${stringToVW('0 16px')};
    font-family: NanumBarunGothic;
    font-style: normal;
  `,
  activity: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--essential-grayscale-ess-neu-700245, #4e5255);
    font-size: ${stringToVW('16px')};
    font-weight: 400;
    line-height: ${stringToVW('26px')};
    margin-top: ${stringToVW('24px')};
    padding-bottom: ${stringToVW('11px')};
    border-bottom: 1px solid #f0f0f0;
  `,
  display: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${stringToVW('8px')};
    font-size: ${stringToVW('12.5px')};
    font-style: normal;
    font-weight: 400;
    line-height: ${stringToVW('17.5px')};
  `,
  titleLabel: css`
    color: #000;
    font-size: ${stringToVW('12.5px')};
    font-weight: 600;
    line-height: ${stringToVW('17px')};
  `,
  description: css`
    overflow: hidden;
    color: #999;
    text-overflow: ellipsis;
    ${globalStyle.fontRobo19}
    font-weight: 400;
    margin-top: ${stringToVW('0.375rem')};
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  commentArea: css`
    display: flex;
    padding: ${stringToVW('13px 16px')};
    align-items: flex-start;
    align-self: stretch;
    border-radius: ${stringToVW('13px')};
    background: #f5f5f5;
    // overflow: hidden;
    color: #4e5255;
    // text-overflow: ellipsis;
    font-size: ${stringToVW('12px')};
    font-weight: 400;
    line-height: ${stringToVW('17px')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    margin-top: ${stringToVW('10px')};
    margin-bottom: ${stringToVW('21px')};
  `,
  information: css`
    display: flex;
    align-items: center;
    gap: ${stringToVW('8px')};
    margin-top: ${stringToVW('10px')};
    margin-bottom: ${stringToVW('21px')};
  `,
  username: css`
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-size: ${stringToVW('12px')};
    font-weight: 400;
    line-height: ${stringToVW('16px')};
  `,
  channel: css`
    display: flex;
    padding: ${stringToVW('3px 6.67px')};
    justify-content: center;
    align-items: center;
    border-radius: ${stringToVW('6px')};
    border: 1px solid var(--essential-line-line-300017, #e0e0e0);
    overflow: hidden;
    color: #7d7d7d;
    text-align: center;
    text-overflow: ellipsis;
    font-weight: 400;
    ${globalStyle.fontRobo15}
  `,
};

const Icon = type => {
  if (type === 'comment') {
    return IconComment;
  } else if (type?.includes('Like')) {
    return IconLike;
  } else if (type?.includes('Dislike')) {
    return IconDislike;
  }
};

// 날짜 포멧 (--일 전,, --분 전)
function timeDifferenceToNow(isoDateString) {
  const now = new Date();
  const dateToCompare = new Date(isoDateString);

  const timeDifference = now - dateToCompare;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  if (minutesDifference < 1) {
    return '방금 전';
  } else if (minutesDifference < 60) {
    return `${minutesDifference}분 전`;
  } else {
    const hoursDifference = Math.floor(minutesDifference / 60);
    if (hoursDifference < 24) {
      return `${hoursDifference}시간 전`;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${daysDifference}일 전`;
    }
  }
}

const ReactionBox = ({ text }) => {
  return (
    <div css={styles.information}>
      <div css={styles.username}>{text?.userName}</div>
      <div css={styles.channel}>{text?.channel}</div>
    </div>
  );
};

const ActivityHistory = ({ data, type }) => {
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  return (
    <div css={styles.wrapper}>
      <div css={styles.activity}>
        <div css={styles.display}>
          <img src={Icon(type)} alt="" style={{ width: stringToVW('20px') }} />
          <span>{data?.label}</span>
        </div>
        <div
          css={css`
            color: #7d7d7d;
            font-size: ${stringToVW('12px')};
          `}
        >
          {timeDifferenceToNow(data?.date)}
        </div>
      </div>
      <div
        css={css`
          margin-top: ${stringToVW('13px')};
          gap: ${stringToVW('4px')};
        `}
        onClick={() => {
          if (data.postId) {
            let prevLink = pathname;
            if (search) {
              prevLink += search;
            }
            if (hash) {
              prevLink += hash;
            }

            navigate(`/post/${data.postId}`, {
              state: { prevLink: prevLink },
            });
          }
        }}
      >
        <div css={styles.titleLabel}>{data?.title}</div>
        <div css={styles.description}>{data?.postContents}</div>
        {type === 'comment' && (
          <div css={styles.commentArea}>
            {Number(data.active) === 2 ? '삭제된 댓글 입니다' : data.comment}
          </div>
        )}
        {(type?.includes('Like') || type?.includes('Dislike')) && (
          <ReactionBox
            text={{ userName: data.userName, channel: data.chnlName || '-' }}
          />
        )}
      </div>
    </div>
  );
};

export default ActivityHistory;
