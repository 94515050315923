import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { Input as BaseInput, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SvgIcon } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { useDispatch } from 'react-redux';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';

const Input = styled(BaseInput)`
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 0px;
  flex: 1 0 0;

  & .${inputClasses.input} {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;

    ${globalStyle.fontNanu21};
    line-height: normal;

    &::placeholder {
      color: #999;
      ${globalStyle.fontNanu21};
      line-height: normal;
      transform: translateY(${stringToVW('1px')});
    }
  }
`;
const HeaderSearch = ({ search, channelList }) => {
  const styles = {
    wrapper: css`
      display: flex;
      padding: ${stringToVW('8.25px 16px 8.25px 20px')};
      align-items: center;
      gap: ${stringToVW('6.7px')};
      flex: 1 0 0;
      border-radius: ${stringToVW('27px')};
      background: #f0f0f0;
      font-size: ${stringToVW('14px')};
    `,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState('');

  const onSearch = async e => {
    const word = e.target.value;
    if (!word.trim()) {
      document.getElementById('searchField').value = '';
      setKeyword('');
      return;
    }
    setKeyword(word);

    if (e?.key == 'Enter') {
      if (/^\d{1,3}$/.test(keyword)) {
        dispatch(
          setAlertInfo({
            msg: '숫자는 4자리 이상으로 입력해야 합니다.',
            severity: 'info',
          }),
        );
        dispatch(openSnackbar());
        return;
      }

      navigate(`/search/result?keyWord=${keyword}`, {
        state: { channelData: channelList },
      });
    }
  };

  return (
    <div>
      {search ? (
        <div
          css={css`
            margin: ${stringToVW('5.3px 0px')};
          `}
        >
          <div css={[styles.wrapper]}>
            <SvgIcon sx={{ fontSize: stringToVW('19px') }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.93425 14.1333C11.248 14.1333 13.9342 11.447 13.9342 8.13334C13.9342 4.81963 11.248 2.13334 7.93425 2.13334C4.62054 2.13334 1.93424 4.81963 1.93424 8.13334C1.93424 11.447 4.62054 14.1333 7.93425 14.1333ZM7.93425 15.8C12.1684 15.8 15.6009 12.3675 15.6009 8.13334C15.6009 3.89916 12.1684 0.466675 7.93425 0.466675C3.70006 0.466675 0.267578 3.89916 0.267578 8.13334C0.267578 12.3675 3.70006 15.8 7.93425 15.8Z"
                  fill="#999999"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.9554 12.9622C12.2899 12.6461 12.8173 12.6611 13.1334 12.9956L17.8521 17.9901C18.1681 18.3247 18.1531 18.8521 17.8186 19.1681C17.4841 19.4842 16.9566 19.4692 16.6406 19.1347L11.9219 14.1402C11.6059 13.8057 11.6208 13.2782 11.9554 12.9622Z"
                  fill="#999999"
                />
              </svg>
            </SvgIcon>
            <Input
              id="searchField"
              onKeyUp={onSearch}
              placeholder="채널/사용자/키워드를 검색해주세요."
              type="text"
              css={css`
                font-size: ${stringToVW('14px')};
              `}
              endAdornment={
                !!keyword && (
                  <InputAdornment
                    position="end"
                    sx={{
                      cursor: 'pointer',
                      margin: 'auto',
                    }}
                    onClick={() => {
                      document.getElementById('searchField').value = '';
                      setKeyword('');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.66683 15C11.7169 15 15.0002 11.7168 15.0002 7.66668C15.0002 3.61659 11.7169 0.333344 7.66683 0.333344C3.61674 0.333344 0.333496 3.61659 0.333496 7.66668C0.333496 11.7168 3.61674 15 7.66683 15ZM4.79 4.79012C5.02866 4.55146 5.41559 4.55146 5.65424 4.79012L7.66665 6.80253L9.67906 4.79012C9.91772 4.55147 10.3046 4.55147 10.5433 4.79012C10.782 5.02877 10.782 5.41571 10.5433 5.65436L8.5309 7.66677L10.5431 9.679C10.7818 9.91766 10.7818 10.3046 10.5431 10.5432C10.3045 10.7819 9.91754 10.7819 9.67889 10.5432L7.66665 8.53101L5.65442 10.5432C5.41576 10.7819 5.02883 10.7819 4.79017 10.5432C4.55152 10.3046 4.55152 9.91766 4.79017 9.67901L6.80241 7.66677L4.79 5.65436C4.55135 5.4157 4.55135 5.02877 4.79 4.79012Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </InputAdornment>
                )
              }
            />
          </div>
        </div>
      ) : null}
      {(/[a-zA-Z0-9]/.test(keyword)
        ? keyword.length >= 3
        : keyword.length >= 1) &&
      channelList &&
      channelList.length ? (
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: ${stringToVW('915px')};
            top: ${stringToVW('100px')};
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 8;
            background: white;
          `}
        >
          {channelList
            .filter(
              channel =>
                channel.channel_nm
                  .toLowerCase()
                  .includes(keyword.toLowerCase()) ||
                channel.stock_cd.toLowerCase().includes(keyword.toLowerCase()),
            )
            .map((channel, idx) => (
              <ul
                css={css`
                  width: 100%;
                  height: ${stringToVW('43px')};
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin: 0;
                  padding: 0 ${stringToVW('24px')};
                `}
                key={idx}
              >
                <li
                  css={css`
                    list-style-type: none;
                  `}
                >
                  <Link
                    to={`/channel/${encodeURIComponent(
                      channel.stock_cd,
                    )}/${encodeURIComponent(channel.channel_id)}`}
                    css={css`
                      font-family: NanumBarunGothic;
                      font-weight: 400;
                      ${globalStyle.fontNanu21}
                      letter-spacing: 0em;
                      text-align: left;
                      text-decoration-line: none;
                      color: #333333;
                    `}
                  >
                    {channel.channel_nm
                      .split(new RegExp(`(${keyword})`, 'gi'))
                      .map((part, i) =>
                        part.toLowerCase() === keyword.toLowerCase() ? (
                          <strong style={{ color: '#84C13D' }} key={i}>
                            {part}
                          </strong>
                        ) : (
                          part
                        ),
                      )}
                  </Link>
                </li>
              </ul>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default HeaderSearch;
