/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { getLoungeReactionList } from '@lib/api-call/app/loungeApi';
import { find, isEmpty } from 'lodash';
import { css } from '@emotion/react';
import { Avatar } from '@mui/material';
import { getUploadImage, roundStockQty } from '@lib/commonUtils';
import { ReactComponent as IconCerti } from '@asset/icons/common/icon-certification.svg';
import { getFollow } from '@lib/api-call/app/profileApi';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { globalStyle, stringToVW } from '@style/globalStyle';
import BackButton from '@component/app/common/BackButton';

const ReactionListPage = ({ reactionType = '' }) => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const [reactionInfo, setReactionInfo] = useState({
    totalAssets: '',
    list: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const reactionTitle = reactionType === 'like' ? '좋아요' : '싫어요';

  const { stockQty, stockPostFix } = roundStockQty(
    reactionInfo.totalAssets || '0',
  );

  const fetchingList = async () => {
    const param = {
      post_id: postId,
      reaction: reactionType,
    };

    const result = await getLoungeReactionList(param);

    if (!isEmpty(result)) {
      setReactionInfo(result);
    }
  };

  const styles = {
    headerContainer: css`
      display: flex;
      padding: ${stringToVW('10px 16px')};
      align-items: center;
      justify-content: space-between;
    `,
    headerTitle: css`
      font-size: ${stringToVW('16px')};
    `,
    assetsContainer: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      height: var(--size-h40, 40px);
      padding: 0px var(--161-rem, 16px);
      background: var(--essential-grayscale-ess-neu-200066, #f7f7f7);
    `,
    assetsLabel: css`
      color: #333;
      ${globalStyle.fontNanu18};
      line-height: normal;
      font-weight: 400;
    `,
    assetValueWrap: css`
      display: flex;
      align-items: center;
      gap: ${stringToVW('1.333px')};
    `,
    assetValue: css`
      color: #333;
      text-align: right;
      ${globalStyle.fontRobo18}
      font-weight: 500;
    `,
  };

  const updateFollow = async item => {
    setIsLoading(true);

    const { USER_ID, isFollowed } = item;

    const { message_cd } = await getFollow({
      followingId: USER_ID,
    });

    if (message_cd === '0') {
      const findInfo = find(reactionInfo.list, { USER_ID: USER_ID });
      if (isFollowed > 0) {
        findInfo.isFollowed = 0;
      } else {
        findInfo.isFollowed = 1;
      }
      setReactionInfo({ ...reactionInfo });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchingList();
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-self: stretch;
      `}
    >
      <div css={styles.headerContainer}>
        <BackButton />

        <div css={styles.headerTitle} className="flex-center">
          {reactionTitle}
        </div>
        <div
          css={css`
            width: ${stringToVW('22px')};
          `}
        />
      </div>
      <div css={styles.assetsContainer}>
        <div css={styles.assetsLabel}>
          {reactionTitle} 누른 사용자의 보유 주식 총합은?
        </div>
        <div css={styles.assetValueWrap}>
          <div css={styles.assetValue}>{stockQty}</div>
          <div css={styles.assetsLabel}>{stockPostFix}</div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          padding: ${stringToVW('8px 0px')};
          flex-direction: column;
          align-items: center;
          align-self: stretch;
        `}
      >
        {reactionInfo.list.map(reaction => (
          <div
            css={css`
              display: flex;
              padding: ${stringToVW('13px 16px')};
              align-items: center;
              gap: ${stringToVW('10.667px')};
              flex: 1 0 0;
              align-self: stretch;
            `}
            key={`reaction_${reactionType}_${reaction.USER_ID}`}
          >
            <Avatar
              sx={{ width: 40, height: 40 }}
              src={getUploadImage(reaction.PROFILE_IMG)}
              component={Link}
              to={`/profile/${encodeURIComponent(reaction.USER_ID)}`}
              state={{ prevLink: pathname }}
            />
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: ${stringToVW('')}var(--4025-rem, 2.667px);
                flex: 1 0 0;

                color: #000;
                ${globalStyle.fontNanu21}

                font-weight: 600;
              `}
              onClick={() => {
                navigate(`/profile/${encodeURIComponent(reaction.USER_ID)}`, {
                  state: { prevLink: pathname },
                });
              }}
            >
              <div>{reaction.USER_NM}</div>
              {reaction.USER_TYPE === '1' && (
                <IconCerti css={styles.iconCertiSize} />
              )}
            </div>
            {reaction.USER_ID !== loginedUser?.USER_ID && (
              <button
                css={css`
                  height: ${stringToVW('30px')};
                  width: ${stringToVW('60px')};
                  border-radius: ${stringToVW('6.7px')};
                  border: none;
                  color: white;
                  ${globalStyle.fontNanu18}
                `}
                style={{
                  background: reaction.isFollowed === 0 ? '#4e5255' : '#ffffff',
                  color: reaction.isFollowed === 0 ? 'white' : '#666666 ',
                  border:
                    reaction.isFollowed === 0
                      ? 'none'
                      : '1px solid var(--nmess-line-2017, #E0E0E0)',
                }}
                onClick={() => {
                  !isLoading && updateFollow(reaction);
                }}
              >
                {reaction.isFollowed === 0 ? '팔로우' : '팔로잉'}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReactionListPage;
