/* eslint-disable import/no-anonymous-default-export */
import { css } from '@emotion/react';

const withLocalLayout = WrappedComponent => {
  const hocComponent = props => {
    return (
      <div
        css={css`
          background-color: #e0e0e0;
        `}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };
  hocComponent.propTypes = {};

  return hocComponent;
};

export default WrapperComponent => withLocalLayout(WrapperComponent);
