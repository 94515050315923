import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import '@asset/css/style.css';
import { toNumber } from "lodash";
import { css } from "@emotion/react";
import Header from '@component/admin/common/Header';
import { DatePicker, Select, Input, Table, Button, Pagination } from "antd";
import { SearchOutlined} from '@ant-design/icons';
import dayjs from "dayjs";
import {
    getAdminList,
    delAdmin
} from "@lib/api-call/admin/adminManageApi";

// 숫자에 천 단위 쉼표 추가하는 함수
function formatNumberWithCommas(number) {
    return toNumber(number).toLocaleString();
}

// 테이블 컬럼설정
const columns = [
    {
        title: "NO",
        dataIndex: "rn",
        key: "no",
        align: "center",
        width: 50,
    },
    {
        title: "등록일",
        dataIndex: "regDt",
        key: "regDt",
        align: "center",
        width: 120,
    },
    {
        title: "관리자ID",
        dataIndex: "adminId",
        key: "adminId",
        align: "center",
        width: 150,
    },
    {
        title: "IP",
        dataIndex: "adminIp",
        key: "adminIp",
        align: "left",
        width: 300,
    },
    {
        title: "승인여부",
        dataIndex: "approval",
        key: "approval",
        align: "center",
        width: 80,
    },
    {
        title: "로그인실패수",
        dataIndex: "failCnt",
        key: "failCnt",
        align: "center",
        width: 80,
    },
    {
        title: "로그인실패일",
        dataIndex: "failDt",
        key: "failDt",
        align: "center",
        width: 120,
    }
  ];

const AdminManage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // pagination
    const [adminList, setAdminList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    // search
    const [joinDate, setJoinDate] = useState([]);
    const [adminId, setAdminId] = useState("");
    const [adminIp, setAdminIp] = useState("");
    const [approval, setApproval] = useState("");
    const { RangePicker } = DatePicker;
    const dateChange = (dates, dateStrings) => {
      setJoinDate(dates ? dateStrings : []);
    };

    //체크박스 id 값 저장
    const [checkBoxRow, setCheckBoxRow] = useState([]);
    const onSelectChange = (newRowKeys, record) => {
        setCheckBoxRow(newRowKeys);
    };
    const rowSelection = {
        selectedRowKeys: checkBoxRow,
        onChange: onSelectChange,
    };
    // 목록 조회
    const onAdminListGet = async () => {
        setLoading(true);
        const data = await getAdminList({
          schStTe: joinDate[0], // 등록일 시작
          schEdTe: joinDate[1], // 등록일 종료
          schAdminId: adminId, // 관리자 ID
          schAdminIp: adminIp, // 관리자 IP
          schApproval: approval, // 승인여부
          pageIdx: page * 10 + 1, // page index
          pageSize: 10, // pagesize
        });
        setTotalCount(data.totCnt);
        setAdminList(data.resData);
        setTimeout(() => {
            setLoading(false);
        });
    };

    // 검색
    const onSearch = () => {
        if (page === 0) {
            onAdminListGet();
        } else {
            setPage(0);
        }
    };

    // 검색조건 초기화
    const onClear = () => {
        setJoinDate([]);
        setAdminId("");
        setAdminIp("");
        setApproval("");
    };

    //페이지 이벤트
    const pageChange = (pageCount) => {
        setPage(pageCount - 1);
    };

    // 삭제
    const deleteAdmin = async () => {
        if(checkBoxRow.length == 0){
          alert('삭제할 관리자를 선택해주세요.');
          return;
        }
        if(confirm('삭제를 진행하시겠습니까?')) {
            const res = await delAdmin({
                adminIdList: checkBoxRow,
            });
            if (res.messageCd === "S") {
            alert("삭제되었습니다.");
            setCheckBoxRow([]);
            onSearch();
            } else {
            alert(res.messageNm);
            onSearch();
            }
        }
    };
    // 등록
    const regAdmin = () => {
        navigate("/admin/admin-manage/regist");
    };

    useEffect(() => {
        onAdminListGet();
    }, [page]);

    // Header BreadCrumb
    const dataProp = [
    {
        title: "관리자 관리",
        href: "/admin/admin-manage",
    },
    ];

    return (
        <>
        <Header dataProp={dataProp} />
        <div className="wrapper">
            <div className="flex gap-16">
                <p className="flex-center">등록일</p>
                <RangePicker
                    value={joinDate.map((x) => dayjs(x))}
                    format="YYYYMMDD"
                    onChange={dateChange}
                />
                
                <p className="flex-center">관리자ID</p>
                <Input
                    value={adminId}
                    onChange={(e) => setAdminId(e.target.value)}
                    onKeyDown={(e) => {
                    // 엔터 키를 눌렀을 때 onSearch 호출
                    if (e.key === "Enter") {
                        onSearch();
                    }
                    }}
                    css={css`width: 100px;`}
                    placeholder=""
                    allowClear
                />

                <p className="flex-center">관리자IP</p>
                <Input
                    value={adminIp}
                    onChange={(e) => setAdminIp(e.target.value)}
                    onKeyDown={(e) => {
                    // 엔터 키를 눌렀을 때 onSearch 호출
                    if (e.key === "Enter") {
                        onSearch();
                    }
                    }}
                    css={css`width: 200px;`}
                    placeholder=""
                    allowClear
                />

                <p className="flex-center">승인여부</p>
                <Select
                    defaultValue={""}
                    placeholder="승인여부"
                    style={{ width: 80, height: 42 }}
                    options={[
                    {
                        label: "전체",
                        value: "",
                    },
                    {
                        label: "예",
                        value: "Y",
                    },
                    {
                        label: "아니오",
                        value: "N",
                    },
                    ]}
                    onChange={(e) => setApproval(e)}
                    value={approval}
                />
                <div className="flex gap-16">
                    <SearchOutlined className="style-search" onClick={onSearch} />
                </div>
            </div>
            <div
                css={css`
                    padding-top: 24px;
                `}
                >
                <div className="bg-title-table flex-between">
                    <p className="text-title-table">
                    총 관리자 &#123;{formatNumberWithCommas(totalCount)}&#125;
                    </p>
                    <div className="flex gap-16">
                    <Button onClick={onClear}>검색조건초기화</Button>
                    <Button onClick={regAdmin}>등록</Button>
                    <Button className="mr-15" danger onClick={deleteAdmin}>삭제</Button>
                    </div>
                </div>
                <Table
                    bordered
                    rowKey="adminId"
                    columns={columns}
                    dataSource={adminList}
                    scroll={{ x: 1550, y: 600 }}
                    rowSelection={rowSelection}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                            navigate(
                                `/admin/admin-manage/detail/${encodeURIComponent(record.adminId)}`
                            );
                            },
                        };
                    }}
                    pagination={false}
                    loading={loading}
                />
                 <Pagination
                    className="pagination-box"
                    defaultCurrent={1}
                    current={page + 1}
                    total={totalCount}
                    pageSize={10}
                    showSizeChanger={false}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '16px 0',
                    }}
                    onChange={(page, pageSize) => pageChange(page)}
                    />
            </div>
        </div>
        </>
    );
};

export default AdminManage;