import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink, useLocation } from 'react-router-dom';

import NotificationIcon from '../icon/NotificationIcon';
import LoungeIcon from '../icon/LoungeIcon';
import SearchIcon from '../icon/SearchIcon';
import SubscribeIcon from '../icon/SubscribeIcon';
import MyProfileIcon from '../icon/MyProfileIcon';
import { useDispatch, useSelector } from 'react-redux';
import { toVW, stringToVW } from '@style/globalStyle';
import {
  setClickedPostId,
  setClickedWrittenPostId,
} from '@store/app/postReducer';

export const navbarHeight = 49;

const styles = {
  fixedBottom: css`
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    border-top: solid 1px #f5f5f5;
  `,
  container: css`
    display: flex;
    height: ${toVW(`${navbarHeight}px`)};
    padding: ${stringToVW('0px 8px')};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    background: #fff;
  `,
};

const BoxNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  flex: 1 0 0;
  background: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:active {
    background: none;
  }
`;
const Navbar = () => {
  const { pathname } = useLocation();
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const dispatch = useDispatch();

  return (
    <nav css={styles.fixedBottom}>
      <div css={styles.container}>
        <BoxNavLink
          to="/"
          onClick={() => {
            dispatch(setClickedPostId(undefined));
            dispatch(setClickedWrittenPostId(undefined));
            window.GA_Event('커뮤니티', '공통', '홈');
          }}
        >
          {({ isActive }) => (
            <LoungeIcon
              isActive={isActive}
              width={toVW('25.5px')}
              height={toVW('20px')}
            />
          )}
        </BoxNavLink>
        <BoxNavLink
          to="/search"
          onClick={() => {
            dispatch(setClickedPostId(undefined));
            dispatch(setClickedWrittenPostId(undefined));
            window.GA_Event('커뮤니티', '공통', '검색');
          }}
        >
          {({ isActive }) => (
            <SearchIcon
              isActive={isActive}
              width={toVW('20px')}
              height={toVW('20px')}
            />
          )}
        </BoxNavLink>
        <BoxNavLink
          to="/subscribe"
          state={{ prevLink: pathname }}
          onClick={() => {
            if (loginedUser) {
              dispatch(setClickedPostId(undefined));
              dispatch(setClickedWrittenPostId(undefined));
              window.GA_Event('커뮤니티', '공통', '구독');
            }
          }}
        >
          {({ isActive }) => (
            <SubscribeIcon
              isActive={isActive}
              width={toVW('20px')}
              height={toVW('20px')}
            />
          )}
        </BoxNavLink>
        <BoxNavLink
          to="/notification"
          state={{ prevLink: pathname }}
          onClick={() => {
            if (loginedUser) {
              dispatch(setClickedPostId(undefined));
              dispatch(setClickedWrittenPostId(undefined));
              window.GA_Event('커뮤니티', '공통', '알림');
            }
          }}
        >
          {({ isActive }) => (
            <NotificationIcon
              isActive={isActive}
              hasNoti={loginedUser && loginedUser.alramCount > 0}
              width={toVW('23px')}
              height={toVW('23px')}
            />
          )}
        </BoxNavLink>
        <BoxNavLink
          to="/my-profile"
          state={{ prevLink: pathname }}
          onClick={() => {
            if (loginedUser) {
              dispatch(setClickedPostId(undefined));
              dispatch(setClickedWrittenPostId(undefined));
              window.GA_Event('커뮤니티', '공통', '프로필');
            }
          }}
        >
          {({ isActive }) => (
            <MyProfileIcon
              isActive={isActive}
              width={toVW('25px')}
              height={toVW('25px')}
            />
          )}
        </BoxNavLink>
      </div>
    </nav>
  );
};
export default Navbar;
