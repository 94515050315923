import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { globalStyle, toVW } from '@style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { setBlockPopupIsShow } from '@store/app/commonReducer';

export const floatBtnHeight = 35;
export const floatBtnBottom = 12;
export const floatBtnBottomWithNavbar = 61;

const FloatButton = ({ hasNavbar, channelInfo }) => {
  const { pathname } = useLocation();
  // const loginedUser = useSelector(state => state.auth.loginedUser);
  // const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const query = searchParams.get('current'); // 쿼리 호출

  return (
    query !== 'Y' && (
      <div
        className="float-button-controller"
        css={css`
          z-index: 1050;
          position: fixed;
          bottom: ${hasNavbar
            ? toVW(`${floatBtnBottomWithNavbar}px`)
            : toVW(`${floatBtnBottom}px`)};
          right: ${toVW('8px')};
        `}
      >
        <Link
          css={css`
            display: flex;
            width: ${toVW('82px')};
            height: ${toVW(`${floatBtnHeight}px`)};
            padding: ${toVW('11px')};
            justify-content: space-around;
            align-items: center;
            flex-shrink: 0;
            border-radius: ${toVW('66.5px')};
            background: #4e5255;
            box-shadow: 0px 2.667px 6.667px 0px rgba(94, 97, 102, 0.25);
            text-decoration: none;
          `}
          onClick={() => {
            window.GA_Event('커뮤니티', '공통', '글쓰기');
          }}
          to={{
            pathname: '/write-post',
            search: `${new URLSearchParams({
              ...(channelInfo?.stock_cd && { stock_cd: channelInfo?.stock_cd }),
              ...(String(channelInfo?.channel_id) && {
                channel_id: channelInfo?.channel_id,
              }),
            })}`,
          }}
          state={{ prevLink: pathname }}
          // to="/write-post"
          // state={{ channelInfo: channelInfo, prevLink: pathname }}
        >
          <span
            css={css`
              width: ${toVW('15px')};
              height: ${toVW('15px')};
              display: flex;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.978 2.36637C10.8003 2.18988 10.5122 2.18988 10.3345 2.36637L3.25345 9.39926C3.20166 9.45069 3.16314 9.51378 3.14116 9.58317L2.4419 11.7901C2.38668 11.9644 2.55174 12.1283 2.72722 12.0735L4.94929 11.379C5.01915 11.3572 5.08267 11.3189 5.13445 11.2675L12.2155 4.23458C12.3932 4.05809 12.3932 3.77195 12.2155 3.59546L10.978 2.36637ZM9.691 1.72724C10.2241 1.19777 11.0884 1.19777 11.6215 1.72724L12.859 2.95633C13.3921 3.4858 13.3921 4.34424 12.859 4.87371L5.77796 11.9066C5.6226 12.0609 5.43204 12.1757 5.22246 12.2412L3.00039 12.9357C2.123 13.2099 1.29772 12.3902 1.57382 11.5188L2.27307 9.31185C2.33902 9.1037 2.45459 8.91444 2.60994 8.76013L9.691 1.72724Z"
                fill="#C5C5C5"
                stroke="#C5C5C5"
                strokeWidth="0.333333"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5231 6.53611L7.99859 4.02877L8.64209 3.38965L11.1666 5.89699L10.5231 6.53611Z"
                fill="#C5C5C5"
                stroke="#C5C5C5"
                strokeWidth="0.333333"
              />
            </svg>
          </span>
          <span
            css={css`
              ${globalStyle.fontNanu19};
              font-weight: 600;
              color: #fff;
            `}
          >
            글쓰기
          </span>
        </Link>
      </div>
    )
  );
};

export default FloatButton;
