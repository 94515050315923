import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';

import SelectChannelWrapper from '@component/app/post/SelectChannelWrapper';
import InputPost from '@component/app/post/InputPost';
import WritePostOptionList from '@component/app/post/WritePostOptionList';
import WritePostHeader from '@component/app/common/WritePostHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsUpdatePost,
  updateWritePostImages,
  updateWritePostItem,
} from '@store/app/postReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';
import { getChannelList } from '@lib/api-call/app/searchApi';
import { getFileFromApi } from '@lib/api-call/app/fileApi';
import { setBlockPopupIsShow } from '@store/app/commonReducer';
import { getChannelInfo } from '@lib/api-call/app/loungeApi';
const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  flex-shrink: 0;
`;

const WritePost = ({ isUpdate = false }) => {
  const navigate = useNavigate();

  const { state: routerState } = useLocation();
  const route = useLocation();

  const [searchChannelList, setSearchChannelList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [isClick, setIsClick] = useState(false);
  const writePostItem = useSelector(state => state.post.writePostItem);
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const getAutoSearchList = async () => {
    try {
      const data = await getChannelList();
      setSearchChannelList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getChannel = async ({ stock_cd, channel_id }) => {
    let channelInfo = await getChannelInfo({ stock_cd, channel_id });
    setSelectedChannel({ ...channelInfo });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const newItem = { ...writePostItem };

    if (selectedChannel !== undefined) {
      if (selectedChannel) {
        newItem.channel_id = selectedChannel.channel_id;
        newItem.stock_cd = selectedChannel.stock_cd;
      } else {
        newItem.channel_id = undefined;
        newItem.stock_cd = undefined;
      }

      dispatch(updateWritePostItem(newItem));
    }
  }, [selectedChannel]);
  useEffect(() => {
    if (loginedUser && loginedUser.USER_STAT_CD === '1') {
      dispatch(setBlockPopupIsShow(true));
      navigate(-1);
    }
    const initItem = { type: '0', holder_yn: 'N', stock_info_yn: 'N' };

    if (!isUpdate && isEmpty(searchChannelList)) {
      getAutoSearchList();
    }

    // if (routerState?.channelInfo) {
    //   setSelectedChannel(routerState?.channelInfo);
    // }
    const searchParams = new URLSearchParams(route.search);

    if (searchParams.get('stock_cd') && searchParams.get('channel_id')) {
      getChannel({
        stock_cd: decodeURIComponent(searchParams.get('stock_cd')),
        channel_id: decodeURIComponent(searchParams.get('channel_id')),
      });
    }

    if (isUpdate && !isEmpty(routerState?.updateItem)) {
      dispatch(setIsUpdatePost(true));
      const { updateItem } = routerState;

      if (loginedUser?.USER_ID !== updateItem.user_id) {
        navigate('/');
      }

      const {
        post_id,
        title,
        contents,
        channel_id,
        stock_cd,
        channel_nm,
        stock_holding_dt,
        stock_qty,
        holding_stock,
        holder_yn,
        post_img,
      } = updateItem;

      initItem.post_id = post_id;
      initItem.title = title;
      initItem.contents = contents;
      initItem.stock_info_yn = isEmpty(holding_stock) ? 'N' : 'Y';
      initItem.holder_yn = holder_yn;

      if (!isEmpty(post_img) && isArray(post_img)) {
        initItem.post_img = post_img;
        (async () => {
          const olderList = [];

          for (const img of post_img) {
            let file;
            try {
              file = await getFileFromApi(img.image_url);
              if (!file) {
                const response = await fetch(decodeURIComponent(img.image_url));
                const arrayBuffer = await response.arrayBuffer();
                const uint8Array = new Uint8Array(arrayBuffer);
                const blob = new Blob([uint8Array], {
                  type: response.headers.get('content-type'),
                });

                const files = new File([blob], img.image_url, {
                  // type: response.headers['content-type'],
                  type: 'image/jpeg',
                });
                file = files;
              }
            } catch (error) {
              // 파일 에러 발생
              file = null;
            }

            if (file) {
              olderList.push(file);
            }
          }

          Promise.all(
            olderList.map(file => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = event => {
                  resolve({ img: event.target.result, file: file });
                };

                reader.onerror = error => {
                  reject(error);
                };

                reader.readAsDataURL(file);
              });
            }),
          )
            .then(images => {
              dispatch(updateWritePostImages(images));
            })
            .catch(error => {
              console.error('Error reading files:', error);
            });
        })();
      }

      setSelectedChannel({
        channel_id: channel_id,
        stock_cd: stock_cd,
        channel_nm: channel_nm,
        isHave:
          !isEmpty(stock_holding_dt) && !isEmpty(stock_qty) ? '1' : undefined,
      });
    } else if (isUpdate && isEmpty(routerState?.updateItem)) {
      navigate('/');
    }

    dispatch(updateWritePostItem(initItem));

    return () => {
      dispatch(updateWritePostItem(undefined));
      dispatch(updateWritePostImages([]));
      dispatch(setIsUpdatePost(false));
    };
  }, []);

  return (
    <div css={wrapper}>
      <WritePostHeader />
      <SelectChannelWrapper
        selectedChannel={selectedChannel}
        setSelectedChannel={setSelectedChannel}
        searchChannelList={searchChannelList}
        isClick={isClick}
        setIsClick={setIsClick}
      />

      <div
        css={css`
          display: ${!isClick ? 'block' : 'none'};
          width: 100%;
        `}
      >
        <InputPost />
        <WritePostOptionList selectedChannel={selectedChannel} />
      </div>
      <CheckWithdrawAccount />
    </div>
  );
};

export default WritePost;
