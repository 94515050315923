import React from 'react';
import Avatar from '@mui/material/Avatar';
import { SvgIcon, css } from '@mui/material';
import IconEdit from '@asset/icons/profile/icon-edit.svg';
import IconSetting from '@asset/icons/profile/icon-setting.svg';
import { Link, useNavigate } from 'react-router-dom';
import { getUploadImage } from '@lib/commonUtils';
import { chain, compact, isEmpty } from 'lodash';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { ReactComponent as IconCerti } from '@asset/icons/common/icon-certification.svg';
import Stack from '@mui/material/Stack';
const styles = {
  wrapper: css`
    font-family: NanumBarunGothic;
    font-style: normal;
  `,
  userInfo: css`
    display: flex;
  `,
  avatar: css`
    width: ${stringToVW('75px')};
    height: ${stringToVW('75px')};
  `,
  edit: css`
    position: absolute;
    bottom: 0px;
    right: 0;
    width: ${stringToVW('30px')};
    height: ${stringToVW('30px')};
  `,
  totalFollow: css`
    color: #000;
    ${globalStyle.fontRobo21}
    font-weight: 500;
  `,
  label: css`
    color: #999;
    ${globalStyle.fontNanu18}
    font-weight: 400;
    padding: ${stringToVW('4px 0px 6px 0px')};
  `,
  cash: css`
    color: #333;
    ${globalStyle.fontRobo15}
    display: flex;
    justify-content: start;
    padding: ${stringToVW('2.5px 6.5px 2.5px 2.5px')};
    align-items: center;
    gap: ${stringToVW('6px')};
    border-radius: ${stringToVW('20px')};
    background: #f3f3f3;
    width: fit-content;
    > span {
      word-break: keep-all;
    }
  `,
  displayCol: css`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  `,
  username: css`
    color: #000;
    font-size: ${stringToVW('16px')};
    font-weight: 600;
    line-height: ${stringToVW('22.67px')};
  `,
  bio: css`
    color: var(--default-grayscale-neu-600013, #666666);
    ${globalStyle.fontNanu18}
  `,
  btnRegular: css`
    min-width: ${stringToVW('60px')};
    height: ${stringToVW('27px')};
    border-radius: ${stringToVW('6.7px')};
    gap: ${stringToVW('10px')};
    background: #4e5255;
    color: #ffffff;
    ${globalStyle.fontNanu18}
    text-align: center;
    border: none;
    margin-top: ${stringToVW('13px')};
  `,
  btnRegularWhite: css`
    min-width: ${stringToVW('60px')};
    height: ${stringToVW('27px')};
    border-radius: ${stringToVW('6.7px')};
    gap: ${stringToVW('10px')};
    background: #ffffff;
    border: 1px solid #e0e0e0;
    color: #666666;
    ${globalStyle.fontNanu18}
    text-align: center;
    margin-top: ${stringToVW('13px')};
  `,
};

function formatNumberToKorean(number) {
  const unitNames = ['', '만', '억', '조', '경']; // 한글 단위

  const formattedString = chain(number)
    .toString()
    .split('')
    .reverse()
    .chunk(4)
    .flatMap((chunk, index) => {
      const formattedChunk = compact(chunk.reverse()).join('');
      return formattedChunk ? [formattedChunk + unitNames[index]] : [];
    })
    .reverse()
    .slice(0, 1) // 뒷자리 제외
    .join(' ')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // 천 단위 쉼표 추가
    .value();

  return formattedString;
}

// 주어진 숫자의 세 번째 자리를 반올림
function roundThirdDigit(num) {
  let a = Number('1' + '0'.repeat(num.length - 2));
  return Math.round(num / a) * a;
}

const formatAmount = number => {
  if (!number) return null;
  let lengths = String(number);
  let newNum = null;
  // console.log(lengths)

  // 반올림
  if (lengths.length > 3) {
    newNum = roundThirdDigit(lengths);
    // console.log(newNum);
  }
  // 천 단위로 쉼표 처리하는 함수
  const addCommas = val => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  if (String(newNum).length < 5)
    return (
      <>
        {addCommas(newNum || number)}
        <span
          css={css`
            ${globalStyle.fontNanu15}
          `}
        >
          원
        </span>
      </>
    );
  else if (lengths.length < 9) {
    // 5자리 ~ 8자리 (만원단위)
    let sum =
      newNum / Math.pow(10, String(newNum).length - (lengths.length - 4));
    return (
      <>
        {addCommas(sum)}
        <span
          css={css`
            ${globalStyle.fontNanu15}
          `}
        >
          만
        </span>
      </>
    );
    // `${addCommas(sum)} 만`;
  } else if (String(newNum).length < 13) {
    // 9~12자리 (억원단위)
    let sum =
      newNum / Math.pow(10, String(newNum).length - (lengths.length - 8));
    return (
      <>
        {addCommas(sum)}
        <span
          css={css`
            ${globalStyle.fontNanu15}
          `}
        >
          억
        </span>
      </>
    );
    // `${addCommas(sum)} 억`;
  } else {
    return (
      <>
        9999
        <span
          css={css`
            ${globalStyle.fontNanu15}
          `}
        >
          억
        </span>
      </>
    );
  }
};

const ProfileInfo = ({
  isProfileOfOthers,
  profile,
  profileAvatarImg,
  follower,
  following,
  intro,
  name,
  userId,
  isFollowed,
  updateFollow,
  totalFollowerAssets,
  userType,
}) => {
  const navigate = useNavigate();

  return (
    <div css={styles.wrapper}>
      <div css={styles.userInfo}>
        <div
          css={css`
            position: relative;
            margin-right: ${stringToVW('32px')};
          `}
        >
          <Avatar
            css={styles.avatar}
            alt=""
            src={getUploadImage(profileAvatarImg)}
            imgProps={{
              onError: () => '@asset/images/profile/avatar-samsung.svg',
            }}
            // onClick={() =>
            //   navigate(
            //     `/profile/follow?userId=${encodeURIComponent(
            //       userId,
            //     )}&tab=follow`,
            //   )
            // }
          />
          {isProfileOfOthers || profile === 'people' ? null : (
            <Link
              to="/profile/edit"
              onClick={() => {
                window.GA_Event('커뮤니티', '내 프로필', '프로필 수정');
              }}
            >
              <img css={styles.edit} src={IconEdit} alt="" />
            </Link>
          )}
        </div>
        <div
          css={css`
            padding-top: ${stringToVW('12px')};
            margin-left: ${stringToVW('16px')};
            min-width: ${stringToVW('60px')};
          `}
        >
          <Link
            to={`/profile/follow?userId=${encodeURIComponent(
              userId,
            )}&tab=follow`}
            css={css`
              text-decoration: none;
            `}
            onClick={() => {
              window.GA_Event('커뮤니티', '내 프로필', '팔로워');
            }}
          >
            <div css={styles.totalFollow}>{follower}</div>
            <div css={styles.label}>팔로워</div>
          </Link>
          {Number(follower) > 4 && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="start"
              gap={stringToVW('4px')}
            >
              <SvgIcon
                sx={{ width: stringToVW('10px'), height: stringToVW('10px') }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                >
                  <path
                    d="M1 0.899414V1.89941C1 5.67065 1 7.55627 2.17157 8.72784C3.34315 9.89941 5.22876 9.89941 9 9.89941H10"
                    stroke="#E0E0E0"
                    strokeWidth="0.798"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
              <div css={styles.cash}>
                <span
                  css={css`
                    width: ${stringToVW('13.5px')};
                    height: ${stringToVW('13.5px')};
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <circle
                      cx="6.66667"
                      cy="7.33366"
                      r="6.66667"
                      fill="#84C13D"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.85507 7.82345H8.78643L8.40382 9.2308C8.37043 9.35666 8.29671 9.46776 8.19421 9.54672C8.0917 9.62567 7.9662 9.66803 7.83734 9.66714H7.83681C7.56899 9.66714 7.34155 9.49142 7.27087 9.2308L6.66667 7.01257L6.063 9.2308C6.02961 9.35666 5.95589 9.46776 5.85339 9.54672C5.75088 9.62567 5.62538 9.66803 5.49652 9.66714C5.2287 9.66714 5.00126 9.49196 4.93005 9.23134L4.54691 7.82345H3.47826C3.35142 7.82345 3.22977 7.77249 3.14008 7.6818C3.05039 7.5911 3 7.46809 3 7.33982C3 7.21155 3.05039 7.08854 3.14008 6.99784C3.22977 6.90715 3.35142 6.85619 3.47826 6.85619H4.28386L3.94855 5.62455C3.93001 5.56276 3.92389 5.49783 3.93056 5.4336C3.93723 5.36937 3.95656 5.30714 3.98739 5.25059C4.01823 5.19403 4.05996 5.1443 4.11012 5.10431C4.16027 5.06433 4.21784 5.03491 4.27942 5.01779C4.341 5.00067 4.40535 4.9962 4.46867 5.00463C4.53199 5.01307 4.59299 5.03424 4.64809 5.0669C4.70318 5.09957 4.75125 5.14306 4.78946 5.19481C4.82766 5.24656 4.85523 5.30553 4.87053 5.36823L5.49652 7.66707L6.10019 5.44937C6.1334 5.32338 6.20705 5.2121 6.30959 5.13302C6.41212 5.05395 6.53772 5.01155 6.66667 5.0125C6.93449 5.0125 7.16246 5.18822 7.23314 5.44937L7.83734 7.66707L8.4628 5.36823C8.47945 5.30698 8.50786 5.24964 8.54641 5.19949C8.58497 5.14934 8.63292 5.10736 8.68752 5.07595C8.74212 5.04454 8.8023 5.02431 8.86463 5.01642C8.92697 5.00853 8.99022 5.01313 9.0508 5.02996C9.11137 5.04679 9.16807 5.07552 9.21766 5.11451C9.26726 5.1535 9.30877 5.20199 9.33983 5.2572C9.3709 5.31241 9.3909 5.37327 9.3987 5.4363C9.40651 5.49933 9.40196 5.5633 9.38531 5.62455L9.04947 6.85619H9.85507C9.98192 6.85619 10.1036 6.90715 10.1933 6.99784C10.2829 7.08854 10.3333 7.21155 10.3333 7.33982C10.3333 7.46809 10.2829 7.5911 10.1933 7.6818C10.1036 7.77249 9.98192 7.82345 9.85507 7.82345Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>{formatAmount(totalFollowerAssets)}</span>
              </div>
            </Stack>
          )}
        </div>
        <div
          css={css`
            height: ${stringToVW('42px')};
            padding: ${stringToVW('0px 16px')};
            margin: ${stringToVW('12px 0px')};
            min-width: ${stringToVW('80px')};
          `}
        >
          <Link
            to={`/profile/follow?userId=${encodeURIComponent(
              userId,
            )}&tab=following`}
            css={css`
              text-decoration: none;
            `}
            onClick={() => {
              window.GA_Event('커뮤니티', '내 프로필', '팔로잉');
            }}
          >
            <div css={styles.totalFollow}>{following}</div>
            <div css={styles.label}>팔로잉</div>
          </Link>
        </div>
        <div
          css={css`
            padding-top: ${stringToVW('8px')};
            width: 100%;
            display: flex;
            justify-content: end;
          `}
        >
          {isProfileOfOthers ? null : (
            <Link
              to="/profile/settings"
              onClick={() => {
                window.GA_Event('커뮤니티', '내 프로필', '더보기');
              }}
            >
              <img
                src={IconSetting}
                alt=""
                style={{
                  width: stringToVW('21.3px'),
                  height: stringToVW('21.3px'),
                }}
              />
            </Link>
          )}
        </div>
      </div>
      <div
        css={css`
          padding-top: ${stringToVW('13px')};
        `}
      >
        <div
          css={styles.username}
          // onClick={() =>
          //   navigate(
          //     `/profile/follow?userId=${encodeURIComponent(userId)}&tab=follow`,
          //   )
          // }
          className="flex gap-5"
          style={{ gap: '5px' }}
        >
          <span>{name}</span>
          <span>
            {userType === 1 && (
              <IconCerti
                css={css`
                  width: ${stringToVW('21px')};
                  height: ${stringToVW('21px')};
                `}
              />
            )}
          </span>
        </div>
        <div css={styles.bio}>{intro || '-'}</div>
        {profile === 'people' && !isEmpty(isFollowed) && (
          <button
            css={
              isFollowed === '0' ? styles.btnRegular : styles.btnRegularWhite
            }
            onClick={() => {
              window.GA_Event('커뮤니티', '타인 프로필', '팔로우');
              updateFollow();
            }}
          >
            {isFollowed === '0' ? '팔로우' : '팔로잉'}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
