import React, { useEffect, useState } from 'react';
import HeaderBasic from '@component/app/common/HeaderBasic';
import Radio from '@mui/material/Radio';
import { css } from '@mui/material/';
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize/TextareaAutosize';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getReportUserIn } from '@lib/api-call/app/authApi';
import { getProfile } from '@lib/api-call/app/profileApi';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { globalStyle, stringToVW } from '@style/globalStyle';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const styles = {
  wrapper: css`
    font-family: NanumBarunGothic;
    font-style: normal;
  `,
  btn: css`
    width: 100%;
    height: ${stringToVW('50px')};
    background: #84c13d;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* position: absolute; */
    bottom: 0;
    left: auto;
    right: auto;
    color: var(--nm-default-6063, #fff);
    text-align: center;
    font-size: ${stringToVW('17px')};
    font-weight: 400;
    line-height: ${stringToVW('26px')};
    position: fixed;
  `,
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    width: 100%;
    ${globalStyle.fontNanu21}
    font-weight: 400;
    padding: ${stringToVW('12px')};
    border-radius: ${stringToVW('10px')};
    color: ${theme.palette.mode === 'dark' ? '#000000' : '#000000'};
    background: ${theme.palette.mode === 'dark' ? grey[100] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };
     resize: none;
    ::placeholder {
      color: #C5C5C5;
    }
    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
const Report = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState('');
  const [content, setContent] = useState('');
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const [searchParams] = useSearchParams();

  const p_report_type = searchParams.get('report_type'); // 신고 타입 (0:게시글,1:사용자,2:댓글,3:대댓글)
  const p_user_id = decodeURIComponent(searchParams.get('user_id')); // 신고 '당하는' 유저
  const p_report_key = searchParams.get('post_id'); // 신고타입 1을 제외하고 post ID
  const [p_reporter_id, setReportId] = useState(null);

  const alertInfo = useSelector(state => state.common.alertInfo);
  const newAlertInfo = { ...alertInfo };
  newAlertInfo.severity = 'info';

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const reportText = () => {
    if (String(p_report_type) === '1') return '프로필이에요';
    else return '컨텐츠에요';
  };

  const getMyProfile = async () => {
    const myProfileData = await getProfile();
    setReportId(myProfileData.user_id);
  };

  const reportSubmit = async () => {
    const report = await getReportUserIn({
      p_reporter_id,
      p_report_type,
      p_user_id,
      p_report_key,
      p_report_reason: selectedValue,
      p_comment: selectedValue === '4' ? content : null,
    });
    if (report.message_cd !== '0') {
      newAlertInfo.msg = report.message_nm;
      newAlertInfo.severity = 'error';
      dispatch(setAlertInfo(newAlertInfo));
      dispatch(openSnackbar());
      return;
    } else {
      newAlertInfo.msg = `${
        p_report_type === '1' ? '프로필' : '컨텐츠'
      } 신고 처리 되었습니다.`;
      newAlertInfo.severity = 'success';
      dispatch(setAlertInfo(newAlertInfo));
      dispatch(openSnackbar());
    }

    navigate(-1);
  };

  useEffect(() => {
    getMyProfile();
  }, []);
  return (
    <div css={styles.wrapper}>
      <HeaderBasic title="신고" statusUser="" />
      <div
        css={css`
          display: flex;
          align-items: center;
          padding: ${stringToVW('4px 24px 14px 24px')};
        `}
      >
        <Radio
          {...controlProps('0')}
          sx={{
            padding: stringToVW('0 10px'),
            color: '#E0E0E0',
            '&.Mui-checked': {
              color: '#84C13D',
            },
          }}
        />
        <div
          css={css`
            color: #333;
            font-size: ${stringToVW('14px')};
          `}
        >
          광고성 {reportText()}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          padding: ${stringToVW('14px 24px')};
        `}
      >
        <Radio
          {...controlProps('1')}
          sx={{
            padding: stringToVW('0 10px'),
            color: '#E0E0E0',
            '&.Mui-checked': {
              color: '#84C13D',
            },
          }}
        />
        <div
          css={css`
            color: #333;
            font-size: ${stringToVW('14px')};
          `}
        >
          성적/선정적인 {reportText()}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          padding: ${stringToVW('14px 24px')};
        `}
      >
        <Radio
          {...controlProps('2')}
          sx={{
            padding: stringToVW('0 10px'),
            color: '#E0E0E0',
            '&.Mui-checked': {
              color: '#84C13D',
            },
          }}
        />
        <div
          css={css`
            color: #333;
            font-size: ${stringToVW('14px')};
          `}
        >
          욕설/비하가 담긴 {reportText()}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          padding: ${stringToVW('14px 24px')};
        `}
      >
        <Radio
          {...controlProps('4')}
          sx={{
            padding: stringToVW('0 10px'),
            color: '#E0E0E0',
            '&.Mui-checked': {
              color: '#84C13D',
            },
          }}
        />
        <div
          css={css`
            color: #333;
            font-size: ${stringToVW('14px')};
          `}
        >
          직접 입력
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          height: 100%;
          position: relative;
          padding: ${stringToVW('0px 24px')};
          margin-bottom: ${stringToVW('60px')};
        `}
      >
        <label
          css={css`
            position: absolute;
            bottom: ${stringToVW('14px')};
            right: ${stringToVW('44px')};
            color: #7d7d7d;
            ${globalStyle.fontRobo15}
          `}
          htmlFor="Textarea"
        >
          {content.length}/100
        </label>
        <Textarea
          sx={{
            fontSize: '14px',
            border: '1px solid #E0E0E0',
            borderRadius: stringToVW('10.7px'),
            boxShadow: 'none',
            '::placeholder': {
              color: '#C5C5C5',
              opacity: 1,
            },
            ':hover': {
              border: '1px solid #E0E0E0',
            },
          }}
          id="Textarea"
          minRows={4}
          aria-label="empty textarea"
          placeholder="사유를 직접 입력해주세요."
          onChange={e => {
            setContent(e.target.value);
          }}
          disabled={selectedValue !== '4'}
          maxLength={100}
        />
      </div>
      <button
        css={styles.btn}
        onClick={() => {
          if (String(p_report_type) === '1') {
            window.GA_Event('커뮤니티', '타인 프로필', '신고 완료');
          } else {
            window.GA_Event('커뮤니티', '공통', '신고 완료');
          }
          reportSubmit();
        }}
      >
        완료
      </button>
    </div>
  );
};

export default Report;
