import React from 'react';
import { css } from '@emotion/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import ThumbnailAllView from '@asset/images/subscribe/thumbnail-subscribe-all.png';
import ThumbnailMore from '@asset/images/subscribe/thumbnail-subscribe-morel.png';
import SlideChannelItem from './SlideChannelItem';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stringToVW, toVW, ratio, globalStyle } from '@style/globalStyle';

const slideGap = 11 * ratio;

function SubscribeChannelPicker({ channelList, channelClick, fixedChannel }) {
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const navigate = useNavigate();

  const styles = {
    container: css`
      width: 100%;
      padding: ${stringToVW('16px')};
      margin-top: ${stringToVW('16px')};
      border-top: 1px solid #f5f5f5;
    `,
  };
  return (
    <div css={styles.container}>
      <div
        css={css`
          ${globalStyle.fontNanu24};
          font-weight: 600;
          margin-bottom: ${stringToVW('10.7px')};
        `}
      >
        MY 구독
      </div>
      <Swiper
        loop={false}
        style={{
          width: `calc(100% + ${toVW('30px')}`,
          margin: `0px ${toVW('-15px')}`,
          padding: `0px ${toVW('15px')}`,
        }}
        slidesPerView="auto"
        spaceBetween={slideGap}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode]}
      >
        <SwiperSlide style={{ width: 'auto' }}>
          <SlideChannelItem
            key="slide_channel_all"
            channel={{
              name: '전체',
              logoImgPath: ThumbnailAllView,
              active: !fixedChannel.channelId && !fixedChannel.stockCd,
              isStaticFile: true,
            }}
            channelClick={channelClick}
          />
        </SwiperSlide>
        {channelList.slice(0, 10).map((channel, index) => {
          return (
            <SwiperSlide
              key={`slide_channel_${index}`}
              style={{ width: 'auto' }}
            >
              <SlideChannelItem
                key={`slide_channel_${index}`}
                channel={{
                  name: channel.channel_nm,
                  logoImgPath: channel.thumb_url,
                  fixed: channel.pin_check,
                  stockCd: channel.stock_cd,
                  channelId: channel.channel_id,
                  active:
                    fixedChannel.channelId === channel.channel_id &&
                    fixedChannel.stockCd === channel.stock_cd,
                  country: channel?.country_cd || null,
                }}
                channelClick={channelClick}
              />
            </SwiperSlide>
          );
        })}
        <SwiperSlide style={{ width: 'auto' }}>
          {/* 클릭 시 채널 목록으로 이동 */}
          <SlideChannelItem
            key="slide_channel_more"
            channel={{
              name: '더보기',
              logoImgPath: ThumbnailMore,
              fixed: false,
              isStaticFile: true,
            }}
            channelClick={() =>
              navigate(
                `/subscribe/channel/${encodeURIComponent(loginedUser.USER_ID)}`,
              )
            }
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

SubscribeChannelPicker.propTypes = {};

export default SubscribeChannelPicker;
