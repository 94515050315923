import { createTheme } from '@mui/material';
import { css } from '@emotion/react';

const theme = createTheme({
  nh: {
    colors: {
      defalutGrayscale: {
        900: '#000000',
        800: '#222222',
        700: '#333333',
        600: '#666666',
        500: '#7D7D7D',
        400: '#999999',
        300: '#C5C5C5',
        200: '#D1D1D1',
        100: '#E0E0E0',
        75: '#F3F3F3',
        50: '#F7F7F7',
        24: '#F9F9F9',
        0: '#FFFFFF',
      },
      essentialGrayscale: {
        900: '#2C363B',
        800: '#2C363B',
        700: '#4E5255',
        300: '#EBEDF1',
        200: '#F7F7F7',
        100: '#F9F9F9',
        0: '#FFFFFF',
      },
      essentialLine: {
        400: '#ADADAD',
        300: '#E0E0E0',
        200: '#E0E0E0',
        100: '#F5F5F5',
      },
      essentialPrimary: {
        900: '#002C2C',
        800: '#0B4D14',
        700: '#228F00',
        600: '#3C9800',
        500: '#84C13D',
        400: '#B1F54D',
        300: '#E7FCC9',
        200: '#F0FAE5',
        100: '#F7FFF3',
        50: '#F9FDF4',
      },
      essentialOthers: {
        red: '#D73838',
        blue: '#247EF2',
        green: '#009F83',
        redLight: '#FFF8F9',
        blueLight: '#F4F9FF',
        BGOrigin: '#008E7E',
        BGMedium: '#73D1A8',
        BGLight: '#DCFBD9',
        OPOrigin: '#FFA569',
        OPMedium: '#FFCD96',
        OPLight: '#FFF0E0',
        PPOrigin: '#7762DE',
        PPMedium: '#B5B3F2',
        PPLight: '#EEEBF5',
        SBOrigin: '#6FADFF',
        SBMedium: '#ADD1FF',
        SBLight: '#E8F2FF',
        CPOrigin: '#FF7D80',
        CPMedium: '#FDB2AC',
        CPLight: '#FFEDEC',
      },
      local: {
        LGLike: '#BEEB7B',
      },
    },
    borderRadius: {
      sharp: '0px',
      xxxs: '6px',
      xxs: '8px',
      xs: '10px',
      sm: '12px',
      md: '16px',
      lg: '20px',
      xl: '24px',
      xxl: '30px',
      pill: '1000px',
    },
  },
  typography: {
    allVariants: {
      color: '#000',
      fontFamily: 'NanumBarunGothic',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: ({ theme }) => ({}),
    },
  },
  tabs: {
    "& button[aria-selected='true']": {
      borderBottom: '1.5px solid red',
    },
  },
});

export default theme;
