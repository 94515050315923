import Box from '@mui/material/Box';

const NotificationIcon = ({ isActive, hasNoti, width = 34, height = 34 }) => {
  return (
    <Box
      sx={{
        width,
        height,
      }}
    >
      {isActive
        ? hasNoti
          ? SvgActiveWithNotification
          : SvgActive
        : hasNoti
        ? SvgInactiveWithNotification
        : SvgInactive}
    </Box>
  );
};

const SvgActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 23"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25063 9.16634C4.25063 4.84011 7.75773 1.33301 12.084 1.33301C16.4102 1.33301 19.9173 4.84011 19.9173 9.16634V13.1925C19.9384 13.2203 19.9585 13.249 19.9776 13.2787L21.2633 15.2787C21.8338 16.166 21.1966 17.333 20.1417 17.333H16.2824C15.9143 19.2952 14.1859 20.7762 12.1153 20.7762C10.0447 20.7762 8.31628 19.2952 7.94817 17.333H4.02619C2.9713 17.333 2.33418 16.166 2.90462 15.2787L4.19033 13.2787C4.20938 13.249 4.22951 13.2203 4.25063 13.1925V9.16634ZM9.73507 17.333C10.0645 18.3245 11.0035 19.0429 12.1153 19.0429C13.2271 19.0429 14.1661 18.3245 14.4955 17.333H9.73507Z"
      fill="#303038"
    />
  </svg>
);
const SvgActiveWithNotification = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.084 1.99902C7.75773 1.99902 4.25063 5.50612 4.25063 9.83235V13.8585C4.22951 13.8863 4.20938 13.915 4.19033 13.9447L2.90462 15.9447C2.33418 16.832 2.9713 17.999 4.02619 17.999H7.94795C8.31592 19.9614 10.0444 21.4425 12.1151 21.4425C14.1858 21.4425 15.9143 19.9614 16.2823 17.999H20.1417C21.1966 17.999 21.8338 16.832 21.2633 15.9447L19.9776 13.9447C19.9585 13.915 19.9384 13.8863 19.9173 13.8585V9.83236L19.9173 9.82647C19.5481 9.93888 19.1563 9.99935 18.7504 9.99935C16.5413 9.99935 14.7504 8.20849 14.7504 5.99935C14.7504 4.83992 15.2437 3.79571 16.0318 3.06514C14.8726 2.38742 13.5237 1.99902 12.084 1.99902ZM14.4954 17.999H9.7348C10.0641 18.9907 11.0032 19.7092 12.1151 19.7092C13.227 19.7092 14.1661 18.9907 14.4954 17.999ZM19.7963 8.45311C19.4715 6.62506 18.5128 5.01623 17.1554 3.86208C16.5049 4.34836 16.0837 5.12467 16.0837 5.99935C16.0837 7.47211 17.2776 8.66601 18.7504 8.66601C19.1216 8.66601 19.4751 8.59016 19.7963 8.45311Z"
      fill="#303038"
    />
    <path
      d="M21.4166 6.00065C21.4166 7.47341 20.2227 8.66732 18.7499 8.66732C17.2772 8.66732 16.0833 7.47341 16.0833 6.00065C16.0833 4.52789 17.2772 3.33398 18.7499 3.33398C20.2227 3.33398 21.4166 4.52789 21.4166 6.00065Z"
      fill="#EF2D26"
    />
  </svg>
);
const SvgInactive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75885 16.2667L5.63991 14.8961L5.98396 14.4437V13.8595V9.83333C5.98396 6.46439 8.71502 3.73333 12.084 3.73333C15.4529 3.73333 18.184 6.4644 18.184 9.83333V13.8595V14.4437L18.528 14.8961L19.4091 16.2667H4.75885ZM19.9173 9.83333V13.8595C19.9384 13.8873 19.9585 13.916 19.9776 13.9457L21.2633 15.9457C21.8338 16.833 21.1966 18 20.1417 18H16.2824C15.9143 19.9622 14.1859 21.4432 12.1153 21.4432C10.0447 21.4432 8.31628 19.9622 7.94818 18H4.02619C2.9713 18 2.33418 16.833 2.90462 15.9457L4.19033 13.9457C4.20938 13.916 4.22951 13.8873 4.25063 13.8595V9.83333C4.25063 5.5071 7.75773 2 12.084 2C16.4102 2 19.9173 5.5071 19.9173 9.83333ZM9.73508 18C10.0645 18.9915 11.0035 19.7099 12.1153 19.7099C13.2271 19.7099 14.1661 18.9915 14.4955 18H9.73508Z"
      fill="#45454C"
    />
  </svg>
);
const SvgInactiveWithNotification = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 23"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63991 14.5621L4.75885 15.9327H19.4091L18.528 14.5622L18.184 14.1097V13.5255V9.62654C18.369 9.65277 18.5581 9.66634 18.7504 9.66634C19.1563 9.66634 19.5481 9.60587 19.9173 9.49346L19.9173 9.49935V13.5255C19.9384 13.5533 19.9585 13.582 19.9776 13.6117L21.2633 15.6117C21.8338 16.499 21.1966 17.666 20.1417 17.666H16.2823C15.9143 19.6284 14.1858 21.1095 12.1151 21.1095C10.0444 21.1095 8.31592 19.6284 7.94795 17.666H4.02619C2.9713 17.666 2.33418 16.499 2.90462 15.6117L4.19033 13.6117C4.20938 13.582 4.22951 13.5533 4.25063 13.5255V9.49934C4.25063 5.17312 7.75773 1.66602 12.084 1.66602C13.5237 1.66602 14.8726 2.05441 16.0318 2.73213C15.605 3.12777 15.2647 3.61538 15.0423 4.16347C14.1661 3.67663 13.1574 3.39935 12.084 3.39935C8.71502 3.39935 5.98396 6.13041 5.98396 9.49934V13.5255V14.1097L5.63991 14.5621ZM9.7348 17.666C10.0641 18.6577 11.0032 19.3762 12.1151 19.3762C13.227 19.3762 14.1661 18.6577 14.4954 17.666H9.7348ZM18.7504 8.33301C18.5095 8.33301 18.276 8.30106 18.0541 8.24117C17.7849 6.95741 17.1121 5.82209 16.1745 4.97406C16.3315 4.38837 16.6829 3.88223 17.1554 3.52908C18.5128 4.68322 19.4715 6.29205 19.7963 8.1201C19.4751 8.25715 19.1216 8.33301 18.7504 8.33301Z"
      fill="#45454C"
    />
    <path
      d="M21.4166 5.66764C21.4166 7.1404 20.2227 8.33431 18.7499 8.33431C17.2772 8.33431 16.0833 7.1404 16.0833 5.66764C16.0833 4.19488 17.2772 3.00098 18.7499 3.00098C20.2227 3.00098 21.4166 4.19488 21.4166 5.66764Z"
      fill="#EF2D26"
    />
  </svg>
);
export default NotificationIcon;
