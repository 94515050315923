/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import IconPin from '@asset/icons/profile/icon-pin.svg';
import IconUnPin from '@asset/icons/profile/icon-unpin.svg';
import IconSearch from '@asset/icons/search/icon_search.svg';
import IconArrow from '@asset/icons/profile/icon-arrow.svg';
import SvgIcon from '@mui/material/SvgIcon';

import { useLocation, useNavigate } from 'react-router-dom';
import { getSubSchnl } from '@lib/api-call/app/profileApi';
import { find, isEmpty, sortBy } from 'lodash';
import {
  getSubChnl,
  getSubsChannelPinCheck,
  getSubsChannelSearch,
} from '@lib/api-call/app/subsribeApi';
import { useSelector } from 'react-redux';

import channelDefaultImgKOR from '@asset/images/channel/channel-default-image-KOR.png';
import channelDefaultImgENG from '@asset/images/channel/channel-default-image-ENG.png';
import channelDefaultImg from '@asset/images/channel/channel-default-image.png';
import { useInView } from 'react-intersection-observer';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { callMTSFunction, getUploadImage } from '@lib/commonUtils';
import { CALL_MTS_ACTION } from '@data/constants';
import GroupButton from '../common/GroupButton';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#F1F1F1',
  marginLeft: 0,
  width: '100%',
  borderRadius: '50px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: stringToVW('0 0 0 44px'),
    transition: theme.transitions.create('width'),
    width: '100%',
    height: stringToVW('40px'),
    color: '#666',
    fontFamily: 'NanumBarunGothic',
    fontSize: stringToVW('14px'),
    fontWeight: 400,
    lineHeight: stringToVW('21.33px'),
    [theme.breakpoints.up('sm')]: {
      width: stringToVW('142px'),
      '&:focus': {
        width: stringToVW('252px'),
      },
    },
    '&::placeholder': {
      color: '#000',
      opacity: 1,
    },
  },
}));
const countryChnlImage = country => {
  if (country === 'KOR') return channelDefaultImgKOR;
  else if (country === 'USA') return channelDefaultImgENG;
  else return channelDefaultImg;
};

const OtherSubschnlList = ({ styles, userId }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [originList, setOriginList] = useState([]);
  const [subchnlList, setSubchnlList] = useState([]);
  const [keyword, setKeyWord] = useState('');
  const [reqId, setReqId] = useState(null);
  const [init, setInit] = useState(false);

  // true = 내림차순, false = 오름차순
  const [sorting, setSorting] = useState(true);

  const onOtherSubchnlGet = async () => {
    if (!userId) return;
    const otherSubchnlData = await getSubSchnl({ profileId: userId });
    setOriginList(otherSubchnlData.list);
    setSubchnlList(otherSubchnlData.list);
    setReqId(otherSubchnlData.user_id);
    setTimeout(() => {
      setInit(true);
    });
  };

  const updateSub = async ({ stockCd, chnlId, flag }) => {
    // console.log(loginedUser);
    if (!reqId) {
      callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
      return;
    }
    const flagChange = await getSubChnl({
      p_user_id: reqId,
      p_subs_yn: flag,
      p_stock_cd: stockCd,
      p_channel_id: chnlId,
    });

    if (flagChange.message_cd !== '0') return;
    onOtherSubchnlGet();
    // const stock = find(subchnlList, { stock_cd: stockCd, channel_id: chnlId });
    // stock.subYn = flag;
    // setSubchnlList([...subchnlList]);
  };

  // mounted
  useEffect(() => {
    onOtherSubchnlGet();
  }, []);

  useEffect(() => {
    let sorted = [...originList];

    if (sorting) {
      sorted = sortBy(sorted, data => new Date(data.REG_DT)).reverse();
    } else {
      sorted = sortBy(sorted, data => new Date(data.REG_DT));
    }

    if (keyword) {
      sorted = sorted.filter(x => x.CHANNEL_NM.includes(keyword));
    }

    setSubchnlList(sorted);
  }, [sorting, keyword]);

  return (
    init &&
    (originList.length > 0 ? (
      // 구독채널이 1개라도 있을 때,
      <div css={css``}>
        <div css={css``}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
                height: ${stringToVW('46.7px')};
                background: #f7f7f7;
                margin: ${stringToVW('0 0 8px 0')};
                padding: ${stringToVW('0 24px')};
              `}
            >
              <Grid xs={1}></Grid>
              <Grid xs={7.5}></Grid>
              <Grid
                xs={3.5}
                css={css`
                  ${globalStyle.fontNanu18}
                  font-weight: 400;
                  letter-spacing: 0px;
                  text-align: right;
                  color: #666666;
                  display: flex;
                  gap: ${stringToVW('6.7px')};
                  justify-content: end;
                  align-items: center;
                `}
                onClick={() => setSorting(!sorting)}
              >
                구독순
                <SvgIcon
                  sx={{ width: stringToVW('9px'), height: stringToVW('5px') }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 9 5"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.43973 0.204922C8.61384 0.37903 8.61384 0.661317 8.43973 0.835425L4.55782 4.71734L0.675894 0.835426C0.501784 0.661317 0.501784 0.379031 0.675893 0.204922C0.850002 0.0308135 1.13229 0.0308131 1.3064 0.204922L4.55782 3.45634L7.80923 0.204923C7.98334 0.0308138 8.26562 0.0308134 8.43973 0.204922Z"
                      fill="#666666"
                    />
                  </svg>
                </SvgIcon>
              </Grid>
            </Grid>
          </Box>
        </div>
        {subchnlList.map((item, index) => (
          <Box
            sx={{ flexGrow: 1 }}
            key={index}
            css={css`
              padding: ${stringToVW('0px 24px')};
              height: ${stringToVW('60px')};
              display: flex;
              align-items: center;
            `}
          >
            <Grid
              container
              css={css`
                width: 100%;
              `}
            >
              <Grid
                xs={8}
                sx={{ gap: stringToVW('16px') }}
                onClick={() => {
                  navigate(`/channel/${item.STOCK_CD}/${item.CHANNEL_ID}`, {
                    state: { prevLink: pathname },
                  });
                }}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <img
                  css={css`
                    width: ${stringToVW('40px')};
                    height: ${stringToVW('40px')};
                    border-radius: 50%;
                  `}
                  src={
                    getUploadImage(item?.THUMB_URL) ||
                    countryChnlImage(item?.COUNTRY_CD)
                  }
                  alt=""
                  onError={e =>
                    (e.target.src = countryChnlImage(item.country_cd))
                  }
                />

                <div>
                  <div
                    css={css`
                      ${globalStyle.fontNanu21}
                      font-weight: 600;
                      //letter-spacing: 0em;
                      text-align: left;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: ${stringToVW('180px')};
                    `}
                  >
                    {item.CHANNEL_NM}
                  </div>
                  <div
                    css={css`
                      ${globalStyle.fontRobo19}
                      color: #666666;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    오늘 올라온 글&nbsp;
                    <span
                      css={css`
                        ${globalStyle.fontRobo15}
                      `}
                    >
                      {item?.postTodayCount}
                    </span>
                    개
                  </div>
                </div>
              </Grid>
              <Grid
                xs={4}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: end;
                `}
              >
                <div>
                  <button
                    css={styles.btnRegular}
                    style={
                      item.isSubscribed === 0
                        ? {
                            background: `#4e5255`,
                            color: '#fff',
                          }
                        : {}
                    }
                    onClick={() => {
                      updateSub({
                        data: item,
                        stockCd: item.STOCK_CD,
                        chnlId: item.CHANNEL_ID,
                        flag: item.isSubscribed === 0 ? 'Y' : 'N',
                      });
                    }}
                    // disabled={!reqId}
                  >
                    {item.isSubscribed === 0 ? '채널구독' : '구독중'}
                  </button>
                </div>
              </Grid>
            </Grid>
          </Box>
        ))}
      </div>
    ) : (
      // 구독채널이 존재하지않을 떄
      <div>
        <div
          css={css`
            ${globalStyle.fontNanu19}
            font-weight: 400;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            justify-content: center;
            color: #666666;
            margin-top: ${stringToVW('120px')};
          `}
        >
          <span
            css={css`
              max-width: 55%;
              display: block;
            `}
          >
            구독한 채널이 없어요.
          </span>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: ${stringToVW('12px')};
            color: #666666;
            width: ${stringToVW('192px')};
            height: ${stringToVW('60px')};
            margin: ${stringToVW('32px')} auto 0;
          `}
        ></div>
      </div>
    ))
  );
};

const SubshnlList = ({ styles, userId, pin, other = false }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // ref
  const [ref, inView] = useInView();

  const [subchnlList, setSubchnlList] = useState([]);
  const [keyword, setKeyWord] = useState('');
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const [init, setInit] = useState(false);

  const [orderBy, setOrderBy] = useState('upd');
  const [page, setPage] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [beforeKeyword, setBeforeKeyword] = useState('');

  // 채널목록 첫호출 Function
  const onSubchnlGet = async () => {
    setIsLoading(true);
    setIsLast(false);
    const mySubchnlData = await getSubsChannelSearch({
      p_type: orderBy,
      p_comment: keyword,
      p_length: 10,
      p_start: page * 10,
      p_user_subs_yn: 'Y',
      ...(other && { p_profile_id: userId }),
    });

    if (!isEmpty(mySubchnlData.subsChannelSearch)) {
      // setOriginList(mySubchnlData.subsChannelSearch);
      setSubchnlList([...mySubchnlData.subsChannelSearch]);
    } else {
      setIsLast(true);
    }

    setTimeout(() => {
      setIsLoading(false);
      setInit(true);
    });
  };

  // 채널목록 추가호출 Function
  const onAddSubchnlGet = async () => {
    setIsLoading(true);
    // setPage(page + 1); // 현재 함수에서는 적용안됨
    const mySubchnlData = await getSubsChannelSearch({
      p_type: orderBy,
      p_comment: keyword,
      p_length: 10,
      p_start: page * 10,
      p_user_subs_yn: 'Y',
      ...(other && { p_profile_id: userId }),
    });

    if (!isEmpty(mySubchnlData.subsChannelSearch)) {
      setSubchnlList([...subchnlList, ...mySubchnlData.subsChannelSearch]);
    } else {
      setIsLast(true);
    }

    setTimeout(() => setIsLoading(false));
  };

  // 구독버튼 클릭
  const updateSub = async ({ stockCd, chnlId, flag }) => {
    console.log(loginedUser);
    const flagChange = await getSubChnl({
      p_user_id: loginedUser.user_id || loginedUser.USER_ID,
      p_subs_yn: flag,
      p_stock_cd: stockCd,
      p_channel_id: chnlId,
    });

    if (flagChange.message_cd !== '0') return;
    const stock = find(subchnlList, { stock_cd: stockCd, channel_id: chnlId });
    stock.subs_yn = flag;
    setSubchnlList([...subchnlList]);
  };

  const onPinChange = async item => {
    const pinCheck = await getSubsChannelPinCheck({
      p_pin_check: item.pin_check === 'Y' ? 'N' : 'Y',
      p_user_id: userId,
      p_stock_cd: item.stock_cd,
      p_channel_id: item.channel_id,
    });

    if (pinCheck.message_cd !== '0') return;

    item.pin_check = item.pin_check === 'Y' ? 'N' : 'Y';
    setSubchnlList([...subchnlList]);
  };

  // mounted
  useEffect(() => {
    onSubchnlGet();
  }, []);

  // inView 변경 시
  useEffect(() => {
    if (!isEmpty(subchnlList) && inView && !isLast && !isLoading) {
      setPage(page + 1);
    }
  }, [inView]);

  useEffect(() => {
    setPage(0);
    if (page === 0) onSubchnlGet();
  }, [orderBy, keyword]);

  useEffect(() => {
    if (page === 0) {
      if (!isLoading) {
        onSubchnlGet();
      }
    } else {
      if (!isEmpty(subchnlList) && inView && !isLast) {
        onAddSubchnlGet();
      }
    }
  }, [page]);

  return (
    init &&
    (subchnlList.length > 0 ? (
      // 구독채널이 1개라도 있을 때,
      <div css={css``}>
        <div css={css``}>
          <Box>
            <Grid
              container
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
                height: ${stringToVW('46px')};
                background: #f7f7f7;
                margin: ${stringToVW('0 0 8px 0')};
                padding: ${stringToVW('0px 12px')};
              `}
            >
              <Grid xs={1}></Grid>
              <Grid xs={6}></Grid>
              <Grid
                xs={5}
                css={css`
                  ${globalStyle.fontNanu18}
                  font-weight: 400;
                  letter-spacing: 0px;
                  text-align: right;
                  color: #666666;
                  display: flex;
                  align-items: center;
                  gap: ${stringToVW('10px')};
                  justify-content: start;
                  width: 100%;
                  height: 100%;
                `}
                onClick={() => {
                  if (isLoading) return;
                  setOrderBy(orderBy === 'new' ? 'upd' : 'new');
                }}
              >
                <GroupButton
                  sortOptions={[
                    { text: '업데이트순', value: 'upd' },
                    { text: '구독순', value: 'new' },
                  ]}
                  value={orderBy}
                  onClick={clickedValue => {
                    if (isLoading) return;
                    setOrderBy(clickedValue);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
        {subchnlList.map((item, index) => (
          <Box
            sx={{ flexGrow: 1 }}
            key={`${item.stock_cd}_${item.channel_id}`}
            css={css`
              padding: ${stringToVW('0px 24px')};
              height: ${stringToVW('60px')};
              display: flex;
              align-items: center;
            `}
          >
            <Grid
              container
              css={css`
                width: 100%;
              `}
            >
              <Grid
                xs={8}
                sx={{ gap: stringToVW('16px') }}
                item
                onClick={() => {
                  if (item.subYn === 'N') return;
                  navigate(`/channel/${item.stock_cd}/${item.channel_id}`, {
                    state: { prevLink: pathname },
                  });
                }}
                display="flex"
                flexDirection="row"
              >
                <div>
                  <img
                    css={css`
                      width: ${stringToVW('40px')};
                      height: ${stringToVW('40px')};
                      border-radius: 50%;
                    `}
                    src={
                      getUploadImage(item.thumb_url) ||
                      countryChnlImage(item.country_cd)
                    }
                    alt=""
                    onError={e =>
                      (e.target.src = countryChnlImage(item.country_cd))
                    }
                  />
                </div>
                <div>
                  <div
                    css={css`
                      ${globalStyle.fontNanu21}
                      font-weight: 600;
                      //letter-spacing: 0em;
                      text-align: left;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: ${stringToVW('180px')};
                    `}
                  >
                    {item.channel_nm}
                  </div>
                  <div
                    css={css`
                      ${globalStyle.fontNanu19}
                      font-weight: 500;
                      color: #666666;
                      display: flex;
                      align-items: center;
                      gap: ${stringToVW('5px')};
                    `}
                  >
                    {item?.today_cnt !== 0 ? (
                      <div
                        css={css`
                          width: ${stringToVW('5px')};
                          height: ${stringToVW('5px')};
                          border-radius: 100px;
                          background: #ff7d80;
                        `}
                      ></div>
                    ) : null}
                    오늘 올라온 글 {item?.today_cnt}개 {pin}
                  </div>
                </div>
              </Grid>

              <Grid
                xs={pin ? 3 : 4}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: end;
                `}
              >
                <div
                  css={css`
                    ${globalStyle.fontNanu18}
                  `}
                >
                  {item.subs_yn === 'Y' ? (
                    <button
                      css={styles.btnRegular}
                      onClick={() => {
                        updateSub({
                          data: item,
                          stockCd: item.stock_cd,
                          chnlId: item.channel_id,
                          flag: 'N',
                        });
                      }}
                    >
                      구독중
                    </button>
                  ) : (
                    <button
                      css={styles.btnRegular}
                      onClick={() => {
                        updateSub({
                          stockCd: item.stock_cd,
                          chnlId: item.channel_id,
                          flag: 'Y',
                        });
                      }}
                      style={{ background: `#4e5255`, color: '#fff' }}
                    >
                      채널구독
                    </button>
                  )}
                </div>
              </Grid>
              {pin && (
                <Grid
                  xs={1}
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                  onClick={() => onPinChange(item)}
                >
                  {item.pin_check === 'N' ? (
                    <img
                      src={IconUnPin}
                      alt=""
                      style={{
                        width: stringToVW('16px'),
                        height: stringToVW('16px'),
                      }}
                    />
                  ) : (
                    <img
                      src={IconPin}
                      alt=""
                      style={{
                        width: stringToVW('16px'),
                        height: stringToVW('16px'),
                      }}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        ))}
        {!isEmpty(subchnlList) && !isLast && !isLoading && (
          <div
            css={css`
              min-height: ${stringToVW('30px')};
              width: 100%;
              background: 'yellow';
              color: #b1f54d;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            ref={ref}
          ></div>
        )}
      </div>
    ) : // 구독채널이 존재하지않을 떄
    other ? (
      <div>
        <div
          css={css`
            ${globalStyle.fontNanu19}
            font-weight: 400;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            justify-content: center;
            color: #666666;
            margin-top: ${stringToVW('120px')};
          `}
        >
          <span
            css={css`
              max-width: 55%;
              display: block;
            `}
          >
            구독한 채널이 없어요.
          </span>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: ${stringToVW('12px')};
            color: #666666;
            width: ${stringToVW('192px')};
            height: ${stringToVW('60px')};
            margin: ${stringToVW('32px')} auto 0;
          `}
        ></div>
      </div>
    ) : (
      <div>
        <div
          css={css`
            ${globalStyle.fontNanu21}
            font-weight: 400;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            justify-content: center;
            color: #666666;
            margin-top: ${stringToVW('120px')};
          `}
        >
          <span
            css={css`
              max-width: 55%;
              display: block;
            `}
          >
            구독한 채널이 없어요. <br />
            찾고싶은 채널이 있으신가요?
          </span>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            color: #666666;
            width: ${stringToVW('148px')};
            height: ${stringToVW('40px')};
            margin: ${stringToVW('32px')} auto 0;
          `}
        >
          <Search onClick={() => navigate('/search')}>
            <SearchIconWrapper>
              <SvgIcon
                sx={{ width: stringToVW('17px'), height: stringToVW('17px') }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <rect
                    x="1.89909"
                    y="2.23356"
                    width="10.9333"
                    height="10.9333"
                    rx="5.46667"
                    stroke="#666666"
                    strokeWidth="1.33333"
                  />
                  <path
                    d="M14.5661 14.9001L11.6328 11.9668"
                    stroke="#666666"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="검색하러 가기"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </div>
      </div>
    ))
  );
};

const SubscriptionChannel = ({ isProfileOfOthers, userId, pin }) => {
  const styles = {
    btnRegular: css`
      min-width: ${stringToVW('70px')};
      height: ${stringToVW('30px')};
      border-radius: ${stringToVW('6.67px')};
      gap: ${stringToVW('10px')};
      background: #ffffff;
      border: 1px solid #e0e0e0;
      color: #666666;
      ${globalStyle.fontNanu18}
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
    `,
    dark: {
      background: 'red',
    },
  };

  return (
    <div>
      <div
        css={css`
          padding: ${stringToVW('0px 16px')};
        `}
      ></div>
      <SubshnlList
        styles={styles}
        userId={userId}
        other={isProfileOfOthers}
        pin={pin && pin}
      />
      {/* {isProfileOfOthers ? (
        <SubshnlList styles={styles} userId={userId} other={true} />
      ) : (
        <SubshnlList styles={styles} userId={userId} pin={pin} />
      )} */}
    </div>
  );
};

export default SubscriptionChannel;
