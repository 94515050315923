import { css } from '@emotion/react';

import { ReactComponent as CommentIcon } from '@asset/icons/post/comment.svg';
import LikeButton, { TYPE_LIKE, TYPE_DISLIKE } from './LikeButton';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  DISLIKE_ACTION,
  DISLIKE_DELETE_ACTION,
  LIKE_ACTION,
  LIKE_DELETE_ACTION,
  updateReaction,
} from '@lib/api-call/app/loungeApi';
import { useDispatch } from 'react-redux';
import { setClickedChannelPostId } from '@store/app/channelReducer';
import { roundStockQty } from '@lib/commonUtils';
import { globalStyle, toVW } from '@style/globalStyle';
import {
  setClickedPostId,
  setClickedWrittenPostId,
} from '@store/app/postReducer';
import { HAVE_PREV_BACK_LINK } from '@data/constants';
import React from 'react';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';

const PostItemFooter = ({ item, mutate, isChDetail, isProfile }) => {
  const {
    post_id,
    countLike,
    sumLikeStockQty,
    countDislike,
    sumDislikeStockQty,
    countComment,
    isLiked,
    isDisliked,
  } = item;
  const [openPopupWithdraw, setOpenPopupWithdraw] = React.useState(false);

  const navigate = useNavigate();
  const { pathname, state: routerState, search, hash } = useLocation();
  const dispatch = useDispatch();

  const styles = {
    container: css`
      display: flex;
      padding-top: ${toVW('10.7px')};
      justify-content: flex-end;
      align-items: flex-start;
      gap: ${toVW('12px')};
      align-self: stretch;
    `,
    reaction: css`
      display: flex;
      align-items: flex-start;
      gap: ${toVW('20px')};
      flex: 1 0 0;
    `,
    likeContainer: css`
      display: flex;
      min-width: ${toVW('96px')};
      align-items: flex-start;
      gap: ${toVW('5.3px')};
    `,
    likeInfo: css`
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: ${toVW('0.5px')};
      color: #4e5255;
    `,
    likeNumber: css`
      ${globalStyle.fontRobo19}
      line-height: ${toVW('17.5px')};
    `,
    likeString: css`
      ${globalStyle.fontNanu19}
    `,
    activeLike: css`
      color: #d73838;
    `,
    activeDislike: css`
      color: #247ef2;
    `,
    activeCount: css`
      font-weight: 500;
    `,
    comment: css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: ${toVW('5px')};
      ${globalStyle.fontNanu19}
    `,
    commentCount: css`
      color: #4e5255;
      text-decoration: none;
      font-family: Roboto;
      display: flex;
      align-items: flex-start;
      height: ${toVW('18px')};
    `,
  };

  const updateLike = async post => {
    const updateItem = { ...post };

    if (!!isLiked) {
      const { message_cd, likeCount, error } = await updateReaction({
        post_id,
        react: LIKE_DELETE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);

      if (message_cd !== '0') {
        return;
      }

      updateItem.isLiked = 0;
      updateItem.countLike = likeCount?.count || 0;
      updateItem.sumLikeStockQty = likeCount?.qtySum || 0;
    } else {
      if (!!isDisliked) {
        const { message_cd, dislikeCount, error } = await updateReaction({
          post_id,
          react: DISLIKE_DELETE_ACTION,
        });
        if (error === 401) setOpenPopupWithdraw(true);
        if (message_cd !== '0') {
          return;
        }

        updateItem.isDisliked = 0;
        updateItem.countDislike = dislikeCount?.count || 0;
        updateItem.sumDislikeStockQty = dislikeCount?.qtySum || 0;
      }

      const { message_cd, likeCount, error } = await updateReaction({
        post_id,
        react: LIKE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);
      if (message_cd !== '0') {
        return;
      }

      const { qtySum, count } = likeCount;

      updateItem.isLiked = 1;
      updateItem.countLike = count;
      updateItem.sumLikeStockQty = qtySum;
    }

    return updateItem;
  };

  const updateDislike = async post => {
    const updateItem = { ...post };

    if (!!isDisliked) {
      const { message_cd, dislikeCount, error } = await updateReaction({
        post_id,
        react: DISLIKE_DELETE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);
      if (message_cd !== '0') {
        return;
      }

      updateItem.isDisliked = 0;
      updateItem.countDislike = dislikeCount?.count || 0;
      updateItem.sumDislikeStockQty = dislikeCount?.qtySum || 0;
    } else {
      if (!!isLiked) {
        const { message_cd, likeCount, error } = await updateReaction({
          post_id,
          react: LIKE_DELETE_ACTION,
        });
        if (error === 401) setOpenPopupWithdraw(true);
        if (message_cd !== '0') {
          return;
        }

        updateItem.isLiked = 0;
        updateItem.countLike = likeCount?.count || 0;
        updateItem.sumLikeStockQty = likeCount?.qtySum || 0;
      }

      const { message_cd, dislikeCount, error } = await updateReaction({
        post_id,
        react: DISLIKE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);
      if (message_cd !== '0') {
        return;
      }

      const { qtySum, count } = dislikeCount;

      updateItem.isDisliked = 1;
      updateItem.countDislike = count;
      updateItem.sumDislikeStockQty = qtySum;
    }

    return updateItem;
  };

  return (
    <div css={styles.container}>
      <div
        css={styles.reaction}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div css={styles.likeContainer}>
          <div
            css={css`
              gap: ${toVW('1px')};
              display: flex;
              flex-direction: column;
            `}
          >
            <LikeButton
              type={TYPE_LIKE}
              isActive={!!isLiked}
              clickHandler={async () => {
                // handleReactionBtn(item, updateLike);
                window.GA_Event('커뮤니티', '공통', '게시글 추천');
                mutate({ post: item, actionFunc: updateLike });
              }}
            />
            {sumLikeStockQty > 0 && (
              <div
                css={css`
                  width: 100%;
                  height: ${toVW('12px')};
                `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // width="100%"
                  height="100%"
                  viewBox="0 0 18 13"
                  fill="none"
                >
                  <path
                    d="M9 2.2998V2.2998C9 4.15975 9 5.08972 9.20445 5.85272C9.75925 7.92327 11.3765 9.54056 13.4471 10.0954C14.2101 10.2998 15.1401 10.2998 17 10.2998V10.2998"
                    stroke="#E0E0E0"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>

          <div
            css={[styles.likeInfo, !!isLiked && styles.activeLike]}
            onClick={() => {
              if (!isChDetail && !isProfile) {
                dispatch(setClickedPostId(item.post_id));
              } else if (isProfile) {
                dispatch(setClickedWrittenPostId(item.post_id));
              } else {
                dispatch(setClickedChannelPostId(item.post_id));
              }
              navigate(`/post/${post_id}/like`);
            }}
          >
            <div css={[styles.likeNumber, !!isLiked && styles.activeCount]}>
              {countLike || 0}
            </div>
            {sumLikeStockQty > 0 && (
              <div
                css={css`
                  line-height: ${toVW('16px')};
                `}
              >
                <span css={styles.likeNumber}>
                  {roundStockQty(sumLikeStockQty).stockQty}
                </span>
                <span css={styles.likeString}>주</span>
              </div>
            )}
          </div>
        </div>
        <div css={styles.likeContainer}>
          <div
            css={css`
              gap: ${toVW('1px')};
              display: flex;
              flex-direction: column;
            `}
          >
            <LikeButton
              type={TYPE_DISLIKE}
              isActive={!!isDisliked}
              clickHandler={async () => {
                window.GA_Event('커뮤니티', '공통', '게시글 비추천');
                mutate({ post: item, actionFunc: updateDislike });
              }}
            />
            {sumDislikeStockQty > 0 && (
              <div
                css={css`
                  width: 100%;
                  height: ${toVW('12px')};
                `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // width="100%"
                  height="100%"
                  viewBox="0 0 18 13"
                  fill="none"
                >
                  <path
                    d="M9 2.2998V2.2998C9 4.15975 9 5.08972 9.20445 5.85272C9.75925 7.92327 11.3765 9.54056 13.4471 10.0954C14.2101 10.2998 15.1401 10.2998 17 10.2998V10.2998"
                    stroke="#E0E0E0"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
          <div
            css={[styles.likeInfo, !!isDisliked && styles.activeDislike]}
            onClick={() => {
              if (!isChDetail && !isProfile) {
                dispatch(setClickedPostId(item.post_id));
              } else if (isProfile) {
                dispatch(setClickedWrittenPostId(item.post_id));
              } else {
                dispatch(setClickedChannelPostId(item.post_id));
              }
              navigate(`/post/${post_id}/dislike`);
            }}
          >
            <div css={[styles.likeNumber, !!isDisliked && styles.activeCount]}>
              {countDislike || 0}
            </div>
            {sumDislikeStockQty > 0 && (
              <div
                css={css`
                  line-height: ${toVW('16px')};
                `}
              >
                <span css={styles.likeNumber}>
                  {roundStockQty(sumDislikeStockQty).stockQty}
                </span>
                <span css={styles.likeString}>주</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div css={styles.commentCount}>
        <div
          css={styles.comment}
          onClick={() => {
            window.GA_Event('커뮤니티', '공통', '댓글 조회');
            if (!isChDetail && !isProfile) {
              dispatch(setClickedPostId(item.post_id));
            } else if (isProfile) {
              dispatch(setClickedWrittenPostId(item.post_id));
            } else {
              dispatch(setClickedChannelPostId(item.post_id));
            }

            let prevLink = pathname;
            if (search) {
              prevLink += search;
            }
            if (hash) {
              prevLink += hash;
            }

            navigate(`/post/${item.post_id}`, {
              state: {
                prevLink: prevLink,
                prevPageBackLink:
                  HAVE_PREV_BACK_LINK.some(link => pathname.includes(link)) &&
                  routerState
                    ? routerState.prevLink
                    : undefined,
              },
            });
          }}
        >
          <CommentIcon
            css={css`
              width: ${toVW('18px')};
              height: ${toVW('18px')};
            `}
          />
          <span>{countComment}</span>
        </div>
      </div>
      {openPopupWithdraw && <CheckWithdrawAccount />}
    </div>
  );
};

export default PostItemFooter;
