import { stringToVW, toVW, ratio } from '@style/globalStyle';
import { Avatar, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const PostLoadingItem = () => {
  return (
    <div
      style={{
        background: '#fff',
        padding: `${stringToVW('21px 16px 16px 16px')}`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
      }}
    >
      {/* 포스트 헤더 */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: `${toVW('12px')}`,
          flex: '1 0 0',
        }}
      >
        <div>
          <Skeleton variant="circular">
            <Avatar sx={{ width: 40 * ratio, height: 40 * ratio }} />
          </Skeleton>
        </div>
        <Box sx={{ width: '100%' }}>
          <Skeleton sx={{ width: '40%' }} />
          <Skeleton sx={{ width: '50%' }} />
        </Box>
      </div>
      {/* 포스트 헤더 */}
      {/* 포스트 컨텐츠 */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: `${toVW('16px')}`,
          alignSelf: 'stretch',
          padding: `${stringToVW('16px 0px')}`,
          minHeight: '67px',
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{ width: '100%', height: '70px' }}
        />
        <div
          style={{
            borderTop: '1px solid #f0f0f0',
            width: '100%',
            marginBottom: toVW('-16px'),
          }}
        />
      </div>
      {/* 포스트 컨텐츠 */}
      {/* 포스트 푸터 */}
      <div
        style={{
          display: 'flex',
          paddingTop: `${toVW('10.7px')}`,
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          gap: `${toVW('12px')}`,
          alignSelf: 'stretch',
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{ width: '100%', height: '30px' }}
        />
      </div>
      {/* 포스트 푸터 */}
    </div>
  );
};

export default PostLoadingItem;
