import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { css } from '@mui/material';
import ModalConfirm from '@component/admin/common/ModalConfirm';
import { useNavigate } from 'react-router-dom';

const ReportDetailComponent = props => {
  const navigate = useNavigate();
  const [isButtonClick, setIsButtonClick] = useState(false);
  const { use } = props;
  const { confirmEvent } = props;
  const { type } = props;

  const showModalConfirm = () => {
    if (use) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '숨김 해제 안내',
        desc: `해당 ${type}을 숨김 해제 처리하시겠습니까?`,
        onOk: async data => {
          console.log('on ok: ', data);
          const lock = await confirmEvent(use);
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setTimeout(() => {
            setIsButtonClick(false);
            if (lock) {
              navigate(0);
            }
          });
        },
        onCancel: () => {
          console.log('on cancel');
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(true);
          console.log(isButtonClick);
        },
      });
    } else {
      setModalProps({
        ...modalProps,
        open: true,
        title: '숨김 안내',
        desc: `해당 ${type}을 숨김 처리하시겠습니까?`,
        onOk: async data => {
          console.log('on ok: ', data);
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });

          const lock = await confirmEvent(use);
          setTimeout(() => {
            setIsButtonClick(true);
            if (lock) {
              navigate(0);
            }
          });
        },
        onCancel: () => {
          console.log('on cancel');
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
          console.log(isButtonClick);
        },
      });
    }
  };
  const [modalProps, setModalProps] = useState({
    open: false,
    title: 'Title',
    desc: 'Title desc',
    onOk: () => {},
    onCancel: () => {
      console.log('on cancel');
      setModalProps({
        ...modalProps,
        open: false,
        title: '',
        desc: '',
      });
    },
  });
  useEffect(() => {
    console.log(props);
  });

  return (
    <>
      <div
        css={css`
          width: 35%;
        `}
      >
        <div className="wrapper-report">
          <div
            className="p-30-20"
            css={css`
              max-height: 661.5px;
            `}
          >
            <div className="flex justify-end item-center relative gap-10">
              {use && <p className="text-title-date-report">{props.title}</p>}
              {use && (
                <Button
                  css={css`
                    /* position: absolute;
                    right: 0; */
                  `}
                  onClick={showModalConfirm}
                  type="primary"
                  style={{ width: 101, height: 37, background: 'black' }}
                >
                  숨김 해제
                </Button>
              )}
              {!use && (
                <Button
                  css={css`
                    position: absolute;
                    right: 0;
                  `}
                  onClick={showModalConfirm}
                  type="primary"
                  style={{ width: 101, height: 37, background: 'black' }}
                >
                  숨김
                </Button>
              )}
            </div>
            <div className="flex flex-col gap-20 mt-30">{props.children}</div>
          </div>
        </div>
      </div>
      <ModalConfirm model={modalProps} />
    </>
  );
};

export default ReportDetailComponent;
