import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import './style.css';
import icon_eye from '@asset/icons/common/icon_eye.svg';
import icon_like from '@asset/icons/common/icon_like.svg';
import icon_dislike from '@asset/icons/common/icon_dislike.svg';
import Header from '@component/admin/common/Header';
import {
  Select,
  Input,
  Button,
  Modal,
  Breadcrumb,
  Upload,
  Switch,
  message,
  Pagination,
} from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import ModalConfirm from '@component/admin/common/ModalConfirm';
import checkbox from '@component/app/common/Checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  getCommentBlock,
  getCommentReplyBlock,
  getNoticeBlock,
  getNoticeView,
  getPostCommentList,
  getPostCommentReplyList,
} from '@lib/api-call/admin/postApi';
import { find } from 'lodash';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import PostImageList from './PostImageList';

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '작성자',
    dataIndex: 'user_nm',
    key: 'user_nm',
  },
  {
    title: '내용',
    dataIndex: 'contents',
    key: 'contents',
  },
  {
    title: '대댓글 수',
    dataIndex: 'post_comment_reply_cnt',
    key: 'post_comment_reply_cnt',
  },
  {
    title: '상태',
    dataIndex: 'comment_active_nm',
    key: 'comment_active_nm',
  },
  {
    title: '등록일',
    dataIndex: 'reg_date',
    key: 'reg_date',
  },
];
const columns2 = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '작성자',
    dataIndex: 'user_nm',
    key: 'user_nm',
  },
  {
    title: '내용',
    dataIndex: 'contents',
    key: 'contents',
  },
  {
    title: '상태',
    dataIndex: 'comment_reply_active_nm',
    key: 'comment_reply_active_nm',
  },
  {
    title: '등록일',
    dataIndex: 'reg_date',
    key: 'reg_date',
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows,
    );
  },
  getCheckboxProps: record => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};
const handleChange = value => {
  console.log(`selected ${value}`);
};

const PostManageDetail = () => {
  const [isButtonClick, setIsButtonClick] = useState(false);
  const navigate = useNavigate();

  const { postId } = useParams(); // 쿼리 호출
  const [messageApi, contextHolder] = message.useMessage();

  const [postDetail, setPostDetail] = useState({});
  const [postImgList, setPostImageList] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [replyList, setReplyList] = useState([]);

  // count
  const [totalCommentCount, setTotalCommentCount] = useState(0);
  const [totalReplyCount, setTotalReplyCount] = useState(0);

  // page
  const [commentPage, setCommentPage] = useState(0);
  const [replyPage, setReplyPage] = useState(0);

  // loading
  const [loading, setLoading] = useState(false);

  // select comment
  const [selectComment, setSelectComment] = useState(null);

  // checkbox comment
  const [checkBoxRowComment, setCheckBoxRowComment] = useState([]);
  const commentRowSelection = {
    selectedRowKeys: checkBoxRowComment,
    onChange: (selectedKeys, selectedRows) => {
      setCheckBoxRowComment(selectedRows.map(row => row.comment_id));
    },
  };

  const commentStatusChange = async () => {
    let result = {
      0: [],
      1: [],
      value: false,
    };

    checkBoxRowComment.forEach(async commentId => {
      let item = commentList.find(x => x.comment_id === commentId);

      if (item) result[item.comment_active].push(commentId);
    });

    if (result[0].length === 0 && result[1].length === 0) return;

    if (result[0].length > 0) {
      const active = await getCommentBlock({
        p_post_id: selectComment,
        p_comment_active: 1,
        p_comment_id_array: result[0],
      });
      console.log('active:', active);
      if (active.message_cd !== '0') result.value = false;
      else result.value = true;
    }

    if (result[1].length > 0) {
      const inactive = await getCommentBlock({
        p_post_id: selectComment,
        p_comment_active: 0,
        p_comment_id_array: result[1],
      });
      console.log('inactive: ', inactive);
      if (inactive.message_cd === '0' && !result.value) result.value = true;
    }

    return result.value;
  };

  // checkbox reply
  const [checkBoxRowReply, setCheckBoxRowReply] = useState([]);
  const replyRowSelection = {
    selectedRowKeys: checkBoxRowReply,
    onChange: (selectedKeys, selectedRows) => {
      setCheckBoxRowReply(selectedRows.map(row => row.comment_reply_id));
    },
  };
  const replyStatusChange = async () => {
    let result = {
      0: [],
      1: [],
      value: false,
    };

    checkBoxRowReply.forEach(async replyId => {
      let item = replyList.find(x => x.comment_reply_id === replyId);

      if (item) result[item.comment_reply_active].push(replyId);
    });

    if (result[0].length === 0 && result[1].length === 0) return;

    if (result[0].length > 0) {
      const active = await getCommentReplyBlock({
        p_post_id: selectComment,
        p_comment_reply_active: 1,
        p_comment_reply_id_array: result[0],
      });
      console.log('active:', active);
      if (active.message_cd !== '0') result.value = false;
      else result.value = true;
    }

    if (result[1].length > 0) {
      const inactive = await getCommentReplyBlock({
        p_post_id: selectComment,
        p_comment_reply_active: 0,
        p_comment_reply_id_array: result[1],
      });
      console.log('inactive: ', inactive);
      if (inactive.message_cd === '0' && !result.value) result.value = true;
    }

    return result.value;
  };
  const dataProp = [
    {
      title: '게시글 관리',
      href: '/admin/post-manage',
    },
    {
      title: '게시글 상세',
      href: '',
    },
  ];

  const onPostDetailView = async () => {
    const detailData = await getNoticeView({
      p_post_id: postId,
    });

    console.log(detailData);
    if (detailData.message_cd !== '0') {
      return;
    }

    setPostDetail(detailData.ntPostView);
    setPostImageList(detailData.ntPostImgList);
  };

  const onCommentListGet = async () => {
    const commentData = await getPostCommentList({
      p_post_id: postId,
      p_start: commentPage * 5,
      p_length: 5,
    });
    console.log(commentData);
    if (commentData.message_cd !== '0') {
      return;
    }

    setCommentList(commentData.postCommentList);
    setTotalCommentCount(commentData.total_count);
  };

  const onReplyListGet = async () => {
    const replyData = await getPostCommentReplyList({
      p_post_id: postId,
      p_start: replyPage * 5,
      p_length: 5,
      p_comment_id: selectComment,
    });
    console.log(replyData);
    if (replyData.message_cd !== '0') {
      return;
    }
    setReplyList(replyData.postCommentPeplyList);
    setTotalReplyCount(replyData.total_count);
  };

  const onPostLock = async activeType => {
    const lockData = await getNoticeBlock({
      p_post_id: postId,
      p_post_active: activeType,
    });
    console.log(lockData);
    if (lockData.message_cd !== '0') return false;
    return true;
  };

  const commentPageChange = pageCount => {
    setCommentPage(pageCount - 1);
  };
  const replyPageChange = pageCount => {
    setReplyPage(pageCount - 1);
  };

  useEffect(() => {
    onPostDetailView();
    onCommentListGet();
    // onReplyListGet();
  }, []);

  useEffect(() => {
    onCommentListGet();
    setCheckBoxRowComment([]);
  }, [commentPage]);

  useEffect(() => {
    onReplyListGet();
    setCheckBoxRowReply([]);
  }, [replyPage]);

  useEffect(() => {
    if (!selectComment) return;
    onReplyListGet();
    setCheckBoxRowReply([]);
  }, [selectComment]);

  const showModalConfirm = type => {
    if (type === 1) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '숨김 안내',
        desc: '해당 게시물을 숨김 처리하시겠습니까?',
        onOk: async data => {
          const lock = await onPostLock(type);
          setTimeout(() => {
            setModalProps({
              ...modalProps,
              open: false,
              title: '',
              desc: '',
            });
            setIsButtonClick(false);
            if (lock) {
              navigate(0);
            }
          });
        },
        onCancel: () => {
          console.log('on cancel');
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(true);
        },
      });
    } else if (type === 0) {
      setModalProps({
        ...modalProps,
        open: true,
        title: '숨김 해제 안내',
        desc: '해당 게시물을 숨김 해제 처리하시겠습니까?',
        onOk: async data => {
          console.log('on ok: ', data);

          const lock = await onPostLock(type);
          setTimeout(() => {
            setModalProps({
              ...modalProps,
              open: false,
              title: '',
              desc: '',
            });
            setIsButtonClick(true);

            if (lock) {
              navigate(0);
            }
          });
        },
        onCancel: () => {
          console.log('on cancel');
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          setIsButtonClick(false);
          console.log(isButtonClick);
        },
      });
    }
  };
  const showModalConfirmTable1 = () => {
    setModalProps({
      ...modalProps,
      open: true,
      title: '상태 변경 안내',
      desc: '해당 댓글 상태를 변경 하시겠습니까?',
      onOk: async data => {
        console.log('on ok: ', data);
        const val = await commentStatusChange();
        setTimeout(() => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          if (val) {
            navigate(0);
          }
        });
      },
      onCancel: () => {
        console.log('on cancel');
        setModalProps({
          ...modalProps,
          open: false,
          title: '',
          desc: '',
        });
      },
    });
  };
  const showModalConfirmTable2 = () => {
    setModalProps({
      ...modalProps,
      open: true,
      title: '상태 변경 안내',
      desc: '해당 대댓글 상태를 변경 하시겠습니까?',
      onOk: async data => {
        console.log('on ok: ', data);

        const val = await replyStatusChange();
        setTimeout(() => {
          setModalProps({
            ...modalProps,
            open: false,
            title: '',
            desc: '',
          });
          if (val) {
            navigate(0);
          }
        });
      },
      onCancel: () => {
        console.log('on cancel');
        setModalProps({
          ...modalProps,
          open: false,
          title: '',
          desc: '',
        });
      },
    });
  };

  const [modalProps, setModalProps] = useState({
    open: false,
    title: 'Title',
    desc: 'Title desc',
    onOk: () => {},
    onCancel: () => {
      console.log('on cancel');
      setModalProps({
        ...modalProps,
        open: false,
        title: '',
        desc: '',
      });
    },
  });

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div className="wrapper-post-info">
          <div className="border-b">
            <div className="flex justify-between item-center padding-title-post-info">
              <p>게시글 정보</p>
              <div className="flex item-center gap-20">
                {postDetail.post_active === '1' && (
                  <p className="text-title-date-report">
                    {postDetail.post_active_date} 게시물 숨김 처리
                  </p>
                )}
                {postDetail.post_active === '0' && (
                  <Button
                    onClick={e => showModalConfirm(1)}
                    type="primary"
                    style={{ width: 126, height: 46, background: 'black' }}
                  >
                    숨김
                  </Button>
                )}
                {postDetail.post_active === '1' && (
                  <Button
                    onClick={e => showModalConfirm(0)}
                    type="primary"
                    style={{ width: 126, height: 46, background: 'black' }}
                  >
                    숨김 해제
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="padding-content-post-info ">
              <div
                className="flex flex-col gap-24 border-b"
                css={css`
                  padding-right: 490px;
                `}
              >
                <div className="flex gap-24">
                  <div className="flex gap-8">
                    <img src={icon_eye} alt="icon_eye" />
                    <p>{postDetail?.post_read_cnt || 0}</p>
                  </div>
                  <div className="flex gap-8">
                    <img src={icon_like} alt="icon_like" />
                    <p>{postDetail.post_like_count}</p>
                  </div>
                  <div className="flex gap-8">
                    <img src={icon_dislike} alt="icon_dislike" />
                    <p>{postDetail.post_dis_count}</p>
                  </div>
                </div>
                <p>{postDetail.channel_nm}</p>
                <div className="flex justify-between">
                  <p>{postDetail.title}</p>
                  <p>upload Date : {postDetail.reg_date}</p>
                </div>
                <p className="mb-30">{postDetail.user_nm || '--'}</p>
              </div>
              <div
                className="mt-30"
                css={css`
                  padding-right: 490px;
                `}
              >
                <pre>{postDetail.contents}</pre>
                {!isEmpty(postImgList) ? (
                  <PostImageList imgList={postImgList} />
                ) : (
                  <div
                    style={{
                      borderTop: '1px solid #f0f0f0',
                      width: '100%',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="flex gap-16">
            <div
              css={css`
                width: 60%;
              `}
            >
              <div className="bg-title-table flex-between">
                <p className="text-title-table">댓글 목록</p>
                <Button className="mr-15" onClick={showModalConfirmTable1}>
                  상태 변경
                </Button>
              </div>
              <Table
                rowKey="comment_id"
                className="ant-table"
                rowSelection={{
                  // type: checkbox,
                  ...commentRowSelection,
                }}
                columns={columns}
                dataSource={commentList}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      setSelectComment(record.comment_id);
                    },
                  };
                }}
                pagination={false}
                loading={loading}
              />
              <Pagination
                current={commentPage + 1}
                defaultCurrent={1}
                total={totalCommentCount || 1}
                pageSize={5}
                showSizeChanger={false}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '16px 0',
                }}
                onChange={(page, pageSize) => commentPageChange(page)}
              />
            </div>
            <div
              css={css`
                width: 40%;
              `}
            >
              <div className="bg-title-table flex-between">
                <p className="text-title-table">대댓글 목록</p>
                <Button className="mr-15" onClick={showModalConfirmTable2}>
                  상태 변경
                </Button>
              </div>
              <Table
                rowKey="comment_reply_id"
                className="ant-table"
                rowSelection={{
                  // type: checkbox,
                  ...replyRowSelection,
                }}
                columns={columns2}
                dataSource={replyList}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      console.log(record);
                    },
                  };
                }}
                pagination={false}
                loading={loading}
              />
              <Pagination
                current={replyPage + 1}
                defaultCurrent={1}
                total={totalReplyCount || 1}
                pageSize={5}
                showSizeChanger={false}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '16px 0',
                }}
                onChange={(page, pageSize) => replyPageChange(page)}
              />
            </div>
          </div>
        </div>
        <ModalConfirm model={modalProps} />
      </div>
    </div>
  );
};

export default PostManageDetail;
