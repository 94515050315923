import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PostItem from './PostItem';
import PostLoadingItem from './PostLoadingItem';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { css } from '@emotion/react';
import { SvgIcon } from '@mui/material';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { POST_VIEW_COUNT } from '@data/constants';

const PostList = ({
  postList,
  isChDetail = false,
  isProfile = false,
  holderYn = false,
  hasNextPage = false,
  isLoading = false,
  isFetchingNextPage = false,
  isUseEmpty = false,
  reloadRef,
  mutate = () => {},
}) => {
  const clickedPostId = useSelector(state => state.post.clickedPostId);
  const navigate = useNavigate();
  const clickedChannelPostId = useSelector(
    state => state.channel.clickedPostId,
  );
  const clickedWrittenPostId = useSelector(
    state => state.post.clickedWrittenPostId,
  );

  const loginedUser = useSelector(state => state.auth.loginedUser);

  const check = postId => {
    if (isChDetail) {
      return clickedChannelPostId;
    } else {
      if (isProfile) {
        return clickedWrittenPostId;
      } else {
        return clickedPostId;
      }
    }
  };

  const clickedPostRef = useRef(null);

  const isHolder = () => {
    if (!holderYn) return true;
    if (loginedUser.STOCK_INFO_YN === 'Y') return true;
    else return false;
  };

  return (
    <div
      css={css`
        background: ${!isEmpty(postList) || isLoading ? '#f5f5f5' : '#fff'};

        & > * {
          margin-bottom: ${toVW('8px')};
        }
      `}
    >
      {/* 글이 존재할 때, */}
      {!isEmpty(postList) && isHolder() && (
        <>
          {postList.map((item, index) => (
            <React.Fragment key={index}>
              {hasNextPage &&
                !isFetchingNextPage &&
                index === postList.length - 2 && <div ref={reloadRef}></div>}
              <PostItem
                key={`post_${item.channel_id}_${item.post_id}`}
                item={item}
                isChDetail={isChDetail}
                isProfile={isProfile}
                clickedPostRef={
                  item.post_id === check() ? clickedPostRef : undefined
                }
                mutate={mutate}
              />
            </React.Fragment>
          ))}
          {isFetchingNextPage && (
            <>
              {[...Array(POST_VIEW_COUNT)].map((_, index) => (
                <PostLoadingItem key={index} />
              ))}
            </>
          )}
        </>
      )}

      {/* 글이 존재하지않으며 */}
      {isEmpty(postList) && isHolder() && (
        <>
          {isLoading ? (
            <>
              {[...Array(POST_VIEW_COUNT)].map((_, index) => (
                <PostLoadingItem key={index} />
              ))}
            </>
          ) : (
            !isUseEmpty && (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: end;
                  min-height: 20vh;
                  color: var(--default-grayscale-neu-600013, #666);
                  ${globalStyle.fontNanu21}
                `}
              >
                {holderYn ? '조건에 맞는 글이 없어요.' : '작성된 글이 없어요'}
              </div>
            )
          )}
        </>
      )}

      {/* 글의 존재여부를 떠나, 주주연동글을 볼려고하는데 주주정보연동을 하지않았을 때, */}
      {!isHolder() && (
        <div
          css={css`
            ${globalStyle.fontNanu21}
            font-weight: 400 !important;
            letter-spacing: 0em;
            text-align: center;
            color: #666666;
            padding-top: ${stringToVW('120px')};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: ${stringToVW('13.3px')};
          `}
        >
          <h3
            css={css`
              ${globalStyle.fontNanu24}
            `}
          >
            {/* 주주 정보 연동 기능이 해제되어 있어요 */}
            주주 정보 연동 기능이 해제되어 있어요
          </h3>
          <div>
            {/* 주주정보 연동 기능을 활성화하고, */}
            주주 정보 기능을 활성화하고
            <br />
            {/* 주주전용글을 확인해보세요! */}주주 전용글을 확인하시겠어요?
          </div>

          <button
            css={css`
              margin-top: ${stringToVW('26.7px')};
              display: flex;
              align-items: center;
              justify-content: center;
              padding: ${stringToVW('10px 20px')};
              gap: ${stringToVW('10px')};
              border-radius: ${stringToVW('1000px')};
              background: #f1f1f1;
              border: 0;
              color: #666;
              ${globalStyle.fontNanu21}
            `}
            onClick={() => {
              navigate('/profile/edit');
            }}
          >
            <SvgIcon sx={{ width: toVW('16px'), height: toVW('16px') }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72962 2.38509C4.72567 2.38509 2.29048 4.72414 2.29048 7.6095C2.29048 10.4949 4.72567 12.8339 7.72962 12.8339C10.3263 12.8339 12.4995 11.0854 13.0397 8.74669C13.1227 8.38724 13.4934 8.16049 13.8676 8.24023C14.2418 8.31998 14.4779 8.67601 14.3949 9.03546C13.7168 11.9714 10.991 14.1672 7.72962 14.1672C3.95902 14.1672 0.902344 11.2312 0.902344 7.6095C0.902344 3.98776 3.95902 1.05176 7.72962 1.05176C9.89027 1.05176 11.8168 2.01658 13.0666 3.51953C13.1267 3.59171 13.1851 3.66514 13.242 3.73977C13.4684 4.03687 13.4012 4.45402 13.0919 4.67149C12.7826 4.88896 12.3483 4.82441 12.1219 4.52731C12.0765 4.4678 12.0299 4.40925 11.982 4.35168C10.9842 3.15185 9.45038 2.38509 7.72962 2.38509Z"
                  fill="#666666"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.14258 4.71305C9.14258 4.34486 9.45332 4.04639 9.83665 4.04639H13.182C13.5654 4.04639 13.8761 4.34486 13.8761 4.71305C13.8761 5.08124 13.5654 5.37972 13.182 5.37972H9.83665C9.45332 5.37972 9.14258 5.08124 9.14258 4.71305Z"
                  fill="#666666"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.1829 0.833008C13.5662 0.833008 13.877 1.13148 13.877 1.49967L13.877 4.71299C13.877 5.08118 13.5662 5.37965 13.1829 5.37965C12.7996 5.37965 12.4888 5.08118 12.4888 4.71299L12.4888 1.49967C12.4888 1.13148 12.7996 0.833008 13.1829 0.833008Z"
                  fill="#666666"
                />
              </svg>
            </SvgIcon>
            {/* 주주연동기능 활성화하기 */}
            주주 정보 연동 활성화하기
          </button>
        </div>
      )}
    </div>
  );
};

PostList.propType = {
  postList: PropTypes.array,
};

export default PostList;
