import '@asset/css/ptr.css';
import RefreshIcon from '@mui/icons-material/Refresh';
// import { spacing } from '@mui/system';
// import ReactPullToRefresh from 'react-pull-to-refresh';
import PullToRefresh from 'react-simple-pull-to-refresh';

const Ptr = ({ children, onRefresh = () => {} }) => {
  return (
    <PullToRefresh
      onRefresh={async () => {
        await new Promise(async (resolve, reject) => {
          await onRefresh();
          setTimeout(() => {
            resolve();
          });
        });
      }}
      refreshingContent={
        <div className="loading">
          <RefreshIcon className="ptr-icon" />
        </div>
      }
      pullingContent=""
      // pullingContent={<span>asdasd</span>}
      // icon={<span className="genericon genericon-next"></span>}
      // loading={
      //   <div className="loading">
      //     <RefreshIcon className="ptr-icon" />
      //   </div>
      // }
    >
      {children}
    </PullToRefresh>
  );
};

export default Ptr;
