import axiosUtils, { getAccTokenHeader } from '../../axiosUtils';

export const getDictionaryList = async param => {
  return await axiosUtils
    .get('/dictionaryList', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

/**
 * p_user_id
 * p_report_type(0:게시글,2:댓글,3:대댓글)
 * p_report_key(글번호)
 *
 */
export const deleleFeedData = async (
  param = { p_user_id: '', p_report_type: '', p_report_key: '' },
) => {
  return await axiosUtils
    .get('/postcommdel', param, { requireAuth: true })
    .then(res => res.data);
};

export const getOgMetaData = async links => {
  return await axiosUtils.post('/getOgMetaData', links).then(res => res.data);
};
