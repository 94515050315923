import React, { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Chip, IconButton, SvgIcon } from '@mui/material';

import { ReactComponent as IconCerti } from '@asset/icons/common/icon-certification.svg';
import ProfileAvatar from '../user/ProfileAvatar';
import { callMTSFunction, getUploadImage } from '@lib/commonUtils';
import { noDecoraionLink } from '@style/common/commonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { deleleFeedData } from '@lib/api-call/app/commApi';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { CALL_MTS_ACTION } from '@data/constants';
import { globalStyle, stringToVW, ratio, toVW } from '@style/globalStyle';
import BackButton from '../common/BackButton';
import { isArray, isEmpty } from 'lodash';
import Popup from '../common/PopupMui';

function PostDetailHeader({ item, backLink = '/', reportData }) {
  const {
    post_id,
    user_type, // 필요
    profile_img,
    user_nm,
    user_id,
    stock_qty,
    stock_holding_dt,
    title,
    channel_nm,
  } = item;

  const navigate = useNavigate();
  const { state: routerState, pathname } = useLocation();
  const { postId } = useParams();
  const [isShow, SetIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const dispatch = useDispatch();

  const styles = {
    container: css`
      display: flex;
      padding: ${stringToVW('12px 16px')};
      align-items: center;
      gap: ${stringToVW('16px')};
      border-bottom: 1px solid var(--essential-line-line-200025, #f0f0f0);
      background: #fff;
      position: fixed;
      width: 100%;
      z-index: 10;
    `,
    userContainer: css`
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: ${stringToVW('10.7px')};
    `,
    userInfo: css`
      display: flex;
      flex: 1 0 0;
      height: ${stringToVW('26px')};
      width: ${stringToVW('160px')};
      padding: 0px;
      align-items: center;
      gap: ${stringToVW('4px')};
    `,
    userName: css`
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      max-width: ${stringToVW('160px')};
      height: 100%;
      padding-top: ${stringToVW('5px')};
      /* text/body/bold */
      ${globalStyle.fontNanu21}
      font-weight: 600;
    `,
    iconCertiSize: css`
      width: ${stringToVW('21px')};
      height: ${stringToVW('21px')};
    `,
    wrapperPopup: css`
      background: rgba(0, 0, 0, 0.4);
      position: fixed;
      margin: 0 auto;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
    `,
    popup: css`
      display: inline-flex;
      align-items: flex-start;
      border-radius: ${stringToVW('10px')};
      background: #f7f7f7;
      color: #000;
      font-size: ${stringToVW('21px')};
      font-weight: 400;
      line-height: ${stringToVW('32px')};
      padding: ${stringToVW('6.7px 0px')};
      right: ${stringToVW('24px')};
      top: ${stringToVW('48px')};
      position: absolute;
      text-decoration-line: none;
      flex-direction: column;
    `,
    popupText: css`
      text-decoration-line: none;
      ${globalStyle.fontNanu21}
      color: #000;
      display: inline-flex;
      gap: ${stringToVW('9.5px')};
      align-items: flex-start;
      min-width: ${stringToVW('170px')};
      padding: ${stringToVW('9.5px 16px')};
    `,
  };

  const handleDeletePost = async () => {
    let msg = '게시글 삭제 실패! 다시 시도해주세요.';
    let severity = 'error';

    try {
      setIsLoading(true);

      const { message_cd } = await deleleFeedData({
        p_user_id: loginedUser.USER_ID,
        p_report_type: '0',
        p_report_key: post_id,
      });

      if (message_cd === '0') {
        msg = '게시글 삭제 성공!';
        severity = 'success';
        navigate(-1);
      }
    } finally {
      setIsLoading(false);
      dispatch(setAlertInfo({ msg, severity }));
      dispatch(openSnackbar());
    }
  };

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <>
      <div css={styles.container} ref={headerRef}>
        <div css={styles.userContainer}>
          <BackButton
          // backLink={(routerState && routerState.prevLink) || '/'}
          // state={{
          //   prevLink:
          //     routerState && routerState.prevPageBackLink
          //       ? routerState.prevPageBackLink
          //       : undefined,
          // }}
          />

          <Link
            css={[
              noDecoraionLink,
              css`
                display: block;
                position: relative;
              `,
            ]}
            to={`/profile/${encodeURIComponent(user_id)}`}
            state={{ prevLink: pathname }}
          >
            <ProfileAvatar
              width={toVW('26.7px')}
              height={toVW('26.7px')}
              userInfo={{
                userName: user_nm,
                filePath: getUploadImage(profile_img),
              }}
            />
            {user_type !== '1' && stock_qty && stock_holding_dt && (
              <Chip
                sx={{
                  position: 'absolute',
                  bottom: stringToVW('-6.67px'), //'-6.67px',
                  left: '50%',
                  transform: 'translate(-50%, -0%)',
                  border: '1px solid #FFF',
                  color: '#FFF',
                  background: '#84C13D',
                  fontSize: stringToVW('8.7px'),
                  lineHeight: 'normal',
                  '& .MuiChip-label': {
                    padding: stringToVW('2px 4px'),
                    height: '100%',
                  },
                  height: stringToVW('16px'),
                }}
                label="주주"
              />
            )}
          </Link>
          <div css={styles.userInfo}>
            <Link
              css={[styles.userName, noDecoraionLink]}
              to={`/profile/${encodeURIComponent(user_id)}`}
              state={{ prevLink: pathname }}
            >
              {user_nm}
            </Link>
            {user_type === '1' && <IconCerti css={styles.iconCertiSize} />}
          </div>
        </div>

        <IconButton onClick={() => SetIsShow(!isShow)}>
          <SvgIcon sx={{ width: toVW('4px'), height: toVW('14px') }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 4 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.28546 1.6589C3.28546 2.39637 2.68762 2.9942 1.95016 2.9942C1.2127 2.9942 0.614868 2.39637 0.614868 1.6589C0.614868 0.92144 1.2127 0.323608 1.95016 0.323608C2.68762 0.323608 3.28546 0.92144 3.28546 1.6589ZM3.28546 7.00008C3.28546 7.73754 2.68762 8.33537 1.95016 8.33537C1.2127 8.33537 0.614868 7.73754 0.614868 7.00008C0.614868 6.26262 1.2127 5.66479 1.95016 5.66479C2.68762 5.66479 3.28546 6.26262 3.28546 7.00008ZM1.95016 13.6766C2.68762 13.6766 3.28546 13.0787 3.28546 12.3413C3.28546 11.6038 2.68762 11.006 1.95016 11.006C1.2127 11.006 0.614868 11.6038 0.614868 12.3413C0.614868 13.0787 1.2127 13.6766 1.95016 13.6766Z"
                fill="#333333"
              />
            </svg>
          </SvgIcon>
        </IconButton>

        {isShow ? (
          <div css={styles.wrapperPopup} onClick={() => SetIsShow(!isShow)}>
            <div
              onClick={event => {
                event.stopPropagation();
              }}
            >
              <div css={styles.popup}>
                <Link
                  css={styles.popupText}
                  onClick={e => {
                    e.preventDefault();
                    window.GA_Event('커뮤니티', '공통', '게시글 공유');
                    SetIsShow(false);
                    const param = [
                      postId,
                      title?.replaceAll(',', ''),
                      channel_nm,
                    ];

                    if (isArray(item?.post_img) && !isEmpty(item?.post_img)) {
                      param.push(
                        getUploadImage(item.post_img[0].image_thumbnail_url),
                      );
                    }
                    callMTSFunction(CALL_MTS_ACTION.SHARE_POST, param);
                  }}
                >
                  <span style={{ width: toVW('20px'), height: toVW('20px') }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.34832 8.86494L12.6514 6.21342M12.6514 14.168L7.34832 11.5165M18.3332 4.69827C18.3332 6.37186 16.9765 7.72858 15.3029 7.72858C13.6293 7.72858 12.2726 6.37186 12.2726 4.69827C12.2726 3.02468 13.6293 1.66797 15.3029 1.66797C16.9765 1.66797 18.3332 3.02468 18.3332 4.69827ZM7.72711 10.0013C7.72711 11.6749 6.3704 13.0316 4.69681 13.0316C3.02322 13.0316 1.6665 11.6749 1.6665 10.0013C1.6665 8.32771 3.02322 6.971 4.69681 6.971C6.3704 6.971 7.72711 8.32771 7.72711 10.0013ZM18.3332 15.3043C18.3332 16.9779 16.9765 18.3346 15.3029 18.3346C13.6293 18.3346 12.2726 16.9779 12.2726 15.3043C12.2726 13.6307 13.6293 12.274 15.3029 12.274C16.9765 12.274 18.3332 13.6307 18.3332 15.3043Z"
                        stroke="#999999"
                        strokeWidth="1.33333"
                      />
                    </svg>
                  </span>
                  게시글 공유
                </Link>
                {loginedUser?.USER_ID !== item.user_id ? (
                  <Link
                    to={{
                      pathname: '/report',
                      search: `${new URLSearchParams(reportData).toString()}`,
                    }}
                    onClick={() => {
                      window.GA_Event('커뮤니티', '공통', '게시글 신고');
                    }}
                    css={styles.popupText}
                  >
                    <span style={{ width: toVW('20px'), height: toVW('20px') }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 1.62533C5.11152 1.62533 1.95833 4.77852 1.95833 8.66699C1.95833 12.5555 5.11152 15.7087 9 15.7087C12.8893 15.7087 16.0417 12.5555 16.0417 8.66699C16.0417 4.77847 12.8893 1.62533 9 1.62533ZM0.625 8.66699C0.625 4.04214 4.37514 0.291992 9 0.291992C13.6257 0.291992 17.375 4.04218 17.375 8.66699C17.375 13.2918 13.6257 17.042 9 17.042C4.37514 17.042 0.625 13.2918 0.625 8.66699Z"
                          fill="#999999"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.99626 4.83691C9.36445 4.83691 9.66292 5.13539 9.66292 5.50358V9.18608C9.66292 9.55427 9.36445 9.85275 8.99626 9.85275C8.62807 9.85275 8.32959 9.55427 8.32959 9.18608V5.50358C8.32959 5.13539 8.62807 4.83691 8.99626 4.83691Z"
                          fill="#999999"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.3291 11.8307C8.3291 11.4625 8.62758 11.1641 8.99577 11.1641H9.0041C9.37229 11.1641 9.67077 11.4625 9.67077 11.8307C9.67077 12.1989 9.37229 12.4974 9.0041 12.4974H8.99577C8.62758 12.4974 8.3291 12.1989 8.3291 11.8307Z"
                          fill="#999999"
                        />
                      </svg>
                    </span>
                    게시글 신고
                  </Link>
                ) : (
                  <>
                    <Link
                      state={{ updateItem: item }}
                      to="/update-post"
                      css={styles.popupText}
                      onClick={() => {
                        window.GA_Event('커뮤니티', '공통', '게시글 수정');
                      }}
                    >
                      <span
                        style={{ width: toVW('20px'), height: toVW('20px') }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.0262 2.05835C13.799 1.8311 13.4305 1.8311 13.2033 2.05835L4.14761 11.114C4.08138 11.1802 4.03212 11.2615 4.00401 11.3508L3.10976 14.1925C3.03914 14.4169 3.25023 14.628 3.47464 14.5574L6.31635 13.6632C6.40569 13.635 6.48692 13.5858 6.55315 13.5196L15.6088 4.46389C15.8361 4.23664 15.8361 3.86819 15.6088 3.64094L14.0262 2.05835ZM12.3803 1.2354C13.0621 0.553645 14.1674 0.553647 14.8492 1.2354L16.4318 2.81799C17.1135 3.49975 17.1135 4.60509 16.4318 5.28684L7.3761 14.3425C7.17742 14.5412 6.93372 14.689 6.6657 14.7733L3.82399 15.6676C2.70193 16.0206 1.64651 14.9652 1.99961 13.8432L2.89385 11.0015C2.97819 10.7334 3.12598 10.4897 3.32466 10.2911L12.3803 1.2354Z"
                            fill="#999999"
                            stroke="#999999"
                            strokeWidth="0.266667"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.4446 7.42839L10.2161 4.1999L11.0391 3.37695L14.2676 6.60544L13.4446 7.42839Z"
                            fill="#999999"
                            stroke="#999999"
                            strokeWidth="0.266667"
                          />
                        </svg>
                      </span>
                      게시글 수정
                    </Link>
                    <Link
                      onClick={() => {
                        window.GA_Event('커뮤니티', '공통', '게시글 삭제');
                        if (!isLoading) {
                          setOpenConfirmModal(true);
                        }
                        // !isLoading ? () => setOpenConfirmModal(true) : undefined;
                      }}
                      css={styles.popupText}
                    >
                      <span
                        style={{ width: toVW('20px'), height: toVW('20px') }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.6667 4.33431H3.33333V13.001C3.33333 14.1055 4.22876 15.001 5.33333 15.001H10.6667C11.7712 15.001 12.6667 14.1055 12.6667 13.001V4.33431ZM2 3.00098V13.001C2 14.8419 3.49238 16.3343 5.33333 16.3343H10.6667C12.5076 16.3343 14 14.8419 14 13.001V3.00098H2Z"
                            fill="#999999"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.666504 3.66764C0.666504 3.29945 0.964981 3.00098 1.33317 3.00098H14.6665C15.0347 3.00098 15.3332 3.29945 15.3332 3.66764C15.3332 4.03583 15.0347 4.33431 14.6665 4.33431H1.33317C0.964981 4.33431 0.666504 4.03583 0.666504 3.66764Z"
                            fill="#999999"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.6665 1.66732C6.29831 1.66732 5.99984 1.96579 5.99984 2.33398V3.66732C5.99984 4.03551 5.70136 4.33398 5.33317 4.33398C4.96498 4.33398 4.6665 4.03551 4.6665 3.66732V2.33398C4.6665 1.22941 5.56193 0.333984 6.6665 0.333984H9.33317C10.4377 0.333984 11.3332 1.22942 11.3332 2.33398V3.66732C11.3332 4.03551 11.0347 4.33398 10.6665 4.33398C10.2983 4.33398 9.99984 4.03551 9.99984 3.66732V2.33398C9.99984 1.96579 9.70136 1.66732 9.33317 1.66732H6.6665Z"
                            fill="#999999"
                          />
                          <path
                            d="M5.3335 5.66699H6.66683V13.0003H5.3335V5.66699Z"
                            fill="#999999"
                          />
                          <path
                            d="M9.3335 5.66699H10.6668V13.0003H9.3335V5.66699Z"
                            fill="#999999"
                          />
                        </svg>
                      </span>
                      게시글 삭제
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <Popup
          open={openConfirmModal}
          confirmEvent={handleDeletePost}
          data={{ title: '게시글 삭제', content: '해당 글을 삭제하시겠어요?' }}
          confirmText="확인"
          cancelText="취소"
          onClose={() => setOpenConfirmModal(false)}
          confirmButton
        />
      </div>
      <div style={{ height: headerHeight }} />
    </>
  );
}

PostDetailHeader.propTypes = {};

export default PostDetailHeader;
