import ProfileAvatar from '../user/ProfileAvatar';
import { getUploadImage } from '@lib/commonUtils';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { toVW } from '@style/globalStyle';

const MyProfileIcon = ({ isActive, width = 40, height = 40 }) => {
  const loginedUser = useSelector(state => state.auth.loginedUser);
  return (
    <div
      css={css`
        border-radius: 1000px;
        width: ${width};
        height: ${height};
        border: ${isActive ? '1px solid #84C13D' : 'none'};
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      {loginedUser ? (
        <ProfileAvatar
          width={toVW('22px')}
          height={toVW('22px')}
          userInfo={{
            filePath: getUploadImage(loginedUser.PROFILE_IMG),
          }}
        />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 23 24"
          fill="none"
        >
          <g clipPath="url(#clip0_3106_182438)">
            <circle
              cx="11.4167"
              cy="12.0007"
              r="9.8"
              stroke="#45454C"
              strokeWidth="1.73333"
            />
            <circle
              cx="11.4167"
              cy="10.667"
              r="3.13333"
              stroke="#45454C"
              strokeWidth="1.73333"
            />
            <path
              d="M18.0833 18.6676C17.4167 17.1121 15.15 14.001 11.4167 14.001C7.68333 14.001 5.41667 17.1121 4.75 18.6676"
              stroke="#45454C"
              strokeWidth="1.73333"
            />
          </g>
          <defs>
            <clipPath id="clip0_3106_182438">
              <rect
                width="22.6667"
                height="22.6667"
                fill="white"
                transform="translate(0.0834961 0.666992)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default MyProfileIcon;
