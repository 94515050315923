import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IconNdsHeaderMd from '@asset/icons/common/nds_icon_header_md.svg';
import { stringToVW } from '@style/globalStyle';
import { callMTSFunction } from '@lib/commonUtils';
import { CALL_MTS_ACTION } from '@data/constants';

const BackButton = ({
  backLink,
  backButtonClickListener,
  state,
  close,
  closeAll,
}) => {
  const getBackLink = () => {
    if (window.history.state?.idx === 0 || !window.history.state?.idx) {
      if (close) {
        callMTSFunction(CALL_MTS_ACTION.CLOSE_VIEW);
        return;
      }
      return '/';
    } else {
      return -1;
    }
  };

  const navigate = useNavigate();

  return (
    <Link
      // to={backLink ? backLink : getBackLink()}
      // to={() => {
      //   return backLink ? backLink : getBackLink();
      // }}
      style={{ display: 'flex' }}
      onClick={() => {
        if (closeAll) {
          callMTSFunction(CALL_MTS_ACTION.CLOSE_ALL);
        } else {
          backButtonClickListener && backButtonClickListener();
          navigate(backLink ? backLink : getBackLink());
        }
      }}
      state={state ? { ...state } : undefined}
    >
      <img
        src={IconNdsHeaderMd}
        alt="back-icon"
        style={{
          height: stringToVW('14px'),
          margin: stringToVW('4px 7px'),
        }}
      />
    </Link>
  );
};

export default BackButton;
