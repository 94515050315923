import React from 'react';
import { css } from '@emotion/react';
import HeaderBasic from '@component/app/common/HeaderBasic';

import SubscriptionChannel from '@component/app/profile/SubscriptionChannel';
import { stringToVW } from '@style/globalStyle';

const Channel = () => {
  const styles = {
    wrapper: css`
      font-style: normal;
    `,
    termItem: css`
      display: flex;
      gap: ${stringToVW('16px')};
      align-items: center;
      padding: ${stringToVW('32px 24px')};
      text-decoration: none;
    `,
    label: css`
      color: #000;
      font-size: ${stringToVW('21px')};
      font-weight: 400;
      line-height: ${stringToVW('32px')};
    `,
  };

  return (
    <div css={styles.wrapper}>
      <HeaderBasic title="구독 채널" backLink="/subscribe" />
      <SubscriptionChannel isProfileOfOthers={false} pin={true} />
      {/* <div css={css``}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            css={css`
              display: flex;
              align-items: center;
              width: 100%;
              height: 62px;
              background: #f7f7f7;
              margin: 0 0 32px 0;
              padding: 0 24px;
            `}
          >
            <Grid xs={1}>
              <img
                css={css`
                  width: 22px;
                  height: 22px;
                  margin-top: 4px;
                `}
                src={IconSearch}
                alt=""
              />
            </Grid>
            <Grid xs={6}>
              <input
                // onChange={e => setKeyWord(e.target.value)}
                css={css`
                  border: none;
                  background: none;
                  outline: none;
                  ::placeholder {
                    color: #999999;
                  }
                  font-family: NanumBarunGothic;
                  font-size: 21px;
                  font-weight: 400;
                  line-height: 32px;
                  letter-spacing: 0px;
                  text-align: left;
                `}
                type="text"
                placeholder="채널명/ 티커 검색"
              />
            </Grid>
            <Grid
              xs={5}
              css={css`
                font-family: NanumBarunGothic;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: right;
                color: #666666;
                display: flex;
                gap: 10px;
                justify-content: end;
              `}
              // onClick={() => setSorting(!sorting)}
            >
              업데이트순
              <img
                css={css`
                  width: 22px;
                  height: 22px;
                `}
                src={IconArrow}
                alt=""
                />
                </Grid>
                </Grid>
                </Box>
              </div> */}
    </div>
    /* transform: rotate(${sorting ? 0 : 180}deg); */
  );
};

export default Channel;
