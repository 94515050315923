import { JS_KEY_ACC_TOKEN, JS_KEY_REFRESH_TOKEN } from '@data/constants';
import axiosUtils, { getAccTokenHeader } from '../../axiosUtils';

export const loginRequest = async ({ userId }) => {
  return await axiosUtils

    .get(`/loginTest`, { user_id: userId })
    .then(({ data, headers }) => {
      const accessToken = headers[JS_KEY_ACC_TOKEN];
      const refreshToken = headers[JS_KEY_REFRESH_TOKEN];
      return { ...data, accessToken, refreshToken };
    });
};

export const getUserInfo = async () => {
  return await axiosUtils.get(`/userInfo`).then(({ data }) => {
    return data;
  });
};

export const getReportUserIn = async param => {
  return await axiosUtils
    .get('/reportin', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};
