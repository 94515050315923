import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import Header from '@component/admin/common/Header';
import { DatePicker, Select, Input, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import icon_right from '@asset/icons/common/icon_right.svg';
import { getMemberView } from '@lib/api-call/admin/memberApi';
import { getMemberNoticeList } from '@lib/api-call/admin/channelApi';

const { RangePicker } = DatePicker;

const handleChange = value => {
  console.log(`selected ${value}`);
};

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '카테고리',
    dataIndex: 'type_nm',
    key: 'type_nm',
  },
  {
    title: '종목/채널명',
    dataIndex: 'channel_nm',
    key: 'channel_nm',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '등록일',
    dataIndex: 'reg_date',
    key: 'reg_date',
  },
  {
    title: '상태',
    dataIndex: 'post_active_nm',
    key: 'post_active_nm',
  },
];

const PostContent = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});

  const { userId } = useParams(); // 쿼리 호출

  const [postList, setPostList] = useState([]);
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const onUserInfoGet = async () => {
    const userData = await getMemberView({ p_user_id: userId });
    console.log(userData);
    setUserInfo(userData.urUserView);
  };

  const onPostListGet = async () => {
    setLoading(true);
    const postData = await getMemberNoticeList({
      p_user_id: userId,
      p_start: page * 10,
      p_length: 10,
    });

    console.log(postData);

    setPostList(postData.ntNoticeList);
    setTotalPostCount(postData.total_count);
    setTimeout(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    onUserInfoGet();
    onPostListGet();
  }, []);

  useEffect(() => {
    onPostListGet();
  }, [page]);

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const dataProp = [
    {
      title: '회원 관리',
      href: '/admin/user-manage',
    },
    {
      title: `${userInfo.user_nm}`,
      href: `/admin/user-detail/${userId}`,
    },
    {
      title: '게시글 내역',
      href: '',
    },
  ];

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div
          className="flex cursor-pointer"
          css={css`
            margin-left: -20px;
          `}
          onClick={() => navigate(-1)}
        >
          <img
            css={css`
              rotate: 180deg;
            `}
            src={icon_right}
            alt=""
          />
          <p>{userInfo.user_nm}</p>
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="bg-title-table">
            <p className="text-title-table">게시글 내역</p>
          </div>
          <Table
            className="ant-table"
            columns={columns}
            dataSource={postList}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  console.log(record);
                  navigate(`/admin/post-manage/post-detail/${record.post_id}`);
                },
              };
            }}
            pagination={false}
            loading={loading}
          />
          <Pagination
            current={page + 1}
            defaultCurrent={1}
            total={totalPostCount || 1}
            showSizeChanger={false}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px 0',
            }}
            onChange={(page, pageSize) => pageChange(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default PostContent;
