import { css } from '@emotion/react';
import CommentInput from './CommentInput';
import { ReactComponent as SendIcon } from '@asset/icons/post/nds_icon_send.svg';
import { useEffect, useRef, useState } from 'react';
import { includes, isEmpty, some } from 'lodash';
import { IconButton, SvgIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { writeComment, writeCommentReply } from '@lib/api-call/app/loungeApi';
import {
  addCommentReply,
  addPostComment,
  setCommentInputHeight,
  updateReplyTargetComment,
} from '@store/app/postReducer';
import { getDictionaryList } from '@lib/api-call/app/commApi';
import Popup from '../common/PopupMui';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { checkTextWithKiso } from '@lib/commonUtils';
import React from 'react';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';

const CommentInputArea = () => {
  const [openPopupWithdraw, setOpenPopupWithdraw] = React.useState(false);

  const [commentValue, setCommentValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [badwords, setBadwords] = useState([]);
  const [popupData, setPopupData] = useState({
    title: undefined,
    data: undefined,
  });
  const ref = useRef(0);

  const postItem = useSelector(state => state.post.postItem);
  const replyTargetComment = useSelector(
    state => state.post.replyTargetComment,
  );

  const dispatch = useDispatch();

  const handleCommentValue = e => {
    setCommentValue(e.target.value);
  };

  const sendCommentData = async () => {
    if (!isEmpty(commentValue.replace(/\s/g, ''))) {
      const checkBadword = await checkTextWithKiso(commentValue);
      const badword = [
        ...checkBadword,
        ...hasInappropriateText(commentValue, badwords).filteredWords,
      ];
      if (badword?.length) {
        // setBadwordDetected(badword[0]);
        setPopupData(
          badword?.length > 1
            ? {
                title: (
                  <div css={styles.modalTitle}>
                    댓글에 금칙어({badword[0]})등이
                    <br />
                    포함되어 있습니다.
                  </div>
                ),
                content: (
                  <div css={styles.modalContent}>댓글을 수정해주세요.</div>
                ),
              }
            : {
                title: (
                  <div css={styles.modalTitle}>
                    댓글에 금칙어({badword[0]})가
                    <br />
                    포함되어 있습니다.
                  </div>
                ),
                content: (
                  <div css={styles.modalContent}>댓글을 수정해주세요.</div>
                ),
              },
        );
        setIsShow(true);
        return;
      }

      setIsLoading(true);
      const commentData = {
        post_id: postItem.post_id,
        contents: commentValue,
      };

      try {
        if (!replyTargetComment) {
          //댓글
          const { message_cd, comment, message_nm, error } =
            await writeComment(commentData);
          if (error === 401) setOpenPopupWithdraw(true);

          if (message_cd === '0') {
            comment.postCommentPeplyList = [];
            dispatch(addPostComment(comment));
          } else {
            dispatch(setAlertInfo({ msg: message_nm, severity: 'error' }));
            dispatch(openSnackbar());
          }
        } else {
          //대댓글
          commentData.comment_id = replyTargetComment.comment_id;
          commentData.reply_comment_id = 0;

          const { message_cd, commentReply, message_nm, error } =
            await writeCommentReply(commentData);
          if (error === 401) setOpenPopupWithdraw(true);

          if (message_cd === '0') {
            dispatch(addCommentReply(commentReply));
            dispatch(updateReplyTargetComment(undefined));
          } else {
            dispatch(setAlertInfo({ msg: message_nm, severity: 'error' }));
            dispatch(openSnackbar());
          }
        }
      } catch (error) {
        dispatch(setAlertInfo({ msg: '댓글 작성 실패!', severity: 'error' }));
        dispatch(openSnackbar());
      } finally {
        setCommentValue('');
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = e => {
    // if (e?.key !== 'Enter') return;
    // sendCommentData();
  };

  const closeDialog = () => {
    setIsShow(false);
  };

  const hasInappropriateText = (text, dictionaryList) => {
    const lowercaseText = text.toLowerCase();
    const lowercaseInappropriateWords = dictionaryList.map(word =>
      word.toLowerCase(),
    );

    const filteredWords = lowercaseInappropriateWords.filter(word =>
      includes(lowercaseText, word),
    );

    const isInappropriateWords = some(lowercaseInappropriateWords, word => {
      const has = includes(lowercaseText, word);

      return has;
    });

    return { isInappropriateWords, filteredWords };
  };

  const fetchingBadWords = async () => {
    const { badWord } = await getDictionaryList();
    setBadwords(badWord);
  };

  useEffect(() => {}, [commentValue]);

  useEffect(() => {
    fetchingBadWords();
    dispatch(setCommentInputHeight(ref?.current?.offsetHeight || 30));

    return () => {
      dispatch(setCommentInputHeight(30));
    };
  }, []);

  return (
    <div
      css={css`
        display: flex;
        align-items: start;
        min-height: ${stringToVW('48px')};
        gap: ${stringToVW('5.33px')};
        width: 100%;
        padding: ${stringToVW('10.5px')};
        position: fixed;
        bottom: 0vh;
        background: #fff;
        z-index: 9;
      `}
      ref={ref}
    >
      <CommentInput
        commentValue={commentValue}
        handleOnChange={handleCommentValue}
        handleOnKeyDown={!isLoading ? handleKeyDown : undefined}
      />
      {!isEmpty(commentValue.replace(/\s/g, '')) && (
        <IconButton
          onClick={sendCommentData}
          disabled={isLoading}
          sx={{ marginRight: stringToVW('-8px') }}
        >
          <SvgIcon inheritViewBox sx={{ width: 27, height: 27 }}>
            <SendIcon />
          </SvgIcon>
        </IconButton>
      )}
      <Popup
        open={isShow}
        data={popupData}
        //   {
        //   title: (
        //     <div css={styles.modalTitle}>
        //       {/* 댓글에 금칙어 및 비속어가 발견되었습니다. */}
        //       댓글에 금칙어({badwordDetected})가
        //       <br />
        //       포함되어 있습니다.
        //     </div>
        //   ),
        //   content: <div css={styles.modalContent}>댓글을 수정해주세요!</div>,
        // }}
        cancelButton={false}
        onClose={closeDialog}
        confirmText="확인"
      />
      {openPopupWithdraw && <CheckWithdrawAccount />}
    </div>
  );
};

const styles = {
  modalTitle: css`
    max-width: ${stringToVW('288px')};
    padding: ${stringToVW('16px')};
    padding-bottom: 0;
    color: #333;
    text-align: center;
    ${globalStyle.fontNanu26}
    word-break: keep-all;
  `,
  modalContent: css`
    color: var(--nm-default-3013, #666);
    text-align: center;
    ${globalStyle.fontNanu21}
    font-weight: 400;
  `,
};

export default CommentInputArea;
