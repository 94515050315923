import axiosUtils, { getAccTokenHeader } from '../../axiosUtils';
import { removeCookie } from '@lib/cookieUtils';
import {
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
  CALL_MTS_ACTION,
} from '@data/constants';
import { callMTSFunction } from '@lib/commonUtils';

export const getSubChnlList = async param => {
  // ?pageSize=10&orderBy=0
  return await axiosUtils
    .get('/subscribe/chnlPostlist', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getSubChnl = async param => {
  return await axiosUtils
    .get('/subscribe/chnlsubs', param, { requireAuth: true })
    .then(res => res.data);
};

export const getSubsChannelSearch = async param => {
  return await axiosUtils
    .post('/subscribe/chnlsearch', param, {
      headers: getAccTokenHeader(),
      requireAuth: true,
    })
    .then(res => res.data);
};
export const getSubsChannelPinCheck = async param => {
  return await axiosUtils
    .get('/subscribe/chnlpin', param, {
      headers: getAccTokenHeader(),
      requireAuth: true,
    })
    .then(res => res.data);
};

export const getSubsChnlList10 = async param => {
  return await axiosUtils
    .get('/subscribe/chnllist', param, { requireAuth: true })
    .then(res => res.data);
};
