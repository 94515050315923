/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import PostItem from '@component/app/post/PostItem';
import ActivityHistory from '@component/app/profile/ActivityHistory';

import {
  getWriteList,
  getCommentList,
  getReactionList,
} from '@lib/api-call/app/profileApi';
import { stringToVW, toVW } from '@style/globalStyle';
import { useInView } from 'react-intersection-observer';
import { isEmpty } from 'lodash';
import useWritePostQuery, { writeQueryKey } from 'query-hook/useWritePostQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setClickedWrittenPostId } from '@store/app/postReducer';
import { loungeHeaderHeight } from '../common/LoungeHeader';
import Loading from '../common/loading';
import PostList from '../post/PostList';
import { useLocation, useNavigate } from 'react-router-dom';
import { POST_VIEW_COUNT } from '@data/constants';

// style
const styles = {
  wrapper: css`
    font-family: NanumBarunGothic;
    font-style: normal;
  `,
  tabs: css`
    display: flex;
    gap: ${stringToVW('6.7px')};
    margin-top: ${stringToVW('13px')};
    padding: ${stringToVW('0 16px')};
  `,
  tabItem: css`
    display: flex;
    height: ${stringToVW('31px')};
    min-width: ${stringToVW('66px')};
    padding: ${stringToVW('10px 16px')};
    justify-content: center;
    align-items: center;
    gap: ${stringToVW('10px')};
    background: var(--nm-default-6063, #fff);
    text-align: center;
    font-size: ${stringToVW('12px')};
    font-weight: 400;
    line-height: ${stringToVW('16px')};
    border-radius: 999px;
  `,
  title: css`
    color: #666;
    text-align: center;
    font-size: ${stringToVW('14px')};
    font-weight: 400;
    line-height: ${stringToVW('22px')};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${stringToVW('237px')};
  `,
};

// tabs
const Tabs = ({ type, setType, display }) => {
  // const [tab, setTab] = useState(0);
  const tabStyles = typeCheck => {
    if (type === typeCheck) {
      return { border: '1px solid #000', color: '#333', fontWeight: 600 };
    } else {
      return { border: '1px solid #E0E0E0', color: '#999' };
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {display && (
        <div css={styles.tabs}>
          <div
            css={styles.tabItem}
            onClick={() => setType('write')}
            style={tabStyles('write')}
          >
            게시글
          </div>
          <div
            css={styles.tabItem}
            onClick={() => setType('comment')}
            style={tabStyles('comment')}
          >
            댓글
          </div>
          <div
            css={styles.tabItem}
            onClick={() => setType('reaction')}
            style={tabStyles('reaction')}
          >
            반응
          </div>
        </div>
      )}
    </>
  );
};

const contentType = type => {
  if (type.includes('post')) {
    return '게시글';
  } else if (type.includes('comment')) {
    return '댓글';
  }
};

// other
const OtherListWrite = ({ userId }) => {
  const [ref, inView] = useInView();

  const loginedUser = useSelector(state => state.auth.loginedUser);

  const onOtherWriteGet = async ({ pageParam, meta }) => {
    return await getWriteList({
      profileId: userId,
      pageSize: POST_VIEW_COUNT,
      lastPostId:
        meta?.prefetch.lastPostId || pageParam === 0 ? undefined : pageParam,
    });
  };

  const params = { profileId: encodeURIComponent(userId) };

  const {
    data,
    isLoading,
    // isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useWritePostQuery({
    rowPerPage: POST_VIEW_COUNT,
    queryFn: onOtherWriteGet,
    params: params,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: [writeQueryKey],
    mutationFn: ({ post, actionFunc }) => updateReactionFunc(post, actionFunc),
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([writeQueryKey, params], oldData => {
        const temp = { ...oldData };

        for (let page of temp.pages) {
          const idx = page.findIndex(item => item.post_id === data.post_id);

          if (idx !== -1) {
            page[idx] = data;
            break;
          }
        }

        return temp;
      });
    },
  });

  const updateReactionFunc = async (post, actionFunc) => {
    return await actionFunc(post);
  };

  useEffect(() => {
    refetch();
  }, [loginedUser]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  //mounted
  useEffect(() => {}, []);

  return (
    <div>
      <PostList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        postList={data?.pages}
        reloadRef={ref}
        mutate={mutate}
        isUseEmpty
        isProfile
      />
      {!isLoading && !data?.pages.length ? (
        <div css={styles.title}>작성한 게시글이 없어요.</div>
      ) : (
        ''
      )}
      {!isEmpty(data?.pages) && hasNextPage && !isFetchingNextPage && (
        <div
          css={css`
            min-height: ${toVW('30px')};
            width: 100%;
            background: 'none';
            color: #b1f54d;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        ></div>
      )}
    </div>
  );
};
const OtherListComment = ({ userId, userNm }) => {
  const [commentList, setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const onOtherCommentGet = async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const otherCommentData = await getCommentList({ profileId: userId });
      setLoading(false);
      setCommentList(otherCommentData);
    } catch (error) {
      setLoading(false);
    }
  };

  //mounted
  useEffect(() => {
    onOtherCommentGet();
  }, []);

  return (
    <div>
      {commentList.length > 0 ? (
        <div>
          {commentList.map((items, index) => {
            return (
              <div key={index}>
                <div
                  css={css`
                    border-bottom: ${toVW('6px')} solid #f5f5f5;
                  `}
                >
                  <ActivityHistory
                    key={index}
                    data={{
                      title: items.TITLE,
                      label: `${userNm}님이 댓글을 남겼습니다`,
                      postContents: items.postContents,
                      comment: items.comments,
                      date: items.REG_DT,
                      postId: items.POST_ID,
                      active: items.active,
                    }}
                    type="comment"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div css={styles.title}>
          {loading ? '로딩중...' : '작성한 댓글이 없어요.'}
        </div>
      )}
    </div>
  );
};
const OtherListReaction = ({ userId, userNm }) => {
  const [reactionList, setReactionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const onOtherReactionGet = async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const otherReactionData = await getReactionList({ profileId: userId });
      setReactionList(otherReactionData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //mounted
  useEffect(() => {
    onOtherReactionGet();
  }, []);

  return (
    <div>
      {reactionList.length > 0 ? (
        <div>
          {reactionList.map(items => {
            return (
              <div key={items}>
                <div
                  css={css`
                    border-bottom: ${toVW('6px')} solid #f5f5f5;
                  `}
                >
                  <ActivityHistory
                    data={{
                      title: items.TITLE,
                      label: `${userNm || '-'} 님이 이 ${contentType(
                        items.postLike,
                      )}을 ${
                        items.postLike.includes('Like') ? '좋아' : '싫어'
                      }합니다`,
                      postContents: items.postContents,
                      comment: items.comments,
                      date: items.REG_DT,
                      userName: userNm || '-',
                      chnlName: items.CHANNEL_NM,
                      postId: items.POST_ID,
                    }}
                    type={items.postLike}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div css={styles.title}>
          {loading ? '로딩중...' : '아직 좋아요/싫어요를 누르지 않았어요.'}
        </div>
      )}
    </div>
  );
};

// my
const MyListWrite = () => {
  const [ref, inView] = useInView();

  const onMyWriteGet = async ({ pageParam, meta }) => {
    return await getWriteList({
      pageSize: POST_VIEW_COUNT,
      lastPostId:
        meta?.prefetch.lastPostId || pageParam === 0 ? undefined : pageParam,
    });
  };

  const params = { profileId: 'my-write' };

  const {
    data,
    isLoading,
    // isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useWritePostQuery({
    rowPerPage: POST_VIEW_COUNT,
    queryFn: onMyWriteGet,
    params: params,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: [writeQueryKey],
    mutationFn: ({ post, actionFunc }) => updateReactionFunc(post, actionFunc),
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([writeQueryKey, params], oldData => {
        const temp = { ...oldData };

        for (let page of temp.pages) {
          const idx = page.findIndex(item => item.post_id === data.post_id);

          if (idx !== -1) {
            page[idx] = data;
            break;
          }
        }

        return temp;
      });
    },
  });

  const updateReactionFunc = async (post, actionFunc) => {
    return await actionFunc(post);
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  //mounted
  useEffect(() => {}, []);

  return (
    <div>
      <PostList
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        postList={data?.pages}
        reloadRef={ref}
        mutate={mutate}
        isUseEmpty
        isProfile
      />
      {!isLoading && !data?.pages.length ? (
        <div css={styles.title}>작성한 게시글이 없어요.</div>
      ) : (
        ''
      )}
      {!isEmpty(data?.pages) && hasNextPage && !isFetchingNextPage && (
        <div
          css={css`
            min-height: ${toVW('30px')};
            width: 100%;
            background: 'none';
            color: #b1f54d;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        ></div>
      )}
    </div>
  );
};
const MyListComment = ({ userNm }) => {
  const [commentList, setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);

  const onMyCommentGet = async () => {
    setLoading(true);
    try {
      const myCommentData = await getCommentList();
      setLoading(false);
      setCommentList(myCommentData);
    } catch (error) {
      setLoading(false);
    }
  };

  //mounted
  useEffect(() => {
    onMyCommentGet();
  }, []);

  return (
    <div>
      {commentList.length > 0 ? (
        <div>
          {commentList.map((items, index) => {
            return (
              <div key={index}>
                <div
                  css={css`
                    border-bottom: ${toVW('6px')} solid #f5f5f5;
                  `}
                >
                  <ActivityHistory
                    data={{
                      title: items.TITLE,
                      label: `${userNm}님이 댓글을 남겼습니다`,
                      postContents: items.postContents,
                      comment: items.comments,
                      date: items.REG_DT,
                      postId: items.POST_ID,
                      active: items.active,
                    }}
                    type="comment"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div css={styles.title}>
          {loading ? '로딩중...' : '작성한 댓글이 없어요.'}
        </div>
      )}
    </div>
  );
};
const MyListReaction = ({ userNm }) => {
  const [reactionList, setReactionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const onMyReactionGet = async () => {
    setLoading(true);
    try {
      const myReactionData = await getReactionList();
      setReactionList(myReactionData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //mounted
  useEffect(() => {
    onMyReactionGet();
  }, []);

  return (
    <div>
      {reactionList.length > 0 ? (
        <div>
          {reactionList.map(items => {
            return (
              <div key={items.POST_ID}>
                <div
                  css={css`
                    border-bottom: ${toVW('6px')} solid #f5f5f5;
                  `}
                >
                  <ActivityHistory
                    data={{
                      title: items.TITLE,
                      label: `${userNm || '-'} 님이 이 ${contentType(
                        items.postLike,
                      )}을 ${
                        items.postLike.includes('Like') ? '좋아' : '싫어'
                      }합니다`,
                      postContents: items.postContents,
                      comment: items.comments,
                      date: items.REG_DT,
                      userName: items.USER_NM || '-',
                      chnlName: items.CHANNEL_NM,
                      postId: items.POST_ID,
                    }}
                    type={items.postLike}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div css={styles.title}>
          {loading ? '로딩중...' : '아직 좋아요/싫어요를 누르지 않았어요.'}
        </div>
      )}
    </div>
  );
};

const Activity = ({
  isProfileOfOthers,
  userId,
  tabFix,
  tabCorverStatus = true,
  userNm,
} = {}) => {
  const WriteComponent = () => {
    return isProfileOfOthers ? (
      <OtherListWrite userId={userId} userNm={userNm} />
    ) : (
      <MyListWrite userNm={userNm} />
    );
  };

  const CommentComponent = () => {
    return isProfileOfOthers ? (
      <OtherListComment userId={userId} userNm={userNm} />
    ) : (
      <MyListComment userNm={userNm} />
    );
  };

  const ReactionComponent = () => {
    return isProfileOfOthers ? (
      <OtherListReaction userId={userId} userNm={userNm} />
    ) : (
      <MyListReaction userNm={userNm} />
    );
  };

  // write = 게시글, comment = 댓글, reaction = 반응
  const [tabType, setTabType] = useState('write');

  const [tabUse] = useState(tabCorverStatus);
  const didMount = useRef(false);

  const routePush = path => {
    sessionStorage.setItem('activityTab', path);
  };

  useEffect(() => {
    if (didMount.current) {
      routePush(tabType);
    } else {
      didMount.current = true;
    }
  }, [tabType]);

  useEffect(() => {
    let session = sessionStorage.getItem('activityTab');
    if (tabFix) {
      setTabType(tabFix);
      routePush(tabFix);
      return;
    }

    if (!tabFix && session) {
      setTabType(session);
      routePush(session);
    }
  }, []);

  return (
    <div css={styles.wrapper}>
      {/* tabs */}
      <Tabs type={tabType} setType={setTabType} display={tabUse} />
      {tabType === 'write' && WriteComponent()}
      {tabType === 'comment' && CommentComponent()}
      {tabType === 'reaction' && ReactionComponent()}
    </div>
  );
};
export default Activity;
