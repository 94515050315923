import { css } from '@emotion/react';
import { Avatar, Chip } from '@mui/material';
import { Input as BaseInput, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';

import { useDispatch, useSelector } from 'react-redux';
import { updateReplyTargetComment } from '@store/app/postReducer';
import { isEmpty } from 'lodash';
import { callMTSFunction, getUploadImage } from '@lib/commonUtils';
import { CALL_MTS_ACTION } from '@data/constants';
import { useRef } from 'react';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { TextareaAutosize } from '@mui/base';
import { setBlockPopupIsShow } from '@store/app/commonReducer';

const Textarea = styled(TextareaAutosize)`
  flex: 1 0 0;
  height: ${stringToVW('24px')};
  padding: ${stringToVW('5px 0px 0px 0px')};
  width: 100%;
  border: none;
  outline: none;
  background: transparent;

  ${globalStyle.fontNanu19}
  font-weight: 400;

  resize: none;

  &::placeholder {
    color: #c5c5c5;
  }
  & .${inputClasses.input} {
  }
`;

const CommentInput = ({
  commentValue = '',
  handleOnChange = e => {},
  handleOnKeyDown = e => {},
}) => {
  const replyTargetComment = useSelector(
    state => state.post.replyTargetComment,
  );
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const dispatch = useDispatch();

  const ref = useRef(null);

  const styles = {
    wrapper: css`
      display: flex;
      padding: ${stringToVW('7.5px 16px 7.5px 7.5px')};
      gap: ${stringToVW('12px')};
      flex: 1 0 0;
      border-radius: ${stringToVW('20px')};
      background: var(--essential-line-line-200025, #f0f0f0);
      min-height: ${stringToVW('36.7px')};
    `,
  };

  const placeholder = isEmpty(replyTargetComment)
    ? '댓글을 입력해보세요!'
    : `${replyTargetComment.user_nm} 님에게 답글 남기는 중`;

  const handleOnFocus = e => {
    if (isEmpty(loginedUser)) {
      callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
      ref.current.blur();
    } else if (loginedUser.USER_STAT_CD === '1') {
      dispatch(setBlockPopupIsShow(true));
      ref.current.blur();
    }
  };

  return (
    <div css={[styles.wrapper]}>
      <Avatar
        sx={{ width: stringToVW('24px'), height: stringToVW('24px') }}
        src={
          !isEmpty(loginedUser)
            ? getUploadImage(loginedUser.profile_img)
            : undefined
        }
      />
      {!isEmpty(replyTargetComment) && (
        <Chip
          sx={{
            fontWeight: 600,
            fontSize: stringToVW('10px'),
            lineHeight: stringToVW('12px'),
          }}
          size="small"
          label={replyTargetComment.user_nm}
          avatar={
            <Avatar
              sx={{ bgcolor: 'white' }}
              src={getUploadImage(replyTargetComment.profile_img)}
            />
          }
          onDelete={() => {
            dispatch(updateReplyTargetComment(undefined));
          }}
        />
      )}
      <Textarea
        placeholder={placeholder}
        type="text"
        ref={ref}
        value={commentValue}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        maxRows={5}
        // slotProps={{
        //   input: {
        //   },`
        // }}
      />
    </div>
  );
};

export default CommentInput;
