import { Avatar } from '@mui/material';

const ProfileAvatar = ({ userInfo, width, height }) => {
  return (
    <Avatar
      alt={userInfo.userName}
      src={userInfo.filePath ? userInfo.filePath : undefined}
      sx={{ width: width, height: height }}
    ></Avatar>
  );
};

export default ProfileAvatar;
