/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import Avatar from '@mui/material/Avatar';
import IconClose from '@asset/icons/search/icon-close.svg';
import SelectPhoto from '@asset/images/profile/select-photo.svg';
import SelectPhoto2 from '@asset/images/profile/select-photo2.svg';
import Popup from '@component/app/common/Popup';
import { isEmpty } from 'lodash';
import { callMTSFunction, getUploadImage } from '@lib/commonUtils';
import VisuallyHiddenInput from '../common/VisuallyHiddenInput';
import { CALL_MTS_ACTION } from '@data/constants';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';

const styles = {
  wrapper: css`
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    margin: 0 auto;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  `,
  body: css`
    background: #ffffff;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    gap: ${stringToVW('20px')};
    border: 0px solid white;
    border-radius: ${stringToVW('16px 16px 0 0')};
  `,
  close: css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${stringToVW('53px')};
    height: ${stringToVW('53px')};
  `,
  closeBorder: css`
    position: absolute;
    top: ${stringToVW('-3px')};
    right: 0;
    left: ${stringToVW('-3px')};
    bottom: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${stringToVW('59px')};
    height: ${stringToVW('59px')};
    border: 2px solid #84c13d;
  `,
  title: css`
    color: #333;
    text-align: center;
    ${globalStyle.fontNanu26}
    font-weight: 400;
  `,
  label: css`
    color: var(--nm-default-2023, #333);
    ${globalStyle.fontNanu24}
    font-weight: 400;
    display: flex;
    align-items: center;
  `,
  btnPage: css`
    width: 100%;
    height: ${stringToVW('50px')};
    background: #84c13d;
    border: 0px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    margin-top: ${stringToVW('53.3px')};
    ${globalStyle.fontNanu26}
  `,
};
const SelectIcon = ({ type, select, children }) => {
  return (
    <>
      <div
        css={css`
          border-radius: 1000px;
          position: relative;
          box-sizing: border-box;
        `}
      >
        {children}
        {type === select ? (
          <>
            <div css={styles.close}>
              <svg
                width="1--%"
                height="1--%"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5859 18.2093L17.8016 25.8819L29.4095 14.1172"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div css={styles.closeBorder} />
          </>
        ) : null}
      </div>
    </>
  );
};
const PopupChangeAvatar = ({
  handleChangeAvatar,
  user,
  imagePush,
  // originImage,
  defaultImage,
}) => {
  const [isShow, setIsShow] = useState(false);

  const handleClick = boolean => {
    setIsShow(boolean);
  };

  const dataProps = {
    title: '카메라 접근 허용',
    content: [
      '촬영한 이미지를 사용하려면 설정에서 카메라 접근을 허용해주세요!',
    ],
    textBtnLeft: '취소',
    textBtnRight: '설정으로 이동',
  };

  const [sampleImage, setSampleImage] = useState(null);

  const [uploadImages, setUploadImages] = useState(null);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);

  const onChangeFiles = e => {
    const fileList = Array.from(e.target.files);
    if (!isEmpty(fileList)) {
      setUploadImages(fileList);
    }
  };

  const onClickCamera = async e => {
    try {
      cameraInputRef.current.click();
    } catch (err) {
      alert(err);
      callMTSFunction(CALL_MTS_ACTION.PERMIT_CAMERA);
    }
  };

  useEffect(() => {
    if (user !== 'company') return;
    if (uploadImages?.length > 0) {
      imagePush(uploadImages);
      handleChangeAvatar(false);
    }
  }, [uploadImages]);

  return (
    <div>
      <div
        css={styles.wrapper}
        onClick={event => {
          handleChangeAvatar(false);
        }}
      >
        <div
          css={styles.body}
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <div>
            <div
              css={css`
                height: ${stringToVW('70px')};
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #e0e0e0;
                align-items: center;
                padding: ${stringToVW('0 13.5px')};
              `}
            >
              <div />
              <div css={styles.title}>내 프로필</div>
              <img
                src={IconClose}
                alt=""
                onClick={event => {
                  handleChangeAvatar(false);
                }}
              />
            </div>
            <div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin: ${stringToVW('53.3px 0 32px 0')};
                `}
              >
                <Avatar
                  src={
                    (sampleImage && getUploadImage(sampleImage)) ||
                    (uploadImages?.[0] &&
                      URL?.createObjectURL(uploadImages?.[0])) ||
                    defaultImage
                  }
                  sx={{ width: 108, height: 108 }}
                />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: ${stringToVW('12px')};
                  margin: ${stringToVW('0px 40px')};
                `}
              >
                {/* <Avatar
                  src={SelectPhoto}
                  onClick={onClickCamera}
                  sx={{ width: 50, height: 49 }}
                /> */}
                {/* <VisuallyHiddenInput
                  type="file"
                  onChange={onChangeFiles}
                  accept="image/*"
                  capture="environment"
                  ref={cameraInputRef}
                /> */}
                {/* {user === 'general' ? ( */}
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: ${stringToVW('12px')};
                    padding: ${stringToVW('6.5px 0px')};
                    width: 100%;
                  `}
                >
                  <SelectIcon type="myprofile%2Fman1.png" select={sampleImage}>
                    <Avatar
                      src={getUploadImage('myprofile%2Fman1.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Fman1.png',
                        });
                        setSampleImage('myprofile%2Fman1.png');
                      }}
                    />
                  </SelectIcon>
                  <SelectIcon type="myprofile%2Fman2.png" select={sampleImage}>
                    <Avatar
                      src={getUploadImage('myprofile%2Fman2.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Fman2.png',
                        });
                        setSampleImage('myprofile%2Fman2.png');
                      }}
                    />
                  </SelectIcon>
                  <SelectIcon type="myprofile%2Fman3.png" select={sampleImage}>
                    <Avatar
                      src={getUploadImage('myprofile%2Fman3.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Fman3.png',
                        });
                        setSampleImage('myprofile%2Fman3.png');
                      }}
                    />
                  </SelectIcon>
                  <SelectIcon type="myprofile%2Fman4.png" select={sampleImage}>
                    <Avatar
                      src={getUploadImage('myprofile%2Fman4.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Fman4.png',
                        });
                        setSampleImage('myprofile%2Fman4.png');
                      }}
                    />
                  </SelectIcon>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: ${stringToVW('12px')};
                  margin: ${stringToVW('24px 40px 0px 40px')};
                `}
              >
                {/* {user === 'general' ? ( */}
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: ${stringToVW('12px')};
                    padding: ${stringToVW('6.5px 0px')};
                    width: 100%;
                  `}
                >
                  <SelectIcon
                    type="myprofile%2Ffemale1.png"
                    select={sampleImage}
                  >
                    <Avatar
                      src={getUploadImage('myprofile%2Ffemale1.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Ffemale1.png',
                        });
                        setSampleImage('myprofile%2Ffemale1.png');
                      }}
                    />
                  </SelectIcon>
                  <SelectIcon
                    type="myprofile%2Ffemale2.png"
                    select={sampleImage}
                  >
                    <Avatar
                      src={getUploadImage('myprofile%2Ffemale2.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Ffemale2.png',
                        });
                        setSampleImage('myprofile%2Ffemale2.png');
                      }}
                    />
                  </SelectIcon>
                  <SelectIcon
                    type="myprofile%2Ffemale3.png"
                    select={sampleImage}
                  >
                    <Avatar
                      src={getUploadImage('myprofile%2Ffemale3.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Ffemale3.png',
                        });
                        setSampleImage('myprofile%2Ffemale3.png');
                      }}
                    />
                  </SelectIcon>
                  <SelectIcon
                    type="myprofile%2Ffemale4.png"
                    select={sampleImage}
                  >
                    <Avatar
                      src={getUploadImage('myprofile%2Ffemale4.png')}
                      sx={{ width: toVW('53px'), height: toVW('53px') }}
                      onClick={() => {
                        // imagePush({
                        //   type: 'avatar',
                        //   url: 'myprofile%2Ffemale4.png',
                        // });
                        setSampleImage('myprofile%2Ffemale4.png');
                        setUploadImages({
                          type: 'avatar',
                          url: 'myprofile%2Ffemale4.png',
                        });
                      }}
                    />
                  </SelectIcon>
                </div>
              </div>
              <div
                css={css`
                  margin: ${stringToVW('16px 40px')};
                  border-bottom: 1px solid #f0f0f0;
                `}
              />
              <div
                css={css`
                  width: 100%;
                  margin: auto;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  ${globalStyle.fontNanu21}
                `}
                onClick={() => fileInputRef.current.click()}
              >
                <img
                  src={SelectPhoto2}
                  alt="SelectPhoto2"
                  style={{
                    width: stringToVW('16px'),
                    height: stringToVW('16px'),
                    marginRight: stringToVW('8px'),
                  }}
                />
                앨범에서 사진 선택
                <input
                  type="file"
                  name="files"
                  onChange={onChangeFiles}
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
              </div>
            </div>
          </div>
          <div
            css={styles.btnPage}
            onClick={() => {
              if (uploadImages.length > 0 || uploadImages?.type === 'avatar') {
                imagePush(uploadImages);
              }
              handleChangeAvatar(false);
            }}
          >
            완료
          </div>
          {/* ) : null} */}
        </div>
      </div>
      {isShow && <Popup data={dataProps} handleClick={handleClick} />}
    </div>
  );
};

export default PopupChangeAvatar;
