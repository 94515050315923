import PostItem from '@component/app/post/PostItem';
import CommentInputArea from '@component/app/comment/CommentInputArea';
import PostDetailFooter from '@component/app/post/PostDetailFooter';
import PostCommentList from '@component/app/post/PostCommentList';
import PostDetailHeader from '@component/app/post/PostDetailHeader';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  updatePostItem,
  updateReplyTargetComment,
} from '@store/app/postReducer';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { getPostDetail } from '@lib/api-call/app/loungeApi';
import { isEmpty } from 'lodash';
import { getStockHoldingDate } from '@lib/dateTimeUtils';
import { roundStockQty } from '@lib/commonUtils';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';

const PostDetail = () => {
  const { postId } = useParams();
  const { state: routerState } = useLocation();
  const postItem = useSelector(state => state.post.postItem);
  const commentInputHeight = useSelector(
    state => state.post.commentInputHeight,
  );
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const dispatch = useDispatch();

  const styles = {
    stockInfo: css`
      display: flex;
      height: ${stringToVW('32px')};
      padding: ${stringToVW('0px 24px')};
      justify-content: flex-end;
      align-items: center;
      gap: ${stringToVW('12px')};
      align-self: stretch;
      background: #f5f5f5;
    `,
    period: css`
      display: flex;
      height: ${stringToVW('40px')};
      padding: 0px;
      justify-content: flex-end;
      align-items: center;
      gap: 0px;
      color: #7d7d7d;

      & > :nth-child(1) {
        ${globalStyle.fontRobo18}
      }
      & > :nth-child(2) {
        ${globalStyle.fontNanu18}
        font-weight: 400;
      }
    `,
    total: css`
      display: flex;
      padding: 0px;
      justify-content: flex-end;
      align-items: center;
      gap: ${stringToVW('3px')};
      align-self: stretch;
      color: #3c9800;

      & > :nth-child(1) {
        ${globalStyle.fontRobo18}
      }
      & > :nth-child(2) {
        ${globalStyle.fontNanu18}
      }
    `,
  };

  const fetchingPostItem = async () => {
    try {
      const postDetail = await getPostDetail(postId);

      if (postDetail) {
        dispatch(updatePostItem(postDetail));
      } else {
        dispatch(updatePostItem({}));
        dispatch(
          setAlertInfo({ severity: 'error', msg: '존재하지 않는 글입니다.' }),
        );
        dispatch(openSnackbar());
      }
    } catch (e) {
      dispatch(updatePostItem({}));
      dispatch(
        setAlertInfo({
          severity: 'error',
          msg: '게시글 정보를 받아오는 도중 오류가 발생했습니다.',
        }),
      );
      dispatch(openSnackbar());
    }
  };

  const getStockQty = () => {
    const { stockQty, stockPostFix } = roundStockQty(postItem.stock_qty);

    return (
      <div css={styles.total}>
        <div>{stockQty}</div>
        <div>{stockPostFix}</div>
      </div>
    );
  };

  useEffect(() => {
    fetchingPostItem();
  }, [loginedUser]);

  useEffect(() => {
    // fetchingPostItem();

    return () => {
      dispatch(updatePostItem(undefined));
      dispatch(updateReplyTargetComment(undefined));
    };
  }, []);

  return (
    postItem !== undefined &&
    (isEmpty(postItem) || isEmpty(postItem.post_id) ? (
      <Navigate
        to={(routerState && routerState.prevLink) || '/'}
        replace={true}
      />
    ) : (
      <div
      //  style={{ paddingBottom: commentInputHeight }}
      >
        <PostDetailHeader
          item={postItem}
          reportData={{
            report_type: '0',
            user_id: encodeURIComponent(postItem.user_id),
            post_id: postItem.post_id,
          }}
        />
        {postItem.user_type !== '1' &&
          postItem.stock_qty &&
          postItem.stock_holding_dt && (
            <div css={styles.stockInfo}>
              {postItem.stock_holding_dt !== '0' && (
                <>
                  <div css={styles.period}>
                    <div>{getStockHoldingDate(postItem.stock_holding_dt)}</div>
                    <div>일째 보유 중</div>
                  </div>
                  <div
                    css={css`
                      width: 1.5px;
                      height: var(--size-h-16, 16px);
                      background: var(--essential-line-line-300017, #e0e0e0);
                    `}
                  />
                </>
              )}
              {getStockQty()}
            </div>
          )}
        <PostItem item={postItem} isDetail />
        <PostDetailFooter item={postItem} />
        <PostCommentList item={postItem} />
        <CommentInputArea />
      </div>
    ))
  );
};

export default PostDetail;
