import React from 'react';
import NdsIconTerms from '@asset/icons/profile/nds-icon-terms.svg';
import NdsIconMenu from '@asset/icons/profile/icon_menu.svg';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import HeaderBasic from '@component/app/common/HeaderBasic';
import { globalStyle, ratio, stringToVW } from '@style/globalStyle';

const Settings = () => {
  const styles = {
    wrapper: css`
      font-style: normal;
    `,
    termItem: css`
      display: flex;
      gap: ${stringToVW('16px')};
      align-items: center;
      padding: ${stringToVW('21px 24px')};
      text-decoration: none;
    `,
    label: css`
      color: #000;
      ${globalStyle.fontNanu21}
      font-weight: 400;
    `,
    labelWithdraw: css`
      color: #000;
      ${globalStyle.fontNanu21}
      font-weight: 400;
      width: 100%;
    `,
  };

  return (
    <div css={styles.wrapper}>
      <HeaderBasic title="커뮤니티 이용안내" statusUser="" />
      <NavLink
        to="/profile/settings/terms"
        css={styles.termItem}
        style={{ borderBottom: '1px solid #F0F0F0' }}
      >
        <img src={NdsIconTerms} alt="" width={15 * ratio} />
        <div css={styles.label}>서비스 약관</div>
      </NavLink>
      <NavLink
        to="/profile/settings/rules"
        css={styles.termItem}
        style={{ borderBottom: '1px solid #F0F0F0' }}
      >
        <img src={NdsIconTerms} alt="" width={15 * ratio} />
        <div css={styles.label}>서비스 이용규칙</div>
      </NavLink>
      <NavLink
        to="/profile/settings/faq"
        css={styles.termItem}
        style={{ borderBottom: '1px solid #F0F0F0' }}
      >
        <img src={NdsIconTerms} alt="" width={15 * ratio} />
        <div css={styles.label}>커뮤니티 서비스 FAQ</div>
      </NavLink>
      <NavLink to="/profile/settings/withdraw" css={styles.termItem}>
        <img src={NdsIconMenu} alt="" width={20 * ratio} />
        <div css={styles.labelWithdraw}>서비스 해지</div>
      </NavLink>
    </div>
  );
};

export default Settings;
