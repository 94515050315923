/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import AchievementItem from '@page/app/profile/AchievementItem';
import { getBadgeList, getEditBadgeOpenYn } from '@lib/api-call/app/profileApi';
import _, { isEmpty } from 'lodash';
import { getBadgeNoneLocation, getUploadImage } from '@lib/commonUtils';
import { globalStyle, stringToVW } from '@style/globalStyle';

const country = {
  BAT001000: '한국',
  BAT001001: '미국',
  BAT001002: '일본',
  BAT001003: '중국',
  BAT001004: '홍콩',
  BAT001005: '영국',
  BAT001006: '베트남',
  BAT001007: '독일',
  BAT001008: '인도네시아',
};
const Header = display => {
  return (
    !display && (
      <Box sx={{ flexGrow: 1 }}>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: end;
            width: 100%;
            height: ${stringToVW('62px')};
            background: #f7f7f7;
            margin: 0;
            padding: ${stringToVW('0 24px')};
          `}
        >
          <div
            css={css`
              ${globalStyle.fontNanu18}
              font-weight: 400;
              letter-spacing: 0px;
              text-align: right;
              color: #000000;
              display: flex;
              gap: ${stringToVW('10px')};
              align-items: center;
              justify-content: end;
            `}
          >
            공개
            <button
              css={css`
                background: #666666;
                width: ${stringToVW('32px')};
                border: none;
                color: white;
                height: ${stringToVW('20px')};
                padding: ${stringToVW('4px 6px')};
                border-radius: ${stringToVW('6px')};
                ${globalStyle.fontNanu15}
                font-weight: 600;
              `}
            >
              OFF
            </button>
          </div>
        </div>
      </Box>
    )
  );
};

const PublicBox = ({ type, updateProfile }) => {
  const onChangeOpenYn = async () => {
    const changeData = await getEditBadgeOpenYn();

    if (changeData.message_cd !== '0') return;
    updateProfile();
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: end;
          width: 100%;
          height: ${stringToVW('46.7px')};
          background: #f7f7f7;
          margin: 0;
          padding: ${stringToVW('0px 24px')};
        `}
      >
        <div
          css={css`
            ${globalStyle.fontNanu18}
            font-weight: 400;
            letter-spacing: 0px;
            text-align: right;
            color: #000000;
            display: flex;
            gap: ${stringToVW('10px')};
            align-items: center;
            justify-content: end;
          `}
        >
          공개
          <button
            css={css`
              ${globalStyle.fontNanu18}
              background: #666666;
              width: ${stringToVW('32px')};
              border: none;
              color: white;
              height: ${stringToVW('20px')};
              padding: ${stringToVW('3px')};
              border-radius: ${stringToVW('6px')};
              font-weight: 600;
              ${type === 'Y' && `background: #84c13d;`}
            `}
            onClick={onChangeOpenYn}
            style={{}}
          >
            {type === 'Y' ? 'ON' : 'OFF'}
          </button>
        </div>
      </div>
    </Box>
  );
};

const ActivityBadge = ({
  isProfileOfOthers,
  userId,
  badgeOpenYn,
  updateProfile,
  badgeYn,
}) => {
  const [display] = useState(!isProfileOfOthers);

  const [masterData, setMasterData] = useState({
    title: '내가 고수',
    total: function () {
      return this.data.reduce((total, item) => total + item.total || 0, 0);
    },
    data: [],
  });

  const [columbusData, setColumbusData] = useState({
    title: '콜럼버스',
    total: function () {
      return this.data.reduce((total, item) => total + item.total || 0, 0);
    },
    data: [],
  });

  const setBadgeDataImage = dataList => {
    if (dataList && Array.isArray(dataList)) {
      dataList.forEach(item => {
        if (item.badgeCd === 'BAT000100') {
          // In case 다양한 만남
          const imgURL =
            item?.list?.data?.length >= 3
              ? item.list.data[0]?.ACD_DESC.replace('100', '102')
              : item?.list?.data?.length === 2
              ? item.list.data[0]?.ACD_DESC.replace('100', '101')
              : item.list.data[0]?.ACD_DESC;
          item.image = getUploadImage(
            !isEmpty(item?.list?.data)
              ? imgURL
              : getBadgeNoneLocation(item.badgeCd),
          );
        } else if (item.badgeCd === 'BAT000000') {
          // In case 월간 순위
          const imgURL3star = (item?.list?.data ?? []).find(
            badge => badge.BADGE_CD === 'BAT000000',
          )?.ACD_DESC;
          const imgURL2star = (item?.list?.data ?? []).find(
            badge => badge.BADGE_CD === 'BAT000001',
          )?.ACD_DESC;
          const imgURL1star = (item?.list?.data ?? []).find(
            badge => badge.BADGE_CD === 'BAT000002',
          )?.ACD_DESC;
          const imgURL = imgURL3star ?? imgURL2star ?? imgURL1star;
          item.image = getUploadImage(
            !isEmpty(item?.list?.data)
              ? imgURL
              : getBadgeNoneLocation(item.badgeCd),
          );
        } else if (item.badgeCd === 'BAT000005') {
          // In case 월간 수익률
          const imgURL3star = (item?.list?.data ?? []).find(
            badge => badge.BADGE_CD === 'BAT000005',
          )?.ACD_DESC;
          const imgURL2star = (item?.list?.data ?? []).find(
            badge => badge.BADGE_CD === 'BAT000006',
          )?.ACD_DESC;
          const imgURL1star = (item?.list?.data ?? []).find(
            badge => badge.BADGE_CD === 'BAT000007',
          )?.ACD_DESC;
          const imgURL = imgURL3star ?? imgURL2star ?? imgURL1star;
          item.image = getUploadImage(
            !isEmpty(item?.list?.data)
              ? imgURL
              : getBadgeNoneLocation(item.badgeCd),
          );
        } else {
          item.image = getUploadImage(
            !isEmpty(item?.list?.data)
              ? item.list.data[0]?.ACD_DESC
              : getBadgeNoneLocation(item.badgeCd),
          );
        }
      });
    }
  };

  const onBadgeListGet = async () => {
    const badgeListData = await getBadgeList({
      profileId: userId,
      // badge_open_yn: 'N',
    });
    // if (badgeListData.message_cd !== '0') return;

    const newMasterData = [
      {
        title: '월간 순위',
        badgeCd: 'BAT000000',
        list: { data: badgeListData.group1 },
        get total() {
          return this.list.data?.length || 0;
        },
      },
      {
        title: '월간 수익률',
        badgeCd: 'BAT000005',
        list: { data: badgeListData.group2 },
        get total() {
          return this.list.data?.length || 0;
        },
      },
      {
        title: '떡상',
        badgeCd: 'BAT000010',
        get list() {
          return {
            data: [
              ...(badgeListData?.group0 || []).filter(
                x => x.BADGE_CD === this.badgeCd,
              ),
            ],
          };
        },
        get total() {
          return this.list.data?.length || 0;
        },
      },
    ];

    setBadgeDataImage(newMasterData);

    setMasterData({
      ...masterData,
      data: newMasterData,
    });

    const mappedCountry = _.map(country, (title, badgeCd) => {
      return {
        title,
        badgeCd,
        list: {
          data: [
            ...(badgeListData.group0 || []).filter(x => x.BADGE_CD === badgeCd),
          ],
        },
        get total() {
          return this.list.data?.length || 0;
        },
      };
    });

    const newColumbusData = [
      {
        title: '다양한 만남',
        badgeCd: 'BAT000100',
        list: {
          // sort message for badge 다양한 만남
          data: [...(badgeListData?.group3 || [])].sort((a, b) => {
            return a.BADGE_CD.localeCompare(b.BADGE_CD);
          }),
        },
      },
      ...mappedCountry,
    ];

    setBadgeDataImage(newColumbusData);

    setColumbusData({
      ...columbusData,
      data: newColumbusData,
    });
  };

  // mounted
  useEffect(() => {
    onBadgeListGet();
  }, []);

  return (
    <div>
      <div css={css``}>
        <div css={css``}>
          <Header display={display} />
        </div>
        {display && (
          <PublicBox type={badgeOpenYn} updateProfile={updateProfile} />
        )}
        <div
          css={css`
            padding-bottom: ${stringToVW('44px')};
          `}
        >
          {isProfileOfOthers && badgeYn === 'N' ? (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${stringToVW('120px')};
              `}
            >
              배지를 공개하지 않았어요.
            </div>
          ) : (
            <>
              <AchievementItem dataProps={masterData} />
              <AchievementItem dataProps={columbusData} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityBadge;
