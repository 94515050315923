import React from 'react';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import CommentWrapper from './CommentWrapper';

import { globalStyle, stringToVW } from '@style/globalStyle';
import { useSelector } from 'react-redux';

function PostCommentList({ item }) {
  const { postCommentList, countComment } = item;
  const commentInputHeight = useSelector(
    state => state.post.commentInputHeight,
  );

  const styles = {
    commentHeader: css`
      display: flex;
      padding: ${stringToVW('16px 16px 5.5px 16px')};
      align-items: center;
      gap: ${stringToVW('6px')};
      align-self: stretch;

      color: #000;

      & > :nth-child(1) {
        ${globalStyle.fontNanu21}
        font-weight: 600;
      }

      & > :nth-child(2) {
        flex: 1 0 0;
        ${globalStyle.fontRobo21}
      }
    `,
  };

  return (
    <div style={{ marginBottom: commentInputHeight }}>
      <div css={styles.commentHeader}>
        <div>댓글</div>
        <div>{countComment}</div>
      </div>
      <div>
        {!isEmpty(postCommentList) &&
          postCommentList.map(item => (
            <CommentWrapper key={`comment_${item.comment_id}`} item={item} />
          ))}
      </div>
    </div>
  );
}

PostCommentList.propTypes = {};

export default PostCommentList;
