import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  useLocation,
} from 'react-router-dom';
import AppLayout from '@page/layouts/AppLayout';
import AdminLayout from '@page/layouts/AdminLayout';

import Home from '@page/app/Home';
import ErrorPage from '@page/app/ErrorPage';
import WritePost from '@page/app/post/WritePost';
import PostDetail from '@page/app/post/PostDetail';
import SearchPage from '@page/app/search/SearchPage';
import SearchResult from '@page/app/search/SearchResult';
import NotificationPage from '@page/app/notification/NotificationPage';
import UserManage from '@page/admin/user/UserManage';
import ReportManage from '@page/admin/report/ReportManage';
import Login from '@page/admin/Login';
import PostManage from '@page/admin/post/PostManage';
import ChannelManage from '@page/admin/channel/ChannelManage';
import UserDetail from '@page/admin/user/UserDetail';
import PostContent from '@page/admin/user/PostContent';
import PostManageDetail from '@page/admin/post/PostManageDetail';
import ReportManageDetail from '@page/admin/report/ReportManageDetail';
import StockContent from '@page/admin/user/StockContent';
import NotificationContact from '@page/app/notification/NotificationContact';
import MyProfile from '@page/app/profile/MyProfile';
import PeopleProfile from '@page/app/profile/PeopleProfile';
import ListFollow from '@page/app/profile/ListFollow';
import EditProfile from '@page/app/profile/EditProfile';
import Settings from '@page/app/profile/Settings';
import SettingContent from '@page/app/profile/SettingContent';
import Report from '@page/app/profile/Report';
import ChannelDetail from '@page/app/channel/ChannelDetail';
import Subscribe from '@page/app/subscribe/Subscribe';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Channel from '@page/app/subscribe/Channel';
import { callMTSFunction } from '@lib/commonUtils';
import {
  CALL_MTS_ACTION,
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
} from '@data/constants';
import { isEmpty } from 'lodash';
import { updateAfterAuthMovePage } from '@store/app/authReducer';
import { getCookie } from '@lib/cookieUtils';
import ReactionListPage from '@page/app/post/ReactionListPage';
import GA from '@lib/GoogleAnalyticsBuilder'; // 지우지 말 것

/* 관리자 관리 2024.07.22 석진규 추가 */
import AdminManage from "@page/admin/AdminManage";
import AdminDetail from "@page/admin/AdminDetail";



const AdminGuard = ({ children }) => {
  const adminLogin = useSelector(state => state.admin.loginedAdmin);
  const accessToken = getCookie('admin_access_token');
  const refreshToken = getCookie('admin_refresh_token');
  // const adminId = getCookie('admin_id');
  if (!adminLogin && !accessToken && !refreshToken) {
    return <Navigate to="/admin" />;
  }
  return children;
};

const AdminLoginGuard = ({ children }) => {
  const adminLogin = useSelector(state => state.admin.loginedAdmin);
  const accessToken = getCookie('admin_access_token');
  const refreshToken = getCookie('admin_refresh_token');
  // const adminId = getCookie('admin_id');

  if (adminLogin || (accessToken && refreshToken)) {
    return <Navigate to="/admin/user-manage" />;
  }
  return children;
};

const AppPrivateRoute = ({ children }) => {
  const accessToken = getCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
  const refreshToken = getCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const location = useLocation();

  // location.search를 통해 현재 페이지의 쿼리 매개변수를 가져옴
  const queryParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const { pathname, state: routerState, search: routerSearch } = useLocation();

  useEffect(() => {
    if (isEmpty(accessToken) && isEmpty(refreshToken) && isEmpty(loginedUser)) {
      dispatch(updateAfterAuthMovePage(pathname + routerSearch));
      if (queryParams.get('check_user') !== 'Y') {
        callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
      }
    }
  }, []);

  return isEmpty(accessToken) && isEmpty(refreshToken) && isEmpty(loginedUser)
    ? (() => {
        if (queryParams.get('check_user') === 'Y') {
          return children;
        }
        return <Navigate to="/" />;
      })()
    : children;
};

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        element: <AppLayout hasNavbar />,
        children: [
          { path: '/search', element: <SearchPage /> },
          { path: '/search/result', element: <SearchResult /> },
          {
            path: '/notification',
            element: (
              <AppPrivateRoute>
                <NotificationPage />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/my-profile',
            element: (
              <AppPrivateRoute>
                <MyProfile />
              </AppPrivateRoute>
            ),
          },
          // { path: '/people-profile/:userId', element: <PeopleProfile /> },
        ],
      },
      {
        element: <AppLayout />,
        children: [
          {
            path: '/500',
            element: <ErrorPage />,
          },
          // {
          //   path: '/slide/channel/:stockCd/:channelId',
          //   element: <ChannelDetail isSlide />,
          // },
          {
            path: '/slide/post/:postId',
            element: <PostDetail isSlide />,
          },
          {
            path: '/write-post',
            element: (
              <AppPrivateRoute>
                <WritePost />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/post/:postId',
            element: <PostDetail />,
          },
          {
            path: '/post/:postId/like',
            element: <ReactionListPage reactionType="like" />,
          },
          {
            path: '/post/:postId/dislike',
            element: <ReactionListPage reactionType="dislike" />,
          },
          {
            path: '/notification/contact/:notiId',
            element: <NotificationContact />,
          },

          {
            path: '/profile/:userId',
            element: (
              <AppPrivateRoute>
                <PeopleProfile />
              </AppPrivateRoute>
            ),
          },
          { path: '/profile/follow', element: <ListFollow /> },
          {
            path: '/profile/edit',
            element: (
              <AppPrivateRoute>
                <EditProfile />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/profile/settings',
            element: (
              <AppPrivateRoute>
                <Settings />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/profile/settings/:contentName',
            element: (
              <AppPrivateRoute>
                <SettingContent />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/report',
            element: (
              <AppPrivateRoute>
                <Report />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/subscribe/channel/:userId',
            element: (
              <AppPrivateRoute>
                <Channel />
              </AppPrivateRoute>
            ),
          },
          {
            path: '/update-post',
            element: (
              <AppPrivateRoute>
                <WritePost isUpdate />
              </AppPrivateRoute>
            ),
          },
        ],
      },
      {
        /**
         * 플로팅버튼 필요한 화면
         * - 홈(라운지)
         * - 채널 상세
         * - 구독채널 홈 (채널상세)
         * - 마이프로필
         */
        element: <AppLayout hasNavbar hasFloatingButton />,
        children: [
          {
            path: '',
            element: <Home />,
          },

          {
            path: '/subscribe',
            element: (
              <AppPrivateRoute>
                <Subscribe />
              </AppPrivateRoute>
            ),
          },
          // {
          //   path: '/my-profile',
          //   element: (
          //     <AppPrivateRoute>
          //       <MyProfile />
          //     </AppPrivateRoute>
          //   ),
          // },
        ],
      },
      {
        element: <AppLayout hasFloatingButton />,
        children: [
          { path: '/channel/:stockCd/:channelId', element: <ChannelDetail /> },
          {
            path: '/slide/channel/:stockCd/:channelId',
            element: <ChannelDetail isSlide />,
          },
        ],
      },
      {
        // 존재하지않는 경로로 이동되었을 때
        children: [
          {
            path: '*',
            element: <ErrorPage />,
          },
        ],
      },
    ],
  },
  // admin
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        path: '',
        element: (
          <AdminLoginGuard>
            <Login />
          </AdminLoginGuard>
        ),
      },
      {
        path: '/admin/user-manage',
        element: (
          <AdminGuard>
            <UserManage />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/user-detail/:userId',
        element: (
          <AdminGuard>
            <UserDetail />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/user-detail/post-content/:userId',
        element: (
          <AdminGuard>
            <PostContent />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/user-detail/stock-content/:userId',
        element: (
          <AdminGuard>
            <StockContent />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/post-manage',
        element: (
          <AdminGuard>
            <PostManage />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/post-manage/post-detail/:postId',
        element: (
          <AdminGuard>
            <PostManageDetail />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/report-manage',

        element: (
          <AdminGuard>
            <ReportManage />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/report-manage/report-detail/:userId',
        element: (
          <AdminGuard>
            <ReportManageDetail />
          </AdminGuard>
        ),
      },
      {
        path: '/admin/channel-manage',
        element: (
          <AdminGuard>
            <ChannelManage />
          </AdminGuard>
        ),
      },
      /* 관리자 관리 2024.07.22 석진규 추가 */
      {
        path: "/admin/admin-manage",
        element: (
          <AdminGuard>
            <AdminManage />
          </AdminGuard>
        ),
      },
      {
        path: "/admin/admin-manage/regist",
        element: (
          <AdminGuard>
            <AdminDetail />
          </AdminGuard>
        ),
      },
      {
        path: "/admin/admin-manage/detail/:adminId",
        element: (
          <AdminGuard>
            <AdminDetail />
          </AdminGuard>
        ),
      },
      /* 관리자 관리 2024.07.22 석진규 추가 */
    ],
  },
]);

const App = () => {
  return (
    <div id="customBody">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
