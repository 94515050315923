import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './font.css';

import store from '@store/store';
import theme from '@style/theme';
import App from './App';

import { Settings } from 'luxon';
import { CookiesProvider } from 'react-cookie';

Settings.defaultLocale = 'ko_KR';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </CookiesProvider>
    </ThemeProvider>
  </Provider>,
);
