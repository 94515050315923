/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getEditInvestingOpenYn,
  getStockList,
} from '@lib/api-call/app/profileApi';
import { toNumber } from 'lodash';
import channelDefaultImgKOR from '@asset/images/channel/channel-default-image-KOR.png';
import channelDefaultImgENG from '@asset/images/channel/channel-default-image-ENG.png';
import channelDefaultImg from '@asset/images/channel/channel-default-image.png';
import dayjs from 'dayjs';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { getUploadImage } from '@lib/commonUtils';
import SvgIcon from '@mui/material/SvgIcon';

const styles = {
  container: css`
    padding: ${stringToVW('0px 24px')};
    height: ${stringToVW('46.7px')};
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${stringToVW('20px')};
    background: #f7f7f7;
  `,
  option: css`
    display: flex;
    height: ${stringToVW('32px')};
    justify-content: space-between;
    align-items: center;
  `,

  stock: css`
    ${globalStyle.fontNanu21}
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  `,
  stockName: css`
    color: #000;
    ${globalStyle.fontNanu21}
    font-weight: 600;
  `,
  total: css`
    color: #666;
    ${globalStyle.fontNanu19}
    font-weight: 500;
  `,
  unit: css`
    color: #666;
    ${globalStyle.fontNanu18}
    font-weight: 400;
    margin-top: ${stringToVW('-2px')};
  `,
};

function addCommasToNumberWithDecimal(number) {
  const parsedNumber = toNumber(number);
  const formatNumber = parsedNumber.toLocaleString();
  if (Number(number) >= 1) {
    return `${formatNumber} 주`;
  } else {
    return '1주 미만';
  }
}

function isDateMoreThanOneDayAgo(dateString) {
  console.log('가입일 : ', dateString);
  const givenDate = dayjs(dateString);
  const currentDate = dayjs();

  const differenceInDays = currentDate.diff(givenDate, 'day');

  // 현재 날짜보다 1일 이상 지났으면 true, 아니면 false
  return differenceInDays >= 1;
}

const countryChnlImage = country => {
  if (country === 'KOR') return channelDefaultImgKOR;
  else if (country === 'USA') return channelDefaultImgENG;
  else return channelDefaultImg;
};

const OrderBox = ({
  display,
  type,
  setType,
  investingOpenYn,
  investingChange,
}) => {
  const [sortOptions, setSortOptions] = useState([
    { text: '보유수량순', value: 'qty', active: false },
    { text: '가나다순', value: 'abc', active: false },
  ]);

  useEffect(() => {
    if (!type) return;
    let check = sortOptions.map(x => {
      if (x.value === type) x.active = true;
      else x.active = false;
      return x;
    });
    setSortOptions(check);
  }, [type]);

  return (
    display && (
      <div
        css={styles.container}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div css={styles.option}>
          <div css={globalStyle.sortBtnGroup}>
            {sortOptions.map((item, index) => {
              const compose =
                index !== 0
                  ? [
                      globalStyle.sortBtn,
                      css`
                        padding-right: ${stringToVW('14.7px')};
                      `,
                    ]
                  : [globalStyle.sortBtn];
              if (item.active) compose.push(globalStyle.sortActive);
              return (
                <div
                  key={item.value}
                  css={compose}
                  onClick={() => {
                    setType(item.value);
                  }}
                >
                  {item.text}
                </div>
              );
            })}
          </div>
        </div>
        {investingOpenYn && (
          <div css={styles.option}>
            <div
              css={css`
                ${globalStyle.fontNanu18}
                font-weight: 400;
                letter-spacing: 0px;
                text-align: right;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: end;
                gap: ${stringToVW('10px')};
              `}
            >
              공개
              <button
                css={css`
                  background: #666666;
                  width: ${stringToVW('32px')};
                  border: none;
                  color: white;
                  height: ${stringToVW('20px')};
                  padding: ${stringToVW('3px')};
                  border-radius: ${stringToVW('6px')};
                  ${globalStyle.fontNanu18}
                  font-weight: 600;
                  ${investingOpenYn === 'Y' && `background: #84c13d;`}
                `}
                onClick={investingChange}
                style={{}}
              >
                {investingOpenYn === 'Y' ? 'ON' : 'OFF'}
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
};
const OtherStock = ({ userId, stockYn, investingOpenYn }) => {
  const [stockList, setStockList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // qty = 보유주식순, abc = 가나다 순
  const [listSorting, setlistSorting] = useState('qty');

  const onOtherStockGet = async () => {
    // stockYn true 면 공개허용한 유저
    if (!userId || !stockYn || investingOpenYn !== 'Y') return;
    setLoading(true);
    try {
      const otherStockData = await getStockList({
        profileId: userId,
        orderBy: listSorting,
      });
      setLoading(false);
      setStockList(otherStockData);
    } catch (error) {
      setLoading(false);
    }
  };

  // mounted
  useEffect(() => {
    onOtherStockGet();
  }, [listSorting, stockYn]);

  return (
    <>
      <OrderBox
        display={stockYn || investingOpenYn === 'Y'}
        type={listSorting}
        setType={setlistSorting}
        my={false}
      />
      <div>
        <div
          css={css`
            min-height: 100%;
            padding: ${stringToVW('8px 16px')};
          `}
        >
          {stockList.length > 0 &&
            stockList?.map((item, index) => (
              <div
                css={css`
                  height: ${stringToVW('66px')};
                  display: flex;
                  align-items: center;
                  gap: ${stringToVW('16px')};
                `}
                key={index}
                onClick={() => {
                  navigate(`/channel/${encodeURIComponent(item.STOCK_CD)}/0`, {
                    state: { prevLink: pathname },
                  });
                }}
              >
                <div css={css``}>
                  <img
                    css={css`
                      width: ${stringToVW('40px')};
                      height: ${stringToVW('40px')};
                      border-radius: 50%;
                    `}
                    src={
                      getUploadImage(item?.THUMB_URL) ||
                      countryChnlImage(item?.COUNTRY_CD)
                    }
                    alt=""
                    onError={e => {
                      e.target.src = countryChnlImage(item?.COUNTRY_CD);
                    }}
                  />
                </div>
                <div css={styles.stock}>
                  <div css={styles.stockName}>{item.STOCK_NM}</div>
                  <div css={styles.total}>
                    {addCommasToNumberWithDecimal(item.STOCK_QTY)}&nbsp;
                    {/* <span css={styles.unit}>주</span> */}
                  </div>
                </div>
              </div>
            ))}
          {stockList.length === 0 && stockYn && investingOpenYn === 'Y' && (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${stringToVW('120px')};
              `}
            >
              {loading ? '로딩중...' : '현재 투자 중인 종목이 없어요.'}
            </div>
          )}
          {(!stockYn || investingOpenYn !== 'Y') && !loading && (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${stringToVW('120px')};
              `}
            >
              보유종목을 공개하지 않았어요.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const MyStock = ({ stockYn, userJoinDate, investingOpenYn, updateProfile }) => {
  const [stockList, setStockList] = useState([]);
  const [loading, setLoading] = useState(true);
  // qty = 보유주식순, abc = 가나다 순
  const [listSorting, setlistSorting] = useState('qty');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alertInfo = useSelector(state => state.common.alertInfo);
  const newAlertInfo = { ...alertInfo };
  newAlertInfo.severity = 'info';

  const onMyStockGet = async () => {
    if (!stockYn) return;
    setLoading(true);
    try {
      const myStockData = await getStockList({
        orderBy: listSorting,
      });
      setLoading(false);
      setStockList(myStockData);
    } catch (error) {
      setLoading(false);
    }
  };

  const onInvestingChange = async () => {
    if (!stockYn) {
      newAlertInfo.msg = '주주정보 연동을 진행 해주세요!';
      newAlertInfo.severity = 'error';
      dispatch(setAlertInfo(newAlertInfo));
      dispatch(openSnackbar());
      return;
    }
    const change = await getEditInvestingOpenYn();
    if (change.message_cd !== '0') return;
    updateProfile();
  };

  // mounted
  useEffect(() => {
    onMyStockGet();
  }, [stockYn, listSorting]);
  return (
    <>
      <OrderBox
        display={true}
        type={listSorting}
        setType={setlistSorting}
        my={true}
        investingOpenYn={investingOpenYn}
        investingChange={onInvestingChange}
      />
      <div>
        <div
          css={css`
            min-height: 100%;
            padding: ${stringToVW('8px 16px')};
          `}
        >
          {/* 리스트가 있을 경우. */}
          {stockList.length > 0 &&
            stockList?.map((item, index) => (
              <div
                css={css`
                  height: ${stringToVW('66px')};
                  display: flex;
                  align-items: center;
                  gap: ${stringToVW('16px')};
                `}
                key={index}
                onClick={() => {
                  navigate(`/channel/${encodeURIComponent(item.STOCK_CD)}/0`, {
                    state: { prevLink: pathname },
                  });
                }}
              >
                <div css={css``}>
                  <img
                    css={css`
                      width: ${stringToVW('40px')};
                      height: ${stringToVW('40px')};
                      border-radius: 50%;
                    `}
                    src={
                      getUploadImage(item?.THUMB_URL) ||
                      countryChnlImage(item?.COUNTRY_CD)
                    }
                    alt=""
                    onError={e => {
                      e.target.src = countryChnlImage(item?.COUNTRY_CD);
                    }}
                  />
                </div>
                <div css={styles.stock}>
                  <div css={styles.stockName}>{item.STOCK_NM || '---'}</div>
                  <div css={styles.total}>
                    {addCommasToNumberWithDecimal(item.STOCK_QTY)}&nbsp;
                    {/* <span css={styles.unit}>주</span> */}
                  </div>
                </div>
              </div>
            ))}

          {/* 리스트는 호출 하였는데 불러올 수 있는 종목이 없을 경우 */}
          {stockList.length === 0 && stockYn && userJoinDate && (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${stringToVW('120px')};
              `}
            >
              {loading ? '로딩중...' : '현재 투자 중인 종목이 없어요.'}
            </div>
          )}

          {stockList.length === 0 && stockYn && !userJoinDate && !loading && (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${stringToVW('120px')};
              `}
            >
              {/* 아직 종목을 불러오는 중이에요.
              <br /> 내일 보유 종목을 확인하실 수 있어요! */}
              아직 종목을 불러오는 중이에요.
              <br />익 영업일부터 보유 종목을 확인하실 수 있어요!
            </div>
          )}

          {/* 공개 거부한 경우 */}
          {!stockYn && !loading && (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${stringToVW('120px')};
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: ${stringToVW('13.3px')};
              `}
            >
              <h3
                css={css`
                  ${globalStyle.fontNanu24}
                `}
              >
                {/* 주주 정보 연동 기능이 해제되어 있어요 */}
                주주 정보 연동 기능이 해제되어 있어요
              </h3>
              <div>
                {/* 주주정보 연동 기능을 활성화하고, */}
                주주 정보 기능을 활성화하고
                <br />
                {/* 주주전용글을 확인해보세요! */}내 보유 종목을 불러오시겠어요?
              </div>

              <button
                css={css`
                  margin-top: ${stringToVW('26.7px')};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: ${stringToVW('10px 20px')};
                  gap: ${stringToVW('10px')};
                  border-radius: ${stringToVW('1000px')};
                  background: #f1f1f1;
                  border: 0;
                  color: #666;
                  ${globalStyle.fontNanu21}
                `}
                onClick={() => {
                  navigate('/profile/edit');
                }}
              >
                <SvgIcon sx={{ width: toVW('16px'), height: toVW('16px') }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.72962 2.38509C4.72567 2.38509 2.29048 4.72414 2.29048 7.6095C2.29048 10.4949 4.72567 12.8339 7.72962 12.8339C10.3263 12.8339 12.4995 11.0854 13.0397 8.74669C13.1227 8.38724 13.4934 8.16049 13.8676 8.24023C14.2418 8.31998 14.4779 8.67601 14.3949 9.03546C13.7168 11.9714 10.991 14.1672 7.72962 14.1672C3.95902 14.1672 0.902344 11.2312 0.902344 7.6095C0.902344 3.98776 3.95902 1.05176 7.72962 1.05176C9.89027 1.05176 11.8168 2.01658 13.0666 3.51953C13.1267 3.59171 13.1851 3.66514 13.242 3.73977C13.4684 4.03687 13.4012 4.45402 13.0919 4.67149C12.7826 4.88896 12.3483 4.82441 12.1219 4.52731C12.0765 4.4678 12.0299 4.40925 11.982 4.35168C10.9842 3.15185 9.45038 2.38509 7.72962 2.38509Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.14258 4.71305C9.14258 4.34486 9.45332 4.04639 9.83665 4.04639H13.182C13.5654 4.04639 13.8761 4.34486 13.8761 4.71305C13.8761 5.08124 13.5654 5.37972 13.182 5.37972H9.83665C9.45332 5.37972 9.14258 5.08124 9.14258 4.71305Z"
                      fill="#666666"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.1829 0.833008C13.5662 0.833008 13.877 1.13148 13.877 1.49967L13.877 4.71299C13.877 5.08118 13.5662 5.37965 13.1829 5.37965C12.7996 5.37965 12.4888 5.08118 12.4888 4.71299L12.4888 1.49967C12.4888 1.13148 12.7996 0.833008 13.1829 0.833008Z"
                      fill="#666666"
                    />
                  </svg>
                </SvgIcon>
                {/* 주주연동기능 활성화하기 */}
                주주 정보 연동 활성화하기
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const StocksOwned = ({
  isProfileOfOthers,
  userId,
  stockYn,
  userJoinDate,
  investingOpenYn,
  updateProfile,
}) => {
  return (
    <div>
      {isProfileOfOthers && (
        <OtherStock
          userId={userId}
          stockYn={stockYn === 'Y'}
          investingOpenYn={investingOpenYn}
        />
      )}
      {!isProfileOfOthers && (
        <MyStock
          userId={userId}
          stockYn={stockYn === 'Y'}
          userJoinDate={isDateMoreThanOneDayAgo(userJoinDate)}
          investingOpenYn={investingOpenYn}
          updateProfile={updateProfile}
        />
      )}
    </div>
  );
};

export default StocksOwned;
