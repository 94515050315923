import { useInfiniteQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

export const loungeQueryKey = 'loungePost';

const useLoungePostQuery = ({ rowPerPage, queryFn, params }) => {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [loungeQueryKey, params],
    queryFn: queryFn,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (isEmpty(lastPage)) {
        return undefined;
      }

      return lastPage[lastPage.length - 1].id;
    },
    select: data => {
      return {
        pages: data?.pages.flatMap(page => {
          return page;
        }),
        pageParams: data.pageParams,
      };
    },
  });

  return {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  };
};

export const useLoungePostQueryV2 = ({ rowPerPage, queryFn, params }) => {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [loungeQueryKey, params],
    queryFn: queryFn,
    initialPageParam: 0,
    getNextPageParam: (lastPage, page) => {
      return lastPage?.data?.length ? lastPage?.currentPage : undefined;
    },
    select: data => {
      return {
        pages: data?.pages.flatMap(page => {
          return page?.data;
        }),
        pageParams: data?.pages[0]?.currentPage,
      };
    },
  });

  return {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  };
};

export default useLoungePostQuery;
