import { css } from '@emotion/react';
import { Button, Modal } from 'antd';
import React, { useEffect } from 'react';

const ModalConfirm = props => {
  return (
    <Modal
      title=""
      zIndex={1001}
      open={props.model.open}
      onCancel={() => props.model.onCancel()}
      footer={null}
      closable={false}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      css={css`
        top: 20%;
      `}
      width={540}
    >
      <div
        className="flex flex-col justify-center item-center"
        css={css`
          padding: 30px 40px 30px 40px;
        `}
      >
        <p className="text-title-modal-confirm pb-35">{props.model.title}</p>
        <p className="text-question-modal-confirm pb-75">{props.model.desc}</p>
        <div className="flex justify-between w-full">
          <Button
            style={{ width: 180, height: 56 }}
            onClick={() => props.model.onCancel()}
          >
            취소
          </Button>
          <Button
            type="primary"
            style={{ width: 180, height: 56, background: 'black' }}
            onClick={() => props.model.onOk()}
          >
            확인
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
