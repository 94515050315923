import { JS_KEY_ACC_TOKEN, JS_KEY_REFRESH_TOKEN } from '@data/constants';
import axiosUtils, { getAdminIdParam2 } from '../../axiosUtils';
import { getCookie } from '@lib/cookieUtils';

export const adminLogin = async ({ admin_id, admin_pw }) => {
  return await axiosUtils
    .post('/admin/login', { admin_id, admin_pw })
    .then(({ data, headers }) => {
      const accessToken = headers[JS_KEY_ACC_TOKEN];
      const refreshToken = headers[JS_KEY_REFRESH_TOKEN];
      return { ...data, accessToken, refreshToken };
    });
};

export const adminLogout = async param => {
  return await axiosUtils
    .post('/admin/logout', { ...param }, { headers: getAdminIdParam2() })
    .then(res => res.data);
};
