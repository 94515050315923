import { configureStore } from '@reduxjs/toolkit';
import channelReducer from './app/channelReducer';
import webviewDataReducer from './app/webviewDataReducer';
import postReducer from './app/postReducer';
import authReducer from './app/authReducer';
import commonReducer from './app/commonReducer';
import adminReducer from './app/adminReducer';

export default configureStore({
  reducer: {
    channel: channelReducer,
    webviewData: webviewDataReducer,
    post: postReducer,
    auth: authReducer,
    common: commonReducer,
    admin: adminReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: getDefaultMiddleware =>
  //   getDefaultMiddleware({ serializableCheck: false }),
});
