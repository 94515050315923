import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { css } from '@mui/material';
import { adminLogin } from '@lib/api-call/admin/authApi';
import { updateLoginedAdmin } from '@store/app/adminReducer';
import { useDispatch, useSelector } from 'react-redux';
import { removeCookie, setCookie } from '@lib/cookieUtils';

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selector = useSelector(state => state.admin.loginedAdmin);

  const onFinish = async values => {
    console.log('로그인 시도:', values);

    const login = await adminLogin({
      admin_id: values.username,
      admin_pw: values.password,
    });

    console.log(login);

    if (login.message_cd !== '0') {
      form.setFields([
        {
          name: 'password',
          errors: [login.message_nm],
        },
      ]);
      return;
    }

    // setCookie('admin_id', login.admin_id);
    setCookie('admin_access_token', login.accessToken, { path: '/' });
    setCookie('admin_refresh_token', login.refreshToken, { path: '/' });

    dispatch(updateLoginedAdmin(true));

    // form.setFields([]);

    // form.setFields([
    //   {
    //     name: 'password',
    //     errors: ['오류발생'],
    //   },
    // ]);
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (!selector) removeCookie('admin_id');
  }, []);

  return (
    <div
      className="flex-center"
      css={css`
        height: 100vh;
      `}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: '아이디가 입력되지 않았어요!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: '비밀번호가 입력되지 않았어요!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
