import React from 'react';
import { css } from '@emotion/react';
import { globalStyle, stringToVW } from '@style/globalStyle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize/TextareaAutosize';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar, setAlertInfo } from '@store/app/commonReducer';
import { withdrawUser } from '@lib/api-call/app/profileApi';
import Popup from '@component/app/common/PopupMui';
import { useNavigate } from 'react-router-dom';
import useAppLoading, { LoadingConponent } from 'query-hook/useAppLoading';
import {
  CALL_MTS_ACTION,
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
} from '@data/constants';
import { removeCookie } from '@lib/cookieUtils';
import { callMTSFunction } from '@lib/commonUtils';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    width: 100%;
    ${globalStyle.fontNanu21}
    font-weight: 400;
    padding: ${stringToVW('12px')};
    border-radius: ${stringToVW('10px')};
    color: ${theme.palette.mode === 'dark' ? '#000000' : '#000000'};
    background: ${theme.palette.mode === 'dark' ? grey[100] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };
     resize: none;
    ::placeholder {
      color: #C5C5C5;
    }
    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
const styles = {
  title: css`
    ${globalStyle.fontNanu24};
    font-weight: 600;
    margin-bottom: ${stringToVW('2.7px')};
  `,
  subTitle: css`
    ${globalStyle.fontNanu21};
    color: #4e5255 !important;
  `,
  radioStyle: {
    color: '#E0E0E0',
    '&.Mui-checked': {
      color: '#84C13D',
    },
  },
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      color: '#333',
      fontSize: `${stringToVW('14px')} !important`,
    },
    '&.MuiFormControlLabel-root': {
      minHeight: stringToVW('48px'),
    },
  },
  radioGroupStyle: {
    marginTop: stringToVW('13.3px'),
  },
};

const WithdrawPage = () => {
  const { isLoading, showLoading, hideLoading } = useAppLoading();
  const dispatch = useDispatch();
  const alertInfo = useSelector(state => state.common.alertInfo);
  const navigate = useNavigate();

  const [content, setContent] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [openPopupConfirm, setPopupconfirm] = React.useState(false);

  const handleConfirmWithdraw = async () => {
    showLoading();
    const newAlertInfo = { ...alertInfo };
    const response = await withdrawUser({
      reason: reason === '직접 입력' ? content : reason,
    });

    if (response?.message_cd === '0') {
      hideLoading();
      callMTSFunction(CALL_MTS_ACTION.WITHDRAW);
      newAlertInfo.severity = 'success';
      newAlertInfo.msg = '커뮤니티 해지가 완료되었습니다';
      dispatch(setAlertInfo(newAlertInfo));
      dispatch(openSnackbar());
      removeCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
      removeCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
      // callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
      navigate('/');
    } else {
      hideLoading();
      newAlertInfo.severity = 'error';
      newAlertInfo.msg = response?.message_nm;
      dispatch(setAlertInfo(newAlertInfo));
      dispatch(openSnackbar());
    }
  };
  return (
    <div>
      <div css={styles.title}>커뮤니티를 해지하는 이유를 알려주세요</div>
      <div css={styles.subTitle}>
        해지하려는 이유를 알려주시면,
        <br />더 좋은 커뮤니티를 만드는데 도움이 됩니다.
      </div>

      <FormControl sx={styles.radioGroupStyle}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={reason}
          onChange={e => setReason(e.target.value)}
        >
          <FormControlLabel
            sx={styles.radioLabel}
            value="더이상 커뮤니티를 이용하지 않아요"
            control={<Radio sx={styles.radioStyle} />}
            label="더이상 커뮤니티를 이용하지 않아요"
          />
          <FormControlLabel
            sx={styles.radioLabel}
            value="불쾌한 컨텐츠가 포함되어 있어요"
            control={<Radio sx={styles.radioStyle} />}
            label="불쾌한 컨텐츠가 포함되어 있어요"
          />
          <FormControlLabel
            sx={styles.radioLabel}
            value="커뮤니티가 만족스럽지 않아요"
            control={<Radio sx={styles.radioStyle} />}
            label="커뮤니티가 만족스럽지 않아요"
          />
          <FormControlLabel
            sx={styles.radioLabel}
            value="다른 커뮤니티를 이용하고 있어요"
            control={<Radio sx={styles.radioStyle} />}
            label="다른 커뮤니티를 이용하고 있어요"
          />
          <FormControlLabel
            sx={styles.radioLabel}
            value="직접 입력"
            control={<Radio sx={styles.radioStyle} />}
            label="직접 입력"
          />
        </RadioGroup>
      </FormControl>
      {reason === '직접 입력' && (
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
          `}
        >
          <label
            css={css`
              position: absolute;
              bottom: ${stringToVW('14px')};
              right: ${stringToVW('14px')};
              color: #7d7d7d;
              ${globalStyle.fontRobo15}
            `}
            htmlFor="Textarea"
          >
            {content.length}/300
          </label>
          <Textarea
            sx={{
              fontSize: stringToVW('14px'),
              border: '1px solid #E0E0E0',
              borderRadius: stringToVW('10.7px'),
              boxShadow: 'none',
              '::placeholder': {
                color: '#C5C5C5',
                opacity: 1,
              },
              ':hover': {
                border: '1px solid #E0E0E0',
              },
            }}
            minRows={4}
            placeholder="사유를 직접 입력해주세요."
            onChange={e => {
              setContent(e.target.value);
            }}
            value={content}
            maxLength={300}
          />
        </div>
      )}
      <Popup
        open={openPopupConfirm}
        data={{
          title: (
            <div
              css={css`
                ${globalStyle.fontNanu24};
                text-align: center;
                color: #333;
              `}
            >
              커뮤니티를 정말로 해지하시겠어요?
            </div>
          ),
          content: (
            <div
              css={css`
                ${globalStyle.fontNanu21};
                text-align: center;
                color: #666;
              `}
            >
              작성한 게시글 / 댓글 / 팔로우 정보 등은
              <br />
              모두 삭제돼요
            </div>
          ),
        }}
        onClose={() => setPopupconfirm(false)}
        confirmText="해지하기"
        cancelText="취소"
        confirmEvent={handleConfirmWithdraw}
      />
      {isLoading && <LoadingConponent />}
      <button
        css={css`
          ${globalStyle.fontNanu26};
          background-color: ${!reason?.length
            ? 'rgba(132, 193, 61, 0.6)'
            : '#84C13D'};

          color: #fff;
          width: 100%;
          position: fixed;
          bottom: 0;
          left: 0;
          border: none;
          height: ${stringToVW('50px')};
        `}
        disabled={!reason?.length}
        onClick={() => {
          setPopupconfirm(true);
        }}
      >
        완료
      </button>
    </div>
  );
};

export default WithdrawPage;
