import { css } from '@emotion/react';

import { ReactComponent as DotIcon } from '@asset/icons/common/dot.svg';

import Chip from '../common/Chip';
import PostImageList from './PostImageList';
import { isEmpty } from 'lodash';
import { convertPostContents } from '@lib/commonUtils';
import { elapsedTime } from '@lib/dateTimeUtils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { noDecoraionLink } from '@style/common/commonStyles';
import { useEffect, useState } from 'react';
import { getOgMetaData } from '@lib/api-call/app/commApi';
import LinkThumbList from './LinkThumbList';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import {
  setClickedPostId,
  setClickedWrittenPostId,
} from '@store/app/postReducer';
import { useDispatch } from 'react-redux';
import { setClickedChannelPostId } from '@store/app/channelReducer';
import { HAVE_PREV_BACK_LINK } from '@data/constants';

const PostContent = ({ item, isDetail, isChDetail, isProfile }) => {
  const {
    post_img: postImgList,
    contents,
    reg_dt,
    mod_dt,
    links,
    ogMeta,
    holder_yn,
  } = item;

  const navigate = useNavigate();
  const { pathname, state: routerState, hash, search } = useLocation();

  const { betweenTime, postFix } = elapsedTime(mod_dt || reg_dt);

  let regBetweenTime, regPostFix;

  if (isDetail && mod_dt) {
    const { betweenTime: bt, postFix: pf } = elapsedTime(reg_dt);
    regBetweenTime = bt;
    regPostFix = pf;
  }

  // 하단 border line 그릴지 말지 정하는 변수
  const hasBottomBorder = !isDetail && isEmpty(postImgList) && isEmpty(links);

  const styles = {
    container: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${toVW('16px')};
      align-self: stretch;
      padding: ${isDetail ? stringToVW('0px') : stringToVW('16px 0px')};
    `,
    detailContainer: css`
      font-family: NanumBarunGothic;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${toVW('2.5px')};
      align-self: stretch;
    `,
    detailFooter: css`
      display: flex;
      align-items: center;
      gap: ${toVW('2.5px')};
      color: #7d7d7d;
    `,
    detailTime: css`
      display: flex;
      align-items: center;
      gap: ${toVW('1px')};
    `,

    number: css`
      ${globalStyle.fontRobo18}
    `,
    string: css`
      ${globalStyle.fontNanu18}
    `,
    datePrefix: css`
      padding-left: ${toVW('2px')};
    `,
    contentsText: css`
      white-space: pre-wrap;
      word-break: break-all;
      color: #4e5255;
      ${globalStyle.fontNanu21};
      font-weight: 400;

      & > a {
        color: #247ef2;
        text-decoration: none;
      }
    `,
    lineClamp: css`
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `,
  };

  useEffect(() => {}, []);

  const dispatch = useDispatch();

  return (
    <div css={styles.container}>
      <div
        css={styles.detailContainer}
        onClick={
          !isDetail
            ? () => {
                window.GA_Event('커뮤니티', '공통', '게시글 조회');
                if (!isChDetail && !isProfile) {
                  dispatch(setClickedPostId(item.post_id));
                } else if (isProfile) {
                  dispatch(setClickedWrittenPostId(item.post_id));
                } else {
                  dispatch(setClickedChannelPostId(item.post_id));
                }

                let prevLink = pathname;
                if (search) {
                  prevLink += search;
                }
                if (hash) {
                  prevLink += hash;
                }

                navigate(`/post/${item.post_id}`, {
                  state: {
                    prevLink: prevLink,
                    prevPageBackLink:
                      HAVE_PREV_BACK_LINK.some(link => {
                        return pathname.includes(link);
                      }) &&
                      routerState &&
                      routerState.prevLink
                        ? routerState.prevLink
                        : undefined,
                  },
                });
              }
            : undefined
        }
      >
        {isDetail && !isEmpty(item.stock_cd) && (
          <Link
            css={[
              noDecoraionLink,
              css`
                margin-bottom: ${toVW('6px')};
                display: block;
                overflow: hidden;
                max-width: 70%;
              `,
            ]}
            onClick={e => {
              e.stopPropagation();
              if (
                !item?.channel_nm ||
                (routerState?.prevLink &&
                  routerState.prevLink.includes('/channel/'))
              ) {
                e.preventDefault();
              }
            }}
            to={`/channel/${encodeURIComponent(
              item.stock_cd,
            )}/${encodeURIComponent(item.channel_id)}`}
            state={{ prevLink: pathname }}
          >
            <Chip
              propStyle={css`
                margin-bottom: ${toVW('6px')};
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              `}
              text={item.channel_nm || '삭제된 채널'}
            />
          </Link>
        )}
        <div
          css={css`
            ${isDetail ? globalStyle.fontNanu24 : globalStyle.fontNanu21}
            font-weight: 600;
          `}
        >
          {item.title}
        </div>
        <div
          css={[styles.contentsText, !isDetail ? styles.lineClamp : undefined]}
          dangerouslySetInnerHTML={{
            __html: isEmpty(links)
              ? contents
              : convertPostContents(contents, links),
          }}
        >
          {/* <div
            css={css`
              white-space: pre-wrap;
              word-break: break-all;
            `}
            dangerouslySetInnerHTML={{
              __html: convertedContents.contents,
            }}
          />
          {!isDetail && contents.length > 300 && (
            <div
              css={css`
                font-weight: 600;
              `}
            >
              더보기
            </div>
          )} */}
        </div>
      </div>
      {!isEmpty(postImgList) && (
        <PostImageList imgList={postImgList} isDetail={isDetail} />
      )}
      {!isEmpty(links) && <LinkThumbList links={links} isDetail={isDetail} />}
      {hasBottomBorder && (
        <div
          style={{
            borderTop: '1px solid #f0f0f0',
            width: '100%',
            marginBottom: toVW('-16px'),
          }}
        />
      )}
      {isDetail && (
        <div css={styles.detailFooter}>
          <div css={styles.detailTime}>
            {betweenTime && <div css={styles.number}>{betweenTime}</div>}
            {postFix && <div css={styles.string}>{postFix}</div>}
            {mod_dt && (
              <div css={[styles.string, styles.datePrefix]}>수정됨</div>
            )}
            {holder_yn === 'Y' && (
              <div
                css={css`
                  ${globalStyle.fontNanu18};
                  color: #3c9800;
                  padding-left: ${toVW('2px')};
                  ::before {
                    content: '·';
                    color: #e0e0e0;
                    padding-right: ${toVW('2px')};
                  }
                `}
              >
                주주전용글
              </div>
            )}
          </div>
          {mod_dt && (
            <>
              <DotIcon />
              <div css={styles.detailTime}>
                {regBetweenTime && (
                  <div css={styles.number}>{regBetweenTime}</div>
                )}
                {regPostFix && <div css={styles.string}>{regPostFix}</div>}

                <div css={[styles.string, styles.datePrefix]}>등록됨</div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PostContent;
