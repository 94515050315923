import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

export const writeQueryKey = 'writePost';

const useWritePostQuery = ({ rowPerPage, queryFn, params }) => {
  useEffect(() => {}, []);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [writeQueryKey, params],
    queryFn: queryFn,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (isEmpty(lastPage)) {
        return undefined;
      }

      return lastPage[lastPage.length - 1].post_id;
    },
    select: data => {
      return {
        pages: data?.pages.flatMap(page => {
          return page;
        }),
        pageParams: data.pageParams,
      };
    },
  });

  return {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  };
};

export default useWritePostQuery;
