import axiosUtils, {
  getAdminIdParam,
  getAdminIdParam2,
} from '../../axiosUtils';

// 관리자 목록
export const getAdminList = async (param) => {
  return await axiosUtils
    .get(
        "/admin/admList", 
        { ...param },
        { headers: getAdminIdParam2() }
    )
    .then((res) => res?.data);
};

// 관리자 상세
export const getAdminDet = async (param) => {
    return await axiosUtils
      .get(
          "/admin/admDetail", 
          { ...param },
          { headers: getAdminIdParam2() }
      )
      .then((res) => res?.data?.resData);
};

// 관리자 ID 중복 
export const getAdminIdDup = async (param) => {
    return await axiosUtils
      .get(
          "/admin/admIddup", 
          { ...param },
          { headers: getAdminIdParam2() }
      )
      .then((res) => res?.data);
};

// 관리자 등록
export const insAdmin = async (param) => {
  return await axiosUtils
    .post(
      "/admin/insAdm",
      { ...param },
      { headers: getAdminIdParam2() }
    )
    .then((res) => res?.data);
};

// 관리자 수정
export const uptAdmin = async (param) => {
    return await axiosUtils
      .post(
        "/admin/uptAdm",
        { ...param },
        { headers: getAdminIdParam2() }
      )
      .then((res) => res?.data);
 };
  
// 관리자 삭제
export const delAdmin = async (param) => {
    return await axiosUtils
      .post(
        "/admin/delAdm",
        { ...param },
        { headers: getAdminIdParam2() }
      )
      .then((res) => res?.data);
  };