import React from 'react';
import PropTypes from 'prop-types';
import settingsStyles from '@style/profile/settingStyles';
import { css } from '@emotion/react';

const TermsOfUse = props => {
  return (
    <>
      <div css={settingsStyles.title}>커뮤니티 서비스 이용 약관</div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '16px' }}>
        제1조 (목적)
      </div>
      <div css={settingsStyles.content} style={{ paddingTop: '10px' }}>
        이 약관은 엔에이치투자증권 주식회사(이하 “회사”)가 고객에게 커뮤니티
        서비스(이하 “서비스”)를 제공하고, 고객(이하 “이용자”)이 서비스를
        이용함에 있어서 필요한 사항을 정하는 것을 목적으로 합니다.
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제2조 (서비스의 제공 및 이용)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          ① 회사는 회사의 HTS, MTS 등 온라인 시스템(이하 “온라인 시스템”) 내에
          커뮤니티 공간을 개설하고, 이용자들이 게시글, 댓글 등의 방법으로
          의사소통할 수 있는 서비스를 제공합니다.
        </span>
        <span>
          ② 서비스는 만 14세 이상의 개인 고객 및 법인 고객에게만 제공됩니다.
        </span>
        <span>
          ③ 이용자는 온라인 시스템을 통해 서비스 가입을 신청하고 이 약관에
          동의하는 방법으로 서비스를 이용할 수 있습니다.
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제3조 (이용자의 의무)
      </div>
      <div css={settingsStyles.content} style={{ paddingTop: '10px' }}>
        ① 이용자는 서비스를 이용함에 있어 다음 각 호의 행위를 하여서는 안됩니다.
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '5px',
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
        }}
      >
        <span>1. 타인의 지식재산권을 침해하는 행위</span>
        <span>2. 타인의 명예를 훼손하거나 사생활을 침해하는 행위</span>
        <span>3. 욕설, 비방이나 음란, 폭력적인 표현을 하는 행위</span>
        <span>
          4. 허위사실을 게시하거나 허위의 정보를 공개 또는 유포하는 행위
        </span>
        <span>
          5. 다량의 정보를 전송하거나 동일 또는 유사한 내용의 정보를 반복적으로
          게시하여 서비스의 운영을 방해하는 행위
        </span>
        <span>
          6. 광고성 정보를 수신자의 의사에 반하여 지속적으로 게시판에 게시하는
          행위
        </span>
        <span>
          7. 정보통신설비의 오작동이나 정보의 파괴 등을 유발하는 컴퓨터 바이러스
          등을 유포하는 행위
        </span>
        <span>
          8. 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
          유통시키거나 상업적으로 이용하는 행위
        </span>
        <span>
          9. 회사의 정상적인 경영 또는 업무를 방해하거나 서비스의 운영을
          방해하는 행위
        </span>
        <span>10. 불법선거운동을 하는 행위</span>
        <span>11. 회사의 동의 없이 영리를 목적으로 서비스를 이용하는 행위</span>
        <span>
          12. 기타 미풍양속을 해하거나 관련 법령, 이 약관에 위반되는 행위
        </span>
        <span style={{ paddingTop: '5px' }}>
          ② 제1항을 위반한 이용자의 행위에 대하여 회사는 어떠한 책임도 부담하지
          아니하며, 그로 인하여 회사에 발생한 손해가 있는 경우 해당 이용자에
          대하여 민∙형사상 조치를 취할 수 있습니다.
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제4조 (지식재산권 등)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          ① 회사가 제공하는 서비스 및 회사가 게재한 게시물(게시글, 댓글, 이미지,
          하이퍼링크, 첨부파일 등을 포함하되 이에 한정하지 아니하며, 이하
          같음)에 관한 지식재산권은 회사에 속합니다. 이용자는 이를 회사의 서면에
          의한 사전 동의 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여
          이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        </span>
        <span>
          ② 이용자가 게재한 게시물에 관한 권리(지식재산권 등)와 책임은 이를
          게시한 이용자에게 있습니다. 회사는 회사의 온라인 시스템(회사와 업무
          제휴 관계에 있는 제3자의 인터넷 사이트를 포함) 내에서 활용하는 경우를
          제외하고, 해당 이용자의 동의 없이 이를 영리적인 목적으로 사용하지
          아니합니다.
        </span>
        <span>
          ③ 이용자가 게재한 게시물은 해당 이용자의 사적인 의견으로, 회사는 그
          정확성 또는 신빙성을 보증하지 아니합니다.
        </span>
        <span>
          ④ 제2항에도 불구하고 회사는 이용자가 게재한 게시물을 데이터 분석 등
          내부적인 용도로 활용할 수 있으며, 이용자는 제2조 제3항에 따른 서비스
          신청을 통해 상기 용도의 활용에 동의한 것으로 간주합니다. 본 항에 따라
          작성된 데이터 분석 결과물에 대한 지식재산권은 회사에 속합니다.
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제5조 (서비스의 해지 및 이용제한)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          ① 이용자는 제9조 제1항의 문의처를 통해 언제든지 서비스의
          해지(이용정지)를 신청할 수 있습니다.
        </span>
        <span>
          ② 회사는 서비스의 개선을 위하여 서비스 내용의 전부 또는 일부를 변경할
          수 있습니다.
        </span>
        <span>
          ③ 회사는 제2항의 개선 작업 또는 온라인 시스템의 유지보수를 위하여
          필요한 범위 내에서 일시적으로 서비스의 이용을 제한할 수 있습니다. 
        </span>
        <span>
          ④ 어느 이용자가 제3조 제1항 각호에 해당하는 행위를 한 경우, 회사는
          사전 통지 없이 해당 게시물을 삭제하거나 해당 이용자에 대한 서비스
          제공을 제한 또는 중단할 수 있습니다.
        </span>
        <span>
          ⑤ 어느 이용자에 대해 관련법령, 거래소규정, 회사의 약관 등에 따른
          수탁의 거부 가 이루어진 경우, 회사는 사전 통지 없이 해당 이용자의
          게시물을 삭제하거나, 해당 이용자에 대한 서비스 제공을 제한 또는 중단할
          수 있습니다.
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제6조 (면책)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
        }}
      >
        <span>
          회사는 다음 각 호 중 어느 하나에 해당하는 사유로 이용자에게 발생한
          손해 또는 손실에 대해서는 책임을 지지 아니합니다.
        </span>
        <span>1. 이용자의 범죄∙불법행위(이 약관 제3조 제1항 위반 포함)</span>
        <span>
          2. 법률, 법원이나 정부기관(감독기관 포함)의 지침에 따른 서비스의 변경
          또는 제한
        </span>
        <span>
          3. 천재지변, 전쟁, 폭동, 파업, 정전, 통신장애 기타 회사의 귀책사유
          없는 불가항력적인 사유
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제7조 (약관의 변경 등)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          ① 회사는 약관을 변경하고자 하는 경우 변경내용(기존 이용자에 대한
          변경약관 적용 여부, 신구대비표 등)을 변경되는 약관의 시행일 1개월 전에
          이용자가 확인할 수 있도록 회사의 영업점에 갖추어 두거나 인터넷
          홈페이지, 온라인 거래를 위한 컴퓨터 화면, 그 밖에 이와 유사한
          전자통신매체를 통하여 게시합니다. 다만, 자본시장법 등 관계법령 또는
          거래소 업무규정의 제·개정에 따른 제도변경 등으로 약관이 변경되는
          경우로서 본문에 따라 안내하기가 어려운 급박하고 부득이한 사정이 있는
          경우에는 변경내용을 앞의 문장과 같은 방법으로 개정 약관의 시행일 전에
          게시합니다.
        </span>
        <span>
          ② 제1항의 변경내용이 이용자에게 불리하거나 중요한 내용인 경우에는 이를
          서면 등 이용자와 사전에 합의한 방법으로 변경되는 약관의 시행일 1개월
          전까지 개별통지(신구대비표 포함) 통지하여야 합니다. 다만, 기존
          이용자에게 변경 전 내용이 그대로 적용되는 경우 또는 이용자가
          변경내용에 대한 통지를 받지 아니하겠다는 의사를 명시적으로 표시한
          경우에는 그러하지 아니합니다.
        </span>
        <span>
          ③ 회사는 제2항의 통지를 할 경우 “이용자는 약관의 변경에 동의하지
          아니하는 경우 서비스 이용을 종료할 수 있으며, 통지를 받은 날로부터
          변경되는 약관의 시행일 전의 영업일까지 서비스 이용 종료의 의사표시를
          하지 아니한 경우에는 변경에 동의한 것으로 본다 ”라는 취지의 내용을
          통지하여야 합니다. 이용자가 본 항에 따라 서비스 이용 종료의 의사표시를
          한 경우, 회사는 해당 이용자에 대한 서비스 제공을 중단합니다.
        </span>
        <span>
          ④ 이용자가 제3항의 통지를 받은 날로부터 변경되는 약관의 시행일 전의
          영업일까지 계약해지의 의사표시를 하지 아니하는 경우에는 변경에 동의한
          것으로 봅니다.
        </span>
        <span>
          ⑤ 회사는 약관을 회사의 영업점에 비치 또는 게시하여 이용자가 요구할
          경우 이를 교부하여야 하며, 인터넷 홈페이지, 온라인 거래를 위한 컴퓨터
          화면, 그 밖에 이와 유사한 전자통신매체에 게시하여 이용자가 약관을
          조회하고 다운로드(화면출력 포함)받을 수 있도록 하여야 합니다.
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제8조 (관계법규의 준용)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        이 약관에서 정하지 아니한 사항은 별도의 약정이 없는 한 관계법규에서
        정하는 바에 따르며, 관계법규에도 정함이 없는 경우에는 일반적인 상관례에
        따릅니다.
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제9조 (분쟁조정 등)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          ① 이용자는 서비스 이용과 관련하여 문제가 발생하는 경우 아래 문의처를
          통하여 신고 또는 문의할 수 있으며, 회사의 VOC나 고객센터에서는 관련
          문의를 접수하지 않습니다.
        </span>
        <span>&#183; 문의처 : community_support@nhqv.com</span>
        <span>
          ② 이용자는 회사와 분쟁이 발생하는 경우 회사의 민원처리기구에 그 해결을
          요구하거나 금융감독원, 한국금융투자협회 등에 분쟁조정을 신청할 수
          있습니다.
        </span>
      </div>
      <div css={settingsStyles.subTitle} style={{ paddingTop: '22px' }}>
        제10조 (관할법원)
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          이 약관에 따른 서비스 이용과 관련하여 회사와 이용자 사이에 소송의
          필요가 생긴 경우에는 관할법원은 민사소송법이 정하는 바에 따릅니다.
        </span>
        <span style={{ paddingTop: '11px' }}>부 칙 (2023.12.15)</span>
        <span>제1조 (시행일) 이 약관은 2023년 12월 15일로부터 시행한다.</span>
        <span style={{ paddingTop: '11px' }}>부 칙 (2024.03.21)</span>
        <span>제1조 (시행일) 이 약관은 2024년 03월 21일로부터 시행한다.</span>
      </div>
    </>
  );
};

TermsOfUse.propTypes = {};

export default TermsOfUse;
