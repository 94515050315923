import axiosUtils, { getAccTokenHeader } from '../../axiosUtils';

export const getProfile = async param => {
  return await axiosUtils
    .get('/profile/info', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getSubSchnl = async param => {
  return await axiosUtils
    .get('/profile/subschnl', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getBadgeList = async param => {
  return await axiosUtils
    .get('/profile/badge', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getStockList = async param => {
  return await axiosUtils
    .get('/profile/property', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getWriteList = async param => {
  return await axiosUtils
    .get('/profile/write', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getCommentList = async param => {
  return await axiosUtils
    .get('/profile/comment', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getReactionList = async param => {
  return await axiosUtils
    .get('/profile/react', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getMyProfile = async param => {
  return await axiosUtils
    .get('/userInfo', param, { headers: getAccTokenHeader() })
    .then(res => {
      return res.data;
    });
};

export const nickNameCheckApi = async param => {
  return await axiosUtils
    .get('/nickNameCheckUTF8', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const profileChangeApi = async param => {
  return await axiosUtils
    .get('/profile/editInfo', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getFollowerList = async param => {
  return await axiosUtils
    .get('/profile/follower', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};
export const getFollowingList = async param => {
  return await axiosUtils
    .get('/profile/following', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getFollow = async param => {
  return await axiosUtils
    .get('/profile/follow', param, { requireAuth: true })
    .then(res => res.data);
};

export const getEditBadgeOpenYn = async param => {
  return await axiosUtils
    .get('/profile/editBadgeOpenYn', param, { headers: getAccTokenHeader() })
    .then(res => res.data);
};

export const getEditInvestingOpenYn = async param => {
  return await axiosUtils
    .get('/profile/editInvestingOpenYn', param, {
      headers: getAccTokenHeader(),
    })
    .then(res => res.data);
};

export const withdrawUser = async body => {
  const result = await axiosUtils.post('/profile/withdrawal', body);
  return result.data;
};
