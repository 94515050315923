import { css } from '@emotion/react';

import { ReactComponent as Logo } from '@asset/images/search/logo.svg';
import { ReactComponent as IconClose } from '@asset/icons/common/icon_close.svg';
import { callMTSFunction } from '@lib/commonUtils';
import { CALL_MTS_ACTION } from '@data/constants';
import { stringToVW, toVW } from '@style/globalStyle';

export const loungeHeaderHeight = toVW('2.5em');

const LoungeHeader = () => {
  return (
    <>
      <div
        css={css`
          height: ${loungeHeaderHeight};
          padding: ${stringToVW('0px 1em')};
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background: #fff;
          position: fixed;
          z-index: 9;
          top: 0;
        `}
        onClick={() => {
          if (window.scrollY !== 0) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${toVW('0.625em')};
          `}
        >
          <span
            css={css`
              width: ${toVW('28px')};
              height: ${toVW('28px')};
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 29 29"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2001 16.782C17.2001 21.0346 13.7527 24.482 9.50015 24.482C7.86988 24.482 6.35795 23.9754 5.11321 23.111C4.34967 23.2322 1.49221 23.6843 1.33962 23.6835C1.20799 23.6827 1.10942 23.6043 1.17126 23.4508C1.17375 23.4448 1.17557 23.4387 1.17736 23.4326C1.17958 23.4252 1.18178 23.4178 1.18519 23.4108L2.61067 20.2246C2.09202 19.1886 1.80015 18.0194 1.80015 16.782C1.80015 12.5294 5.24755 9.08203 9.50015 9.08203C13.7527 9.08203 17.2001 12.5294 17.2001 16.782Z"
                fill="#3C9800"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.2725 20.8752C21.646 21.9606 19.6794 22.5954 17.5611 22.5954C11.9614 22.5954 7.42188 18.1598 7.42188 12.6883C7.42188 7.21679 11.9614 2.78125 17.5611 2.78125C23.1608 2.78125 27.7003 7.21679 27.7003 12.6883C27.7003 14.5811 27.157 16.3499 26.2146 17.854C26.7763 19.1622 27.6546 21.2202 27.6907 21.3818C27.7302 21.5588 27.6456 21.7139 27.4005 21.6753C27.3908 21.6736 27.3811 21.6728 27.3715 21.672C27.3602 21.6711 27.3489 21.6702 27.3376 21.6677L23.2725 20.8752Z"
                fill="#84C13D"
              />
              <path
                d="M13.6053 13.5975C14.1564 13.5975 14.6032 13.1509 14.6032 12.6C14.6032 12.0491 14.1564 11.6025 13.6053 11.6025C13.0542 11.6025 12.6074 12.0491 12.6074 12.6C12.6074 13.1509 13.0542 13.5975 13.6053 13.5975Z"
                fill="white"
              />
              <path
                d="M17.5614 13.5975C18.1125 13.5975 18.5593 13.1509 18.5593 12.6C18.5593 12.0491 18.1125 11.6025 17.5614 11.6025C17.0102 11.6025 16.5635 12.0491 16.5635 12.6C16.5635 13.1509 17.0102 13.5975 17.5614 13.5975Z"
                fill="white"
              />
              <path
                d="M22.5153 12.6C22.5153 13.1509 22.0685 13.5975 21.5174 13.5975C20.9663 13.5975 20.5195 13.1509 20.5195 12.6C20.5195 12.0491 20.8981 11.6025 21.4492 11.6025C22.0003 11.6025 22.5153 12.0491 22.5153 12.6Z"
                fill="white"
              />
            </svg>
          </span>
          <div
            css={css`
              margin-top: ${toVW('0.2rem')};
              color: #000;
              font-family: NanumBarunGothic;
              font-size: ${toVW('1em')};
              font-weight: 400;
            `}
          >
            <span
              css={css`
                font-weight: 600;
              `}
            >
              커뮤니티
            </span>
          </div>
        </div>

        <IconClose
          css={css`
            width: ${toVW('23px')};
          `}
          onClick={e => {
            e.stopPropagation();
            callMTSFunction(CALL_MTS_ACTION.CLOSE_ALL);
          }}
        />
      </div>
      <div
        css={css`
          height: ${loungeHeaderHeight};
        `}
      />
    </>
  );
};

export default LoungeHeader;
