import React from 'react';
import { css } from '@emotion/react';
import { globalStyle, stringToVW } from '@style/globalStyle';

const FAQPage = () => {
  const styles = {
    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: ${stringToVW('21.3px')};
      width: calc(100dvw - ${stringToVW('32px')});
      letter-spacing: 0em;
    `,
    headTitle: css`
      ${globalStyle.fontNanu26};
      font-weight: 600;
    `,
    title: css`
      ${globalStyle.fontNanu21};
      font-weight: 600;
    `,
    content: css`
      ${globalStyle.fontNanu21}
    `,
    contentWrapper: css`
      display: flex;
      flex-direction: column;
      gap: ${stringToVW('4px')};
    `,
  };

  const linkMail = (
    <a
      css={[
        styles.title,
        css`
          text-decoration: none;
          color: #247ef2;
          translate
        `,
      ]}
      href="mailto:“community_support@nhqv.com”"
    >
      community_support@nhqv.com
    </a>
  );

  return (
    <div css={styles.wrapper}>
      <div css={styles.headTitle}>커뮤니티 서비스 FAQ</div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>1. (프로필) 닉네임 요건이 궁금해요.</div>
        <div css={styles.content}>
           -닉네임은 등록 이후 60일이 지나야 변경 가능합니다.  (최초 가입 포함)
          신중하게 등록/변경 해주세요! 
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>2. (프로필) 닉네임 재사용이 불가한가요?</div>
        <div css={styles.content}>
           -네, 닉네임이 변경되면 변경 전 닉네임은 재사용이 불가능해요. 누군가
          과거에 사용하던 닉네임도 사용이 어려워요.
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          3. (프로필) 주주정보 연동 기능이 어떤 거예요?
        </div>
        <div css={styles.content}>
           -주주정보 연동을 활성화하면 종목 채널 내 게시글 작성 시 주주여부,
          보유주수, 보유기간, 보유종목에 대한 데이터가 연동돼요.
          <br />
          (마이데이터 가입자는 타 증권사 잔고 데이터도 포함) <br />
          -커뮤니티 최초 가입 시, 익 영업일 오전 9시 경부터 연동되며, 잔고
          정보는 전일자 체결 기준으로 매일 반영됩니다. 영업일 기준이기 때문에
          주말에는 반영되지 않아요. 금요일에 가입하신 분들은 월요일에 나의
          주주정보를 확인 할 수 있습니다.
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          4. (프로필) 팔로워 아래에 있는 숫자는 어떤 거예요?
        </div>
        <div css={styles.content}>
          - 나를 팔로우하고 있는 팔로워들의 자산 총 합 데이터에요. 많은 팔로워를
          모아서 Money Power를 키워보세요! 
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>5. (프로필) 배지는 어떻게 받는 거예요?</div>
        <div css={styles.content}>
          - 내가 고수 영역의 월간 순위 배지는 투자 성과 상위 30% 이상(월간
          나무/QV 기준), 월간 수익률 배지는 수익률 5% 이상(월간 나무/QV 기준)
          달성 시, 익월 4~7영업일 사이에 부여돼요.
          <br />- 콜롬버스 영역의 한국~인도네시아 배지는 전월 기준 해당 국가
          신규 매수 10만원 이상 달성 시, 익월 초(1~5 영업일 사이)에 부여돼요.
          <br />- 내가 고수(상한가) 배지는 일별로 내가 기보유한 종목이 상한가를
          달성했을 때 부여되며, 다양한 만남 배지는 누적 거래 종목 수가 10개 이상
          달성 시 부터 익일 부여됩니다.
          <br />
          *모든 배지의 선정 기준은 커뮤니티 가입일로부터 기산합니다. (수익률,
          성과, 누적 거래 종목수 등)
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          6. (프로필) 투자 중 탭 종목은 어느 기준이에요?
        </div>
        <div css={styles.content}>
           - 현재 투자 중인 한국/미국 종목이 보여지며, 마이데이터 가입자의 경우
          타사의 한국/미국 잔고까지 연동됩니다. (단, 주주 정보 연동 기능 on
          필수) 내 종목을 다른 유저들에게 공개하길 원치 않으면, 투자 중 탭 내
          우측 상단에 있는 설정값을 공개 off로 설정해 주세요.
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>7. (피드) 주주 전용글이 어떤 거예요?</div>
        <div css={styles.content}>
          - 내가 가진 종목의 채널에 글을 작성할 때, 하단의 주주 전용글로
          작성하기 옵션을 체크해보세요. 주주 전용글로 작성하면 해당 종목을
          보유한 유저들만 볼 수 있어요! (주주정보 연동 필수*)
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>8. (채널) ‘채널’이 무엇인가요?</div>
        <div css={styles.content}>
           -채널이란, 각 주제 별 게시판의 개념이에요. 현재 NH투자증권
          커뮤니티에서는 국내 상장종목 개별 채널 / 미국 상장 종목 개별 채널 /
          국가별 라운지 채널 /운용사 채널/ 공모 청약 채널 /다이렉트인덱싱 채널
          등 총 약 14,600개의 채널로 구성되어 있어요.
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          9. (신고) 갑자기 내가 쓴 게시글/댓글이 사라졌어요.
        </div>
        <div css={styles.content}>
          - 앗 혹시 나도 모르게 홧김에 글을 쓰지 않았나요? 게시글/댓글에 신고가
          5회 이상 누적되면, 자동 숨김처리 되고 있어요. 
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          10. (신고) 갑자기 계정이 차단(이용중지)처리 됐어요.
        </div>
        <div css={styles.content}>
          - 신고 누적으로 숨김처리된 게시글/댓글이 5개 이상이면, 차단(이용
          중지)처리가 돼요. 차단일 당일 포함 14일동안 이용 중지 후 자동 원복
          됩니다. 이용 중지 기간에는 글쓰기, 댓글쓰기, 좋아요, 싫어요 등 활동이
          불가하니 이점 유의해주세요.
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          {/* 11. (해지/이용중지) 서비스를 더 이상 이용하고 싶지 않아요. */}
          11. (해지) 서비스를 더 이상 이용하고 싶지 않아요.
        </div>
        <div css={styles.content}>
          - 커뮤니티 서비스 이용을 원치 않으시면 나의 프로필 우측 상단에 있는
          이용안내 버튼을 눌러주세요. 서비스 해지 메뉴를 선택하여 해지할 수
          있습니다.
          <br />
          커뮤니티 서비스 해지 시에 모든 데이터(작성한 글, 댓글, 팔로워 등)는
          영구 삭제되어요. 언제든지 커뮤니티에 새롭게 재가입 할 수 있으니
          기다리고 있을게요.
        </div>
      </div>
      <div css={styles.contentWrapper}>
        <div css={styles.title}>
          12. (기타) 커뮤니티 이용에 궁금한 게 생겼어요.
        </div>
        <div css={styles.content}>
          - 커뮤니티 관련 모든 문의는 공식 이메일로 보내주시면 최대한 빠르고
          신속하게 확인하고 있어요. <br />- 공식 이메일 주소:&nbsp;
          {linkMail}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
