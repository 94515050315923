/* eslint-disable no-unused-vars */
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import IconLike from '@asset/icons/notification/ms_icon_feed_like.svg';
// import IconLike from '@asset/icons/notification/';
import IconTabPost from '@asset/icons/notification/ms_icon_tab_post.svg';
import IconBangInfo from '@asset/icons/notification/nds_icon_banginfo.svg';
import IconComment from '@asset/icons/notification/nds_icon_comment.svg';
import IconFollowing from '@asset/icons/notification/nds_icon_nav_following.svg';
import Popup from '@component/app/common/PopupMui';
import Popup2 from '@component/app/common/Popup';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';

// api
import { getAlarmList, getAlarmRead } from '@lib/api-call/app/notificationApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { getUserInfo } from '@lib/api-call/app/authApi';
import { useDispatch } from 'react-redux';
import { updateLoginedUser } from '@store/app/authReducer';

// type 에 따른 이미지 반환
const getIconImage = type => {
  if (type === '01' || type === '02' || type === '03') {
    return IconBangInfo;
  } else if (type === '04') {
    return IconTabPost;
  } else if (type === '05') {
    return IconFollowing;
  } else if (type === '06' || type === '07') {
    return IconComment;
  } else if (type === '08' || type === '09') {
    return IconLike;
  }
};

const getDateTwoWeeksLater = date => {
  const currentDate = dayjs(date).format('YYYY.MM.DD');
  const futureDate = dayjs(date).add(2, 'week').format('YYYY.MM.DD');
  return [currentDate, futureDate];
};

const contentType = type => {
  if (type === '1') return '게시글';
  else if (type === '2') return '댓글';
  else if (type === '3') return '답글';
};

const BoldText = ({ children }) => {
  return (
    <span
      css={css`
        font-weight: bold;
      `}
    >
      {children}
    </span>
  );
};
// type 에 따른 알람 텍스트출력
const AlarmTypeTitle = ({ children, type, data }) => {
  //   01 신고         내가 쓴 게시글이 숨김처리 되었습니다. 'Content_title'
  // 02 신고         내 개정이 일시적으로 활동정지 되었습니다.
  // 03 신고         내가 신고한 게시글이 숨김처리 되었습니다.'Content_title'
  if (type === '01') {
    return (
      <div>
        내가 쓴 {contentType(data.noti_info)}이 숨김처리 되었습니다.
        <br />'{data.title}'
      </div>
    );
  } else if (type === '02') {
    return <span>내 계정이 일시적으로 활동정지 되었습니다.</span>;
  } else if (type === '03') {
    return (
      <span>
        내가 신고한 {contentType(data.noti_info)}이 숨김처리 되었습니다. '
        {data.title}'
      </span>
    );
  } else if (type === '04') {
    return (
      <span>
        <BoldText>{data.sender_nm || '-'}</BoldText>
        님이 새글을 썼습니다. '{data.title || '-'}'
      </span>
    );
  } else if (type === '05') {
    return (
      <span>
        <BoldText>{data.sender_nm || '-'}</BoldText>님이 나를 팔로우하기
        시작했습니다.
      </span>
    );
  } else if (type === '06') {
    // return <span>{data.title || '-'} 에 댓글이 달렸습니다.</span>;
    return (
      <span>
        <BoldText>{data.sender_nm}</BoldText>님이 다음 게시글에 댓글을
        달았습니다. '{data.title}'
      </span>
    );
  } else if (type === '07') {
    // return <span>{data.title || '-'} 에 대댓글이 달렸습니다.</span>;
    return (
      <span>
        <BoldText>{data.sender_nm}</BoldText>님이 다음 게시글에 답글을
        달았습니다. '{data.title}'
      </span>
    );
  } else if (type === '08' || type === '09') {
    // return (
    //   <span>
    //     {data.title || '-'} 에 {data.sender_nm || '-'} 님이&nbsp;
    //     {type === '08' ? '좋아요' : '싫어요'}를 눌렸습니다.
    //   </span>
    // );
    return (
      <span>
        <BoldText>{data.sender_nm}</BoldText>님이 다음 게시글에&nbsp;
        {type === '08' ? '좋아요' : '싫어요'}를 눌렀습니다. '{data.title}'
      </span>
    );
  }
};

// type 에 따른 알람 컨텐츠 출력
const AlarmTypeContent = ({ children, type, data }) => {
  if (type === '01') {
    return (
      <span
        css={css`
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          -webkit-box-orient: vertical;
        `}
      >
        신고 {contentType(data.noti_info)} 내용: {data.noti_contents}
      </span>
    );
  } else if (type === '02') {
    const date = getDateTwoWeeksLater(data.reg_dt);
    return (
      <span
        css={css`
          text-align: center;
        `}
      >
        정지기간에는 게시글작성/반응 등의 활동이 불가하며,
        <br /> 게시글 열람만 가능합니다.
        <br />
        <br />
        정지기간 : {date[0]} - {date[1]}
        <br />
        문의 : community_support@nhqv.com
      </span>
    );
  } else if (type === '03') {
    // return <span>{'신고{Report_type} 내용 : {content}'}</span>;
    return (
      <span
        css={css`
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          -webkit-box-orient: vertical;
        `}
      >
        신고 {contentType(data.noti_info)} 내용: {data.noti_contents}
      </span>
    );
  }
};

// 날짜 포멧 (--일 전,, --분 전)
function timeDifferenceToNow(isoDateString) {
  const now = new Date();
  const dateToCompare = new Date(isoDateString);

  const timeDifference = now - dateToCompare;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  if (minutesDifference < 1) {
    return '방금 전';
  } else if (minutesDifference < 60) {
    return `${minutesDifference}분 전`;
  } else {
    const hoursDifference = Math.floor(minutesDifference / 60);
    if (hoursDifference < 24) {
      return `${hoursDifference}시간 전`;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${daysDifference}일 전`;
    }
  }
}

const NotificationItem = forwardRef((props, refs) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useImperativeHandle(refs, () => ({
    onAlarmListGet,
  }));

  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alarmList, setAlarmList] = useState([]);

  const [alertContent, setAlertContent] = useState({
    type: '',
    title: '',
    content: <span></span>,
  });

  const onAlarmListGet = async () => {
    setLoading(true);
    const alarmListData = await getAlarmList();
    setLoading(false);
    setAlarmList(alarmListData);
  };
  const handleShow = async item => {
    const alarmRead = await getAlarmRead({
      notification_id: item.notification_id,
    });

    if (alarmRead.message_cd !== '0') return;
    const userInfo = await getUserInfo();
    if (userInfo) {
      dispatch(updateLoginedUser(userInfo));
    }

    if (item.type === '01' || item.type === '02' || item.type === '03') {
      setAlertContent({
        type: item.type,
        title: AlarmTypeTitle({ type: item.type, data: item }),
        content: AlarmTypeContent({ type: item.type, data: item }),
      });
      setIsShow(true);
    } else if (
      item.type === '04' ||
      item.type === '06' ||
      item.type === '07' ||
      item.type === '08' ||
      item.type === '09'
    ) {
      navigate(`/post/${item.post_id}`, {
        state: { prevLink: pathname },
      });
    } else if (item.type === '05') {
      navigate(`/profile/${encodeURIComponent(item.sender_id)}`, {
        state: { prevLink: pathname },
      });
    }
  };

  const openDialog = () => {
    setIsShow(true);
  };
  const closeDialog = () => {
    setIsShow(false);
  };

  const toMail = () => {
    window.location.href = 'mailto:community_support@nhqv.com';
  };

  // mounted
  useEffect(() => {
    onAlarmListGet();
  }, []);

  useEffect(() => {
    if (!isShow) onAlarmListGet();
  }, [isShow]);

  return (
    <div
      css={css`
        margin-top: ${stringToVW('12px')};
      `}
    >
      {/* <button onClick={() => setIsShow2(true)}>hello</button>
      {isShow2 ? (
        <Popup2
          data={dataProps2}
          handleClick={() => setIsShow2(false)}
          confirmClick={() => setIsShow2(false)}
        />
      ) : null} */}
      {alarmList.map((item, index) => (
        <Box
          sx={{ flexGrow: 1 }}
          css={css`
            padding: ${stringToVW('16px')};
            border-bottom: 1px solid #f0f0f0;
          `}
          style={
            item.isread === 'N'
              ? { background: '#F5F5F5' }
              : { background: '#FFFFFF' }
          }
          key={index}
          onClick={() => {
            window.GA_Event('커뮤니티', '알림', '알림 선택');
            handleShow(item);
          }}
        >
          <Stack direction="row">
            <div
              css={css`
                position: relative;
                padding-right: ${stringToVW('8px')};
              `}
            >
              <img
                css={css`
                  width: ${stringToVW('18px')};
                  height: ${stringToVW('18px')};
                `}
                src={getIconImage(item.type)}
                alt=""
              />
            </div>
            <Grid
              xs={12}
              css={css`
                font-weight: 400;
                letter-spacing: 0em;
                text-align: left;
                width: 100%;
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <div
                  css={css`
                    color: #333333;
                    ${globalStyle.fontNanu21}
                  `}
                >
                  {item.acd_nm}
                  {item.isread === 'N' && (
                    <span
                      css={css`
                        display: inline-block;
                        width: ${stringToVW('3px')} !important;
                        height: ${stringToVW('3px')} !important;
                        border-radius: 50%;
                        background: #ef2d26;
                        margin: ${stringToVW('0 0 8px 3px')};
                      `}
                    />
                  )}
                </div>
                <div
                  css={css`
                    color: #999999;
                    ${globalStyle.fontNanu18}
                  `}
                >
                  {/* {item.date} */}
                  {timeDifferenceToNow(item.reg_dt)}
                </div>
              </div>
              <div
                css={css`
                  color: #999;
                  ${globalStyle.fontNanu21}
                  font-weight: 400;
                  text-align: left;
                  margin-top: ${stringToVW('6px')};
                `}
              >
                <AlarmTypeTitle type={item.type} data={item} />
              </div>
            </Grid>
          </Stack>
        </Box>
      ))}
      {!alarmList?.length && (
        <div
          css={css`
            width: 100%;
            height: 80dvh;
            display: grid;
            justify-items: center;
            align-items: center;
          `}
        >
          <span
            css={css`
              color: #666;
              ${globalStyle.fontNanu21}
            `}
          >
            {loading ? '로딩중...' : '첫 번째 알림을 기다려볼까요?'}
          </span>
        </div>
      )}
      {/* {isShow ? (
        <Popup
          data={
            type === '1' ? dataProps1 : type === '2' ? dataProps2 : dataProps3
          }
          handleClick={handleClick}
          confirmClick={handleClick}
        />
      ) : null} */}
      <Popup
        open={isShow}
        data={alertContent}
        cancelButton={false}
        // confirmButton={false}
        onClose={closeDialog}
        cancelEvent={toMail}
        // cancelText="문의하기"
        confirmText="확인"
      ></Popup>
    </div>
  );
});

export default NotificationItem;
