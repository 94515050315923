import HeaderSearch from '@component/app/search/HeaderSearch';
import { css } from '@emotion/react';
import NotificationItem from '@component/app/notification/NotiificationItem';
import LoungeHeader from '@component/app/common/LoungeHeader';
import { globalStyle, stringToVW } from '@style/globalStyle';
import useLoungePostQuery from 'query-hook/useLoungePostQuery';
import { useRef } from 'react';
import Ptr from '@component/app/common/ptr';
import { useSelector, useDispatch } from 'react-redux';
import { getAlarmRead } from '@lib/api-call/app/notificationApi';
import { getUserInfo } from '@lib/api-call/app/authApi';
import { updateLoginedUser } from '@store/app/authReducer';
import useAppLoading, { LoadingConponent } from 'query-hook/useAppLoading';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';

const NotificationPage = () => {
  const { isLoading, showLoading, hideLoading } = useAppLoading();
  const notiRef = useRef({});
  const { refetch } = useLoungePostQuery({
    queryFn: () => {
      notiRef.current.onAlarmListGet();
    },
  });
  const dispatch = useDispatch();
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const handleReadAll = async () => {
    showLoading();
    const alarmRead = await getAlarmRead();
    if (alarmRead.message_cd !== '0') return;
    const userInfo = await getUserInfo();
    if (userInfo) {
      dispatch(updateLoginedUser(userInfo));
      await refetch();
    }
    hideLoading();
  };
  return (
    <Ptr
      onRefresh={async () => {
        await refetch();
      }}
    >
      <div
        css={css`
          /* position: relative; */
          min-height: calc(100vh - ${stringToVW('74px')});
        `}
      >
        <LoungeHeader />
        <div
          css={css`
            padding: ${stringToVW('0px 15px')};
          `}
        >
          <HeaderSearch />
        </div>
        {loginedUser?.alramCount && (
          <div
            css={css`
              height: ${stringToVW('28px')};
              display: flex;
              justify-content: end;
              align-items: center;
              padding: ${stringToVW('0px 20px')};
              margin-bottom: -${stringToVW('12px')};
            `}
          >
            <span
              css={css`
                ${globalStyle.fontNanu18};
                color: #3c9800;
              `}
              onClick={() => handleReadAll()}
            >
              모두 읽기&nbsp;
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.03911 6.87886L4.08259 9.79553C4.3203 10.0233 4.3203 10.3927 4.08259 10.6205C3.84488 10.8483 3.45947 10.8483 3.22176 10.6205L0.178283 7.70382C-0.0594276 7.47601 -0.0594276 7.10667 0.178283 6.87886C0.415993 6.65106 0.801398 6.65106 1.03911 6.87886ZM12.9609 3.37886C13.1986 3.15106 13.584 3.15106 13.8217 3.37886C14.0594 3.60667 14.0594 3.97601 13.8217 4.20382L7.12606 10.6205C6.88835 10.8483 6.50295 10.8483 6.26524 10.6205L3.22176 7.70382C2.98405 7.47601 2.98405 7.10667 3.22176 6.87886C3.45947 6.65106 3.84488 6.65106 4.08259 6.87886L6.69565 9.38305L12.9609 3.37886ZM10.7782 3.37886C11.016 3.60667 11.016 3.97601 10.7782 4.20382L7.12688 7.70304C6.88917 7.93084 6.50377 7.93084 6.26606 7.70304C6.02835 7.47523 6.02835 7.10588 6.26606 6.87808L9.91741 3.37886C10.1551 3.15106 10.5405 3.15106 10.7782 3.37886Z"
                  fill="#84C13D"
                />
              </svg>
            </span>
          </div>
        )}

        <NotificationItem ref={notiRef} />
      </div>
      {isLoading ? <LoadingConponent /> : null}
      <CheckWithdrawAccount />
    </Ptr>
  );
};

export default NotificationPage;
