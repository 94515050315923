import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import Header from '@component/admin/common/Header';
import { DatePicker, Select, Input, message, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { getReportUserList } from '@lib/api-call/admin/reportApi';
import { toNumber } from 'lodash';
import dayjs from 'dayjs';

// 숫자에 천 단위 쉼표 추가하는 함수
function formatNumberWithCommas(number) {
  return toNumber(number).toLocaleString();
}
function getOneMonthAgoToNow() {
  // 1달전
  const oneMonthAgo = dayjs().subtract(1, 'month').format('YYYYMMDD');

  // 현재 날짜
  const currentDate = dayjs().format('YYYYMMDD');

  return [oneMonthAgo, currentDate];
}

const { RangePicker } = DatePicker;
const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'idx',
  },
  {
    title: '신고당한 사람',
    dataIndex: 'user_nm',
    key: 'reported_nickname',
  },
  {
    title: '회원 타입',
    dataIndex: 'user_type_nm',
    key: 'user_type',
  },
  // {
  //   title: '회원 상태',
  //   dataIndex: 'status',
  //   key: 'status',
  // },
  {
    title: '누적 신고 수',
    dataIndex: 'report_count',
    key: 'report_count',
  },
  {
    title: '최근 신고일',
    dataIndex: 'report_date',
    key: 'report_date',
  },
];

const ReportManage = () => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const [reportList, setReportList] = useState([]);
  const [totalReportCount, setTotalReportCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  //search
  const [reportDate, setReportDate] = useState(getOneMonthAgoToNow());
  const [nickName, setNickName] = useState('');

  const onReportListGet = async () => {
    setLoading(true);
    const reportData = await getReportUserList({
      p_start: page * 10,
      p_from_dt: reportDate[0],
      p_to_dt: reportDate[1],
      p_length: 10,
      p_nickname: nickName,
    });
    console.log(reportData);
    if (reportData.message_cd !== '0') {
      messageApi.open({
        type: 'error',
        content: '목록 호출 오류 발생',
      });
    }
    setReportList(reportData.reportUserList);
    setTotalReportCount(reportData.total_count);
    setTimeout(() => setLoading(false));
  };

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const dateChange = (dates, dateStrings) => {
    console.log(dates, dateStrings);
    setReportDate(dates ? dateStrings : []);
  };

  const onSearch = () => {
    if (page === 0) {
      onReportListGet();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    onReportListGet();
  }, []);

  useEffect(() => {
    onReportListGet();
  }, [page]);

  const dataProp = [
    {
      title: '신고 관리',
      href: '/admin/report-manage',
    },
    {
      title: '신고 목록',
      href: '',
    },
  ];

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div className="flex gap-16">
          <RangePicker
            value={reportDate.map(x => dayjs(x))}
            format="YYYYMMDD"
            onChange={dateChange}
            allowClear={false}
          />
          <Input
            onChange={e => setNickName(e.target.value)}
            css={css`
              width: 421px;
            `}
            placeholder="닉네임을 입력해 주세요."
            allowClear
          />
          <SearchOutlined className="style-search" onClick={onSearch} />
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="bg-title-table">
            <p className="text-title-table">
              총 신고 수 &#123;{formatNumberWithCommas(totalReportCount)}&#125;
            </p>
          </div>
          <Table
            className="ant-table"
            columns={columns}
            dataSource={reportList}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  console.log(record);
                  navigate(
                    `/admin/report-manage/report-detail/${encodeURIComponent(
                      record.user_id,
                    )}`,
                  );
                },
              };
            }}
            pagination={false}
            loading={loading}
          />
          <Pagination
            current={page + 1}
            defaultCurrent={1}
            total={totalReportCount || 1}
            showSizeChanger={false}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px 0',
            }}
            onChange={(page, pageSize) => pageChange(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportManage;
