import dayjs from 'dayjs';
import { DateTime } from 'luxon';

export const elapsedTime = date => {
  const sliceTimeZone = date?.substring(0, date.indexOf('+')) || date;

  const start = DateTime.fromISO(sliceTimeZone);
  const end = DateTime.now(); // 현재 날짜

  const diff = end.diff(start).toObject(); // 경과 시간

  const diffSec = diff.milliseconds / 1000;

  if (diffSec < 60 * 5) {
    return { postFix: '방금 전' };
  } else if (diffSec < 60 * 60) {
    const betweenTime = Math.floor(diffSec / 60);
    return { betweenTime: betweenTime, postFix: '분 전' };
  } else if (diffSec < 60 * 60 * 24) {
    const betweenTime = Math.floor(diffSec / 3600);
    return { betweenTime: betweenTime, postFix: '시간 전' };
  }

  // 모든 단위가 맞지 않을 시
  return { betweenTime: start.toFormat('yyyy.MM.dd', { locale: 'ko' }) };
};

export const getStockHoldingDate = holdingDate => {
  return holdingDate < 9999 ? holdingDate : holdingDate + '+';
};

export const getDateTwoWeeksLater = date => {
  const currentDate = dayjs(date).format('YYYY.MM.DD');
  const futureDate = dayjs(date).add(2, 'week').format('YYYY.MM.DD');
  return [currentDate, futureDate];
};
