import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import './style.css';
import Header from '@component/admin/common/Header';
import {
  Select,
  Input,
  Button,
  Modal,
  Breadcrumb,
  Upload,
  Switch,
  Pagination,
  InputNumber,
} from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import ModalConfirm from '@component/admin/common/ModalConfirm';
import {
  getChannelDel,
  getChannelDetail,
  getChannelInsert,
  getChannelList,
  getChannelRecomm,
} from '@lib/api-call/admin/channelApi';
import { find, isEmpty, toNumber } from 'lodash';
import { uploadFiles } from '@lib/api-call/app/fileApi';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { getUploadImage } from '@lib/commonUtils';

// 숫자에 천 단위 쉼표 추가하는 함수
function formatNumberWithCommas(number) {
  return toNumber(number).toLocaleString();
}
const styles = {
  avatar: css`
    width: 200px;
    height: 200px;
  `,
};
const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'idx',
  },
  {
    title: '채널명',
    dataIndex: 'channel_nm',
    key: 'channel_name',
  },
  {
    title: '채널 카테고리',
    dataIndex: 'type_nm',
    key: 'channel_category',
  },
  {
    title: '가입일',
    dataIndex: 'reg_date',
    key: 'date',
  },
  {
    title: '추천채널',
    dataIndex: 'recomm_channel_yn',
    key: 'recomm_channel_yn',
    render: text => (text === 'Y' ? 'O' : 'X'),
  },
  {
    title: '채널 제한',
    dataIndex: 'channel_auth_cd',
    key: 'channel_auth_cd',
    render: text => (text === 'Y' ? 'X' : 'O'),
  },
];

const chnlType = [
  { key: 0, name: '주식' },
  { key: 1, name: '상품/서비스' },
  { key: 2, name: '국가' },
  { key: 3, name: '기타' },
];
const ChannelManage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalChannelDetailOpen, setIsModalChannelDetailOpen] =
    useState(false);

  const [chnlList, setChnlList] = useState([]);
  const [totalChnlCount, setTotalChnlCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  //detail
  const [detailInfo, setDetailInfo] = useState({});

  //채널등록
  const [chnlCtgr, setChnlCtgr] = useState(null);
  const [chnlName, setChnlName] = useState('');
  const [stockCd, setStockCd] = useState('');
  const [chnlImg, setChnlImg] = useState(null);
  const [chnlAuthCd, setChnlAuthCd] = useState(null);

  //search
  const [chnlNameSearch, setChnlNameSearch] = useState('');

  const dataProp = [
    {
      title: '채널 관리',
      href: '/admin/report-manage',
    },
    {
      title: '채널 목록',
      href: '',
    },
  ];

  const onChnlListGet = async () => {
    setLoading(true);
    const chnlData = await getChannelList({
      p_start: page * 10,
      p_length: 10,
      p_comment: chnlNameSearch,
    });
    console.log(chnlData);

    setChnlList(chnlData.chChannelList);
    setTotalChnlCount(chnlData.total_count);
    setTimeout(() => setLoading(false));
  };

  const onChnlDetailGet = async ({ channelId, stockCd }) => {
    const chnlDetailData = await getChannelDetail({
      p_stock_cd: stockCd,
      p_channel_id: channelId,
    });
    console.log(chnlDetailData);
    setDetailInfo(chnlDetailData.chChannelView);
  };

  const onChangeReComm = async yn => {
    const chnlRecommData = await getChannelRecomm({
      p_stock_cd: detailInfo.stock_cd,
      p_channel_id: detailInfo.channel_id,
      p_recomm_yn: yn ? 'Y' : 'N',
    });
    console.log(chnlRecommData);
    onChnlDetailGet({
      channelId: detailInfo.channel_id,
      stockCd: detailInfo.stock_cd,
    });
  };

  const onChnlSubmit = async () => {
    let profile_img = null;
    if (!isEmpty(chnlImg)) {
      console.log(chnlImg);
      const upload = await uploadFiles([chnlImg[0].originFileObj]);
      profile_img = upload?.[0].imageURL;
      console.log('업로드', profile_img);
    }
    const submit = await getChannelInsert({
      p_stock_cd: stockCd,
      p_type: chnlCtgr,
      p_channel_nm: chnlName,
      p_thumb_url: profile_img,
      p_channel_auth_cd: chnlAuthCd,
    });

    if (submit.message_cd !== '0') return false;
    return true;
  };

  const onChnlDel = async () => {
    const deleteChnl = await getChannelDel({
      p_channel_id: detailInfo.channel_id,
      p_stock_cd: detailInfo.stock_cd,
    });
    console.log(deleteChnl);
    if (deleteChnl.message_cd !== '0') return false;
    return true;
  };

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const onSearch = () => {
    if (page === 0) {
      onChnlListGet();
    } else {
      setPage(0);
    }
  };

  const onChangeFiles = info => {
    console.log(info);
    setChnlImg(info.fileList);
  };

  const ctgrChange = value => {
    setChnlCtgr(value);
  };

  const cacChange = value => {
    setChnlAuthCd(value);
  };

  useEffect(() => {
    onChnlListGet();
  }, []);

  useEffect(() => {
    onChnlListGet();
  }, [page]);

  useEffect(() => {
    if (!isModalChannelDetailOpen) {
      setDetailInfo({});
    }
  }, [isModalChannelDetailOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalChannelDetail = ({ stockCd, channelId }) => {
    onChnlDetailGet({ stockCd, channelId });
    setIsModalChannelDetailOpen(true);
  };
  const handleOkChannelDetail = () => {
    setIsModalChannelDetailOpen(false);
  };
  const handleCancelChannelDetail = () => {
    setIsModalChannelDetailOpen(false);
  };

  const showModalConfirm = () => {
    setModalProps({
      ...modalProps,
      open: true,
      title: '삭제 확인',
      desc: '해당 채널을 삭제하시겠습니까?',
      onOk: async data => {
        console.log('on ok: ', data);
        setModalProps({
          ...modalProps,
          open: false,
          title: '',
          desc: '',
        });
        const del = await onChnlDel();
        setTimeout(() => {
          setIsModalChannelDetailOpen(false);
          if (del) {
            console.log(del);
            navigate(0);
          }
        });
      },
    });
  };

  const showModalConfirm2 = () => {
    setModalProps({
      ...modalProps,
      open: true,
      title: '등록 확인',
      desc: '해당 채널을 등록하시겠습니까?',
      onOk: async data => {
        console.log('on ok: ', data);
        setModalProps({
          ...modalProps,
          open: false,
          title: '',
          desc: '',
        });
        const submit = await onChnlSubmit();
        setTimeout(() => {
          setIsModalOpen(false);
          if (submit) {
            navigate(0);
          }
        });
      },
    });
  };

  const [modalProps, setModalProps] = useState({
    open: false,
    title: 'Title',
    desc: 'Title desc',
    onOk: () => {},
    onCancel: () => {
      console.log('on cancel');
      setModalProps({
        ...modalProps,
        open: false,
        title: '',
        desc: '',
      });
    },
  });

  const handleImageError = event => {
    event.target.src = createNoImageTextImage(); // "이미지 없음" 텍스트 이미지를 생성하여 교체
    event.target.onerror = null; // 이벤트 핸들러가 무한루프에 빠지지 않도록 삭제
  };

  const createNoImageTextImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');

    // 흰색 배경
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // "이미지 없음" 텍스트
    ctx.fillStyle = '#000000'; // 텍스트 색상
    ctx.font = '16px sans-serif'; // 폰트 및 크기
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('이미지 없음', canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL(); // 생성된 이미지를 data URL로 반환
  };

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div className="flex gap-16">
          <Input
            onChange={e => setChnlNameSearch(e.target.value)}
            css={css`
              width: 421px;
            `}
            placeholder="채널명을 검색해주세요."
          />
          <SearchOutlined className="style-search" onClick={onSearch} />
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="bg-title-table flex-between">
            <p className="text-title-table">
              총 채널 수 &#123;{formatNumberWithCommas(totalChnlCount)}&#125;
            </p>
            <Button className="mr-15" onClick={showModal}>
              채널 등록
            </Button>

            <Modal
              title={null}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  style={{ width: 126, height: 46, background: 'black' }}
                  onClick={showModalConfirm2}
                >
                  등록하기
                </Button>,
              ]}
            >
              <Breadcrumb
                className="pb-20 border-b style-title-breadcrumb"
                separator=">"
              >
                <Breadcrumb.Item>
                  <p className="text-breadcrumb">채널 관리</p>
                </Breadcrumb.Item>
                <Breadcrumb.Item>채널 등록</Breadcrumb.Item>
              </Breadcrumb>
              <div className="flex flex-col gap-20 mt-35 mb-130">
                <div className="flex-between">
                  <p className="text-label">채널 카테고리</p>
                  <Select
                    placeholder="등록할 채널 카테고리를 선택해주세요."
                    style={{
                      width: 330,
                      height: 43,
                    }}
                    onChange={ctgrChange}
                    options={[
                      {
                        value: '0',
                        label: '주식',
                      },
                      {
                        value: '1',
                        label: '상품/서비스',
                      },
                      {
                        value: '2',
                        label: '국가',
                      },
                      {
                        value: '3',
                        label: '기타',
                      },
                    ]}
                  ></Select>
                </div>
                <div className="flex-between">
                  <p className="text-label">채널명 </p>
                  <Input
                    placeholder="등록할 채널명을 입력해주세요."
                    onChange={e => setChnlName(e.target.value)}
                    style={{
                      width: 330,
                      height: 43,
                    }}
                  ></Input>
                </div>
                <div className="flex-between">
                  <p className="text-label">채널 제한 </p>
                  <Select
                    placeholder="채널을 사용할 수 있는 회원을 선택해주세요."
                    style={{
                      width: 330,
                      height: 43,
                    }}
                    onChange={cacChange}
                    options={[
                      {
                        value: 'Y',
                        label: '일반회원',
                      },
                      {
                        value: 'N',
                        label: '기업회원',
                      },
                    ]}
                  ></Select>
                </div>
                <div className="flex justify-between item-start">
                  <div className="spc-display-1">
                    <p className="text-label">채널 썸네일</p>
                  </div>
                  <div>
                    <Upload
                      // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture"
                      customRequest={({ file, onSuccess, onError }) => {
                        if (!file.type.startsWith('image/')) onError('IMAGE');
                        else onSuccess();
                      }}
                      onChange={info => onChangeFiles(info)}
                      maxCount={1}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        type="primary"
                        style={{
                          width: 330,
                          height: 43,
                        }}
                      >
                        채널 썸네일 이미지를 등록해주세요.
                      </Button>
                    </Upload>
                  </div>
                </div>

                <div className="flex-between">
                  <p className="text-label">종목 코드 </p>
                  <Input
                    placeholder="종목코드를 입력해주세요."
                    onChange={e => setStockCd(e.target.value)}
                    style={{
                      width: 330,
                      height: 43,
                    }}
                    maxLength={30}
                  ></Input>
                </div>
              </div>
            </Modal>
          </div>

          <Table
            className="ant-table"
            columns={columns}
            dataSource={chnlList}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  console.log(record);
                  showModalChannelDetail({
                    stockCd: record.stock_cd,
                    channelId: record.channel_id,
                  });
                },
              };
            }}
            pagination={false}
            loading={loading}
          />
          <Pagination
            current={page + 1}
            defaultCurrent={1}
            total={totalChnlCount || 1}
            showSizeChanger={false}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px 0',
            }}
            onChange={(page, pageSize) => pageChange(page)}
          />
          <Modal
            title={null}
            open={isModalChannelDetailOpen}
            onOk={handleOkChannelDetail}
            onCancel={handleCancelChannelDetail}
            footer={[
              <Button
                key="submit"
                type="primary"
                style={{ width: 126, height: 46, background: 'black' }}
                onClick={showModalConfirm}
              >
                삭제하기
              </Button>,
            ]}
          >
            <Breadcrumb
              className="pb-20 border-b style-title-breadcrumb"
              separator=">"
            >
              <Breadcrumb.Item>
                <p className="text-breadcrumb">채널 관리</p>
              </Breadcrumb.Item>
              <Breadcrumb.Item>채널 상세</Breadcrumb.Item>
            </Breadcrumb>
            <div className="relative flex flex-col gap-20 mt-35 mb-60">
              <div className="flex-between">
                <p className="text-label">채널 카테고리</p>
                <Input
                  placeholder="채널 카테고리"
                  value={find(chnlType, { key: detailInfo.type })?.name}
                  style={{
                    width: 330,
                    height: 43,
                  }}
                  disabled
                ></Input>
              </div>
              <div className="flex-between">
                <p className="text-label">채널명 </p>
                <Input
                  placeholder="등록할 채널명을 입력해주세요."
                  value={detailInfo.channel_nm}
                  style={{
                    width: 330,
                    height: 43,
                  }}
                  disabled
                ></Input>
              </div>
              <div className="flex justify-between item-start">
                <div className="spc-display-1">
                  <p className="text-label">채널 썸네일</p>
                </div>
                <div
                  css={css`
                    width: 330px;
                  `}
                >
                  <div
                    css={css`
                      width: 200px;
                      height: 200px;
                      background: #ffffff;
                    `}
                  >
                    <img
                      css={styles.avatar}
                      alt=""
                      src={getUploadImage(
                        detailInfo.thumb_url
                          ? detailInfo.thumb_url
                          : createNoImageTextImage,
                      )}
                      onError={handleImageError}
                    />
                  </div>
                </div>
              </div>
              <div className="display-switch">
                <div className="wrapper-switch">
                  <p className="text-switch">추천 채널 전환</p>
                  <Switch
                    onChange={onChangeReComm}
                    checked={
                      detailInfo?.recomm_channel_yn === 'Y' ? true : false
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <ModalConfirm model={modalProps} />
      </div>
    </div>
  );
};

export default ChannelManage;
