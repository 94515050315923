import React, { useEffect, useState } from 'react';
import '@asset/css/style.css';
import Header from '@component/admin/common/Header';
import { DatePicker, Select, Input, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Table } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';

import { getNoticeList } from '@lib/api-call/admin/postApi';
import { toNumber } from 'lodash';
import dayjs from 'dayjs';

function getOneMonthAgoToNow() {
  // 1달전
  const oneMonthAgo = dayjs().subtract(1, 'month').format('YYYYMMDD');

  // 현재 날짜
  const currentDate = dayjs().format('YYYYMMDD');

  return [oneMonthAgo, currentDate];
}

// 숫자에 천 단위 쉼표 추가하는 함수
function formatNumberWithCommas(number) {
  return toNumber(number).toLocaleString();
}

const { RangePicker } = DatePicker;

const handleChange = value => {
  console.log(`selected ${value}`);
};

const columns = [
  {
    title: 'NO',
    dataIndex: 'num',
    key: 'num',
  },
  {
    title: '카테고리',
    dataIndex: 'type_nm',
    key: 'type_nm',
  },
  {
    title: '종목/채널명',
    dataIndex: 'channel_nm',
    key: 'channel_nm',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '작성자',
    dataIndex: 'user_nm',
    key: 'user_nm',
  },
  {
    title: '등록일',
    dataIndex: 'reg_date',
    key: 'reg_date',
  },
  {
    title: '상태',
    dataIndex: 'post_active_nm',
    key: 'post_active_nm',
  },
];

const statusOptionsSelect = [
  {
    value: '0',
    label: '공개',
  },
  {
    value: '1',
    label: '비공개',
  },
];

const typeOptionsSelect = [
  { value: '0', label: '주식' },
  { value: '1', label: '상품/서비스' },
  { value: '2', label: '국가' },
  { value: '3', label: '기타' },
];

const PostManage = () => {
  const navigate = useNavigate();

  const dataProp = [
    {
      title: '게시글',
      href: '/admin/post-manage',
    },
    {
      title: '게시글 목록',
      href: '',
    },
  ];

  const [postList, setPostList] = useState([]);
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  //search
  const [regDate, setRegDate] = useState(getOneMonthAgoToNow());
  const [postStatus, setPostStatus] = useState(null);
  const [comment, setComment] = useState('');
  const [postType, setPostType] = useState(null);

  const onPostListGet = async () => {
    setLoading(true);
    const postData = await getNoticeList({
      p_start: page * 10,
      p_length: 10,
      p_from_dt: regDate[0],
      p_to_dt: regDate[1],
      p_post_active: postStatus,
      p_type: postType,
      p_comment: comment,
    });

    console.log(postData);
    setPostList(postData.ntPostList);
    setTotalPostCount(postData.total_count);
    setTimeout(() => setLoading(false));
  };

  const pageChange = pageCount => {
    setPage(pageCount - 1);
  };

  const dateChange = (dates, dateStrings) => {
    console.log(dates, dateStrings);
    setRegDate(dates ? dateStrings : []);
  };

  const statusChange = (key, values) => {
    setPostStatus(key || null);
  };
  const typeChange = (key, values) => {
    setPostType(key || null);
  };

  const onSearch = () => {
    if (page === 0) {
      onPostListGet();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    onPostListGet();
  }, []);

  useEffect(() => {
    onPostListGet();
  }, [page]);

  return (
    <div>
      <Header dataProp={dataProp} />
      <div className="wrapper">
        <div className="flex gap-16">
          <RangePicker
            value={regDate.map(x => dayjs(x))}
            format="YYYYMMDD"
            onChange={dateChange}
          />
          <Select
            placeholder="상태"
            style={{ width: 140, height: 42 }}
            onChange={setPostStatus}
            options={statusOptionsSelect.map(item => ({
              value: item.value,
              label: item.label,
              disabled: item.disabled,
            }))}
            allowClear
          />
          <Select
            placeholder="카테고리"
            style={{ width: 120, height: 42 }}
            onChange={setPostType}
            options={typeOptionsSelect.map(item => ({
              value: item.value,
              label: item.label,
              disabled: item.disabled,
            }))}
            allowClear
          />
          <Input
            onChange={e => setComment(e.target.value)}
            css={css`
              width: 421px;
            `}
            placeholder="종목/채널명 을 입력해주세요"
            allowClear
          />
          <SearchOutlined className="style-search" onClick={onSearch} />
        </div>
        <div
          css={css`
            padding-top: 24px;
          `}
        >
          <div className="bg-title-table">
            <p className="text-title-table">
              총 게시글 &#123;{formatNumberWithCommas(totalPostCount)}&#125;{' '}
            </p>
          </div>
          <Table
            className="ant-table"
            columns={columns}
            dataSource={postList}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  console.log(record);
                  navigate(`/admin/post-manage/post-detail/${record.post_id}`);
                },
              };
            }}
            pagination={false}
            loading={loading}
          />
          <Pagination
            current={page + 1}
            defaultCurrent={1}
            total={totalPostCount || 1}
            showSizeChanger={false}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px 0',
            }}
            onChange={(page, pageSize) => pageChange(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default PostManage;
