import { css } from '@emotion/react';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';

const Chip = ({ size, text, circle, propStyle }) => {
  const commonStyle = css`
    border-radius: ${!circle ? toVW('5.33px') : '50%'};
    font-family: NanumBarunGothic;
    font-style: normal;
    font-weight: 400;
  `;

  const sizeStyle =
    size !== 'sm'
      ? css`
          padding: ${stringToVW('5.5px 8px 4px 8px')};
          border: 1px solid #e0e0e0;
          color: #4e5255;
          font-size: ${toVW('12px')};
          line-height: ${toVW('16px')};
        `
      : css`
          padding: ${stringToVW('3.33px 6px')};
          border: 1px solid #f0f0f0;
          color: #7d7d7d;
          ${globalStyle.fontNanu18}
        `;

  return (
    <div css={[commonStyle, sizeStyle, propStyle && propStyle]}>{text}</div>
  );
};

export default Chip;
