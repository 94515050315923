import Checkbox from '@component/app/common/Checkbox';
import { css } from '@emotion/react';
import { updateWritePostItem } from '@store/app/postReducer';
import { globalStyle, toVW } from '@style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';

const WritePostOptionList = ({ selectedChannel }) => {
  const loginedUser = useSelector(state => state.auth.loginedUser);
  const writePostItem = useSelector(state => state.post.writePostItem);
  const dispatch = useDispatch();

  const styles = {
    wrapper: css`
      display: flex;
      padding: ${toVW('24px')};
      flex-direction: column;
      align-items: flex-start;
      gap: ${toVW('12.3px')};
      flex: 1 0 0;
      align-self: stretch;
    `,

    label: css`
      color: #333;

      ${globalStyle.fontNanu21}
      font-weight: 400;
    `,
  };

  const handleStockInfoYn = event => {
    const newItem = { ...writePostItem };
    if (event.target.checked) {
      newItem.stock_info_yn = 'Y';
    } else {
      newItem.stock_info_yn = 'N';
    }

    dispatch(updateWritePostItem(newItem));
  };

  const handleHolderYn = event => {
    const newItem = { ...writePostItem };
    if (event.target.checked) {
      newItem.holder_yn = 'Y';
    } else {
      newItem.holder_yn = 'N';
    }

    dispatch(updateWritePostItem(newItem));
  };

  return (
    loginedUser &&
    loginedUser.USER_TYPE === '0' && (
      <div css={styles.wrapper}>
        {selectedChannel && selectedChannel.isHave === '1' && (
          <Checkbox
            label="주주 전용글로 작성하기"
            checked={writePostItem?.holder_yn === 'Y'}
            onChange={handleHolderYn}
          />
        )}
        {loginedUser.STOCK_INFO_YN && (
          <Checkbox
            label="내 보유종목 공개하기"
            checked={writePostItem?.stock_info_yn === 'Y'}
            onChange={handleStockInfoYn}
          />
        )}
      </div>
    )
  );
};

export default WritePostOptionList;
