import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { SvgIcon } from '@mui/material';
import { css } from '@emotion/react';

const ErrorPage = () => {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: '100dvh', width: '100dvw' }}
    >
      <Box sx={style.header}>
        <SvgIcon
          sx={{ width: stringToVW('14px'), marginRight: stringToVW('4px') }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.2491 0.401664C13.9232 0.075748 13.3948 0.0757474 13.0688 0.401662L6.4699 7.00059L13.0688 13.5996C13.3948 13.9255 13.9232 13.9255 14.2491 13.5996C14.575 13.2736 14.575 12.7452 14.2491 12.4193L8.83039 7.00059L14.2491 1.58191C14.575 1.25599 14.575 0.72758 14.2491 0.401664Z"
              fill="#C5C5C5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.0512 0.401664C1.37711 0.075748 1.90553 0.0757474 2.23144 0.401662L8.83039 7.00059L2.23144 13.5996C1.90553 13.9255 1.37712 13.9255 1.0512 13.5996C0.725284 13.2736 0.725283 12.7452 1.0512 12.4193L6.4699 7.00059L1.0512 1.58191C0.725284 1.25599 0.725283 0.72758 1.0512 0.401664Z"
              fill="#C5C5C5"
            />
          </svg>
        </SvgIcon>
      </Box>
      <Stack alignItems="center">
        <Box
          css={css`
            ${globalStyle.fontNanu26};
            color: #666;
          `}
        >
          서비스 이용에 불편을 드려 죄송합니다
        </Box>
        <Box
          sx={{ margin: stringToVW('16px 0'), textAlign: 'center' }}
          css={css`
            ${globalStyle.fontNanu40};
            font-weight: 600;
          `}
        >
          예기지 못한 오류가
          <br />
          발생했습니다
          <br />
          <br />
          잠시 후 재접속 해주세요
        </Box>
        <SvgIcon
          sx={{
            width: stringToVW('200px'),
            height: stringToVW('200px'),
            margin: stringToVW('40px 44px 0 44px'),
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 200 201"
            fill="none"
          >
            <path
              d="M138.371 63.1702C138.371 76.3915 127.814 87.3244 114.682 88.0872C114.167 88.0872 113.652 88.0872 113.138 88.0872C108.76 88.0872 104.383 86.8159 100.778 84.7819C93.0538 80.4595 87.9041 72.3234 87.9041 62.916C87.9041 56.5596 90.2215 50.7118 94.3412 46.3894C98.9759 41.3043 105.67 37.999 113.138 37.999C122.922 37.999 131.419 43.5926 135.539 51.7288C137.341 55.2883 138.371 59.1022 138.371 63.1702Z"
              fill="#C0F44D"
              stroke="black"
              strokeWidth="1.3"
              strokeMiterlimit="10"
            />
            <path
              opacity="0.1"
              d="M138.15 63.1699C138.15 76.3912 127.593 87.3241 114.461 88.0869C109.569 87.3241 104.934 86.0528 100.557 85.0358C92.8325 80.7135 87.6829 72.5773 87.6829 63.1699C87.6829 56.8136 90.0002 50.9657 94.12 46.6434C102.617 46.3891 112.401 47.1519 122.443 48.9317C127.078 49.6944 131.455 50.7114 135.575 51.7285C137.12 55.288 138.15 59.1019 138.15 63.1699Z"
              fill="#AAB2C5"
            />
            <path
              d="M168.937 79.9975C170.88 69.2145 146.919 55.9857 115.419 50.4504C83.9193 44.915 56.8082 49.169 54.8649 59.9521C52.9216 70.7352 76.882 83.9639 108.382 89.4993C139.882 95.0347 166.993 90.7806 168.937 79.9975Z"
              fill="#54D600"
              stroke="black"
              strokeWidth="1.3"
              strokeMiterlimit="10"
            />
            <path
              d="M100.428 54.8475C102.198 54.9117 103.754 55.3242 104.837 55.9091C105.96 56.5158 106.387 57.191 106.367 57.7204C106.347 58.2497 105.869 58.8921 104.702 59.4157C103.578 59.9205 101.995 60.219 100.225 60.1548C98.4559 60.0906 96.8996 59.6781 95.817 59.0932C94.694 58.4865 94.2664 57.8112 94.2867 57.2819C94.3069 56.7526 94.7849 56.1102 95.9511 55.5866C97.0754 55.0818 98.6588 54.7833 100.428 54.8475Z"
              stroke="black"
              strokeWidth="1.3"
            />
            <path
              d="M79.4741 58.6634C79.6474 59.1655 79.4333 59.9342 78.5343 60.8349C77.6675 61.7033 76.2969 62.5417 74.6212 63.1073C72.9455 63.6729 71.3426 63.8382 70.1196 63.6751C68.8513 63.506 68.2065 63.0272 68.0332 62.5251C67.8598 62.0229 68.0739 61.2542 68.9729 60.3536C69.8398 59.4851 71.2103 58.6468 72.8861 58.0811C74.5618 57.5155 76.1647 57.3503 77.3877 57.5133C78.6559 57.6824 79.3008 58.1613 79.4741 58.6634Z"
              stroke="black"
              strokeWidth="1.3"
            />
            <path
              d="M127.038 59.8551C128.793 60.0909 130.3 60.6525 131.319 61.3397C132.376 62.0525 132.734 62.7661 132.661 63.2912C132.588 63.8164 132.048 64.4092 130.836 64.817C129.667 65.2102 128.062 65.3535 126.308 65.1177C124.554 64.8819 123.046 64.3203 122.027 63.6331C120.971 62.9203 120.612 62.2068 120.685 61.6816C120.758 61.1565 121.298 60.5636 122.51 60.1558C123.679 59.7627 125.284 59.6194 127.038 59.8551Z"
              stroke="black"
              strokeWidth="1.3"
            />
            <path
              d="M148.442 70.6754C150.046 71.4124 151.317 72.3886 152.084 73.3422C152.88 74.3307 153.01 75.1179 152.782 75.6007C152.554 76.0835 151.86 76.492 150.581 76.5279C149.348 76.5625 147.772 76.2312 146.168 75.4942C144.564 74.7573 143.293 73.7811 142.526 72.8275C141.731 71.839 141.6 71.0518 141.828 70.569C142.056 70.0862 142.75 69.6777 144.029 69.6418C145.262 69.6072 146.838 69.9385 148.442 70.6754Z"
              stroke="black"
              strokeWidth="1.3"
            />
            <path
              d="M108.691 72.138L84.487 71.8838L42 158.015L141.5 162.995L135.984 80.7827L108.691 72.138Z"
              fill="url(#paint0_linear_5046_38162)"
            />
            <path
              d="M159.41 162.999H35.5603C38.9076 160.011 43.7998 157.023 49.4644 154.782C52.5542 153.662 56.159 152.168 59.7638 151.421C70.5781 148.433 83.7098 146.565 97.6139 146.565C114.35 146.565 129.799 149.18 141.644 153.288C145.763 154.782 149.368 156.276 152.458 158.143C155.29 159.637 157.608 161.131 159.41 162.999Z"
              fill="#ABB5B5"
              stroke="black"
              strokeWidth="1.3"
              strokeMiterlimit="10"
            />
            <path
              d="M61.0127 154.509C61.175 156.026 58.5208 157.842 55.4484 158.168C52.376 158.494 49.6804 157.502 49.2621 156.012C52.2535 154.927 55.7298 153.536 59.2602 152.65C60.3384 153.047 60.9316 153.751 61.0127 154.509Z"
              fill="black"
            />
            <path
              d="M83.1948 152.159C87.3187 152.159 90.6618 151.362 90.6618 150.379C90.6618 149.396 87.3187 148.6 83.1948 148.6C79.0709 148.6 75.7278 149.396 75.7278 150.379C75.7278 151.362 79.0709 152.159 83.1948 152.159Z"
              fill="black"
            />
            <path
              d="M123.444 152.013C123.528 150.752 120.261 149.512 116.146 149.242C112.032 148.973 108.627 149.777 108.543 151.038C108.458 152.299 111.725 153.54 115.84 153.809C119.955 154.078 123.359 153.274 123.444 152.013Z"
              fill="black"
            />
            <path
              d="M151.132 158.58C150.109 158.463 148.88 158.502 147.374 158.073C143.311 157.35 140.132 155.706 140.278 154.443C140.307 154.19 140.307 154.19 140.592 153.967C144.568 155.447 148.182 156.879 151.132 158.58Z"
              fill="black"
            />
            <path
              d="M135.829 81.5773C136.701 76.7387 125.832 70.782 111.552 68.2727C97.2721 65.7633 84.989 67.6514 84.117 72.49C83.245 77.3286 94.1143 83.2852 108.394 85.7946C122.674 88.304 134.957 86.4158 135.829 81.5773Z"
              fill="#C0F44D"
              stroke="black"
              strokeWidth="1.3"
              strokeMiterlimit="10"
            />
            <path
              d="M92.6652 127.214C92.7527 127.191 92.8264 127.171 92.8841 127.156L93.1218 127.207L94.1484 127.432C94.9976 127.617 96.1289 127.864 97.2573 128.112C98.3858 128.359 99.5111 128.607 100.348 128.792C100.767 128.885 101.113 128.962 101.351 129.016C101.458 129.04 101.542 129.059 101.6 129.072L101.663 129.107C101.691 129.122 101.722 129.139 101.756 129.158C101.894 129.235 102.086 129.345 102.299 129.481C102.74 129.762 103.221 130.125 103.522 130.51C103.822 130.892 103.841 131.143 103.73 131.352C103.572 131.653 103.039 132.132 101.562 132.617C98.7093 133.555 95.7453 133.328 93.1377 132.64C90.5354 131.953 88.3311 130.818 87.0267 129.991C86.8843 129.895 86.8336 129.83 86.8169 129.802C86.8266 129.776 86.8593 129.709 86.9674 129.599C87.3209 129.239 88.0675 128.83 89.0108 128.437C89.9252 128.055 90.9292 127.725 91.711 127.489C92.1004 127.372 92.4317 127.278 92.6652 127.214ZM102.418 128.79C102.403 128.781 102.388 128.772 102.373 128.763C102.354 128.822 102.33 128.876 102.302 128.921C102.347 128.878 102.39 128.831 102.416 128.793C102.417 128.792 102.417 128.791 102.418 128.79ZM101.948 129.11C101.992 129.101 102.11 129.058 102.208 129.005C102.186 129.021 102.167 129.035 102.151 129.045C102.075 129.084 102.003 129.102 101.948 129.11ZM101.855 129.117C101.872 129.116 101.917 129.114 101.945 129.11C101.901 129.117 101.869 129.117 101.856 129.117H101.855ZM86.8096 129.786C86.8098 129.786 86.8105 129.788 86.8112 129.791C86.8098 129.788 86.8094 129.786 86.8096 129.786Z"
              fill="#A4D831"
              stroke="black"
              strokeWidth="1.3"
            />
            <path
              d="M106.719 127.744C107.558 128.506 108.276 129.009 108.959 129.31C107.454 130.468 105.049 131.746 102.443 132.33C99.2568 133.044 95.9245 132.694 93.5357 130.013L93.1688 129.601L92.7027 129.896C91.3146 130.775 90.0289 130.934 88.8706 130.709C87.6918 130.479 86.599 129.842 85.6485 129.056C84.7046 128.276 83.9328 127.374 83.393 126.659C84.0445 126.71 84.777 126.712 85.5614 126.671C87.0909 126.591 88.875 126.347 90.7508 125.941C94.3089 125.173 98.2503 123.813 101.444 121.856C103.767 124.706 105.42 126.565 106.719 127.744Z"
              fill="#C0F44D"
              stroke="black"
              strokeWidth="1.3"
            />
            <path
              d="M128.03 118.99C127.423 118.552 126.908 118.167 126.496 117.854C127.041 117.754 127.635 117.6 128.258 117.403C129.551 116.995 131.022 116.387 132.531 115.621C135.391 114.171 138.433 112.131 140.686 109.764C143.313 111.69 145.129 112.914 146.499 113.634C147.415 114.115 148.159 114.386 148.835 114.484C147.846 115.911 146.26 117.793 144.177 119.465C141.68 121.469 138.502 123.146 134.807 123.415C132.039 121.79 129.693 120.191 128.03 118.99Z"
              fill="#C0F44D"
              stroke="black"
              strokeWidth="1.14681"
            />
            <path
              d="M60.4242 102.006C59.9386 102.356 59.5261 102.664 59.1971 102.915C59.6325 102.995 60.1077 103.118 60.6068 103.276C61.641 103.602 62.8175 104.088 64.0249 104.701C66.3126 105.862 68.7467 107.494 70.5487 109.387C72.6508 107.846 74.1035 106.867 75.1988 106.291C75.9323 105.906 76.5275 105.689 77.068 105.611C76.2771 104.469 75.0082 102.964 73.3419 101.627C71.3443 100.023 68.8016 98.6815 65.8454 98.4661C63.6312 99.766 61.7543 101.046 60.4242 102.006Z"
              fill="#C0F44D"
              stroke="black"
              strokeWidth="0.917452"
            />
            <path
              d="M190 162.999H182.182H20.1005H10"
              stroke="black"
              strokeWidth="1.3"
              strokeMiterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5046_38162"
                x1="98.3969"
                y1="74.454"
                x2="98.3969"
                y2="115.983"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0.4" />
                <stop offset="1" stopColor="#54D600" stopOpacity="0.05" />
              </linearGradient>
            </defs>
          </svg>
        </SvgIcon>
      </Stack>
      <Box
        sx={{
          marginBottom: stringToVW('64px'),
          width: '100%',
          padding: stringToVW('0 36px'),
        }}
      >
        <Button
          variant="contained"
          sx={style.checkedButton}
          css={css`
            ${globalStyle.fontNanu30}
          `}
        >
          확인
        </Button>
      </Box>
    </Stack>
  );
};

const style = {
  checkedButton: {
    width: '100%',
    height: stringToVW('60px'),
    backgroundColor: '#84C13D !important',
    borderRadius: stringToVW('8px'),
    color: '#fff',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: stringToVW('40px'),
    padding: stringToVW('0 16px'),
  },
};

export default ErrorPage;
