import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { toVW } from '@style/globalStyle';
import LinkThumb from './LinkThumb';

const LinkThumbList = ({ links, isDetail }) => {
  const [ogMetaList, setOgMetaList] = useState([]);
  const [isFinish, setIsFinish] = useState(false);

  const checkFinish = idx => {
    if (links.length === idx + 1) {
      setIsFinish(true);
    }
  };

  const handleOgMetaList = item => {
    setOgMetaList(ogMetaList => [...ogMetaList, item]);
  };

  return (
    links && (
      <>
        <Swiper
          style={{ width: '100%' }}
          loop={false}
          spaceBetween={10}

          // pagination={{
          //   clickable: true,
          // }}
          // modules={[FreeMode]}
        >
          {links.map((link, index) => (
            <SwiperSlide style={{ width: '100%' }} key={link + index}>
              <LinkThumb
                link={link}
                idx={index}
                checkFinish={checkFinish}
                handleOgMetaList={handleOgMetaList}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {!isDetail && isFinish && ogMetaList.length === 0 && (
          <div
            style={{
              borderTop: '1px solid #f0f0f0',
              width: '100%',
              marginBottom: toVW('-16px'),
            }}
          />
        )}
      </>
    )
  );
};

export default LinkThumbList;
