import React from 'react';
import Popup from '@component/app/common/PopupMui';
import { css } from '@emotion/react';
import { globalStyle } from '@style/globalStyle';
import { useSelector } from 'react-redux';
import { callMTSFunction } from '@lib/commonUtils';
import {
  CALL_MTS_ACTION,
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
} from '@data/constants';
import { removeCookie } from '@lib/cookieUtils';
import { useNavigate } from 'react-router-dom';

export const CheckWithdrawAccount = () => {
  const navigate = useNavigate();
  const [openPopupConfirm, setOpenPopupConfirm] = React.useState(false);
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const handleConfirm = () => {
    setOpenPopupConfirm(false);
    removeCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
    removeCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
    callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
    navigate('/');
  };

  React.useEffect(() => {
    loginedUser?.WITHDRAWAL_DT?.length && setOpenPopupConfirm(true);
  }, [loginedUser?.WITHDRAWAL_DT?.length]);
  return (
    <Popup
      open={openPopupConfirm}
      data={{
        title: (
          <div
            css={css`
              ${globalStyle.fontNanu24};
              text-align: center;
              color: #333;
            `}
          >
            탈퇴 처리된 계정입니다.
          </div>
        ),
        content: null,
      }}
      cancelButton={false}
      onClose={() => {}}
      confirmText="확인"
      confirmEvent={handleConfirm}
    />
  );
};
