import React, { useEffect, useState } from 'react';
import Input from '@mui/material/Input';
import { css, keyframes } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import IconTooltip from '@asset/icons/profile/icon-tooltip.svg';
import IconTooltip from '@asset/icons/post/icon_tooltip.svg';
import Popup from '@component/app/common/PopupMui';

import IconLine from '@asset/icons/profile/icon-line.svg';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import PopupChangeAvatar from '@component/app/profile/PopupChangeAvatar';
import { checkTextWithKiso, getUploadImage } from '@lib/commonUtils';
import HeaderBasic from '@component/app/common/HeaderBasic';
import {
  getMyProfile,
  nickNameCheckApi,
  profileChangeApi,
} from '@lib/api-call/app/profileApi';
import { useNavigate } from 'react-router-dom';
import { uploadFiles } from '@lib/api-call/app/fileApi';
import { getDictionaryList } from '@lib/api-call/app/commApi';
import _ from 'lodash';
import HtmlTooltip from '@component/app/common/HtmlTooltip';
import Stack from '@mui/material/Stack';
import { stringToVW, globalStyle } from '@style/globalStyle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getUserInfo } from '@lib/api-call/app/authApi';
import { updateLoginedUser } from '@store/app/authReducer';
import {} from '@lib/commonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { checkImageUrlValidWithGreenEyeAPI, isDev } from '@lib/commonUtils';
import { setAlertInfo, openSnackbar } from '@store/app/commonReducer';

const loadingAnimate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ariaLabel = { 'aria-label': 'description' };

// async function hasInappropriateText(text, dictionaryList) {
//   const lowercaseText = text?.toLowerCase() || '';
//   const lowercaseInappropriateWords = dictionaryList.map(word =>
//     word.toLowerCase(),
//   );

//   return _.some(lowercaseInappropriateWords, word =>
//     _.includes(lowercaseText, word),
//   );
// }
async function hasInappropriateText(text, dictionaryList) {
  const lowercaseText = text.toLowerCase();
  const lowercaseInappropriateWords = dictionaryList.map(word =>
    word.toLowerCase(),
  );

  const filteredWords = lowercaseInappropriateWords.filter(word =>
    _.includes(lowercaseText, word),
  );

  const isInappropriateWords = _.some(lowercaseInappropriateWords, word => {
    const has = _.includes(lowercaseText, word);

    return has;
  });

  return { isInappropriateWords, filteredWords };
}

async function hasInappropriateExactMatch(text, dictionaryList) {
  const lowercaseText = text.toLowerCase();
  const lowercaseInappropriateWords = dictionaryList.map(word =>
    word.toLowerCase(),
  );

  return _.some(lowercaseInappropriateWords, word =>
    _.isEqual(lowercaseText, word),
  );
}
const ErrorBox = ({ message }) => {
  return (
    <span
      css={css`
        color: #d73838;
        ${globalStyle.fontNanu15}
        font-weight: 400;
      `}
    >
      {message}
    </span>
  );
};

export const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 21,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(15px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#84C13D',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alertInfo = useSelector(state => state.common.alertInfo);

  const [isShow, setIsShow] = useState(false);
  const [loadingOverlayShow, setLoadingOverlayShow] = useState(false);
  const [loadingOverlayText, setLoadingOverlayText] = useState('');

  // nickName, 소개 유효성 체크
  const [nickNameValidation, setNickNameValidation] = useState(false);
  const [nickNameCheck, setNickNameCheck] = useState(true);
  const [nickNameValidationText, setNickNameValidationText] = useState('');

  const [introValidation, setIntroValidation] = useState(false);
  // const [nickNameCheck, setNickNameCheck] = useState(false);
  const [introValidationText, setIntroValidationText] = useState('');

  // 값
  const [nickName, setNickName] = useState('');
  const [intro, setIntro] = useState('');
  const [profileImage, setProfileImage] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [avatarImage, setAvatarImage] = useState(null);
  const [linked, setLinked] = useState(false);
  const [badWordList, setBadWordList] = useState([]);
  const [forbiddenWordList, setForbiddenWordList] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [nickNameDisabled, setNickNameDisabled] = useState(false);
  const [openPopupErrorAvatar, setOpenPopupErrorAvatar] = useState(false);
  // myProfile
  const [myProfile, setMyProfile] = useState({});

  const handleShow = () => {
    setIsShow(true);
    // eslint-disable-next-line no-console
  };
  const handleChangeAvatar = boolean => {
    setIsShow(boolean);
  };

  const imagePush = files => {
    setAvatarImage(null);
    setUploadImage([]);
    if (files?.type === 'avatar') {
      setAvatarImage(files.url);
      return;
    }
    setUploadImage(files);
  };

  const onMyProfileGet = async () => {
    const myProfileData = await getMyProfile();
    setMyProfile({ ...myProfileData });
    setNickName(myProfileData.USER_NM);
    setIntro(myProfileData.PROFILE_INTRODUCTION);
    setProfileImage(myProfileData.PROFILE_IMG);
    setLinked(myProfileData.STOCK_INFO_YN === 'Y' ? true : false);
    setTimeout(() => {
      setNickNameCheck(true);
    });
  };

  const ondictionaryListGet = async () => {
    const dictionaryList = await getDictionaryList();
    setBadWordList(dictionaryList.badWord);
    setForbiddenWordList(dictionaryList.forbiddenWord);
    setCompanyName(dictionaryList.companyName);
  };

  useEffect(() => {
    onMyProfileGet();
    ondictionaryListGet();
  }, []);

  useEffect(() => {
    if (myProfile.USER_NM === nickName) return;
    setNickNameCheck(false);
  }, [nickName]);

  useEffect(() => {
    // if (myProfile.USER_NM === nickName) return setNickNameCheck(true);
    // setNickNameCheck(false);
  }, [nickName]);

  const dupCheck = async () => {
    if (myProfile.USER_NM === nickName) {
      setNickNameValidation(false);
      setNickNameCheck(true);
      return;
    }
    const check = await nickNameCheckApi({
      nickName: encodeURIComponent(nickName),
    });

    if (check.message_cd !== '0') {
      setNickNameValidation(true);
      setNickNameValidationText(check.message_nm);
      setNickNameCheck(false);
      if (check.message_cd === '6') {
        setNickNameDisabled(true);
        setNickName(myProfile.USER_NM);
        setNickNameCheck(true);
      }
    } else {
      // let text = await checkTextWithKiso(nickName);
      // let checkBadWordTypeOne = hasInappropriateText(
      //   nickName,
      //   forbiddenWordList,
      // );
      // console.log(text, checkBadWordTypeOne);
      setNickNameValidation(false);
      setNickNameValidationText('');
      setNickNameCheck(true);
    }
  };

  const submitProfile = async () => {
    setIntroValidation(false);
    setNickNameValidation(false);
    setLoadingOverlayShow(true);
    setLoadingOverlayText('');
    let text = await checkTextWithKiso(nickName);
    let { isInappropriateWords } = await hasInappropriateText(nickName, [
      ...forbiddenWordList,
      ...companyName,
      ...badWordList,
    ]);

    if (text?.length || isInappropriateWords) {
      setLoadingOverlayShow(false);
      setNickNameValidation(true);
      setNickNameValidationText('금칙어가 포함되어 있습니다.');
      return;
    }
    // 닉네임 유효성 검사
    if (!nickNameCheck) {
      setLoadingOverlayShow(false);
      setNickNameValidation(true);
      setNickNameValidationText('중복확인을 진행 해주세요');
      return;
    }

    const [checkBadwordIntro] = await Promise.all([checkTextWithKiso(intro)]);
    // 인트로 유효성 검사
    if (checkBadwordIntro?.length) {
      setIntroValidation(true);
      setIntroValidationText('금칙어가 포함되어 있습니다.');
      setLoadingOverlayShow(false);
      return;
    } else if (intro?.length > 30) {
      setIntroValidation(true);
      setIntroValidationText('소개는 30자까지 입력 가능합니다.');
      setLoadingOverlayShow(false);
      return;
    }
    //   return console.log('비속어');

    let profile_img = profileImage || avatarImage || null;

    if (uploadImage?.length > 0 && !avatarImage) {
      setLoadingOverlayText('이미지 업로드 중입니다.');
      const upload = await uploadFiles(uploadImage);
      // profile_img = upload?.[0].imageURL;
      const { isConfidence, isConfidenceData } =
        // eslint-disable-next-line no-undef
        await checkImageUrlValidWithGreenEyeAPI(
          upload.map(res => {
            let url = decodeURIComponent(res.imageURL);
            return url;
          }),
        );
      if (!isConfidence) {
        const newAlertInfo = { ...alertInfo };
        newAlertInfo.severity = 'error';
        newAlertInfo.msg = isDev()
          ? 'GreenEyeResult ' + JSON.stringify(isConfidenceData)
          : '';
        dispatch(setAlertInfo(newAlertInfo));
        isDev() && dispatch(openSnackbar());
        setOpenPopupErrorAvatar(true);
        setLoadingOverlayShow(false);

        return;
      } else {
        profile_img = upload?.[0].thumbnailURL;
      }
    }

    if (avatarImage) {
      profile_img = avatarImage;
    }

    setLoadingOverlayText('정보를 저장하는 중입니다.');
    try {
      const submit = await profileChangeApi({
        user_nm: nickName,
        profile_introduction: intro,
        stock_info_yn: linked ? 'Y' : 'N',
        profile_img,
      });
      if (submit.message_cd === '0') {
        setLoadingOverlayText('저장이 완료되었습니다!');
        const userInfo = await getUserInfo();
        if (userInfo) {
          dispatch(updateLoginedUser(userInfo));
        }
        navigate('/my-profile');
      } else {
        setLoadingOverlayShow(false);
      }
    } catch (err) {
      setLoadingOverlayShow(false);
      setIntroValidation(true);
      setIntroValidationText('사용불가능한 문자가 포함되어 있습니다.');
    }

    // 오류내용 입력해야함
  };

  const styles = {
    wrapper: css`
      color: #000;
      ${globalStyle.fontNanu21}
      font-weight: 400;
      min-height: 100dvh;
    `,
    btnSelectImg: css`
      color: var(--nm-default-3013, #666);
      text-align: center;
      ${globalStyle.fontNanu18}
      font-weight: 400;
      display: flex;
      height: ${stringToVW('27px')};
      padding: ${stringToVW('10px 13px 8px 13px')};
      justify-content: center;
      align-items: center;
      gap: ${stringToVW('10px')};
      border-radius: 999px;
      border: 1px solid var(--nmess-line-2017, #e0e0e0);
      background: var(--nm-default-6063, #fff);
    `,
    btnRegular: css`
      color: #fff;
      text-align: center;
      ${globalStyle.fontNanu18}
      font-weight: 400;
      height: ${stringToVW('26px')};
      padding: ${stringToVW('5px 13px 5px 13px')};
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${stringToVW('10px')};
      border-radius: ${stringToVW('6.5px')};
      margin-bottom: ${stringToVW('5.5px')};
      background: #4e5255;
      border: none;
    `,
    input: css`
      width: 100%;
      ${globalStyle.fontNanu21}
      font-weight: 400;
      height: ${stringToVW('40px')};
      ::before {
        border-bottom: 1px solid var(--essential-line-line-300017, #e0e0e0);
      }
    `,
  };
  return (
    <div css={styles.wrapper}>
      <HeaderBasic
        title="내 프로필 편집"
        statusUser="company"
        saveEvent={submitProfile}
      />
      <div
        css={css`
          margin-bottom: ${stringToVW('32px')};
          margin-top: ${stringToVW('16px')};
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              position: relative;
              width: ${stringToVW('62px')};
              height: ${stringToVW('62px')};
            `}
          >
            {Number(myProfile.USER_TYPE) !== 0 ? (
              <i
                css={css`
                  position: absolute;
                  z-index: 10;
                  width: ${stringToVW('30px')};
                  right: 0;
                  bottom: ${stringToVW('-10px')};
                `}
              >
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.4284 3.44583V11.0862C26.4284 26.1381 15.0001 28.5714 15.0001 28.5714C15.0001 28.5714 3.57129 26.1376 3.57129 11.0862V3.44583C3.57129 3.44583 11.8157 5.21747 15.0001 0C18.184 5.21747 26.4284 3.44583 26.4284 3.44583Z"
                    fill="#6FADFF"
                  />
                  <path
                    d="M10 15.2857L13.5714 19.5714L20.7143 11"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </i>
            ) : null}
            <Avatar
              src={
                getUploadImage(avatarImage) ||
                (uploadImage?.[0] && URL?.createObjectURL(uploadImage?.[0])) ||
                getUploadImage(profileImage)
              }
              sx={{ width: stringToVW('62px'), height: stringToVW('62px') }}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
            margin-top: ${stringToVW('10.5px')};
          `}
        >
          <button css={styles.btnSelectImg} onClick={handleShow}>
            이미지 변경
          </button>
          <input
            id="changeAvt"
            css={css`
              display: none;
            `}
            type="file"
          />
        </div>
      </div>
      <Box
        css={css`
          padding: ${stringToVW('10.5px 24px')};
          border-top: 1px solid #f5f5f5;
        `}
      >
        <Grid container>
          <Grid xs={3} display="flex" alignItems="center">
            <label htmlFor="input">닉네임</label>
          </Grid>
          <Grid xs={9}>
            <div
              css={css`
                position: relative;
              `}
            >
              <Input
                css={styles.input}
                color="secondary"
                id="input"
                placeholder={myProfile.USER_NM}
                inputProps={{ ...ariaLabel, maxLength: 12 }}
                value={nickName}
                onChange={e => setNickName(e.target.value)}
                disabled={nickNameDisabled}
                endAdornment={
                  <InputAdornment position="end">
                    {nickNameCheck ? (
                      <img
                        css={css`
                          width: ${stringToVW('22.5px')};
                          height: ${stringToVW('22.5px')};
                        `}
                        src={IconLine}
                        alt=""
                      />
                    ) : (
                      <button
                        css={styles.btnRegular}
                        onClick={() => dupCheck()}
                      >
                        중복확인
                      </button>
                    )}
                  </InputAdornment>
                }
              />
            </div>
            {nickNameValidation && (
              <ErrorBox message={nickNameValidationText} />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          css={css`
            margin-top: ${stringToVW('10px')};
          `}
        >
          <Grid xs={3} display="flex" alignItems="center">
            <label htmlFor="input">소개</label>
          </Grid>
          <Grid xs={9}>
            <div>
              <Input
                multiline
                css={styles.input}
                id="input"
                placeholder="한줄 소개를 입력해주세요."
                inputProps={{ ...ariaLabel, maxLength: 30 }}
                value={intro}
                onChange={e => setIntro(e.target.value)}
                onFocus={e => {
                  let len = e.target.value.length;
                  setTimeout(() => {
                    e.target.setSelectionRange(len, len);
                    e.target.scrollTo(9999, 9999);
                  });
                }}
              />
            </div>
            {introValidation && <ErrorBox message={introValidationText} />}
          </Grid>
        </Grid>
        {Number(myProfile.USER_TYPE) === 0 && (
          <Grid
            container
            css={css`
              margin-top: ${stringToVW('10.7px')};
            `}
            spacing={2}
          >
            <Grid xs={6} css={css``}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <span>주주정보 연동</span>
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    ${globalStyle.fontNanu21}
                    margin-left: ${stringToVW('5.5px')};
                  `}
                >
                  <HtmlTooltip
                    Icon={
                      <img
                        alt="icon"
                        src={IconTooltip}
                        style={{
                          width: stringToVW('16px'),
                          height: stringToVW('16px'),
                        }}
                      />
                    }
                  >
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      css={css`
                        ${globalStyle.fontNanu18};
                        letter-spacing: ${stringToVW('0.05px')};
                      `}
                    >
                      <div
                        style={{ fontWeight: 'bold', width: '100%' }}
                        css={css`
                          ${globalStyle.fontNanu21}
                        `}
                      >
                        주주정보 연동을 활성화하면?
                      </div>
                      <div
                        style={{ height: stringToVW('6.5px'), width: '100%' }}
                      />
                      <span style={{ width: stringToVW('9px') }}>·</span>
                      <span
                        style={{ width: `calc(100% - ${stringToVW('9px')})` }}
                      >
                        종목 채널 내 게시글 작성 시 주주여부, 보유주수,
                        <br />
                        보유기간, 보유종목에 대한 데이터가 연동돼요.
                        <br />
                        <span style={{ width: stringToVW('9px') }}>- </span>
                        <span
                          style={{
                            width: `calc(100% - ${stringToVW('9px')})`,
                          }}
                        >
                          마이데이터로 연결된 타 증권사 잔고는 최근 데이터
                          <br />
                          갱신 시점 기준이며, 보유기간이 표시되지 않아요.
                        </span>
                      </span>

                      <br />

                      <div
                        style={{ height: stringToVW('6.5px'), width: '100%' }}
                      />
                      <span style={{ width: stringToVW('9px') }}>·</span>
                      <span
                        style={{ width: `calc(100% - ${stringToVW('9px')})` }}
                      >
                        내가 가진 종목 채널 게시글에 좋아요/싫어요를 누르면
                        <br />
                        나의 보유주수 데이터가 합산돼요.
                      </span>
                      <div
                        style={{ height: stringToVW('6.5px'), width: '100%' }}
                      />
                      <span style={{ width: stringToVW('9px') }}>·</span>
                      <span
                        style={{ width: `calc(100% - ${stringToVW('9px')})` }}
                      >
                        내 프로필에서 투자중인 종목 정보를 노출할 수 있어요.
                      </span>
                      <div
                        style={{ height: stringToVW('6.5px'), width: '100%' }}
                      />
                      <span style={{ width: stringToVW('9px') }}>·</span>
                      <span
                        style={{ width: `calc(100% - ${stringToVW('9px')})` }}
                      >
                        커뮤니티 최초 가입 시, 익영업일 오전 9시 이후부터
                        <br />
                        반영돼요.
                      </span>
                    </Stack>
                  </HtmlTooltip>
                </div>
              </div>
            </Grid>
            <Grid
              xs={6}
              css={css`
                display: flex;
                align-items: center;
                justify-content: end;
                padding: 0;
                margin: 0;
              `}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={linked}
                    onChange={e => setLinked(e.target.checked)}
                  />
                }
                label=""
                css={css`
                  padding: 0;
                  margin: 0;
                `}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          css={css`
            /* display: flex;
            flex-direction: column;
            align-items: start; */
            margin-top: ${stringToVW('16px')};
          `}
        >
          <span
            class="title"
            css={css`
              gap: 3px;
              ${globalStyle.fontNanu21}
            `}
          >
            유의해주세요!
          </span>
          <span class="content">
            <p
              css={css`
                position: relative;
                margin-top: ${stringToVW('11px')};
                padding-left: ${stringToVW('10px')};
                color: #666;
                ${globalStyle.fontNanu18}
                &::before {
                  content: '·';
                  position: absolute;
                  top: 0;
                  left: 10px;
                  transform: translate(-150%);
                }
              `}
            >
              유명인의 이름 및 사진을 사칭하여 허위 사실의 유포, 불공정 거래,
              명예 훼손 등을 유발할 수 있는 게시글/댓글을 작성하는 경우 해당
              글은 사전, 사후 통보 없이 삭제될 수 있으며, 해당 이용자는 서비스
              이용이 제한될 수 있습니다.
            </p>
          </span>
        </Grid>
      </Box>
      {/* <Box css={css``}></Box> */}

      {loadingOverlayShow && (
        <div
          className="loading-overlay"
          css={css`
            position: fixed;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 30;
            background: black;
            opacity: 0.3;
            color: #fff;
            ${globalStyle.fontNanu21}
          `}
        >
          <RefreshIcon
            fontSize="large"
            className="ptr-icon"
            css={css`
              animation: ${loadingAnimate} 0.4s linear infinite;
            `}
          />
          <span>{loadingOverlayText}</span>
        </div>
      )}

      {isShow && (
        <PopupChangeAvatar
          user={Number(myProfile.USER_TYPE) !== 0 ? 'company' : 'general'}
          handleChangeAvatar={handleChangeAvatar}
          imagePush={imagePush}
          // originImage={
          //   avatarImage ||
          //   (uploadImage.length > 0 && uploadImage?.[0]) ||
          //   profileImage
          // }
          defaultImage={
            getUploadImage(avatarImage) ||
            (uploadImage.length > 0 &&
              URL?.createObjectURL(uploadImage?.[0])) ||
            getUploadImage(profileImage)
          }
        />
      )}
      <Popup
        open={openPopupErrorAvatar}
        data={{
          title: (
            <div css={styles.modalTitle}>
              이미지에 불건전한 컨텐츠가
              <br />
              발견되었습니다.
            </div>
          ),
          content: (
            <div css={styles.modalContent}>
              쾌적한 커뮤니티를 위해 광고/유해성 이미지
              <br />
              필터링 중입니다. 이미지를 재업로드 해주세요.
            </div>
          ),
        }}
        cancelButton={false}
        onClose={() => setOpenPopupErrorAvatar(false)}
        confirmText="확인"
      />
    </div>
  );
};
export default EditProfile;
