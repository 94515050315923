/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';

import { updateTopRankChannel } from '@store/app/channelReducer';
import { POST_VIEW_COUNT } from '@data/constants';

import PostList from '@component/app/post/PostList';
import PostListHeader from '@component/app/post/PostListHeader';
import PopularChannelList from '@component/app/channel/PopularChannelList';
import LoungeHeader from '@component/app/common/LoungeHeader';

import {
  getLoungeList,
  getLoungeListVersion2,
  getTopRankChannel,
} from '@lib/api-call/app/loungeApi';
import { isEmpty } from 'lodash';
import { stringToVW, toVW } from '@style/globalStyle';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useLoungePostQuery, {
  loungeQueryKey,
  useLoungePostQueryV2,
} from 'query-hook/useLoungePostQuery';
import Ptr from '@component/app/common/ptr';
const Home = () => {
  const [orderBy, setOrderBy] = useState(null);
  const [holderYn, setHolderYn] = useState(undefined);

  // const topRankChannel = useSelector(state => state.channel.topRankChannel);
  const loginedUser = useSelector(state => state.auth.loginedUser);

  const dispatch = useDispatch();

  const [ref, inView] = useInView();

  // const fetchingPosts = async ({ pageParam, meta }) => {
  //   const pageSize = meta?.prefetch.PageSize || 5;
  //   const lastPostId =
  //     meta?.prefetch.lastPostId || pageParam === 0 ? undefined : pageParam;

  //   const res = await getLoungeList({
  //     pageSize: pageSize,
  //     lastPostId: lastPostId,
  //     orderBy: orderBy || '0',
  //     holderYn: holderYn ? 'Y' : 'N',
  //   });
  //   return res ?? [];
  // };

  // const params = { orderBy: orderBy || '0', holderYn: holderYn ? 'Y' : 'N' };

  // const {
  //   data,
  //   isLoading,
  //   // isError,
  //   fetchNextPage,
  //   isFetchingNextPage,
  //   hasNextPage,
  //   refetch,
  // } = useLoungePostQuery({
  //   rowPerPage: 5,
  //   queryFn: fetchingPosts,
  //   params: params,
  // });
  const fetchingPosts = async ({ pageParam, meta }) => {
    const pageSize = meta?.prefetch.PageSize || POST_VIEW_COUNT;
    const pageNumber = pageParam + 1 ?? 1;

    const res = await getLoungeListVersion2({
      pageSize: pageSize,
      pageNumber,
      orderBy: orderBy || '0',
      holderYn: holderYn ? 'Y' : 'N',
    });
    return res ?? { currentPage: 1, data: [] };
  };

  const params = { orderBy: orderBy || '0', holderYn: holderYn ? 'Y' : 'N' };

  const {
    data,
    isLoading,
    // isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useLoungePostQueryV2({
    rowPerPage: POST_VIEW_COUNT,
    queryFn: fetchingPosts,
    params: params,
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: [loungeQueryKey],
    mutationFn: ({ post, actionFunc }) => updateReactionFunc(post, actionFunc),
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([loungeQueryKey, params], oldData => {
        const temp = { ...oldData };

        for (let page of temp.pages) {
          const idx = page.data?.findIndex(
            item => item.post_id === data.post_id,
          );

          if (idx !== -1) {
            page.data[idx] = data;
            break;
          }
        }

        return temp;
      });
    },
  });

  const updateReactionFunc = async (post, actionFunc) => {
    return await actionFunc(post);
  };

  const styles = {
    optionsContainer: css`
      padding: ${stringToVW('0px 16px 0px 16px')};
      border-radius: ${stringToVW('21.3px 21.3px 0px 0px')};
      background: #fff;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: ${toVW('13.33px')};
      /* width: 100%; */
      /* height: auto;
      padding-bottom: ${toVW('10.7px')};
      border-bottom: 1px solid var(--essential-line-line-200025, #f0f0f0); */
    `,
    divider: css`
      height: 1px;
      align-self: stretch;
      margin-bottom: ${toVW('10.7px')};
      background: var(--essential-line-line-200025, #f0f0f0);
    `,
  };

  useEffect(() => {
    if (loginedUser) {
      (async () => {
        await refetch();
      })();
    }
  }, [loginedUser]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  // useEffect(() => {
  //   (async () => {
  //     const rankChannelList = await getTopRankChannel();
  //     dispatch(updateTopRankChannel(rankChannelList));
  //   })();

  //   return () => {};
  // }, []);

  return (
    <Ptr
      onRefresh={async () => {
        queryClient.removeQueries(loungeQueryKey);
        await refetch();
      }}
    >
      <div>
        <LoungeHeader />
        <div
          css={css`
            margin-top: ${toVW('16px')};
            // background-color: #f5f5f5;
          `}
        >
          {/* <PopularChannelList channelList={topRankChannel} /> */}
          <div css={styles.optionsContainer}>
            <PostListHeader
              setOrderBy={setOrderBy}
              setHolderYn={setHolderYn}
              holderYn={holderYn}
              line
            />
            {/* <div css={styles.divider} /> */}
          </div>
        </div>
        <PostList
          holderYn={holderYn}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          postList={!isLoading ? data?.pages : undefined}
          reloadRef={ref}
          mutate={mutate}
        />
        {!isEmpty(data?.pages) && hasNextPage && !isFetchingNextPage && (
          <div
            css={css`
              min-height: ${toVW('30px')};
              width: 100%;
              background: 'none';
              color: #b1f54d;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          ></div>
        )}
      </div>
    </Ptr>
  );
};

export default Home;
