import { useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { globalStyle } from '@style/globalStyle';
import RefreshIcon from '@mui/icons-material/Refresh';

function useAppLoading() {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  return { isLoading, showLoading, hideLoading };
}

export const LoadingConponent = () => {
  const loadingAnimate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
  return (
    <div
      className="loading-overlay"
      css={css`
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 30;
        background: black;
        opacity: 0.3;
        color: #fff;
        ${globalStyle.fontNanu21}
      `}
    >
      <RefreshIcon
        fontSize="large"
        className="ptr-icon"
        css={css`
          animation: ${loadingAnimate} 0.8s linear infinite;
        `}
      />
    </div>
  );
};

export default useAppLoading;
