import React from 'react';
import { css } from '@emotion/react';

import { ReactComponent as IconPin } from '@asset/icons/subscribe/ms_icon_pin.svg';
import { isEmpty, isUndefined } from 'lodash';
import channelDefaultImgKOR from '@asset/images/channel/channel-default-image-KOR.png';
import channelDefaultImgENG from '@asset/images/channel/channel-default-image-ENG.png';
import channelDefaultImg from '@asset/images/channel/channel-default-image.png';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { getUploadImage } from '@lib/commonUtils';

const imageOuterBoder = 2;
const imageInnerBodder = 1;

const countryChnlImage = country => {
  if (country === 'KOR') return channelDefaultImgKOR;
  else if (country === 'USA') return channelDefaultImgENG;
  else return channelDefaultImg;
};

const SlideChannelItem = ({ channel, channelClick }) => {
  const { active, fixed } = channel;

  const styles = {
    slide: css`
      width: ${stringToVW('64px')};
    `,
    channelContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${stringToVW('8px')};
    `,
    logoWrapper: css`
      display: flex;
      padding: calc(7px - ${imageOuterBoder}px - ${imageInnerBodder}px);
      justify-content: center;
      align-items: center;
      border-radius: var(--pill, 1000px);
      border: 2px solid var(--essential-primary-pri-600272, #fff);
    `,
    wrapperActive: css`
      border: 2px solid var(--essential-primary-pri-600272, #3c9800);
    `,
    thumbnails: css`
      height: ${stringToVW('54px')};
      position: relative;
      border-radius: var(--pill, 1000px);
    `,
    thumbnailsActive: css`
      border: 1px solid var(--essential-primary-pri-600272, #3c9800);
    `,
    channelName: css`
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      //줄바꿈 해야할 때
      //word-break: break-word;
      color: #666;
      ${globalStyle.fontNanu18}
      font-weight: 400;
    `,
    channelNameActive: css`
      color: #000;
    `,
  };

  const pinStyles = {
    container: css`
      width: ${stringToVW('16px')};
      height: ${stringToVW('16px')};

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      right: ${stringToVW('3px')};
      top: ${stringToVW('8px')};

      border-radius: ${stringToVW('32px')};
      // border: 2px solid #fff;
      // background: #fff;
      // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
    `,
  };

  return (
    <div css={styles.slide}>
      <div css={[styles.channelContainer]}>
        <div
          css={[styles.logoWrapper, active && styles.wrapperActive]}
          onClick={() =>
            channelClick({
              channelId: channel.channelId,
              stockCd: channel.stockCd,
            })
          }
          style={{ position: 'relative' }}
        >
          <img
            src={
              !channel?.isStaticFile
                ? getUploadImage(channel.logoImgPath) ||
                  countryChnlImage(channel.country)
                : channel.logoImgPath
            }
            alt={channel.name}
            css={[styles.thumbnails, active && styles.thumbnailsActive]}
            onError={e => (e.target.src = countryChnlImage(channel.country))}
          />
          {fixed === 'Y' && (
            <div css={pinStyles.container}>
              <IconPin />
            </div>
          )}
        </div>
        <div css={[styles.channelName, active && styles.channelNameActive]}>
          {channel.name}
        </div>
      </div>
    </div>
  );
};

SlideChannelItem.propTypes = {};

export default SlideChannelItem;
