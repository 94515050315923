import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'admin',
  initialState: {
    loginedAdmin: false,
    p_admin_id: null,
  },
  reducers: {
    updateLoginedAdmin(state, action) {
      state.loginedAdmin = action.payload;
    },
    updateLoginAdminId(state, action) {
      state.p_admin_id = action.payload;
    },
  },
});

export const { updateLoginedAdmin } = authSlice.actions;

export default authSlice.reducer;
