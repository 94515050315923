import { css } from '@emotion/react';
import { Fragment } from 'react';
import LikeButton, {
  SIZE_MD,
  SIZE_XL,
  TYPE_DISLIKE,
  TYPE_LIKE,
} from './LikeButton';
import ReactionRatioBar from './ReactionRatioBar';
import LikeStockArrow from '@asset/icons/post/like-stock-arrow.svg';
import DislikeStockArrow from '@asset/icons/post/dislike-stock-arrow.svg';
import IconTooltip from '@asset/icons/post/icon_tooltip.svg';
import HtmlTooltip from '../common/HtmlTooltip';
import {
  DISLIKE_ACTION,
  DISLIKE_DELETE_ACTION,
  LIKE_ACTION,
  LIKE_DELETE_ACTION,
  updateReaction,
} from '@lib/api-call/app/loungeApi';
import { useDispatch, useSelector } from 'react-redux';
import { updatePostItem } from '@store/app/postReducer';
import { globalStyle, stringToVW } from '@style/globalStyle';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loungeQueryKey } from 'query-hook/useLoungePostQuery';
import { find as _find } from 'lodash';
import { writeQueryKey } from 'query-hook/useWritePostQuery';
import { CheckWithdrawAccount } from '../../../component/app/user/CheckWithdrawAccount';
import React from 'react';

const PostDetailFooter = ({ item }) => {
  const {
    post_id,
    isLiked,
    isDisliked,
    countLike,
    countDislike,
    sumLikeStockQty,
    sumDislikeStockQty,
    holding_stock,
    user_type,
  } = item;
  const [openPopupWithdraw, setOpenPopupWithdraw] = React.useState(false);
  const totalReacCnt = countLike + countDislike;
  const totalReacStock = sumLikeStockQty + sumDislikeStockQty;

  const dispatch = useDispatch();

  const styles = {
    container: css`
      display: flex;
      padding: ${stringToVW('0px 10.5px 12px 10.5px')};
      flex-direction: column;
      align-items: center;
      gap: ${stringToVW('16px')};
      align-self: stretch;
      border-bottom: 1px solid var(--essential-line-line-200025, #f0f0f0);
    `,
    haveStock: css`
      padding-left: ${stringToVW('8px')};
      padding-right: ${stringToVW('32px')};
      display: flex;
      flex-direction: column;
      gap: ${stringToVW('1px')};
      align-self: stretch;
      color: #7d7d7d;

      ${globalStyle.fontNanu15}
      font-weight: 400;
    `,
    postReaction: css`
      display: flex;
      gap: ${stringToVW('10px')};
      align-self: stretch;
    `,
    reactionInner: css`
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: flex-start;
      gap: ${stringToVW('10px')};
    `,
    ratioBarContainer: css`
      display: flex;
      justify-content: space-between;
      gap: ${stringToVW('2px')};
      align-self: stretch;
    `,
    tooltipRow: css`
      margin-left: ${stringToVW('8px')};
      position: relative;
      ::before {
        content: '·';
        position: absolute;
        left: ${stringToVW('-8px')};
      }
    `,
  };

  // 코드 추가사유: 이전페이지에서 클릭한 기록이 남아있을 때, 뒤로가기 하면 리액션이 바로 반영되게 하기 위해서. (~line 117)
  const clickedPostId = useSelector(state => state.post.clickedPostId);
  const queryClient = useQueryClient();
  const { mutate: loungeMutate } = useMutation({
    mutationKey: [loungeQueryKey],
    mutationFn: item => item,
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([loungeQueryKey], oldData => {
        const temp = { ...oldData };
        for (let page of temp.pages) {
          const idx = page.findIndex(item => item.post_id === clickedPostId);
          if (idx !== -1) {
            page[idx] = data;
            break;
          }
        }
        return temp;
      });
    },
  });

  const clickedWrittenPostId = useSelector(
    state => state.post.clickedWrittenPostId,
  );
  const { mutate: writeMutate } = useMutation({
    mutationKey: [writeQueryKey],
    mutationFn: item => item,
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([writeQueryKey], oldData => {
        const temp = { ...oldData };

        for (let page of temp.pages) {
          const idx = page.findIndex(
            item => item.post_id === clickedWrittenPostId,
          );

          if (idx !== -1) {
            page[idx] = data;
            break;
          }
        }

        return temp;
      });
    },
  });

  // 좋아요 눌렀을 때,
  const handleLikeBtn = async e => {
    const updateItem = { ...item };

    if (!!isLiked) {
      const { message_cd, likeCount, error } = await updateReaction({
        post_id,
        react: LIKE_DELETE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);

      if (message_cd !== '0') {
        return;
      }

      updateItem.isLiked = 0;
      updateItem.countLike = likeCount?.count || 0;
      updateItem.sumLikeStockQty = likeCount?.qtySum || 0;
    } else {
      if (!!isDisliked) {
        const { message_cd, dislikeCount, error } = await updateReaction({
          post_id,
          react: DISLIKE_DELETE_ACTION,
        });
        if (error === 401) setOpenPopupWithdraw(true);

        if (message_cd !== '0') {
          return;
        }

        updateItem.isDisliked = 0;
        updateItem.countDislike = dislikeCount?.count || 0;
        updateItem.sumDislikeStockQty = dislikeCount?.qtySum || 0;
      }

      const { message_cd, likeCount, error } = await updateReaction({
        post_id,
        react: LIKE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);

      if (message_cd !== '0') {
        return;
      }

      const { qtySum, count } = likeCount;

      updateItem.isLiked = 1;
      updateItem.countLike = count;
      updateItem.sumLikeStockQty = qtySum;
    }

    if (clickedPostId) {
      loungeMutate(updateItem);
    }
    if (clickedWrittenPostId) {
      writeMutate(updateItem);
    }

    dispatch(updatePostItem(updateItem));
  };

  // 싫어요 눌렀을 때,
  const handleDislikeBtn = async () => {
    const updateItem = { ...item };

    if (!!isDisliked) {
      const { message_cd, dislikeCount, error } = await updateReaction({
        post_id,
        react: DISLIKE_DELETE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);
      if (message_cd !== '0') {
        return;
      }

      updateItem.isDisliked = 0;
      updateItem.countDislike = dislikeCount?.count || 0;
      updateItem.sumDislikeStockQty = dislikeCount?.qtySum || 0;
    } else {
      if (!!isLiked) {
        const { message_cd, likeCount, error } = await updateReaction({
          post_id,
          react: LIKE_DELETE_ACTION,
        });
        if (error === 401) setOpenPopupWithdraw(true);

        if (message_cd !== '0') {
          return;
        }

        updateItem.isLiked = 0;
        updateItem.countLike = likeCount?.count || 0;
        updateItem.sumLikeStockQty = likeCount?.qtySum || 0;
      }

      const { message_cd, dislikeCount, error } = await updateReaction({
        post_id,
        react: DISLIKE_ACTION,
      });
      if (error === 401) setOpenPopupWithdraw(true);

      if (message_cd !== '0') {
        return;
      }

      const { qtySum, count } = dislikeCount;

      updateItem.isDisliked = 1;
      updateItem.countDislike = count;
      updateItem.sumDislikeStockQty = qtySum;
    }

    if (clickedPostId) {
      loungeMutate(updateItem);
    }

    if (clickedWrittenPostId) {
      writeMutate(updateItem);
    }

    dispatch(updatePostItem(updateItem));
  };

  return (
    <div css={styles.container}>
      <div
        css={css`
          width: 100%;
          padding: ${stringToVW('0px 8px')};
          height: 1px;
          background: var(--essential-line-line-200025, #f0f0f0);
        `}
      />
      {user_type !== '1' && holding_stock && (
        <div css={styles.haveStock}>{holding_stock}</div>
      )}
      <div css={styles.postReaction}>
        <div css={styles.reactionInner}>
          <div
            css={css`
              display: grid;
              place-items: center;
              height: ${stringToVW('27px')};
            `}
          >
            <LikeButton
              type={TYPE_LIKE}
              size={SIZE_XL}
              isActive={!!isLiked}
              clickHandler={() => {
                window.GA_Event('커뮤니티', '공통', '게시글 추천');
                handleLikeBtn();
              }}
            />
          </div>
          {sumLikeStockQty > 0 && (
            <img
              alt="ikeStockArrow"
              style={{ width: stringToVW('27px'), height: stringToVW('27px') }}
              src={LikeStockArrow}
            />
          )}
        </div>
        <div
          css={[
            styles.reactionInner,
            css`
              flex: 1 0 0;
            `,
          ]}
        >
          {countLike === countDislike && countDislike === 0 ? (
            <div css={styles.ratioBarContainer}>
              <ReactionRatioBar
                cnt={countLike}
                total={totalReacCnt}
                isPareZero
              />
              <ReactionRatioBar
                cnt={countDislike}
                total={totalReacCnt}
                isDislike
                isPareZero
              />
            </div>
          ) : (
            <div css={styles.ratioBarContainer}>
              <ReactionRatioBar cnt={countLike} total={totalReacCnt} />
              <ReactionRatioBar
                cnt={countDislike}
                total={totalReacCnt}
                isDislike
              />
            </div>
          )}
          {(sumLikeStockQty > 0 || sumDislikeStockQty > 0) && (
            <div css={styles.ratioBarContainer}>
              {sumLikeStockQty > 0 && (
                <ReactionRatioBar
                  cnt={sumLikeStockQty}
                  total={totalReacStock}
                  isStock
                />
              )}
              {sumDislikeStockQty > 0 && (
                <ReactionRatioBar
                  cnt={sumDislikeStockQty}
                  total={totalReacStock}
                  isDislike
                  isStock
                />
              )}
            </div>
          )}
        </div>
        <div css={styles.reactionInner}>
          <div
            css={css`
              display: grid;
              place-items: center;
              height: ${stringToVW('27px')};
            `}
          >
            <LikeButton
              type={TYPE_DISLIKE}
              size={SIZE_XL}
              isActive={!!isDisliked}
              clickHandler={() => {
                window.GA_Event('커뮤니티', '공통', '게시글 비추천');
                handleDislikeBtn();
              }}
            />
          </div>
          {sumDislikeStockQty > 0 && (
            <img
              alt="DislikeStockArrow"
              style={{ width: stringToVW('27px'), height: stringToVW('27px') }}
              src={DislikeStockArrow}
            />
          )}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-self: stretch;
          justify-content: end;
          align-items: center;
          gap: ${stringToVW('2.5px')};
        `}
      >
        <div
          css={css`
            color: #7d7d7d;
            ${globalStyle.fontNanu18}
          `}
        >
          유의사항
        </div>
        <div
          css={css`
            height: ${stringToVW('26px')};
            width: ${stringToVW('26px')};
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${stringToVW('12.5px')};
          `}
        >
          <HtmlTooltip
            Icon={
              <img
                alt="icon"
                src={IconTooltip}
                style={{
                  width: stringToVW('20px'),
                  height: stringToVW('20px'),
                }}
              />
            }
          >
            <Fragment>
              <div css={styles.tooltipRow}>
                주주여부 및 보유종목 정보, 좋아요/싫어요 주수는
                <br />
                전일자 잔고 기준입니다.(매 영업일 8시 ~ 9시 경
                <br />
                업데이트 되며, 주말/공휴일에는 업데이트 되지 않습
                <br />
                니다.)
              </div>
              <div css={styles.tooltipRow}>
                마이데이터로 연결된 타 증권사 잔고는 최근 데이터
                <br />
                갱신 시점 기준이며 보유 기간이 표시되지 않습니다.
              </div>
              <div css={styles.tooltipRow}>
                ‘주주정보 연동’이 활성화 된 유저의 데이터만 표시
                <br />
                됩니다.
              </div>
            </Fragment>
          </HtmlTooltip>
        </div>
      </div>
      {openPopupWithdraw && <CheckWithdrawAccount />}
    </div>
  );
};

export default PostDetailFooter;
