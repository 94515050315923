import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loginedUser: undefined,
    // afterAuthFuncCall: [],
    afterAuthMovePage: undefined,
  },
  reducers: {
    updateLoginedUser(state, action) {
      state.loginedUser = action.payload;
    },
    // updateAfterAuthFuncCall(state, action) {
    //   state.afterAuthFuncCall = action.payload;
    // },
    // pushAfterAuthFuncCall(state, action) {
    //   state.afterAuthFuncCall.push(action.payload);
    // },
    // clearAfterAuthFuncCall(state) {
    //   state.afterAuthFuncCall = [];
    // },
    updateAfterAuthMovePage(state, action) {
      state.afterAuthMovePage = action.payload;
    },
  },
});

export const {
  updateLoginedUser,
  // updateAfterAuthFuncCall,
  // pushAfterAuthFuncCall,
  // clearAfterAuthFuncCall,
  updateAfterAuthMovePage,
} = authSlice.actions;

export default authSlice.reducer;
