import { useState } from 'react';
import { css } from '@emotion/react';
import IconClose from '@asset/icons/search/icon-close.svg';
import Popup from '@component/app/common/Popup';

import { searchHistListDelete } from '@lib/api-call/app/searchApi';
import { useNavigate } from 'react-router-dom';
import { globalStyle, stringToVW, toVW } from '@style/globalStyle';
import { isEmpty } from 'lodash';

const SearchItem = ({ histList, histListReset }) => {
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const handleShow = () => {
    setIsShow(true);
    // eslint-disable-next-line no-console
  };
  const handleClick = boolean => {
    setIsShow(boolean);
  };

  const confirmClick = () => {
    setIsShow(false);
    histListDelete();
  };

  /**
   *
   * @param {string} channel_word 검색기록의 hid
   */
  const histListDelete = async channel_word => {
    const histDel = await searchHistListDelete({ channel_word });

    if (histDel.status === '200') histListReset();
  };

  const dataProps = {
    title: '전체 삭제',
    content: ['최근 검색어 내역을 전체 삭제 하시겠습니까?'],
    textBtnLeft: '취소',
    textBtnRight: '확인',
  };
  return (
    <div>
      <div
        css={css`
          width: 100%;
          padding-top: ${toVW('21.333px')};
          padding-bottom: ${toVW('10.667px')};
          gap: ${toVW('6.667px')};
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            flex: 1 0 0;
            ${globalStyle.fontNanu24}
            font-weight: 600;
          `}
        >
          최근검색
        </div>
        {!isEmpty(histList) && (
          <div
            css={css`
              ${globalStyle.fontNanu18}
              font-weight: 400;
              cursor: pointer;
              color: #666;
            `}
            onClick={handleShow}
          >
            전체삭제
          </div>
        )}
      </div>

      {histList.map((item, index) => (
        <div
          css={css`
            width: 100%;
            padding: ${stringToVW('11.333px 0px')};
            gap: ${stringToVW('10px')};
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
          key={index}
          onClick={e => {
            e.stopPropagation();
            navigate(
              `/search/result?keyWord=${encodeURIComponent(item.channel_word)}`,
            );
          }}
        >
          <div
            css={css`
              ${globalStyle.fontNanu21}
              font-weight: 400;
              letter-spacing: 0em;
              flex: 1 0 0;
            `}
          >
            {item.channel_word}
          </div>
          <img
            css={css`
              cursor: pointer;
            `}
            src={IconClose}
            alt=""
            style={{ width: toVW('14.7px'), height: toVW('14.7px') }}
            onClick={e => {
              e.stopPropagation();
              histListDelete(item.channel_word);
            }}
          />
        </div>
      ))}
      {isShow ? (
        <Popup
          data={dataProps}
          handleClick={handleClick}
          confirmClick={() => {
            window.GA_Event('커뮤니티', '검색', '전체삭제');
            confirmClick();
          }}
        />
      ) : null}
    </div>
  );
};

export default SearchItem;
