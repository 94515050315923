import React from 'react';
import PropTypes from 'prop-types';
import settingsStyles from '@style/profile/settingStyles';

const RulesOfUse = props => {
  return (
    <>
      <div css={settingsStyles.title}>커뮤니티 이용규칙</div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '21px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          1. 닉네임은 다른 회원과 중복 및 재사용 할 수 없으며, 1회 설정 시 60일
          간 변경이 불가합니다.
        </span>
        <span>
          닉네임에 욕설, 비방, 저주, 미신적 언사, 모욕, 명예훼손, 외설적 혹은
          선정적 내용, 기타 불건전하거나 불쾌감을 유발하는 내용(은어, 속어,
          모음, 숫자, 기호 등 표현방식 불문)이 포함된 경우 커뮤니티 이용이
          제한될 수 있습니다.
        </span>
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '21px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          2. 아래에 해당하는 글, 이미지 및 댓글은 서비스 약관에 따라 삭제되거나,
          작성자의 서비스 이용이 제한될 수 있습니다.
        </span>
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '10px',
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
        }}
      >
        <span>- 글 혹은 댓글에 대해 다수의 신고가 접수된 경우</span>
        <span>- 작성자의 글이 주제와 연관성이 없는 경우</span>
        <span>- 작성자의 댓글이 해당 글의 내용과 연관성이 없는 경우</span>
        <span>
          - 글 또는 이미지에 욕설, 비방, 저주, 미신적 언사, 모욕, 명예훼손,
          외설적 혹은 선정적 내용, 기타 불건전하거나 불쾌감을 유발하는
          내용(은어, 속어, 모음, 숫자, 기호 등 표현방식 불문)이 포함된 경우
        </span>
        <span>- 타인의 명의를 도용·사칭한 경우</span>
        <span>
          - 저작권자 동의 없이 타인의 저작물을 무단으로 복제해 타인의 지적
          재산권을 침해하는 것으로 의심될 만한 내용이 포함된 경우
        </span>
        <span>- 무분별하게 글 혹은 댓글을 반복 게재하는 경우</span>
        <span>
          - 투자와 무관한 상품 또는 서비스의 홍보 또는 광고 등이 포함된 경우
        </span>
        <span>- 투자와 무관한 정치논쟁 또는 평론 등이 포함된 경우</span>
        <span>- 뉴스 기사를 단순 복사한 게시물의 경우</span>
        <span>
          - 메신저 그룹채팅, 소셜미디어, 커뮤니티 등에 게시글 링크를 무분별하게
          배포하여 신고 접수된 경우
        </span>
        <span>
          - 실명, 계좌번호, 전화번호, 주민등록번호, 이메일 주소 등 개인정보가
          포함된 경우
        </span>
        <span>
          - 게시물에 연락창구(전화번호, 단체 채팅방 링크, 채팅방 ID 등)를 기재한
          경우
        </span>
        <span>
          - (유사)투자자문업, 집합투자업, 대부업, 투자조합 등에 대한 홍보, 소개,
          광고 등이 포함된 경우
        </span>
        <span>
          - 당사와 무관한 홍보성, 회원모집, 광고 등의 목적으로 글, 이미지 혹은
          댓글을 게재하는 경우
        </span>
        <span>- 유명인사 및 기관을 사칭하는 경우</span>
        <span>
          - 개인 간 거래를 유도하거나 투자 권유 및 영리 목적을 가진 경우
        </span>
        <span>
          - 풍문의 유포, 위계, 미공개중요정보의 누설, 기타 부정거래 또는
          시장질서 교란행위에 관련된 것(통정매매, 공동모의에 따른 매수 또는
          매도, 허위 호가의 제출, 기타 시세조종행위와 관련된 단체 또는 세력의
          홍보, 소개, 광고나 그와 관련된 참여의 권유, 유혹, 획책 등으로 의심될
          만한 내용이 포함된 경우
        </span>
        <span>
          - 불안감을 조성하여 혼란을 줄 수 있는 경우 (정치글, 선동글 등)
        </span>
        <span>
          - 회사의 정상적인 서비스 운영 및 기타 업무를 방해한다고 판단되는 경우
        </span>
        <span>
          - 한 닉네임 당 하루에 작성할 수 있는 게시글은 30건이며, 댓글(대댓글
          포함)의 경우 하루에 50건으로 제한됩니다.
        </span>
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '21px',
          display: 'flex',
          gap: '21px',
          flexDirection: 'column',
        }}
      >
        <span>
          3. 누적 5번 이상 신고된 게시글 및 댓글은 숨김 처리되며, 게시물 신고
          횟수가 누적되어서 이용제한 회원으로 등록되는 경우 글쓰기 등 모든
          서비스 이용이 제한될 수 있습니다.
        </span>
        <span>
          4. 허위사실 유포는 자본시장과 금융투자업에 관한 법률 제176조2항에
          해당하는 시세조종행위로써 1년 이상의 유기징역 또는 그 위반행위로 얻은
          이익 또는 회피한 손실액의 3배 이상 5배 이하에 상당하는 벌금에 처할 수
          있습니다.
        </span>
        <span>
          5. 부적절한 닉네임을 이용하는 회원이 발견될 경우, 해당 회원에 대한
          서비스 이용을 제한할 수 있습니다.
        </span>
        <span>
          6. 부적절한 방법으로 커뮤니티 서비스를 이용하는 행위가 발견될 경우,
          회사는 해당 회원에 대한 서비스 이용을 제한할 수 있습니다.
        </span>
        <span>
          7. 서비스 이용이 제한된 회원은 글쓰기, 댓글, 좋아요 기능 등 게시글
          읽기 외 모든 기능을 사용할 수 없습니다.
        </span>
        <span>8. 서비스 운영 정책은 사전 공지 없이 변경될 수 있습니다.</span>
        <span>9. 서비스는 회사 사정에 따라 종료될 수 있습니다.</span>
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '21px',
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
          wordWrap: 'break-word',
        }}
      >
        <span>
          10. 불건전 게시물에 현혹되어 피해가 발생하지 않도록 주의해주시고,
          불공정거래는 금융감독원과 한국거래소에 신고할 수 있습니다.
        </span>
        <span style={{ paddingTop: '5px' }}>
          - 한국거래소 민원센터 (1577-0088)
          <br />
          https://stockwatch.krx.co.kr/main/main.jsp
        </span>
        <span>
          - 금융감독원 증권불공정거래 신고센터
          <br />
          http://www.fss.or.kr/fss/scop/report/report.jsp(PC버전만 지원)
        </span>
      </div>
      <div
        css={settingsStyles.content}
        style={{
          paddingTop: '21px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <span>
          11. 서비스 관련하여 문제가 발생하는 경우 아래 문의처를 통하여 신고
          또는 문의할 수 있으며, 회사의 VOC나 고객센터에서는 관련 문의를
          접수하지 않습니다.
        </span>
        <span>
          - NH투자증권 커뮤니티 문의처 : <br /> community_support@nhqv.com
        </span>
      </div>
    </>
  );
};

RulesOfUse.propTypes = {};

export default RulesOfUse;
