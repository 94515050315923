import axios from 'axios';
import { getCookie, removeCookie, setCookie } from './cookieUtils';
import { showNewVersionPopup } from '@lib/commonUtils';
import { callMTSFunction } from './commonUtils';
import memoize from 'memoize';
import {
  JS_KEY_ACC_TOKEN,
  HEADER_KEY_ACC_TOKEN,
  HEADER_KEY_REFRESH_TOKEN,
  COOKIE_NAME_ACC_TOKEN,
  COOKIE_NAME_REFRESH_TOKEN,
  CALL_MTS_ACTION,
} from '@data/constants';
import { isEmpty } from 'lodash';

const REFRESH_TOKEN_URL = '/accessTokenGen';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    const { headers, requireAuth } = config;

    const controller = new AbortController();

    const accessToken = getCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
    const refreshToken = getCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });

    // if (url !== REFRESH_TOKEN_URL && accessToken && refreshToken) {
    //   const decodeRefresh = jwtDecode(refreshToken);
    //   const now = DateTime.now();
    //   if (now > DateTime.fromSeconds(decodeRefresh.exp)) {
    //   }
    // }

    if (!isEmpty(accessToken) && !isEmpty(refreshToken)) {
      headers[HEADER_KEY_ACC_TOKEN] = accessToken;
      headers[HEADER_KEY_REFRESH_TOKEN] = refreshToken;
    } else if (requireAuth) {
      controller?.abort();
    }

    return {
      ...config,
      startTime: performance.now(),
      signal: controller.signal,
    };
  },
  error => Promise.reject(error),
);

const refreshAccessToken = memoize(
  async () => {
    const failAction = () => {
      removeCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
      removeCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
      callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
    };

    try {
      const { headers } = await axiosInstance.get(REFRESH_TOKEN_URL);

      const newAccessToken = headers[JS_KEY_ACC_TOKEN];
      if (newAccessToken) {
        setCookie(COOKIE_NAME_ACC_TOKEN, newAccessToken, { path: '/' });
        return newAccessToken;
      } else {
        failAction();
        return;
      }
    } catch (error) {
      failAction();
      return;
    }
  },
  { maxAge: 1000 },
);

axiosInstance.interceptors.response.use(
  async response => {
    if (
      response.headers['app-version'] &&
      response.headers['app-version'] !== process.env.REACT_APP_VERSION
    ) {
      console.log(
        response.headers['app-version'],
        process.env.REACT_APP_VERSION,
      );
      showNewVersionPopup();
    }
    const minimumDelay = response.config.delay || 10;
    const latency = performance.now() - response.config.startTime;
    const shouldNotDelay = minimumDelay < latency;

    if (shouldNotDelay) {
      return response;
    }

    const remainder = minimumDelay - latency;
    const [responseWithDelay] = await Promise.all([
      response,
      new Promise(resolve => setTimeout(resolve, remainder)),
    ]);

    return responseWithDelay;
  },
  async error => {
    const { config, response } = error;

    if (config?.signal.aborted) {
      callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
      return Promise.reject(config?.signal.reason);
    }

    if (
      !response ||
      config.url === REFRESH_TOKEN_URL ||
      config.sent ||
      (response.status !== 401 && response.status !== 403)
    ) {
      // window.location.href = '/500';
      return Promise.reject(error);
    }

    const accessToken = getCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });

    if (!isEmpty(accessToken)) {
      config.sent = true;

      const newAccessToken = await refreshAccessToken(accessToken);
      if (newAccessToken) {
        config.headers[HEADER_KEY_ACC_TOKEN] = newAccessToken;

        return axiosInstance(config);
      } else {
        const refreshToken = getCookie(COOKIE_NAME_REFRESH_TOKEN, {
          path: '/',
        });
        if (isEmpty(refreshToken)) {
          removeCookie(COOKIE_NAME_ACC_TOKEN, { path: '/' });
          removeCookie(COOKIE_NAME_REFRESH_TOKEN, { path: '/' });
          callMTSFunction(CALL_MTS_ACTION.CHECK_USER);
          return Promise.reject(new Error('Expired Login Session'));
        }
      }
    }

    console.error('axiosError:::::::', error);

    return Promise.reject(error);
  },
);

export default axiosInstance;
