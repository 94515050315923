import axiosUtils, {
  getAdminIdParam,
  getAdminIdParam2,
} from '../../axiosUtils';
import { removeCookie } from '@lib/cookieUtils';
import { updateLoginedAdmin } from '@store/app/adminReducer';

export const getReportUserList = async param => {
  return await axiosUtils
    .post('/admin/reportlist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getReportUserDetailList = async param => {
  return await axiosUtils
    .post('/admin/reportdtllist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
export const getReportUserDetailView = async param => {
  return await axiosUtils
    .post('/admin/reportdtlview', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
