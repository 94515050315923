import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import { Avatar } from '@mui/material';

import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import channelDefaultImgKOR from '@asset/images/channel/channel-default-image-KOR.png';
import channelDefaultImgENG from '@asset/images/channel/channel-default-image-ENG.png';
import channelDefaultImg from '@asset/images/channel/channel-default-image.png';
import { globalStyle, stringToVW, toVW, ratio } from '@style/globalStyle';
import { getUploadImage } from '@lib/commonUtils';
import IconCerti from '@asset/icons/common/icon-certification.svg';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const highlightText = (lightText, text) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {text.includes(lightText) ? (
        <>
          {text.split(lightText)[0]}
          <span
            css={css`
              color: #3c9800;
            `}
          >
            {lightText}
          </span>
          {text.split(lightText)[1]}
        </>
      ) : (
        text
      )}
    </>
  );
};

const formatDate = inputDate => {
  // 주어진 날짜 문자열을 Date 객체로 파싱
  const date = new Date(inputDate);

  // 년, 월, 일 추출
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // YYYY.MM.DD 형식으로 포맷팅
  const formattedDate = `${year}.${month}.${day}`;

  return formattedDate;
};

const countryChnlImage = country => {
  if (country === 'KOR') return channelDefaultImgKOR;
  else if (country === 'USA') return channelDefaultImgENG;
  else return channelDefaultImg;
};

export default function SearchResultStock({ searchList }) {
  const { pathname, search, hash } = useLocation();
  const [value, setValue] = React.useState(
    hash ? Number(hash.replace('#', '')) : 0,
  );

  const [searchParams] = useSearchParams();
  const query = searchParams.get('keyWord'); // 쿼리 호출

  const handleChange = (event, newValue) => {
    setValue(newValue);
    routePush(newValue);
  };

  const routePush = path => {
    navigate(`${pathname + search}#${path}`, { replace: true });
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1.5,
          borderColor: 'divider',
          margin: stringToVW('0 -15px'),
        }}
      >
        <Tabs
          value={value}
          sx={{
            transform: 'translateY(1.5px)',
          }}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'black',
              fontSize: 600,
            },
          }}
        >
          <Tab
            css={css`
              ${globalStyle.fontNanu21}
              letter-spacing: 0em;
              text-align: center;
              opacity: 1;
              font-weight: 400;
              color: #999;
            `}
            label="채널"
            {...a11yProps(0)}
            sx={{
              '&.Mui-selected': {
                fontWeight: 600,
                color: '#000',
                borderBottom: '1px solid #000',
              },
            }}
          />
          <Tab
            css={css`
              ${globalStyle.fontNanu21}
              letter-spacing: 0em;
              text-align: center;
              opacity: 1;
              font-weight: 400;
              color: #999;
            `}
            label="사용자"
            {...a11yProps(1)}
            sx={{
              '&.Mui-selected': {
                fontWeight: 600,
                color: '#000',
              },
            }}
          />
          <Tab
            css={css`
              ${globalStyle.fontNanu21}
              letter-spacing: 0em;
              text-align: center;
              color: #999;
              opacity: 1;
              font-weight: 400;
            `}
            label="제목"
            {...a11yProps(2)}
            sx={{
              '&.Mui-selected': {
                fontWeight: 600,
                color: '#000',
              },
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div
          css={css`
            min-height: 100vh;
            margin-top: ${stringToVW('8px')};
          `}
        >
          {searchList.chnl?.length > 0 ? (
            searchList.chnl.map((item, index) => (
              <div
                css={css`
                  height: ${stringToVW('66.67px')};
                  display: flex;
                  align-items: center;
                  pađing: ${stringToVW('10px 0px')};
                  gap: ${stringToVW('12px')};
                `}
                key={index}
                onClick={() => {
                  navigate(
                    `/channel/${encodeURIComponent(
                      item.stock_cd,
                    )}/${encodeURIComponent(item.channel_id)}`,
                    {
                      state: { prevLink: pathname },
                    },
                  );
                }}
              >
                <img
                  heìght={40 * ratio}
                  width={40 * ratio}
                  css={css`
                    border-radius: 50%;
                  `}
                  src={
                    (item.thumb_url && getUploadImage(item.thumb_url)) ||
                    countryChnlImage(item.country_cd)
                  }
                  onError={e => {
                    e.target.src = countryChnlImage(item.country_cd);
                  }}
                  alt=""
                />
                <div
                  css={css`
                    ${globalStyle.fontNanu21}

                    font-weight: 600;
                    letter-spacing: 0em;
                    text-align: left;
                  `}
                >
                  {item.channel_nm}
                </div>
              </div>
            ))
          ) : (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${toVW('200px')};
              `}
            >
              검색 결과가 없어요.
            </div>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div
          css={css`
            min-height: 100vh;
            margin-top: ${toVW('8px')};
          `}
        >
          {searchList.user?.length > 0 ? (
            searchList.user?.map((item, index) => (
              <div
                css={css`
                  height: ${toVW('66.67px')};
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: ${toVW('16px')};
                  padding: ${stringToVW('10px 0px')};
                `}
                key={index}
                onClick={() => {
                  navigate(`/profile/${encodeURIComponent(item.user_id)}`, {
                    state: { prevLink: pathname + search + hash },
                  });
                }}
              >
                <div
                  css={css`
                    height: ${toVW('66.67px')};
                    display: flex;
                    align-items: center;
                    gap: ${toVW('16px')};
                  `}
                >
                  <div css={css``}>
                    <Avatar
                      src={getUploadImage(item.profile_img)}
                      sx={{ width: toVW('40px'), height: toVW('40') }}
                    />
                  </div>
                  <div
                    css={css`
                      display: flex;
                      gap: ${toVW('4px')};
                      align-items: center;
                    `}
                  >
                    <div
                      css={css`
                        ${globalStyle.fontNanu21}
                        font-weight: 600;
                        letter-spacing: 0em;
                        //text-align: left;
                      `}
                    >
                      {item.user_nm}
                    </div>
                    {item.user_type === '1' && (
                      <img
                        style={{
                          width: toVW('13.33px'),
                          paddingBottom: toVW('3px'),
                        }}
                        src={IconCerti}
                        alt="certification"
                      />
                    )}
                  </div>
                </div>
                {/*<div>*/}
                {/*  <button*/}
                {/*    css={css`*/}
                {/*      width: 105px;*/}
                {/*      height: 46px;*/}
                {/*      padding: 15px, 20px, 13px, 20px;*/}
                {/*      border-radius: 10px;*/}
                {/*      gap: 10px;*/}
                {/*      background: #4e5255;*/}
                {/*      border: none;*/}
                {/*      color: white;*/}
                {/*    `}*/}
                {/*  >*/}
                {/*    팔로우*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
            ))
          ) : (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${toVW('200px')};
              `}
            >
              검색 결과가 없어요.
            </div>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div
          css={css`
            min-height: 100vh;
            margin-top: ${stringToVW('8px')};
          `}
        >
          {searchList.post?.length > 0 ? (
            searchList.post?.map((item, index) => (
              <div
                css={css`
                  height: ${stringToVW('72px')};
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                `}
                key={index}
              >
                <div
                  css={css`
                    ${globalStyle.fontNanu18}

                    font-weight: 400;
                    letter-spacing: 0em;
                    text-align: left;
                  `}
                  onClick={() => {
                    navigate(`/post/${item.post_id}`, {
                      state: { prevLink: pathname + search + hash },
                    });
                  }}
                >
                  {/* <span
                    css={css`
                      color: #3c9800;
                    `}
                  >
                    에코프로가
                  </span>{' '}
                  대세다 */}
                  {highlightText(query, item.title)}
                </div>
                <div
                  css={css`
                    ${globalStyle.fontNanu21}
                    font-weight: 400;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #999999;
                  `}
                >
                  {formatDate(item.reg_dt)}
                </div>
              </div>
            ))
          ) : (
            <div
              css={css`
                ${globalStyle.fontNanu21}
                font-weight: 400 !important;
                letter-spacing: 0em;
                text-align: center;
                color: #666666;
                padding-top: ${toVW('200px')};
              `}
            >
              검색 결과가 없어요.
            </div>
          )}
        </div>
      </CustomTabPanel>
    </Box>
  );
}
