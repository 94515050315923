import { callMTSFunction } from './commonUtils.js';
/* Main Script */
let Scheme = 'jscall://';

// 개발
let UAcode = { QV: null, NAMUH: null };
let GTMcode = { QV: null, NAMUH: null };

// let UAcode = { QV: 'UA-135202849-4', NAMUH: 'UA-135202849-5' };
// let GTMcode = { QV: 'GTM-WNG4532', NAMUH: 'GTM-MPK8RFC' };
// 운영
// let UAcode = { QV: 'UA-135202849-1', NAMUH: 'UA-135202849-2' };
// let GTMcode = { QV: 'GTM-M6BMPKH', NAMUH: 'GTM-PQCJM2V' };
let initMap = {};
window.addEventListener('load', () => {
  if (process.env.REACT_APP_KEY === 'prod') {
    UAcode = { QV: 'UA-135202849-1', NAMUH: 'UA-135202849-2' };
    GTMcode = { QV: 'GTM-M6BMPKH', NAMUH: 'GTM-PQCJM2V' };
  } else if (process.env.REACT_APP_KEY === 'dev') {
    UAcode = { QV: 'UA-135202849-4', NAMUH: 'UA-135202849-5' };
    GTMcode = { QV: 'GTM-WNG4532', NAMUH: 'GTM-MPK8RFC' };
  }

  try {
    initMap.set = function (key, val) {
      this[key] = val;
    };

    initMap.set('userId', 'USERID'); // 사용자 ID로 세팅필요
    initMap.set('dimension2', 'USERID'); // 사용자 ID로 세팅필요
    initMap.set('dimension5', 'Y'); // 로그인 여부로 세팅필요 Y/N
    //initMap.set("title", "커뮤티니 GA테스트 페이지");	// 페이지 타이틀로 세팅필요 -> <title>에 것을 그대로 들고감

    initMap.set('dimension3', 'MOWEB'); // [fix] 채널유형 : PCWEB / MOWEB / MTS / HTS
    initMap.set('dimension4', 'NAMUH'); // [fix] 브랜드_매체 : QV / QV종합업무시스템 / NAMUH / WM파트너스 / 연금
    initMap.set('dimension6', 'MTS'); // [fix] 호출매체

    // page view 호출
    GA_Init(initMap, 'NAMUH'); // 매체는 나무로 고정
  } catch (e) {}
});

let AndroidWebview = 'GA_Android';
let iOS_Webview_WK = 'GA_iOS_WK';
let iOS_WebView_UI = 'GA_iOS_UI';
let CustomObject = { dimension: {}, metric: {} };

let gaLoad = { GTM: false, GA: true, APP: false };
let browserInfo = navigator.userAgent;
if (
  browserInfo.indexOf(AndroidWebview) > -1 ||
  browserInfo.indexOf(iOS_Webview_WK) > -1 ||
  browserInfo.indexOf(iOS_WebView_UI) > -1
) {
  gaLoad.APP = true;
}

window.getCid = function () {
  let _cookieData = document.cookie;
  let _Cookies = _cookieData.split(';');
  let _clientId = '';
  for (let i = 0; i < _Cookies.length; i++) {
    if (
      _Cookies[i].split('=')[0] == '_ga' ||
      _Cookies[i].split('=')[0] == ' _ga'
    ) {
      _clientId = _Cookies[i].split('=')[1];
    }
  }
  if (_clientId != '') {
    _clientId = _clientId.substring(6);
  }

  if (_clientId == '') {
    ga(function (tracker) {
      _clientId = tracker.get('clientId');
    });
  }
  return _clientId;
};

// GA 이벤트 전송 (WEB,APP) --> 맞춤 측정 기준이 페이지 내에 변경 및 추가가 있을 경우 사용
window.GADatasend_Event = function (GAInfo_GA) {
  try {
    let GAInfo = {};
    let _category = '';
    let _action = '';
    let _title = '';

    if (!gaLoad.APP) {
      GAInfo.hitType = 'event';
      for (var key in GAInfo_GA) {
        if (key.indexOf('dimension') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('metric') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('userId') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('location') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('title') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('eventLabel') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('eventValue') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('eventCategory') !== -1) {
          _category = GAInfo_GA[key];
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('eventAction') !== -1) {
          _action = GAInfo_GA[key];
          GAInfo[key] = GAInfo_GA[key];
        }
      }
      if (_category.length > 0 && _action.length > 0) {
        ga('gp.send', GAInfo);
      }
    } else {
      let emptyObject = JSON.parse(JSON.stringify(CustomObject));
      emptyObject.type = 'E';

      for (var key in GAInfo_GA) {
        if (key.indexOf('dimension') !== -1) {
          emptyObject.dimension[key] = GAInfo_GA[key];
        }
        if (key.indexOf('metric') !== -1) {
          emptyObject.metric[key] = GAInfo_GA[key];
        }
        if (key.indexOf('userId') !== -1) {
          emptyObject[key] = GAInfo_GA[key];
        }
        if (key.indexOf('location') !== -1) {
          emptyObject[key] = GAInfo_GA[key];
        }
        if (key.indexOf('title') !== -1) {
          emptyObject[key] = GAInfo_GA[key];
        }
        if (key.indexOf('eventLabel') !== -1) {
          emptyObject.label = GAInfo_GA[key];
        }
        if (key.indexOf('eventValue') !== -1) {
          emptyObject.value = GAInfo_GA[key];
        }
        if (key.indexOf('eventCategory') !== -1) {
          _category = GAInfo_GA[key];
          emptyObject.category = GAInfo_GA[key];
        }
        if (key.indexOf('eventAction') !== -1) {
          _action = GAInfo_GA[key];
          emptyObject.action = GAInfo_GA[key];
        }
      }

      if (_category.length > 0 && _action.length > 0) {
        if (browserInfo.indexOf(AndroidWebview) > -1) {
          window.Android.GA_DATA(JSON.stringify(emptyObject));
        } else if (browserInfo.indexOf(iOS_Webview_WK) > -1) {
          webkit.messageHandlers.callbackHandler.postMessage(
            JSON.stringify(emptyObject),
          );
        } else if (browserInfo.indexOf(iOS_WebView_UI) > -1) {
          window.location =
            Scheme + encodeURIComponent(JSON.stringify(emptyObject));
        }
      }
    }
  } catch (e) {}
};

// GA 페이지뷰 전송 (WEB,APP)
// 기본 페이지뷰를 제외하고 페이지뷰는 보내고 싶은데 맞춤 측정 기준의 변경 및 추가가 있을 때 사용하는 함수
window.GADatasend_Page = function (GAInfo_GA) {
  try {
    let GAInfo = {};
    if (!gaLoad.APP) {
      GAInfo.hitType = 'pageview';
      for (var key in GAInfo_GA) {
        if (key.indexOf('dimension') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('metric') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('userId') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('location') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
        if (key.indexOf('title') !== -1) {
          GAInfo[key] = GAInfo_GA[key];
        }
      }

      ga('gp.send', GAInfo);
    } else {
      let emptyObject = JSON.parse(JSON.stringify(CustomObject));
      emptyObject.type = 'P';

      for (var key in GAInfo_GA) {
        if (key.indexOf('dimension') !== -1) {
          emptyObject.dimension[key] = GAInfo_GA[key];
        }
        if (key.indexOf('metric') !== -1) {
          emptyObject.metric[key] = GAInfo_GA[key];
        }
        if (key.indexOf('userId') !== -1) {
          emptyObject[key] = GAInfo_GA[key];
        }
        if (key.indexOf('location') !== -1) {
          emptyObject[key] = GAInfo_GA[key];
        }
        if (key.indexOf('title') !== -1) {
          emptyObject[key] = GAInfo_GA[key];
        }
      }

      if (browserInfo.indexOf(AndroidWebview) > -1) {
        window.Android.GA_DATA(JSON.stringify(emptyObject));
      } else if (browserInfo.indexOf(iOS_Webview_WK) > -1) {
        webkit.messageHandlers.callbackHandler.postMessage(
          JSON.stringify(emptyObject),
        );
      } else if (browserInfo.indexOf(iOS_WebView_UI) > -1) {
        window.location =
          Scheme + encodeURIComponent(JSON.stringify(emptyObject));
      }
    }
  } catch (e) {}
};

//GTM 맞춤 측정 기준 설정
window.GTMsetCustomDimension = function (GAInfo_GTM) {
  try {
    let GAInfo = {};

    for (let key in GAInfo_GTM) {
      if (key.indexOf('dimension') !== -1) {
        GAInfo[key] = GAInfo_GTM[key];
      }
      if (key.indexOf('metric') !== -1) {
        GAInfo[key] = GAInfo_GTM[key];
      }
      if (key.indexOf('userId') !== -1) {
        GAInfo[key] = GAInfo_GTM[key];
      }
      if (key.indexOf('title') !== -1) {
        GAInfo[key] = GAInfo_GTM[key];
      }
    }

    dataLayer = [GAInfo];
  } catch (e) {}
};

// 1. analytics.js & gtm.js 로드
// 2. 초기 맞춤 측정 기준 설정 (GTM, GA, APP)
// 3. 초기 화면 전송
let gaInitCall = false;
window.GA_Init = function (GAInfo, GBN) {
  gaInitCall = true;
  try {
    //앱에서 실행된 웹뷰가 아닐경우
    if (!gaLoad.APP) {
      if (gaLoad.GTM) {
        //dataLayer 맞춤 측정 설정
        GTMsetCustomDimension(GAInfo);

        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          let f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', GTMcode[GBN]);
      }
      if (gaLoad.GA) {
        (function (i, s, o, g, r, a, m) {
          i.GoogleAnalyticsObject = r;
          // (i[r] =
          //   i[r] ||
          //   function () {
          //     (i[r].q = i[r].q || []).push(arguments);
          //   }),
          //   (i[r].l = 1 * new Date());
          // (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);

          i[r] =
            i[r] ||
            function () {
              (i[r].q = i[r].q || []).push(arguments);
            };
          i[r].l = 1 * new Date();
          a = s.createElement(o);
          m = s.getElementsByTagName(o)[0];
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(
          window,
          document,
          'script',
          'https://www.google-analytics.com/analytics.js',
          'ga',
        );
        ga('create', UAcode[GBN], 'auto', 'gp');
        ga('gp.set', 'anonymizeIp', true);

        GAInfo.set(GACustomKey.Dimension1, getCid());

        for (var key in GAInfo) {
          if (key.indexOf('dimension') !== -1) ga('gp.set', key, GAInfo[key]);
          if (key.indexOf('metric') !== -1) ga('gp.set', key, GAInfo[key]);
          if (key.indexOf('title') !== -1) ga('gp.set', key, GAInfo[key]);
          if (key.indexOf('userId') !== -1) ga('gp.set', key, GAInfo[key]);
        }
      }
    } else {
      for (var key in GAInfo) {
        if (key.indexOf('dimension') !== -1)
          CustomObject.dimension[key] = GAInfo[key];
        if (key.indexOf('metric') !== -1)
          CustomObject.metric[key] = GAInfo[key];
        if (key.indexOf('title') !== -1) CustomObject[key] = GAInfo[key];
        if (key.indexOf('userId') !== -1) CustomObject[key] = GAInfo[key];
      }

      GADatasend_Page(GAInfo);
    }
  } catch (e) {}
};

// include-ga.jsp 가 포함 안되어있는 경우  GA_Init 호출 해줌
// $(function () {
//   try {
//     initMap;
//   } catch (e) {
//     let GAInfo = {};
//     let gbn = '';
//     GAInfo.set = function (key, val) {
//       this[key] = val;
//     };
//     GAInfo.set(GACustomKey.Dimension3, 'MOWEB');
//     if (location.host.indexOf('nhqv.com') > -1) {
//       GAInfo.set(GACustomKey.Dimension4, 'QV');
//       gbn = 'QV';
//     } else if (location.host.indexOf('mynamuh.com') > -1) {
//       GAInfo.set(GACustomKey.Dimension4, 'NAMUH');
//       gbn = 'NAMUH';
//     } else {
//       gbn = 'NAMUH';
//     }
//     GA_Init(GAInfo, gbn);
//   }
// });
(function () {
  if (process.env.REACT_APP_KEY === 'prod') return;
  try {
    initMap;
  } catch (e) {
    let GAInfo = {};
    let gbn = '';
    GAInfo.set = function (key, val) {
      this[key] = val;
    };
    GAInfo.set(GACustomKey.Dimension3, 'MOWEB');
    if (window.location.host.indexOf('nhqv.com') > -1) {
      GAInfo.set(GACustomKey.Dimension4, 'QV');
      gbn = 'QV';
    } else if (window.location.host.indexOf('mynamuh.com') > -1) {
      GAInfo.set(GACustomKey.Dimension4, 'NAMUH');
      gbn = 'NAMUH';
    } else {
      gbn = 'NAMUH';
    }
    GA_Init(GAInfo, gbn);
  }
})();

// 일반 이벤트 세팅 --> 페이지 로드할때와 맞춤 측정 기준의 변경이 없을 경우에는 사용하는 함수
// GA_Event = function (_category, _action, _label) {
//   if (process.env.REACT_APP_KEY === 'prod') {
//     return;
//   }
//   console.log('GA_Event');
//   if (!gaLoad.APP) {
//     let GAInfo = {};
//     GAInfo.hitType = 'event';
//     GAInfo.eventCategory = _category;
//     GAInfo.eventAction = _action;
//     GAInfo.eventLabel = _label;
//     try {
//       ga('gp.send', GAInfo);
//     } catch (e) {}
//   } else {
//     let emptyObject = JSON.parse(JSON.stringify(CustomObject));
//     emptyObject.type = 'E';

//     if (_category != null && _category.length > 0) {
//       emptyObject.category = _category;
//     }
//     if (_action != null && _action.length > 0) {
//       emptyObject.action = _action;
//     }
//     if (_label != null && _label.length > 0) {
//       emptyObject.label = _label;
//     }

//     if (browserInfo.indexOf(AndroidWebview) > -1) {
//       window.Android.GA_DATA(JSON.stringify(emptyObject));
//     } else if (browserInfo.indexOf(iOS_Webview_WK) > -1) {
//       webkit.messageHandlers.callbackHandler.postMessage(
//         JSON.stringify(emptyObject),
//       );
//     } else if (browserInfo.indexOf(iOS_WebView_UI) > -1) {
//       window.location =
//         Scheme + encodeURIComponent(JSON.stringify(emptyObject));
//     }
//   }
// };

window.GA_Event = function (_category, _action, _label) {
  callMTSFunction('SendToGaEvent', [_category, _action, _label]);
};

// 일반 이벤트 세팅 --> 페이지 로드할때와 맞춤 측정 기준의 변경이 "있을" 경우에는 사용하는 함수
window.GA_Event_Data = function (mass) {
  debugger;
  let GAInfo = {};
  if (mass[13]) GAInfo[GACustomKey.Dimension13] = mass[13];
  if (mass[14]) GAInfo[GACustomKey.Dimension14] = mass[14];
  if (mass[38]) GAInfo[GACustomKey.Dimension38] = mass[38];
  GAInfo[GAEventKey.EventCategory] = mass.category;
  GAInfo[GAEventKey.EventAction] = mass.action;
  GAInfo[GAEventKey.EventLabel] = mass.label;

  GADatasend_Event(GAInfo);
};

let browserVersionCheck = (function () {
  let userAgentbrowser = navigator.userAgent,
    tem,
    M =
      userAgentbrowser.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgentbrowser) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = userAgentbrowser.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = userAgentbrowser.match(/version\/(\d+)/i)) != null)
    M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

let loadingGaCategory, loadingGaAction, loadingGaLabel;
if (window.location.pathname != '/') {
  // $(function () {
  //   try {
  //     if (loadingGaCategory) {
  //       // 페이지 진입시점 ga 호출
  //       function loadingGa_call() {
  //         if (gaInitCall) {
  //           try {
  //             if (loadingGaData) {
  //               let mass = {
  //                 category: loadingGaCategory,
  //                 action: loadingGaAction,
  //                 label: loadingGaLabel,
  //               };
  //               if (loadingGaData[13]) mass[13] = loadingGaData[13];
  //               if (loadingGaData[14]) mass[14] = loadingGaData[14];
  //               GA_Event_Data(mass);
  //             }
  //           } catch (e) {
  //             GA_Event(loadingGaCategory, loadingGaAction, loadingGaLabel);
  //           }
  //         } else {
  //           setTimeout(function () {
  //             loadingGa_call();
  //           }, 100);
  //         }
  //       }
  //       setTimeout(function () {
  //         loadingGa_call();
  //       }, 100);
  //     }
  //   } catch (e) {}

  //   try {
  //     let gpGaEventClickClass = $('.gp_className');
  //     gpGaEventClickClass.each(function (n) {
  //       if (this.nodeName.toLowerCase() == 'select') {
  //         gpGaEventClickClass.eq(n).bind('change', function () {
  //           let gpGaEventAttrCategory = this.getAttribute('ga-category');
  //           let gpGaEventAttrAction = this.getAttribute('ga-action');
  //           let gpGaEventAttrLabel = this.getAttribute('ga-label');
  //           if (gpGaEventAttrLabel) {
  //             try {
  //               let label = this.options[this.selectedIndex].innerText.replace(
  //                 /\s/g,
  //                 '',
  //               );
  //               gpGaEventAttrLabel = gpGaEventAttrLabel.replace(
  //                 '_value',
  //                 '_' + label,
  //               );
  //             } catch (e) {}
  //           }

  //           if (
  //             gpGaEventAttrCategory !== null &&
  //             gpGaEventAttrAction !== null
  //           ) {
  //             if (
  //               gpGaEventAttrLabel !== null ||
  //               gpGaEventAttrLabel !== undefined
  //             ) {
  //               GA_Event(
  //                 gpGaEventAttrCategory,
  //                 gpGaEventAttrAction,
  //                 gpGaEventAttrLabel,
  //               );
  //             } else {
  //               GA_Event(gpGaEventAttrCategory, gpGaEventAttrAction, undefined);
  //             }
  //           }

  //           if (
  //             gpGaEventAttrCategory === null &&
  //             gpGaEventAttrAction === null
  //           ) {
  //             //console.log('gp_className 클래스를 가진 태그에 카테고리와 액션을 추가해주세요.');
  //           }
  //         });
  //       } else {
  //         //  a radio check
  //         gpGaEventClickClass.eq(n).click(function () {
  //           let gpGaEventAttrCategory = this.getAttribute('ga-category');
  //           let gpGaEventAttrAction = this.getAttribute('ga-action');
  //           let gpGaEventAttrLabel = this.getAttribute('ga-label');

  //           if (
  //             gpGaEventAttrCategory !== null &&
  //             gpGaEventAttrAction !== null
  //           ) {
  //             if (
  //               gpGaEventAttrLabel !== null ||
  //               gpGaEventAttrLabel !== undefined
  //             ) {
  //               GA_Event(
  //                 gpGaEventAttrCategory,
  //                 gpGaEventAttrAction,
  //                 gpGaEventAttrLabel,
  //               );
  //             } else {
  //               GA_Event(gpGaEventAttrCategory, gpGaEventAttrAction, undefined);
  //             }
  //           }
  //           if (
  //             gpGaEventAttrCategory === null &&
  //             gpGaEventAttrAction === null
  //           ) {
  //             //console.log('gp_className 클래스를 가진 태그에 카테고리와 액션을 추가해주세요.');
  //           }
  //         });
  //       }
  //     });
  //   } catch (e) {}
  //   //return false;
  // });
  (function () {
    try {
      if (loadingGaCategory) {
        // 페이지 진입시점 ga 호출
        function loadingGa_call() {
          if (gaInitCall) {
            try {
              if (loadingGaData) {
                let mass = {
                  category: loadingGaCategory,
                  action: loadingGaAction,
                  label: loadingGaLabel,
                };
                if (loadingGaData[13]) mass[13] = loadingGaData[13];
                if (loadingGaData[14]) mass[14] = loadingGaData[14];
                GA_Event_Data(mass);
              }
            } catch (e) {
              GA_Event(loadingGaCategory, loadingGaAction, loadingGaLabel);
            }
          } else {
            setTimeout(function () {
              loadingGa_call();
            }, 100);
          }
        }
        setTimeout(function () {
          loadingGa_call();
        }, 100);
      }
    } catch (e) {}

    try {
      let gpGaEventClickClass = document.getElementsByClassName('gp_className');
      gpGaEventClickClass.each(function (n) {
        if (this.nodeName.toLowerCase() == 'select') {
          gpGaEventClickClass.eq(n).bind('change', function () {
            let gpGaEventAttrCategory = this.getAttribute('ga-category');
            let gpGaEventAttrAction = this.getAttribute('ga-action');
            let gpGaEventAttrLabel = this.getAttribute('ga-label');
            if (gpGaEventAttrLabel) {
              try {
                let label = this.options[this.selectedIndex].innerText.replace(
                  /\s/g,
                  '',
                );
                gpGaEventAttrLabel = gpGaEventAttrLabel.replace(
                  '_value',
                  '_' + label,
                );
              } catch (e) {}
            }

            if (
              gpGaEventAttrCategory !== null &&
              gpGaEventAttrAction !== null
            ) {
              if (
                gpGaEventAttrLabel !== null ||
                gpGaEventAttrLabel !== undefined
              ) {
                GA_Event(
                  gpGaEventAttrCategory,
                  gpGaEventAttrAction,
                  gpGaEventAttrLabel,
                );
              } else {
                GA_Event(gpGaEventAttrCategory, gpGaEventAttrAction, undefined);
              }
            }

            if (
              gpGaEventAttrCategory === null &&
              gpGaEventAttrAction === null
            ) {
              //console.log('gp_className 클래스를 가진 태그에 카테고리와 액션을 추가해주세요.');
            }
          });
        } else {
          //  a radio check
          gpGaEventClickClass.eq(n).click(function () {
            let gpGaEventAttrCategory = this.getAttribute('ga-category');
            let gpGaEventAttrAction = this.getAttribute('ga-action');
            let gpGaEventAttrLabel = this.getAttribute('ga-label');

            if (
              gpGaEventAttrCategory !== null &&
              gpGaEventAttrAction !== null
            ) {
              if (
                gpGaEventAttrLabel !== null ||
                gpGaEventAttrLabel !== undefined
              ) {
                GA_Event(
                  gpGaEventAttrCategory,
                  gpGaEventAttrAction,
                  gpGaEventAttrLabel,
                );
              } else {
                GA_Event(gpGaEventAttrCategory, gpGaEventAttrAction, undefined);
              }
            }
            if (
              gpGaEventAttrCategory === null &&
              gpGaEventAttrAction === null
            ) {
              //console.log('gp_className 클래스를 가진 태그에 카테고리와 액션을 추가해주세요.');
            }
          });
        }
      });
    } catch (e) {}
    //return false;
  })();
}
