import axios from 'axios';
import { ceil, floor, isArray, toNumber } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { openReloadPopup } from '@store/app/commonReducer';
import store from '@store/store';

export const getStockLogoImage = stockCode => {
  // const code = stockCode.replace('A', '');
  const code = stockCode;

  return `https://downloadcdn.nhqv.com/mts/ci/ss_img_ci_${code}.png`;
};

export const getUploadImage = fileName => {
  if (!fileName) return fileName;
  if (String(fileName).startsWith('data:image')) return fileName;
  // return /^(https?|ftp):\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
  //   decodeURIComponent(fileName),
  // )
  //   ? decodeURIComponent(fileName)
  //   : `${
  //       process.env.REACT_APP_API_URL
  //     }/file/display?fileName=${encodeURIComponent(fileName)}`;

  if (
    /^(https?|ftp):\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
      decodeURIComponent(fileName),
    )
  ) {
    return decodeURIComponent(fileName);
  } else {
    return `${
      process.env.REACT_APP_API_URL
    }/file/display?fileName=${encodeURIComponent(fileName)}`;
  }
};

export const roundStockQty = stockQty => {
  const stockNum = toNumber(stockQty);
  return stockNum >= 1
    ? { stockQty: floor(stockNum).toLocaleString(), stockPostFix: '주' }
    : { stockQty: ceil(stockNum).toLocaleString(), stockPostFix: '주 미만' };
};

export const convertPostContents = (contents, links) => {
  let convContents = contents;

  const escapedLink = links.map(link =>
    link.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
  );

  const regex = new RegExp(escapedLink.join('|'), 'gi');

  convContents = convContents.replace(regex, match => {
    return `<a href="${match}" target="_blank" rel="noreferrer">${match}</a>`;
  });

  return convContents;
};

export const callMTSFunction = (action, data) => {
  let callUrl = `hybrid://SendDataToForm/{"FunctionName":"OnReceiveData","Data":"${action}`;

  if (isArray(data)) {
    data.forEach(item => {
      callUrl += `^${encodeURIComponent(item)}`;
    });
  } else {
    callUrl += `^${encodeURIComponent(data)}`;
  }

  callUrl += '"}';
  console.log('callMTSFunction', callUrl, data);

  window.location.href = callUrl;
};

export const getBadgeNoneLocation = badgeCd => {
  return `badge%2F${badgeCd}_NONE.png`;
};

export const getFileExtension = filename => {
  // const filename = 'file.backup.txt';
  const lastDotIndex = filename.lastIndexOf('.');
  let extension = '';
  if (lastDotIndex !== -1) {
    extension = '.' + filename.substring(lastDotIndex + 1);
  }
  return extension;
};

export const checkTextWithKiso = async str => {
  if (typeof str !== 'string') return;
  let myHeaders = new Headers();
  myHeaders.append('x-api-key', '56b7445ffde154f4beea989c8492438c');
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  let urlencoded = new URLSearchParams();
  urlencoded.append('text', str);
  urlencoded.append('mode', 'normal');

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    // redirect: 'follow',
  };
  try {
    let result = await fetch(
      'https://www.safekiso.com/api/v1/filter',
      requestOptions,
    );
    if (result.ok) {
      let res = await result.json();
      return res?.Detected.map(item => (item?.[1] ? item?.[1] : item));
    }
  } catch (error) {
    return [];
  }
};

const verifyGreenEyeResult = data => {
  let isConfidenceData;
  let inconfidenceItem = data.find(item => {
    let result = {
      adult: item?.result?.adult?.confidence,
      normal: item?.result?.normal?.confidence,
      porn: item?.result?.porn?.confidence,
      sexy: item?.result?.sexy?.confidence,
    };
    return result.adult >= 0.7 || result.porn >= 0.7 || result.sexy >= 0.7;
  });
  return inconfidenceItem
    ? {
        isConfidence: false,
        isConfidenceData: {
          adult:
            (inconfidenceItem?.result?.adult?.confidence * 100).toFixed(2) +
            '% ',
          normal:
            (inconfidenceItem?.result?.normal?.confidence * 100).toFixed(2) +
            '% ',
          porn:
            (inconfidenceItem?.result?.porn?.confidence * 100).toFixed(2) +
            '% ',
          sexy:
            (inconfidenceItem?.result?.sexy?.confidence * 100).toFixed(2) +
            '% ',
        },
      }
    : { isConfidence: true, isConfidenceData };
};
export const checkImageUrlValidWithGreenEyeAPI = async urlList => {
  // is valid = true, invalid = false
  const uuId = uuidv4();
  let myHeaders = new Headers();
  myHeaders.append(
    'X-GREEN-EYE-SECRET',
    'dEpLRXF3RFZoQlNhT2hWeHJrS3FmeFprU29Ia3pMU1c=',
  );
  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: {
      version: 'V1',
      requestId: uuId,
      timestamp: 0,
      images: urlList.map((url, index) => {
        return {
          name: `check Image Url ${index} ${uuId}`,
          url: url,
        };
      }),
    },
  };

  try {
    const result = await axios.post(
      'https://devgardenapi.nhqv.com/file/green-eye',
      requestOptions.body,
      {
        headers: myHeaders,
      },
    );
    let { isConfidence, isConfidenceData } = verifyGreenEyeResult(
      result?.data?.images,
    );
    // return false if image inconfidence
    return { isConfidence, isConfidenceData };
  } catch (error) {
    return { isConfidence: true, isConfidenceData: {} };
  }
};
export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const isDev = () => {
  let a = 'localhost';
  let b = '221.168.32.173';
  let c = 'devgarden.nhqv.com';
  return [a, b, c].includes(window.location.hostname);
};

export const showNewVersionPopup = () => {
  store.dispatch(openReloadPopup());
};
