import { css } from '@emotion/react';
import { ReactComponent as DeleteIcon } from '@asset/icons/common/delete-icon.svg';
import { getSearchList } from '@lib/api-call/app/searchApi';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Input as BaseInput, inputClasses } from '@mui/base/Input';
import { ReactComponent as IconSearch } from '@asset/icons/search/icon_search.svg';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { globalStyle, toVW, stringToVW } from '@style/globalStyle';
import SvgIcon from '@mui/material/SvgIcon';
const Input = styled(BaseInput)`
  flex: 1 0 0;
  height: 100%;

  & .${inputClasses.input} {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;

    color: #000;
    ${globalStyle.fontNanu21}
    font-weight: 400;
    caret-color: #84c13d;
  }

  & .${inputClasses.input} {
    &::placeholder {
      ${globalStyle.fontNanu21};
      letter-spacing: 0px;
      color: #999;
      opacity: 1;
    }
  }
`;

const SelectChannelWrapper = props => {
  const {
    selectedChannel,
    setSelectedChannel,
    searchChannelList,
    isClick,
    setIsClick,
  } = props;

  const isUpdatePost = useSelector(state => state.post.isUpdatePost);

  const [searchChList, setSearchChList] = useState(undefined);
  const [keyword, setKeyword] = useState('');

  const inputRef = useRef(null);

  const placeholderBefore = '종목/채널을 검색해주세요';
  const placeholderAfterClick = '종목/채널을 검색해주세요';

  const styles = {
    wrapper: css`
      display: flex;
      padding: ${stringToVW('8px 16px')};
      align-items: center;
      gap: ${stringToVW('12px')};
      align-self: stretch;
    `,
    channel: css`
      cursor: ${!selectedChannel ? 'pointer' : 'default'};
      display: flex;
      height: ${stringToVW('40px')};
      padding-right: ${stringToVW('16px')};
      padding-left: ${!selectedChannel
        ? !isClick
          ? toVW('12px')
          : toVW('20px')
        : toVW('10px')};
      align-items: center;
      gap: ${stringToVW('6.7px')};
      width: 100%;
      border-radius: 1000px;
      background: #f5f5f5;
    `,
    placeholder: css`
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      gap: ${stringToVW('10px')};
      flex: 1 0 0;
      color: #999;
      ${globalStyle.fontNanu21}
    `,
    chip: css`
      display: flex;
      height: ${stringToVW('25px')};
      padding: ${stringToVW('2px 10px 0px 10px')};
      justify-content: flex-end;
      align-items: center;
      gap: ${stringToVW('10px')};
      border-radius: 1000px;
      background: #fff;
      color: #666;
      text-align: center;
      ${globalStyle.fontNanu18}
    `,
    channelInfo: css`
      display: flex;
      height: ${stringToVW('30px')};
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      gap: ${stringToVW('5px')};
      flex: 1 0 0;
    `,
    channelName: css`
      color: #000;
      ${globalStyle.fontNanu21}
      font-weight: 400;
      line-height: normal;
      flex: 1 1 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    searchItem: css`
      padding: ${stringToVW('11px 16px')};
      align-self: stretch;
      color: #333;
      ${globalStyle.fontNanu21}
    `,
  };

  const handleAutoSearch = word => {
    if (!word.trim()) {
      setSearchChList([]);
      setKeyword('');
      return;
    }
    setKeyword(word);

    if (/[a-zA-Z0-9]/.test(word)) {
      if (word.length < 3) {
        return;
      }
    }
    const chnl = searchChannelList.filter(
      channel =>
        channel.channel_nm.toLowerCase().includes(word.toLowerCase()) ||
        channel.stock_cd.toLowerCase().includes(word.toLowerCase()),
    );
    setSearchChList(chnl);
  };

  const onSearch = e => {
    if (isEmpty(keyword) || e?.key !== 'Enter') return;

    (async () => {
      const { chnl } = await getSearchList({
        keyword: keyword,
        type: 'channel',
      });
      if (chnl && isArray(chnl)) {
        setSearchChList(chnl);
      }
    })();
  };

  useEffect(() => {
    if (isClick) {
      inputRef.current.focus();
    } else {
      setKeyword('');
      setSearchChList(undefined);
    }
  }, [isClick]);

  useEffect(() => {}, []);

  return (
    <>
      <div css={styles.wrapper}>
        <div css={styles.channel}>
          {!isClick ? (
            !selectedChannel ? (
              <div
                css={styles.placeholder}
                onClick={() => {
                  setIsClick(true);
                }}
              >
                <IconSearch
                  css={css`
                    width: ${toVW('17.812px')};
                    height: ${toVW('18.929px')};
                  `}
                />
                <span>{placeholderBefore}</span>
              </div>
            ) : (
              <>
                <div
                  css={styles.chip}
                  onClick={() => {
                    setIsClick(true);
                    setKeyword(selectedChannel.channel_nm);
                  }}
                >
                  채널명
                </div>
                <div css={styles.channelInfo}>
                  <div css={styles.channelName}>
                    {selectedChannel.stock_cd === 'ZZZ0001' &&
                    selectedChannel.channel_id === 0
                      ? '자유토론방'
                      : selectedChannel.channel_nm}
                    {/* {selectedChannel.channel_nm} */}
                  </div>
                  {!isUpdatePost && (
                    <DeleteIcon
                      css={css`
                        cursor: pointer;
                        height: ${toVW('16px')};
                      `}
                      onClick={() => {
                        setSelectedChannel(null);
                      }}
                    />
                  )}
                </div>
              </>
            )
          ) : (
            <>
              <IconSearch
                css={css`
                  width: ${toVW('17.812px')};
                  height: ${toVW('18.929px')};
                `}
              />
              <Input
                ref={el => {
                  if (el) {
                    inputRef.current = el.firstChild;
                  }
                }}
                placeholder={placeholderAfterClick}
                value={keyword}
                onChange={e => handleAutoSearch(e.target.value)}
                onKeyDown={e => onSearch(e)}
                //onBlur={e => setIsClick(false)}
              />
              <DeleteIcon
                css={css`
                  cursor: pointer;
                  height: ${toVW('16px')};
                `}
                onClick={() => {
                  setIsClick(false);
                }}
              />
            </>
          )}
        </div>
      </div>
      {isClick && searchChList && (
        <div
          css={css`
            display: flex;
            margin-top: ${toVW('8px')};
            flex-direction: column;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
          `}
        >
          {!isEmpty(searchChList) ? (
            searchChList.map((channel, index) => (
              <div
                key={`search_ch_${index}`}
                css={[
                  styles.searchItem,
                  css`
                    cursor: pointer;
                  `,
                ]}
                onClick={() => {
                  setSelectedChannel(channel);
                  setIsClick(false);
                }}
              >
                {channel.channel_nm
                  .split(new RegExp(`(${keyword})`, 'gi'))
                  .map((part, i) =>
                    part.toLowerCase() === keyword.toLowerCase() ? (
                      <strong style={{ color: '#84C13D' }} key={i}>
                        {part}
                      </strong>
                    ) : (
                      part
                    ),
                  )}
              </div>
            ))
          ) : (
            <div
              css={[
                styles.searchItem,
                css`
                  color: #ff7d80;
                `,
              ]}
            >
              검색 결과가 없어요. 다른 채널명을 입력해주세요.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SelectChannelWrapper;
