import { createSlice } from '@reduxjs/toolkit';

export const channelSlice = createSlice({
  name: 'channel',
  initialState: {
    topRankChannel: [],
    postList: undefined,
    postIsLast: false,
    lastPostId: null,
    searchList: [],
    clickedPostId: undefined,
  },
  reducers: {
    updateTopRankChannel(state, action) {
      state.topRankChannel = action.payload;
    },
    updateSearchList(state, action) {
      state.searchList = action.payload;
    },
    setClickedChannelPostId(state, action) {
      state.clickedPostId = action.payload;
    },
  },
});

export const {
  updateTopRankChannel,
  updateSearchList,
  setClickedChannelPostId,
} = channelSlice.actions;

export default channelSlice.reducer;
