import { css } from '@emotion/react';
import { stringToVW } from '@style/globalStyle';

const settingsStyles = {
  title: css`
    color: #000;
    font-size: ${stringToVW('17px')};
    font-weight: 600;
    line-height: ${stringToVW('25px')};
  `,
  subTitle: css`
    color: #000;

    font-size: ${stringToVW('16px')};
    font-weight: 600;
    line-height: ${stringToVW('23px')};
  `,
  content: css`
    color: #333;
    font-size: ${stringToVW('14px')};
    font-weight: 400;
    line-height: ${stringToVW('21px')};
  `,
};

export default settingsStyles;
