import { Link, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import channelDefaultImg from '@asset/images/channel/channel-default-image.png';
import channelDefaultImgKOR from '@asset/images/channel/channel-default-image-KOR.png';
import channelDefaultImgENG from '@asset/images/channel/channel-default-image-ENG.png';
import { globalStyle, stringToVW, toVW, ratio } from '@style/globalStyle';
import { getUploadImage } from '@lib/commonUtils';
const countryChnlImage = country => {
  if (country === 'KOR') return channelDefaultImgKOR;
  else if (country === 'USA') return channelDefaultImgENG;
  else return channelDefaultImg;
};

const PopularChannelList = ({ channelList }) => {
  const { pathname } = useLocation();
  const styles = {
    container: css`
      display: flex;
      padding: ${stringToVW('21.3px 16px')};
      flex-direction: column;
      align-items: flex-start;
      gap: ${toVW('8px')};
      align-self: stretch;
    `,
    header: css`
      /* text/title/bold */
      display: flex;
      align-items: start;
      gap: ${toVW('4px')};
      ${globalStyle.fontNanu24}
      font-weight: 600;
    `,
    overlapContainer: css`
      height: ${toVW('124px')};
      position: relative;
    `,
    logoContainer: css`
      align-items: center;
      background-position: right bottom;
      background-size: 100%;
      display: flex;
      justify-content: end;
      align-items: start;
      right: 0;
      top: 0;
      position: absolute;
      overflow: hidden;
    `,
    logo: css`
      height: ${toVW('27px')};
      width: ${toVW('27px')};
      object-fit: cover;
      border-radius: 50%;
      margin: ${stringToVW('8px 8px 0 0')};
    `,
    background: css`
      position: absolute;
      width: 100%;

      height: ${toVW('124px')};
    `,
    channelInfo: css`
      width: ${toVW('144px')};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      left: ${toVW('10px')};
      top: ${toVW('49px')};
      bottom: ${toVW('18px')};
      position: absolute;
      gap: ${toVW('1.5px')};
    `,
    newPostText: css`
      display: flex;
      height: ${toVW('18px')};
      padding: 0;
      align-items: baseline;
      gap: ${toVW('2px')};
      align-self: stretch;
      color: #666;
    `,
  };

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        {/* <img style={{ height: toVW('16px') }} src={LoungeFire} alt="fire" /> */}
        <span>오늘의 인기 채널 TOP5</span>
      </div>

      {channelList && Array.isArray(channelList) && channelList.length > 0 && (
        <Swiper
          style={{
            width: `calc(100% + ${toVW('30px')}`,
            margin: `0px ${toVW('-15px')}`,
            padding: `0px ${toVW('15px')}`,
          }}
          loop={false}
          slidesPerView="auto"
          spaceBetween={10.7 * ratio}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode]}
        >
          {channelList.map((channel, idx) => (
            <SwiperSlide
              style={{
                width: toVW('134px'),
                height: toVW('100px'),
                background: '#fff',
                borderRadius: toVW('16px'),
                overflow: 'hidden',
              }}
              key={`topRankChannel${idx}`}
            >
              <Link
                to={`/channel/${encodeURIComponent(
                  channel.stock_cd,
                )}/${encodeURIComponent(channel.channel_id)}`}
                state={{ prevLink: pathname }}
              >
                <div css={styles.overlapContainer}>
                  <div css={styles.logoContainer}>
                    <img
                      css={styles.logo}
                      alt="ci"
                      src={
                        (channel.thumb_url &&
                          getUploadImage(channel.thumb_url)) ||
                        countryChnlImage(channel.country_cd)
                      }
                      onError={e => {
                        e.target.src = countryChnlImage(channel.country_cd);
                      }}
                    />
                  </div>

                  <div css={styles.channelInfo}>
                    <div
                      css={css`
                        width: ${toVW('112px')};
                        text-overflow: ellipsis;
                        text-align: baseline;
                        white-space: nowrap;
                        overflow: hidden;
                        color: #000;
                        ${globalStyle.fontNanu21}
                        font-weight: 600;
                      `}
                    >
                      {channel.channel_nm}
                    </div>
                    <div css={styles.newPostText}>
                      <div
                        css={css`
                          ${globalStyle.fontNanu18}
                          font-weight: 400;
                        `}
                      >
                        오늘
                      </div>
                      <div
                        css={css`
                          ${globalStyle.fontRobo18}
                          font-weight: 500;
                        `}
                      >
                        {channel.new_post}
                      </div>
                      <div
                        css={css`
                          ${globalStyle.fontNanu18}
                          font-weight: 400;
                        `}
                      >
                        개의 새 글
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
export default PopularChannelList;
