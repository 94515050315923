import React, { useState } from 'react';
import { css } from '@emotion/react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import HeaderBasic from '@component/app/common/HeaderBasic';
import { globalStyle, stringToVW } from '@style/globalStyle';

const NotificationContact = () => {
  const [content, setContent] = useState('');
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    ${globalStyle.fontNanu21}
    font-weight: 400;
    padding: ${stringToVW('12px 12px 12px 12px')};
    border-radius: ${stringToVW('10px')};
    color: ${theme.palette.mode === 'dark' ? '#000000' : '#000000'};
    background: ${theme.palette.mode === 'dark' ? grey[100] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };
     resize: none;
    ::placeholder {
      color: #C5C5C5;
    }
    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  return (
    <div
      css={css`
        padding: 0px var(--2415-rem, 15px);
        position: relative;
      `}
    >
      <HeaderBasic title="문의하기" statusUser="" />
      <div
        css={css`
          width: 100%;
          height: 100%;
          position: relative;
        `}
      >
        <label
          css={css`
            position: absolute;
            bottom: ${stringToVW('20px')};
            right: ${stringToVW('20px')};
            color: #7d7d7d;
          `}
          htmlFor="Textarea"
        >
          {content.length}/300
        </label>
        <Textarea
          id="Textarea"
          minRows={8}
          aria-label="empty textarea"
          placeholder="문의사항을 입력해주세요."
          onChange={e => {
            setContent(e.target.value);
          }}
        />
      </div>
      <Textarea
        aria-label="empty textarea"
        placeholder="답변을 전달받을 이메일을 입력해주세요. "
      />
    </div>
  );
};

export default NotificationContact;
