import { updateLoginedAdmin } from '@store/app/adminReducer';
import axiosUtils, {
  getAdminIdParam,
  getAdminIdParam2,
} from '../../axiosUtils';
import { removeCookie } from '@lib/cookieUtils';

export const getMemberList = async param => {
  return await axiosUtils
    .post('/admin/memlist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};

export const getMemberView = async param => {
  return await axiosUtils
    .post('/admin/memview', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};

export const withdrawMember = async body => {
  return await axiosUtils
    .post('/admin/withdrawal', body, { headers: getAdminIdParam2() })
    .then(res => {
      return res.data;
    });
};

export const getMemberBadgeList = async param => {
  return await axiosUtils
    .post('/admin/membadgelist', { ...param }, { headers: getAdminIdParam2() })
    .then(res => {
      if (res.data.message_cd === '10') {
        updateLoginedAdmin(false);
        removeCookie('admin_refresh_token', { path: '/' });
        removeCookie('admin_access_token', { path: '/' });
        window.location.href = '/admin';
        return;
      }
      return res.data;
    });
};
