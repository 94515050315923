import { css } from '@emotion/react';

import ProfileAvatar from '@component/app/user/ProfileAvatar';
import Chip from '@component/app/common/Chip';
import { Chip as MuiChip } from '@mui/material';

import { ReactComponent as DotIcon } from '@asset/icons/common/dot.svg';
import IconCerti from '@asset/icons/common/icon-certification.svg';
import { getUploadImage, roundStockQty } from '@lib/commonUtils';
import { elapsedTime, getStockHoldingDate } from '@lib/dateTimeUtils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { noDecoraionLink } from '@style/common/commonStyles';
import { isEmpty } from 'lodash';
import { globalStyle, stringToVW, toVW, ratio } from '@style/globalStyle';
import {
  setClickedPostId,
  setClickedWrittenPostId,
} from '@store/app/postReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setClickedChannelPostId } from '@store/app/channelReducer';

//TODO: 숫자데이터 콤마처리 해야함
const PostItemHeader = ({ item, isChDetail = false, isProfile = false }) => {
  const {
    user_type, // 필요
    profile_img,
    user_nm,
    channel_id,
    stock_cd,
    channel_nm, //필요 --> channel_id 0일때 자유채널인지 확인 필요
    stock_qty,
    stock_holding_dt,
    reg_dt,
    mod_dt,
  } = item;

  const { betweenTime, postFix } = elapsedTime(mod_dt || reg_dt);
  const { stockQty, stockPostFix } = roundStockQty(stock_qty);
  const { pathname } = useLocation();

  const styles = {
    container: css`
      width: 100%;
      display: flex;
      align-items: center;
      gap: ${toVW('12px')};
      flex: 1 0 0;
    `,
    writerInfo: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 1 0 0;
    `,
    writerTitle: css`
      display: flex;
      height: ${toVW('26px')};
      align-items: center;
      gap: ${toVW('4px')};
      align-self: stretch;
    `,
    writerSubtitle: css`
      display: flex;
      align-items: center;
      gap: ${toVW('4px')};
    `,
    writeDateContainer: css`
      color: #7d7d7d;
      display: flex;
      align-items: center;
    `,
    stockInfoContainer: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: ${toVW('6px')};
    `,
    stockHasDate: css`
      color: #7d7d7d;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `,
    stockAmount: css`
      color: #3c9800;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: ${toVW('3px')};
    `,
  };

  const dispatch = useDispatch();

  return (
    <div css={styles.container}>
      <div
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Link
          css={[
            noDecoraionLink,
            css`
              display: block;
              position: relative;
            `,
          ]}
          onClick={() => {
            window.GA_Event('커뮤니티', '공통', '프로필 조회');
            if (!isChDetail && !isProfile) {
              dispatch(setClickedPostId(item.post_id));
            } else if (isProfile) {
              dispatch(setClickedWrittenPostId(item.post_id));
            } else {
              dispatch(setClickedChannelPostId(item.post_id));
            }
          }}
          to={`/profile/${encodeURIComponent(item.user_id)}`}
          state={{ prevLink: pathname }}
        >
          <ProfileAvatar
            width={40 * ratio}
            height={40 * ratio}
            userInfo={{
              userName: user_nm,
              filePath: getUploadImage(profile_img),
            }}
          />
          {user_type !== '1' && stock_qty && stock_holding_dt && (
            <MuiChip
              sx={{
                position: 'absolute',
                height: toVW('16px'),
                bottom: toVW('-8px'),
                left: '50%',
                transform: 'translate(-50%, -0%)',
                border: '1.5px solid #FFF',
                color: '#FFF',
                background: '#84C13D',
                fontFamily: 'NanumBarunGothic',
                fontSize: toVW('9px'),
                lineHeight: toVW('9px'),
                '& .MuiChip-label': {
                  padding: stringToVW('3px 6.5px'),
                },
              }}
              label="주주"
            />
          )}
        </Link>
      </div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <div css={styles.writerInfo}>
          <div
            css={styles.writerTitle}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Link
              css={noDecoraionLink}
              onClick={() => {
                window.GA_Event('커뮤니티', '공통', '프로필 조회');
                if (!isChDetail && !isProfile) {
                  dispatch(setClickedPostId(item.post_id));
                } else if (isProfile) {
                  dispatch(setClickedWrittenPostId(item.post_id));
                } else {
                  dispatch(setClickedChannelPostId(item.post_id));
                }
              }}
              to={`/profile/${encodeURIComponent(item.user_id)}`}
              state={{ prevLink: pathname }}
            >
              <div
                css={css`
                  ${globalStyle.fontNanu19}
                  font-weight: 600;
                  color: #000;
                `}
              >
                {user_nm || '-'}
              </div>
            </Link>
            {user_type === '1' && (
              <img
                style={{ width: toVW('13.33px') }}
                src={IconCerti}
                alt="certification"
              />
            )}
          </div>
          {user_type !== '1' &&
            stock_qty &&
            stock_holding_dt &&
            stock_holding_dt !== '0' && (
              <div css={styles.stockHasDate}>
                <span
                  css={css`
                    ${globalStyle.fontRobo18}
                    font-weight: 400;
                  `}
                >
                  {getStockHoldingDate(stock_holding_dt)}
                </span>
                <span
                  css={css`
                    ${globalStyle.fontNanu18}
                    font-weight: 400;
                  `}
                >
                  일째 보유중
                </span>
              </div>
            )}
        </div>
        <div css={styles.stockInfoContainer}>
          <div css={styles.writerSubtitle}>
            {!isEmpty(stock_cd) && (
              <>
                <div
                  css={css`
                    max-width: ${toVW('120px')};
                  `}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {!isChDetail && channel_nm != null ? (
                    <Link
                      css={[
                        noDecoraionLink,
                        css`
                          overflow: hidden;
                        `,
                      ]}
                      onClick={() => {
                        if (!isProfile) {
                          dispatch(setClickedPostId(item.post_id));
                        } else {
                          dispatch(setClickedWrittenPostId(item.post_id));
                        }
                      }}
                      to={`/channel/${encodeURIComponent(
                        stock_cd,
                      )}/${encodeURIComponent(channel_id)}`}
                      state={{ prevLink: pathname }}
                    >
                      <Chip
                        size="sm"
                        propStyle={css`
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        `}
                        text={channel_nm}
                      />
                    </Link>
                  ) : (
                    <Chip
                      size="sm"
                      propStyle={css`
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      `}
                      text={channel_nm || '삭제된 채널'}
                    />
                  )}
                </div>
                <DotIcon />
              </>
            )}

            <div css={styles.writeDateContainer}>
              {betweenTime && (
                <span
                  css={css`
                    ${globalStyle.fontRobo18}
                    font-weight: 400;
                  `}
                >
                  {betweenTime}
                </span>
              )}
              {postFix && (
                <span
                  css={css`
                    ${globalStyle.fontNanu18}
                    font-weight: 400;
                  `}
                >
                  {postFix}
                </span>
              )}
            </div>
          </div>
          {user_type !== '1' && stock_qty && stock_holding_dt && (
            <div css={styles.stockAmount}>
              <span
                css={css`
                  ${globalStyle.fontRobo18}
                  font-weight: 400;
                `}
              >
                {stockQty}
              </span>
              <span
                css={css`
                  ${globalStyle.fontNanu18}
                  font-weight: 400;
                `}
              >
                {stockPostFix}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostItemHeader;
