import { isArray } from 'lodash';
import axiosUtils from '../../axiosUtils';
import { getFileExtension } from '@lib/commonUtils';

/**
 * 
 * @param {} files  file array
 * @returns
 * [
      {
          "imageURL": "2023%5C12%5C05%2F08a10a38-3eac-4ea3-90d7-d8d4c01b5ba4_%ED%8F%B0%ED%8A%B82.jpg",
          "fileName": "폰트2.jpg",
          "uuid": "08a10a38-3eac-4ea3-90d7-d8d4c01b5ba4",
          "folderPath": "2023\\12\\05"
      },
      {
          "imageURL": "2023%5C12%5C05%2Fe34de664-cad3-4d2f-ae76-753bcb946d97_%ED%8F%B0%ED%8A%B83.jpg",
          "fileName": "폰트3.jpg",
          "uuid": "e34de664-cad3-4d2f-ae76-753bcb946d97",
          "folderPath": "2023\\12\\05"
      }
    ]
 */
export const uploadFiles = async param => {
  let files;

  if (typeof param === 'FileList') {
    files = Array.from(param);
  } else {
    files = param;
  }

  const formData = new FormData();

  if (isArray(files)) {
    files.forEach(f => formData.append('uploadFiles', f));
  } else {
    formData.append('uploadFiles', files);
  }

  return await axiosUtils
    .post('/file/uploadAjax', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      transformRequest: (data, config) => {
        return data;
      },
    })
    .then(res => res.data);
};

export const getFileFromApi = async fileName => {
  return await axiosUtils
    .get('/file/display', { fileName }, { responseType: 'arraybuffer' })
    .then(response => {
      console.log('then', response);
      try {
        const { data } = response;
        const splitLocation = decodeURIComponent(fileName).split('/');
        let name = splitLocation[splitLocation.length - 1]; // 파일 이름

        const uuid = name.match(
          /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i,
        );
        if (uuid) {
          console.log(uuid[0]); // 추출된 UUID 출력
          name = name.replace(uuid[0] + '_', '');
        } else {
          console.log('UUID not found');
          name = 'temp' + getFileExtension(fileName);
        }

        const blob = new Blob([new Uint8Array(data)], {
          type: response.headers['content-type'],
        });

        const file = new File([blob], name, {
          type: response.headers['content-type'],
        });

        return file;
      } catch {
        return null;
      }
    })
    .catch(e => {
      return null;
    });
};

export const removeFile = async fileName => {
  return await axiosUtils
    .post('/file/removeFile', { fileName })
    .then(res => res.data);
};
